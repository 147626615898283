import React from "react";
import ValueCard from "../../dashboard/ValueCard";
import { Box } from "@mui/material";

const TimeUpCard = ({data, previousDashboardData, selectedTime}) => {
  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <ValueCard title={"Total uptime (hrs)"} data={data} previousDashboardData={previousDashboardData} selectedTime={selectedTime}/>
    </Box>
  );
};

export default TimeUpCard;
