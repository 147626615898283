import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../custom/CustomTable";
import TableFilters from "../charging-sessions/TableFilters";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";

const TableBox = ({ columns, rows, isRowClickable, loading }) => {
  const navigate = useNavigate();
  const [filteredChargingPoints, setFilteredChargingPoints] = useState(rows);
  const [sortColumn, setSortColumn] = useState(null);

  const handleAddNewChargingPoints = () => {
    navigate(MyRoutes.addNewChargingPoint);
  };

  useEffect(() => {
    setFilteredChargingPoints(rows);
  }, [rows]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredChargingPoints(filteredData);
  };

  const myFilters = [
    { value: "name", title: "Name" },
    { value: "station", title: "Station" },
    { value: "status", title: "Status" },
    { value: "device_id", title: "DEVICE ID" },
    { value: "chargingPointType", title: "Charger Type" },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={rows}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
        <Button
          onClick={handleAddNewChargingPoints}
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "160px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            ":hover": {
              background: "#222",
            },
          }}
        >
          + ADD NEW
        </Button>
      </Box>
      <CustomTable
        columns={columns}
        rows={filteredChargingPoints}
        isRowClickable={isRowClickable}
        loading={loading}
        clickableColumns={["name", "station"]}
        actionType={"copy-edit-delete"}
        rowClickPath="/charging_points"
        columnClickPaths={{
          name: "/charging_points",
          station: "/charging_stations",
        }}
        modalHeading={"Charging Points"}
        columnKeyMappings={{
          name: "_id",
          station: "station",
        }}
        statsHeading={"true"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default TableBox;
