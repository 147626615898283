import { Backdrop, Box, Divider, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import Heading from "../../../utils/Heading";
import { formatDateTime } from "../../../utils/FormatDateTime";
import { Link } from "react-router-dom";
import OrgDocModal from "../../Modals/OrgDocModal";

const DocumentTable = ({ orgDocs }) => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenModal = (row) => {
    setOpenViewModal(true);
    setSelectedRow(row);
  };

  const handleCloseViewModal = (e) => {
    e.stopPropagation();
    setOpenViewModal(false);
    setSelectedRow(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Heading text={"Documents uploaded"} />
        </Box>
        <Divider sx={{ paddingTop: "8px" }} />
      </Box>

      {/* table */}

      <Grid container>
        <Grid item md={7}>
          <Box
            sx={{
              display: "flex",
              padding: "8px 16px",
              borderRadius: "4px",
              background: "rgba(41, 45, 51, 0.10)",
            }}
          >
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"10%"}
            >
              S.No
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Date
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"start"}
              width={"40%"}
            >
              Document Name
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"14px"}
              textAlign={"center"}
              width={"30%"}
            >
              Action
            </Typography>
          </Box>

          {orgDocs?.map((orgDoc, index) => (
            <React.Fragment>
              <Box
                sx={{
                  padding: "12px 16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"10%"}
                >
                  {index + 1}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  {formatDateTime(orgDoc.createdAt).date +
                    " " +
                    formatDateTime(orgDoc.createdAt).time}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  {orgDoc?.name}
                </Typography>
                <Box
                  sx={{
                    width: "30%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid #C0C0C0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenModal(orgDoc)}
                  >
                    <span
                      style={{ color: "#790000", fontWeight: "200" }}
                      className="material-symbols-outlined"
                    >
                      visibility
                    </span>
                  </Box>

                  <Link
                    to={orgDoc?.document?.url}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Box
                      sx={{
                        borderRadius: "4px",
                        border: "1px solid #C0C0C0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{ color: "#292D33", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        download
                      </span>
                    </Box>
                  </Link>
                </Box>
              </Box>
              <Divider />
            </React.Fragment>
          ))}
        </Grid>
      </Grid>

      <OrgDocModal
        open={openViewModal}
        handleClose={handleCloseViewModal}
        data={selectedRow}
        heading={"Org Document"}
      />
    </Box>
  );
};

export default DocumentTable;
