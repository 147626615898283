// store.js

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./action/authSlice";
import profileReducer from "./action/profileSlice";
import tariffReducer from "./action/tariff/createTariffSlice";
import adminReducer from "./action/adminManagement/adminSlice";
import stationsReducer from "./action/stations/stationSlice";
import otherStationsReducer from "./action/stations/otherStationSlice";
import companyReducer from "./action/companyManagement/companyManagementSlice";
import sessionsReducer from "./action/sessions/sessionsSlice";
import chargingPointsReducer from "./action/chargingPointSlice/chargingPointSlice";
import statisticsReducer from "./action/statistics/statisticsSlice";
import dashboardReducer from "./action/dashboard/dashboardSlice";
import paymentReducer from "./action/payments/paymentSlice";
import financeStationsReducer from "./action/financeManager/financeManagerSlice";
import rfidReducer from "./action/RFID/rfidSlice";
import ocpiReducer from "./action/ocpiManagement/ocpiSlice";
import hubManagementReducer from "./action/hubManagement/hubManagementSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    tariff: tariffReducer,
    admin: adminReducer,
    stations: stationsReducer,
    otherStations: otherStationsReducer,
    company: companyReducer,
    sessions: sessionsReducer,
    chargingPoints: chargingPointsReducer,
    statistics: statisticsReducer,
    dashboard: dashboardReducer,
    payment: paymentReducer,
    financeStations: financeStationsReducer,
    rfid: rfidReducer,
    ocpi: ocpiReducer,
    hubManagement: hubManagementReducer,
  },
});

export default store;
