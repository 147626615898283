import { Box, Grid } from "@mui/material";
import React from "react";
import SessionDetailBox1 from "./Details/SessionDetailBox1";
import SessionDetailBox2 from "./Details/SessionDetailBox2";
import SessionDetailBox3 from "./Details/SessionDetailBox3";

const DetailsBox = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <SessionDetailBox1 data={data} />
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <SessionDetailBox2 data={data} />
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <SessionDetailBox3 data={data} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailsBox;
