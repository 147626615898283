// import Layout from "./components/layout/Layout";
// import { allRoutes } from "./routes/routes";
// import { Route, Routes, useNavigate } from "react-router-dom";
// import "./App.css";
// import Login from "./pages/Login/Login";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { getProfile } from "./redux/action/profileSlice";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function App() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const token = localStorage.getItem("token");
//   const { loading, error, profile } = useSelector((state) => state.profile);

//   const [initialLoadComplete, setInitialLoadComplete] = useState(false);

//   useEffect(() => {
//     if (token) {
//       dispatch(getProfile()).finally(() => {
//         setInitialLoadComplete(true);
//       });
//     } else {
//       setInitialLoadComplete(false);
//       navigate("/login");
//     }
//   }, [dispatch, token, navigate]);

//   useEffect(() => {
//     if (initialLoadComplete && !loading && !profile && token) {
//       navigate("/login");
//     }
//   }, [loading, profile, token, navigate, initialLoadComplete]);

//   return (
//     <div className="App">
//       <ToastContainer autoClose={2000} />
//       <Routes>
//         {token ? (
//           allRoutes.map((item) => (
//             <Route
//               key={item.path}
//               path={item.path}
//               element={<Layout>{item.element}</Layout>}
//             />
//           ))
//         ) : (
//           <>
//             <Route path="/login" element={<Login />} />
//             <Route path="*" element={<Login />} />
//           </>
//         )}
//       </Routes>
//     </div>
//   );
// }

// export default App;

import Layout from "./components/layout/Layout";
import { allRoutes, convertCountryRoute } from "./routes/routes";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProfile } from "./redux/action/profileSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmail from "./pages/AuthPages/VerifyEmail/VerifyEmail";
import ResetPassword from "./pages/AuthPages/ResetPassword/ResetPassword";
import CreatePassword from "./pages/AuthPages/CreatePassword/CreatePassword";
import { countryCodeToCountry } from "./utils/currency/countryCodeToCountry";
import { Box, CircularProgress } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const { loading, error, profile } = useSelector((state) => state.profile);

  // const token = true;

  useEffect(() => {
    if (token) {
      dispatch(getProfile());
    } else {
      // Keep the user on the page if they are on auth-related routes
      if (
        !["/verify_email", "/reset_password", "/create_new_password"].includes(
          window.location.pathname
        )
      ) {
        navigate("/login");
      }
    }
  }, [dispatch, token, navigate]);

  const getCountryCode = (phoneNumber) => {
    const countryCodeMatch = phoneNumber?.match(/^\+(\d{1,2})/); // Match country code part with 1 to 4 digits
    return countryCodeMatch ? countryCodeMatch[0] : null; // Return only the country code
  };

  const countyCheck = profile?.phoneNumber;
  const countryCode = getCountryCode(countyCheck);

  // Mapping the country code using the countryCodeToCountry map
  const countryCodeMapped =
    countryCode && countryCodeToCountry[countryCode]
      ? countryCodeToCountry[countryCode]
      : "Unknown"; // Fallback to 'Unknown' if not found

  // Convert route based on country
  const allRoutes = convertCountryRoute(countryCodeMapped);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      // Navigate to a specific page or handle back button click
      navigate(-1); // Replace with your desired path
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  // Navigate to /login if there is no profile
  // useEffect(() => {
  //   // Navigate to /login if not loading and profile is not available
  //   if (!loading && !profile && token) {
  //     navigate("/login");
  //   }
  // }, [loading, profile, token, navigate]);

  if (!profile && loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="App">
      <ToastContainer
        autoClose={2000}
        style={{ width: "100%", maxWidth: "400px" }}
      />
      <Routes>
        {token && profile ? (
          allRoutes.map((item) => (
            <Route
              key={item.path}
              path={`${item.path}`}
              element={
                // newPath === item.path ? (
                <Layout>{item.element}</Layout>
                // ) : (
                //   item.element
                // )
              }
            />
          ))
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/create_new_password" element={<CreatePassword />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
