import {
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Heading from "../../utils/Heading";
import CustomTransition from "../custom/CustomTransition";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Acitve" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

// Utility function to format date and time
const formatDateTime = (isoDateString) => {
  const date = new Date(isoDateString);

  // Format date as "dd-mm-yyyy"
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  // Format time as "hh:mm:ss AM/PM"
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });

  return `${formattedDate} ${formattedTime}`;
};

const ReviewModal = ({ open, onClose, row }) => {
  useEffect(() => {
    console.log("rrr344rr", row);
  }, [row]);
  

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      TransitionComponent={CustomTransition}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img src="/images/reviews.png" alt="" />
          <Heading text={"Review Details"} />
        </Box>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>
        <Divider sx={{ mt: "8px", mb: "48px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <InfoBox label={"User ID"} value={row?.user} />
          <InfoBox label={"Reviewd On"}      value={formatDateTime(row?.created_at)} />

          <InfoBox label={"Review"} value={row?.description} />

          {/* attachments will be havivng images */}
          <InfoBox label={"Attachments"} value={row?.paymentId} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            {/* Check if ratingReviewImages is an array and map over it */}
            {Array.isArray(row.ratingReviewImages) &&
        row.ratingReviewImages.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: '144px',
              overflow: 'hidden',
              borderRadius: '8px',
            }}
          >
            <img
              src={image.url} // Access the url property
              alt={`Review image ${index}`}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
            />
          </Box>
        ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
