import { Box, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import OcpiMainTableBox from "./ManageOCPI/OcpiMainTableBox";
import OcpiRegisterModal from "../Modals/OCPI/OcpiRegisterModal";
import { getDefaultTariffList } from "../../redux/action/ocpiManagement/ocpiSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ManageBox = ({operatorId, networkDetail}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false); // Track registration status
  const dispatch = useDispatch();

  const handleProceedClick = () => {
    setIsModalOpen(true);
  };

  // Function to handle form registration
  const handleRegister = (formData) => {
    console.log("Registered Data:", formData);
    setIsRegistered(true); 
    setIsModalOpen(false); 
  };


  const {
    defaultTariffs,
    loading,
    error,
  } = useSelector((state) => state.ocpi);

  useEffect(() => {
    if (operatorId) {
      dispatch(getDefaultTariffList(operatorId));
    }
  }, [dispatch, operatorId]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <OcpiMainTableBox
                isRegistered={isRegistered}
                onClick={handleRegister}
                operatorId={operatorId}
                networkDetail={networkDetail}
                defaultTariffs={defaultTariffs}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <OcpiRegisterModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRegister={handleRegister} // Pass the handler
      />
    </Box>
  );
};

export default ManageBox;
