import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import Heading from "../../../utils/Heading";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
          sx={{ textWrap: "wrap" }}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
const ConnectorBox = ({ connectors }) => (
  <Box
    sx={{ display: "flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}
  >
    {connectors.map((connector, index) => (
      <Box
        key={index}
        sx={{
          padding: "2px 12px",
          background: "rgba(41, 45, 51, 0.08)",
          maxWidth: "85px",
          width: "100%",
          borderRadius: "85px",
        }}
      >
        <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
          {connector}
        </Typography>
      </Box>
    ))}
  </Box>
);

const OCPPDetails = ({ data }) => {
  console.log("ocpp", data);
  return (
    <Box
      sx={{
        padding: "16px 16px 48px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            page_info
          </span>
          <Heading text={"OCPP (Version: 1.6j)"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* OCPP */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <InfoBox value={"Secure"} />
          <Box sx={{ display: "flex", gap: "24px", alignItems: "center" }}>
            <InfoBox label={"Protocol"} value={"wss"} />
            <InfoBox label={"Device Id"} value={data?.device_id} />
            <InfoBox label={"Port"} value={"443"} />
            <InfoBox label={"Path"} value={"/ws/ocpp/16/"} />
          </Box>
        </Box>
        <InfoBox label={"Host/Domain/Server IP"} value={"1cev.in"} />
        <InfoBox
          label={"Full URL"}
          value={`wss://1cev.in/ws/ocpp/16/${data?.device_id}`}
          icon="content_copy"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            mt: "8px",
          }}
        >
          <InfoBox value={"Non-Secure"} />
          <Box sx={{ display: "flex", gap: "24px", alignItems: "center" }}>
            <InfoBox label={"Protocol"} value={"ws"} />
            <InfoBox label={"Device Id"} value={data?.device_id} />
            <InfoBox label={"Port"} value={"80"} />
            <InfoBox label={"Path"} value={"/ws/ocpp/16/"} />
          </Box>
          <InfoBox
            label={"Host/Domain/Server IP"}
            value={"1cev.in"}
          />
          <InfoBox
            label={"Full URL"}
            value={`ws://1cev.in/ws/ocpp/16/${data?.device_id}`}
            icon="content_copy"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OCPPDetails;
