import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "36px 32px",
};

const AddAmountModal = ({
  open,
  handleClose,
  onProceed,
  amountData,
  selectedStationName,
}) => {
  if (!amountData) return null;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "80px",
                background: "rgba(41, 45, 51, 0.08);",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{ fontSize: "42px", fontWeight: "300" }}
                className="material-symbols-outlined"
              >
                currency_rupee_circle
              </span>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Heading text={"Payment Amount"} />
              <Typography
                color={"#0C0C0C"}
                fontFamily={"mySecondFont"}
                fontSize={"clamp(1.375rem, 1.2453rem + 0.6289vw, 2rem)"}
                textAlign={"center"}
              >
                ₹{amountData.amount_added}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                color={"#2F2F2F"}
                fontFamily={"myThirdFont"}
                fontSize={"16px"}
                textAlign={"center"}
              >
                Station/ Hub Name
              </Typography>
              <Typography
                color={"#2F2F2F"}
                fontFamily={"mySecondFont"}
                fontSize={"18px"}
                textAlign={"center"}
              >
                {selectedStationName}
              </Typography>
            </Box>
            <Typography
              color={"#2F2F2F"}
              fontSize={"16px"}
              fontFamily={"myThirdFont"}
              mt={"12px"}
            >
              Please review the above details and click on proceed
            </Typography>
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Button
                onClick={handleClose}
                sx={{
                  padding: "12px 24px",
                  borderRadius: "8px",
                  border: "1px solid #808080",
                  maxWidth: "136px",
                  width: "100%",
                  color: "#790000",
                  fontFamily: "mySecondFont",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onProceed}
                sx={{
                  padding: "12px 24px",
                  borderRadius: "8px",
                  border: "1px solid #808080",
                  width: "272px",
                  color: "#790000",
                  fontFamily: "mySecondFont",
                  background: "#222",
                  color: "#fff",
                  ":hover": {
                    background: "#222",
                  },
                }}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddAmountModal;
