

import React from "react";
import { useLocation } from "react-router-dom";
import TopHeader from "../../components/add_new_ocpi_tariff/TopHeader";
import AddNewOcpiTariffForm from "../../components/add_new_ocpi_tariff/AddNewOcpiTariffForm";

const AddNewOcpiTariff = () => {
  const location = useLocation();
  return (
    <>
      {/* Top - Bar */}
      <TopHeader headingCheck={false}/>

      
      <AddNewOcpiTariffForm/>
    </>
  );
};

export default AddNewOcpiTariff;

