import React, { useEffect, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import DeleteModal from "../components/Modals/DeleteModal";
import ViewModal from "../components/Modals/ViewModal";
import { useDispatch } from "react-redux";
import {
  deleteTariff,
  updateTariff,
} from "../redux/action/tariff/createTariffSlice";
import AddNewTariff from "../components/Modals/AddNewTariff";
import { deleteUser } from "../redux/action/adminManagement/adminSlice";
import AddNewUserModal from "../components/Modals/AddNewUserModal";
import OcpiEditModal from "../components/Modals/OcpiEditModal";
import { deleteOrg } from "../redux/action/companyManagement/companyManagementSlice";
import { useNavigate } from "react-router-dom";
import {
  deleteChargingPoint,
  deleteReview,
  fetchChargingPointQr,
} from "../redux/action/chargingPointSlice/chargingPointSlice";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { deleteOneStation } from "../redux/action/stations/stationSlice";
import { TextCopy } from "./TextCopy/TextCopy";
import WarningRFID from "../components/Modals/RFID/WarningRFID";
import {
  setSelectedRowData,
  setSelectedRowDataRedux,
} from "../redux/action/RFID/rfidSlice";
import CreateHubStationModal from "../components/Modals/HubManagement/CreateHubStationModal";
import { getAllStationChargers } from "../redux/action/hubManagement/hubManagementSlice";
import { MyRoutes } from "../routes/routes";
import OcpiAddNewModal from "../components/Modals/OCPI/OcpiAddNewModal";
import {
  getAllOcpiLocationsList,
  getAssignedOcpiEvses,
  getDefaultTariffList,
  getEvseOfOneLocation,
} from "../redux/action/ocpiManagement/ocpiSlice";
import RFIDConfirmModal from "../components/Modals/RFID/RFIDConfirmModal";
import { useSelector } from "react-redux";

const ActionIcons = ({ actions, row, heading, setTableData }) => {
  const [chargerData, setChargerData] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openTariffModal, setOpenTariffModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  // hub station
  const [openCreateHubStation, setOpenCreateHubStation] = useState(false);
  // rfid modal
  const [openRFIDModal, setOpenRFIDModal] = useState(false);
  const [openWarningRFIDModal, setOpenWarningRFIDModal] = useState(false);
  const [openOcpiEditModal, setOpenOcpiEditModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false); // Manage tooltip visibility
  const [operatorId, setOperatorId] = useState(null);

  const [openAddNewOcpiModal, setOpenAddNewOcpiModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenRFIDModal = (e) => {
    e?.stopPropagation();
    setSelectedRow(row);
    dispatch(setSelectedRowDataRedux(row));
    setOpenRFIDModal(true);
  };
  const handleCloseRFIDModal = (e) => {
    e?.stopPropagation();
    setOpenRFIDModal(false);
    setSelectedRow(null);
  };

  const handleOpenWarningRFIDModal = (e) => {
    e?.stopPropagation();
    setSelectedRow(row);
    setOpenWarningRFIDModal(true);
  };
  const handleCloseWarningRFIDModal = (e) => {
    e?.stopPropagation();
    setOpenWarningRFIDModal(false);
    setSelectedRow(null);
  };

  const handleOpenDeleteModal = (e) => {
    e?.stopPropagation();
    setSelectedRow(row);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (e) => {
    e?.stopPropagation();
    setOpenDeleteModal(false);
    setSelectedRow(null);
  };

  const handleOpenViewModal = (e) => {
    e.stopPropagation();
    setSelectedRow(row);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = (e) => {
    e.stopPropagation();
    setOpenViewModal(false);
    // setSelectedRow(null);
  };

  const handleOpenTariffModal = (e) => {
    e.stopPropagation();
    setSelectedRow(row);
    setOpenTariffModal(true);
  };

  const handleCloseTariffModal = (e) => {
    e?.stopPropagation();
    setOpenTariffModal(false);
    setSelectedRow(null);
  };

  const handleOpenUserModal = (e) => {
    e.stopPropagation();
    setSelectedRow(row);
    setOpenUserModal(true);
  };

  const handleCloseUserModal = (e) => {
    e?.stopPropagation();
    setOpenUserModal(false);
    setSelectedRow(null);
  };

  // hub station
  const handleOpenHubStation = async (e) => {
    e.stopPropagation();

    // Store the row ID directly
    const stationId = row?._id;

    if (!stationId) {
      console.error("Station ID is undefined");
      return; // Exit if station ID is undefined
    }

    // Log the station ID for debugging
    console.log("Selected Station ID:", stationId);

    try {
      // Dispatch and wait for the result using the stationId
      const result = await dispatch(getAllStationChargers(stationId)).unwrap();
      setChargerData(result);
      setOpenCreateHubStation(true);
    } catch (error) {
      console.error("Failed to fetch chargers:", error);
      toast.error("Failed to load charger data.");
    }
  };

  const handleCloseHubStation = (e) => {
    e?.stopPropagation();
    setOpenCreateHubStation(false);
    setSelectedRow(null);
  };

  const handleOpenOcpiEditModal = (e) => {
    e.stopPropagation();
    setSelectedRow(row);
    setOpenOcpiEditModal(true);
  };

  const handleCloseOcpiEditModal = (e) => {
    e?.stopPropagation();
    setOpenOcpiEditModal(false);
    setSelectedRow(null);
  };

  const handleOpenAddNewOcpiModal = (e) => {
    // Handle add action
    e.stopPropagation();
    fetchLocations(); // Fetch locations when modal is opened
    setOpenAddNewOcpiModal(true);
  };

  const handleCloseAddNewOcpiModal = (e) => {
    e?.stopPropagation();
    setOpenAddNewOcpiModal(false);
  };

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setOperatorId(id);
    }
  }, []);

  const [locations, setLocations] = useState([]); // Local state for locations

  const { assignedEvses, loading, error } = useSelector((state) => state.ocpi);

  const fetchLocations = () => {
    if (operatorId) {
      dispatch(getAllOcpiLocationsList(operatorId))
        .unwrap()
        .then((data) => {
          setLocations(data); // Store the fetched locations
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
        });
    }
  };

  const fetchAssignedEvses = () => {
    if (operatorId) {
      dispatch(getAssignedOcpiEvses(operatorId));
    }
  };

  // Update the useEffect to fetch assigned EVSEs when operatorId changes
  useEffect(() => {
    fetchAssignedEvses();
  }, [dispatch, operatorId]);

  const handleEdit = (e) => {
    console.log("clicked");
    e?.stopPropagation();
    if (heading === "Tariff") {
      handleOpenTariffModal(e);
    } else if (heading === "Admin Management") {
      handleOpenUserModal(e);
    } else if (heading === "OCPI Management") {
      handleOpenAddNewOcpiModal(e);
      console.log("nnnnnnnnnnnn", row._id);
    } else if (heading === "Companies Management") {
      // Navigate to Add New Company page with pre-filled values
      navigate(MyRoutes.add_company, {
        state: { orgData: row._id, edit: true },
      });
    } else if (heading === "Charging Points") {
      // Navigate to Add New Company page with pre-filled values
      navigate(MyRoutes.addNewChargingPoint, {
        state: { CPData: row._id, edit: true },
      });
    } else if (heading === "Charging Stations") {
      console.log("Navigating with data:", row._id);
      // Navigate to Add New Station page with pre-filled values
      navigate(MyRoutes.addNewChargingStation, {
        state: { stationData: row._id, edit: true },
      });
    } else if (heading === "Guards") {
      console.log("Navigating with data:", row._id);
      // Navigate to Add New Station page with pre-filled values
      navigate(MyRoutes.add_new_guard, {
        state: { guardData: row._id, edit: true },
      });
    } else if (heading === "Hub Stations") {
      handleOpenHubStation(e);
    } else {
      if (heading === "Tariff") {
        dispatch(updateTariff({ formValues: row }));
        // } else if (heading === "User") {
        //   dispatch(updateUser({ formValues: row }));
      } else {
        // Add more conditions for other headings if needed
      }
    }
  };

  const handleDelete = async (e) => {
    e?.stopPropagation();
    if (selectedRow) {
      console.log(selectedRow);
      try {
        let action;
        if (heading === "Tariff") {
          action = deleteTariff(selectedRow._id);
        } else if (heading === "Admin Management") {
          action = deleteUser(selectedRow._id);
        } else if (heading === "Companies Management") {
          action = deleteOrg(selectedRow._id);
        } else if (heading === "Charging Points") {
          action = deleteChargingPoint(selectedRow._id);
        } else if (heading === "Charging Stations") {
          action = deleteOneStation(selectedRow._id);
        } else if (heading === "Reviews") {
          action = deleteReview(selectedRow._id);
        } else {
          // Add more conditions for other headings if needed
          throw new Error("Unknown heading");
        }

        // Wait for the action to be resolved
        const result = await dispatch(action).unwrap();
        console.log("Deletion result:", result);

        // Check if the action was fulfilled
        if (result === selectedRow._id || result.success === true) {
          // Successfully deleted, show success toast
          toast.success("Deleted successfully.");

          // Update table data immediately
          setTableData((prevData) =>
            prevData.filter((row) => row._id !== selectedRow._id)
          );

          // Close the delete modal
          handleCloseDeleteModal();
        } else {
          throw new Error("Failed to delete.");
        }
      } catch (error) {
        // Handle any errors that occur during the delete operation
        toast.error(`Failed to delete: ${error.message}`);
      }
    }
  };

  const downloadQRCode = (base64String, fileName = "qrcode.png") => {
    // Create a Blob from the base64 string
    const link = document.createElement("a");
    link.href = base64String;
    link.download = fileName;

    // Trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadZipWithQRCodes = (qrCodes) => {
    const zip = new JSZip();

    qrCodes.forEach((qrCode, index) => {
      zip.file(`qrcode${index + 1}.png`, qrCode.split(",")[1], {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "qrcodes.zip");
    });
  };

  const handleView = (e) => {
    e?.stopPropagation();
    setSelectedRow(row);
    console.log(selectedRow);

    if (heading === "Charging Points") {
      dispatch(fetchChargingPointQr(row.device_id || row.deviceId)).then(
        (result) => {
          if (fetchChargingPointQr.fulfilled.match(result)) {
            const qrCodes = result.payload.allQrCodes;

            if (qrCodes.length === 1) {
              // Download the single QR code
              downloadQRCode(qrCodes[0]);
            } else if (qrCodes.length > 1) {
              // Download all QR codes as a zip file
              downloadZipWithQRCodes(qrCodes);
            } else {
              toast.error("QR code not available.");
            }
          } else {
            console.error("Failed to fetch QR code:", result.payload);
          }
        }
      );
    }
  };

  // const handleCopy = (row) => {
  //   const textToCopy = `wss://test.1charging.com/ws/ocpp/16/${row.device_id}`;
  //   navigator.clipboard.writeText(textToCopy).then(() => {
  //     setTooltipOpen(true);
  //     setTimeout(() => setTooltipOpen(false), 2000); // Hide tooltip after 2 seconds
  //   }).catch((error) => {
  //     console.error("Failed to copy text: ", error);
  //   });
  // };
  const handleCopy = (row) => {
    const textToCopy = `ws://test.1charging.com/ws/ocpp/16/${
      row.device_id || row.deviceId
    }`;
    TextCopy(textToCopy, setTooltipOpen); // Use the TextCopy function
  };

  const { defaultTariffs, evseData } = useSelector((state) => state.ocpi);

  useEffect(() => {
    if (operatorId) {
      dispatch(getDefaultTariffList(operatorId));
    }
  }, [dispatch, operatorId]);

  const handleDownload = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up

    console.log("oljlolj", row);

    // Construct the URL with the sessionId
    const invoiceUrl = `https://new-cms-uploads.s3.ap-south-1.amazonaws.com/charging/invoices/${row._id}.pdf`;

    const link = document.createElement("a");
    link.href = invoiceUrl;
    link.download = "invoice.pdf"; // Default file name
    link.click();
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: "4px" }}>
        {actions === "assign-reject" ? (
          <>
            <Button
              sx={{
                width: "120px",
                padding: "4px 16px",
                fontFamily: "mySecondFont",
                fontSize: "16px",
                borderRadius: "4px",
                border: "1px solid #027900",
                color: "#027900",
                textTransform: "capitalize",
              }}
              onClick={(e) => handleOpenRFIDModal(e)}
            >
              Assign
            </Button>
            <Button
              sx={{
                width: "65px",
                padding: "4px 16px",
                fontFamily: "mySecondFont",
                fontSize: "14px",
                borderRadius: "4px",
                border: "1px solid #C0C0C0",
                color: "#790000",
                textTransform: "capitalize",
              }}
              onClick={(e) => handleOpenWarningRFIDModal(e)}
            >
              Reject
            </Button>
          </>
        ) : (
          actions &&
          actions.split("-").map((action, index) => {
            switch (action) {
              case "edit":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #c0c0c0",
                      borderRadius: "4px",
                    }}
                    onClick={(e) => handleEdit(e)}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#222222",
                        cursor: "pointer",
                      }}
                      className="material-symbols-outlined"
                    >
                      edit
                    </span>
                  </Box>
                );
              case "delete":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #c0c0c0",
                      borderRadius: "4px",
                    }}
                    onClick={(e) => handleOpenDeleteModal(e)}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#790000",
                        cursor: "pointer",
                      }}
                      className="material-symbols-outlined"
                    >
                      delete
                    </span>
                  </Box>
                );
              case "view":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #c0c0c0",
                      borderRadius: "4px",
                    }}
                    onClick={handleOpenViewModal}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#222222",
                        cursor: "pointer",
                      }}
                      className="material-symbols-outlined"
                    >
                      visibility
                    </span>
                  </Box>
                );
              case "qr":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #c0c0c0",
                      borderRadius: "4px",
                    }}
                    onClick={(e) => {
                      handleView(e);
                    }}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#222222",
                        cursor: "pointer",
                      }}
                      className="material-symbols-outlined"
                    >
                      qr_code_scanner
                    </span>
                  </Box>
                );
              case "copy":
                return (
                  <Tooltip title="Copied!" open={tooltipOpen} arrow>
                    <Box
                      key={index}
                      sx={{
                        width: "24px",
                        height: "24px",
                        padding: "2px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #c0c0c0",
                        borderRadius: "4px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(row);
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          color: "#222222",
                          cursor: "pointer",
                        }}
                        className="material-symbols-outlined"
                      >
                        content_copy
                      </span>
                    </Box>
                  </Tooltip>
                );
              case "download":
                const isDownloadDisabled = row?.from === "WEB_CHARGING"; // Disable if form is "WEB"
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "24px",
                      height: "24px",
                      padding: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #c0c0c0",
                      borderRadius: "4px",
                      cursor: isDownloadDisabled ? "not-allowed" : "pointer",
                      opacity: isDownloadDisabled ? 0.5 : 1, // Reduce opacity when disabled
                    }}
                    onClick={(e) => {
                      if (!isDownloadDisabled) {
                        handleDownload(e);
                      }
                    }}
                  >
                    <span className="material-symbols-outlined">download</span>
                  </Box>
                );
              default:
                return null;
            }
          })
        )}
      </Box>

      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
        heading={heading}
        data={selectedRow}
      />

      {heading === "Admin Management" && (
        <ViewModal
          open={openViewModal}
          handleClose={handleCloseViewModal}
          data={selectedRow}
          heading={heading}
        />
      )}

      {heading === "Tariff" && (
        <AddNewTariff
          open={openTariffModal}
          handleClose={handleCloseTariffModal}
          data={selectedRow} // Pass the selected row data to AddNewTariff modal
        />
      )}

      {heading === "Admin Management" && (
        <AddNewUserModal
          open={openUserModal}
          handleClose={handleCloseUserModal}
          data={selectedRow}
        />
      )}
      {heading === "RFID Management" && (
        <RFIDConfirmModal
          open={openRFIDModal}
          handleClose={handleCloseRFIDModal}
          data={selectedRow}
          handleOpenRFIDModal={handleOpenRFIDModal}
        />
      )}
      {heading === "RFID Management" && (
        <WarningRFID
          open={openWarningRFIDModal}
          handleClose={handleCloseWarningRFIDModal}
          data={selectedRow}
        />
      )}
      {heading === "Hub Stations" && openCreateHubStation && (
        <CreateHubStationModal
          open={openCreateHubStation}
          handleClose={handleCloseHubStation}
          data={chargerData} // Use the charger data from the API
          stationId={row?._id}
          updating={true}
          newCheck={true}
        />
      )}
      {heading === "OCPI Management" && (
        <OcpiAddNewModal
          open={openAddNewOcpiModal}
          handleClose={handleCloseAddNewOcpiModal}
          heading={"Ocpi Management"}
          locations={locations}
          operatorId={operatorId}
          onUpdateSuccess={fetchAssignedEvses}
          checkUpdate={true}
          evsesEditData={row}
          defaultTariffs={defaultTariffs}
          newData={evseData}
        />
      )}
    </>
  );
};
export default ActionIcons;
