import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TableBox from "./TableBox";
import {
  ActiveSessionsColumns,
  AllSessionsColumns,
} from "../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSessions } from "../../redux/action/sessions/sessionsSlice";
import { useTablePage } from "../../Hooks/TablePageContext";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const { page } = useTablePage();

  const dispatch = useDispatch();
  const { sessions, loading, error } = useSelector((state) => state.sessions);

  useEffect(() => {
    const payload = selectedTab === 0 ? true : false;
    dispatch(fetchAllSessions(payload));
  }, [dispatch, selectedTab]);

  // Ensure sessions is an array
  if (!Array.isArray(sessions)) {
    console.error("Sessions data is not an array", sessions);
  }
  const activeSessions = sessions?.data;
  const totalDataCount = sessions?.totalCount;

  const tabsData = [
    {
      label: "Active Sessions",
      content: (
        <Box>
          <TableBox
            columns={ActiveSessionsColumns()}
            rows={activeSessions}
            isRowClickable={false}
            loading={loading}
            totalCount={totalDataCount}
            pageLimit={pageLimit}
          />
        </Box>
      ),
    },
    {
      label: "All Sessions",
      content: (
        <Box>
          <TableBox
            columns={AllSessionsColumns()}
            rows={activeSessions}
            isRowClickable={true}
            loading={loading}
            totalCount={totalDataCount}
            pageLimit={pageLimit}
            isAllSessions={true}
          />
        </Box>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "8px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "20px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box sx={{ display: "none" }}>
          <MainHeading text={"Charging Sessions"} />
        </Box>
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
