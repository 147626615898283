import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import {
  TariffManagementColumns,
  TariffManagementData,
} from "../../utils/DummyData";
import AddNewTariff from "../Modals/AddNewTariff";
import { useDispatch, useSelector } from "react-redux";
import { getTariffs } from "../../redux/action/tariff/createTariffSlice";

const TariffTableBox = () => {
  //Add Tariff Modal
  const [openNewTariffModal, setOpenNewTariffModal] = useState(false);
  const [filteredTariffs, setFilteredTariffs] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const dispatch = useDispatch();
  const { tariffs, loading, error } = useSelector((state) => state.tariff);

  useEffect(() => {
    dispatch(getTariffs());
  }, [dispatch]);

  const handleOpenAddNewTariffModal = (e) => {
    e.stopPropagation();
    setOpenNewTariffModal(true);
  };
  const handleCloseAddNewTariffModal = (e) => {
    e?.stopPropagation();
    setOpenNewTariffModal(false);
  };

  //filters
  useEffect(() => {
    if (tariffs.length > 0) {
      setFilteredTariffs(tariffs);
    }
  }, [tariffs]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredTariffs(filteredData);
  };

  const myFilters = [
    {
      value: "_id",
      title: "Tariff ID",
    },
    {
      value: "name",
      title: "Tariff Name",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={tariffs}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
        <Button
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "142px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            ":hover": {
              background: "#222",
            },
          }}
          onClick={handleOpenAddNewTariffModal}
        >
          + Add New
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "16px",
          mt: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#027900",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
            Active
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: "#790000",
            }}
          ></Box>
          <Typography fontSize={"14px"} fontFamily={"myThirdFont"}>
            Inactive
          </Typography>
        </Box>
      </Box>

      {/* Table */}
      <CustomTable
        columns={TariffManagementColumns}
        rows={filteredTariffs}
        actionType={"edit-delete"}
        specialColumns={["_id"]}
        activeMark={true}
        modalHeading={"Tariff"}
        sortColumn={sortColumn}
      />

      <AddNewTariff
        open={openNewTariffModal}
        handleClose={handleCloseAddNewTariffModal}
        heading={"Add New Tariff"}
      />
    </Box>
  );
};

export default TariffTableBox;
