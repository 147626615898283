import React from "react";
import TopHeader from "../../components/add_new_charging_station/TopHeader";
import AddChargingStationForm from "../../components/add_new_charging_station/AddChargingStationForm";
import { useLocation } from "react-router-dom";

const AddNewChargingStation = () => {
  const location = useLocation();
  const {edit} = location.state || [];
  console.log("state123", edit)
  return (
    <>
      {/* Top header */}
      <TopHeader headingCheck={edit} />

      {/* Add Charging Station Form */}
      <AddChargingStationForm />
    </>
  );
};

export default AddNewChargingStation;
