import React from "react";
import TopBar from "../../components/chargingPointsDetails/TopBar";
import { Box } from "@mui/material";
import Breadcrumbs from "../../components/breadcrumbs/BreadCrumbs";

const ChargingPointsDetails = () => {
  return (
    <Box>
      {/* Top - Bar */}


      <TopBar />
    </Box>
  );
};

export default ChargingPointsDetails;
