import { GetCurrencySymbol } from "./currency/currencyChangeFunction";

// alert card data
export const alertCardData = [
  {
    id: 1,
    icon: "warning",
    title: "Critical alert title",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ipsum nisi nisi nulla sed. Ipsum nisi nisi nulla sed.",
    time: "2m",
    alertType: "max",
  },
  {
    id: 2,
    icon: "warning",
    title: "Critical alert title",
    description:
      "Device ID: HFJKK5466LJ Station: Urbana M3M Ipsum nisi nisi nulla sed.",
    time: "2m",
    alertType: "max",
  },
  {
    id: 3,
    icon: "priority_high",
    title: "Alert title",
    description:
      "Device ID: HFJKK5466LJ Station: Urbana M3M Ipsum nisi nisi nulla sed.",
    time: "2m",
    alertType: "mid",
  },
];

// value cards dashboards

export const valueCardData = [
  {
    id: 1,
    title: "Total uptime (hrs)",
    icon: "pace",
    profit: "5.5%",
    compare: "1.15",
    value: "25.72",
  },
  {
    id: 2,
    title: "New users",
    icon: "groups",
    profit: "-25%",
    compare: "-28",
    value: "125",
  },
  {
    id: 3,
    title: "Billed Amount",
    icon: "currency_rupee_circle",
    profit: "0.45%",
    compare: "256",
    value: "₹25,5400",
  },
  {
    id: 4,
    title: "Total Energy Consumed (kWh)",
    icon: "bolt",
    profit: "3.7%",
    compare: "58kW",
    value: "1556.54",
  },
  {
    id: 5,
    title: "Total Sessions",
    icon: "bolt",
    profit: "0.45%",
    compare: "1",
    value: "257",
  },

  {
    id: 6,
    title: "Refund Amount (₹)",
    icon: "receipt",
    profit: "0.45%",
    compare: "256",
    value: "₹6700",
  },
  {
    id: 7,
    title: "Direct amount (QR)",
    icon: "qr_code_2",
    profit: "0.45%",
    compare: "256",
    value: "₹15,000",
  },
  {
    id: 8,
    title: "Amount from app",
    icon: "smartphone",
    profit: "0.45%",
    compare: "256",
    value: "₹10,5400",
  },

  {
    id: 9,
    title: "Wallet Recharge",
    icon: "account_balance_wallet",
    profit: "0.45%",
    compare: "256",
    value: "₹5830",
  },

  {
    id: 10,
    title: "Cashbacks",
    icon: "smartphone",
    profit: "0.45%",
    compare: "256",
    value: "₹830",
  },
];

// top perform cards

export const performCardData = [
  {
    id: 1,
    name: "Noida City Center station",
    energy: "1.7kWh",
    value: "1075",
    station: "7",
  },
  {
    id: 2,
    name: "M3M Urbana Center",
    energy: "1.45kWh",
    value: "954",
    station: "4",
  },
  {
    id: 3,
    name: "Ambiance Mall, Gurugram",
    energy: "0.95kWh",
    value: "864",
    station: "12",
  },
];

// ac dc cards

export const AcDcCardData = [
  {
    id: 1,
    title: "DC fast",
    icon: "pace",
    profit: "54.5%",
    energy: "1.7kWh",
    value: "12,050",
  },
  {
    id: 2,
    title: "DC slow",
    icon: "groups",
    profit: "-20%",
    energy: "0.25kWh",
    value: "1075",
  },
  {
    id: 3,
    title: "AC Fast",
    icon: "currency_rupee_circle",
    profit: "-10.5%",
    energy: "1.5kWh",
    value: "5645",
  },
  {
    id: 4,
    title: "AC slow",
    icon: "bolt",
    profit: "7.5%",
    energy: "0.06kWh",
    value: "204",
  },
];

// custom tabs
export const chargingSessionsTabs = [
  { label: "Active Sessions" },
  { label: "All Sessions" },
];

// table - column

export const ActiveSessionsColumns = () => {
  const currencySymbol = GetCurrencySymbol();

  return [
    { id: "sr", label: "S.NO", align: "left" },

    { id: "station", label: "Station Name", align: "left" },
    { id: "deviceId", label: "Device ID", align: "left" },
    { id: "energyConsumed", label: `Energy consumed (kWh)`, align: "left" },

    {
      id: "enteredAmount",
      label: `Entered Amount (${currencySymbol})`,
      align: "left",
    },

    { id: "from", label: "Mode", align: "left" },
    { id: "connectorId", label: "Connector ID", align: "center" },
    { id: "_id", label: "Session ID", align: "left" },
    { id: "transactionId", label: "Transaction ID", align: "left" },
    { id: "sessionDuration", label: "Session Duration", align: "left" },
    { id: "sessionStart", label: "Start Time", align: "left" },
 
    
  ];
};

export const AllSessionsColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },

    { id: "station", label: "Station Name", align: "left" },
    { id: "deviceId", label: "Device ID", align: "left" },
    { id: "energyConsumed", label: `Energy consumed (kWh)`, align: "left" },
    {
      id: "enteredAmount",
      label: `Entered Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "chargingAmount",
      label: `Billed Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "refundAmount",
      label: `Refund Amount (${currencySymbol})`,
      align: "left",
    },
    { id: "stopReason", label: "Stop Reason", align: "left" },
    { id: "from", label: "Mode", align: "left" },
    { id: "connectorId", label: "Connector ID", align: "left" },

    { id: "_id", label: "Session ID", align: "left" },
    { id: "transactionId", label: "Transaction ID", align: "left" },
    { id: "sessionDuration", label: "Session Duration", align: "left" },
    { id: "sessionStart", label: "Start Time", align: "left" },
    { id: "sessionStop", label: "End Time", align: "left" },
    { id: "action", label: "Invoice", align: "left" },
  ];
};

// Active Session table data
export const activeSessionIdData = [
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
];

export const deviceStatusData = [
  {
    id: 1,
    status: "online",
    noOfDevices: "215",
    totalDeviceNo: "240",
    activeSessionNo: "24",
    cardStatus: "live",
    ratingStatus: "good",
  },
  {
    id: 2,
    status: "faulted",
    noOfDevices: "15",
    totalDeviceNo: "240",
    activeSessionNo: "24",
    cardStatus: "live",
    ratingStatus: "ok",
  },
  {
    id: 3,
    status: "offline",
    noOfDevices: "30",
    totalDeviceNo: "240",
    activeSessionNo: "24",
    cardStatus: "live",
    ratingStatus: "bad",
  },
];

//Dashboard-Total Session Data
export const totalSessionData = [
  {
    id: 1,
    sessionType: "Direct",
    sessionName: "Direct Session",
    sessionCount: "290",
    profit: "30.5",
    compare: "25",
  },
  {
    id: 2,
    sessionType: "App",
    sessionName: "App Session",
    sessionCount: "609",
    profit: "-20",
    compare: "-17",
  },
];

//Dashboard Line-Bar Graph Data
export const dashboardComposedGraphData = [
  {
    name: "Mon",
    id: "1",
    energyValue: 1000,
    revenueGenerated: 500,
  },
  {
    name: "Tue",
    id: "2",
    energyValue: 2100,
    revenueGenerated: 1100,
  },
  {
    name: "Wed",
    id: "3",
    energyValue: 3800,
    revenueGenerated: 1800,
  },
  {
    name: "Thu",
    id: "4",
    energyValue: 900,
    revenueGenerated: 600,
  },
  {
    name: "Fri",
    id: "5",
    energyValue: 3400,
    revenueGenerated: 1600,
  },
  {
    name: "Sat",
    id: "6",
    energyValue: 1500,
    revenueGenerated: 800,
  },
  {
    name: "Sun",
    id: "7",
    energyValue: 4600,
    revenueGenerated: 2100,
  },
];

// Data to be used in the DoughnutChart
export const walletRechargeData = [
  {
    id: 1,
    rechargeRange: "Less than ₹100",
    //less than 100 = less
    rechargeRangeParameter: "lessWallet",
    rechargeValue: "194",
  },
  {
    id: 2,
    rechargeRange: "₹100-₹500",
    //between 100-500 = between
    rechargeRangeParameter: "betweenWallet",
    rechargeValue: "84",
  },
  {
    id: 3,
    rechargeRange: "More than ₹500",
    // more than 500 = more
    rechargeRangeParameter: "moreWallet",
    rechargeValue: "124",
  },
];

export const appStatsData = [
  {
    id: 1,
    planType: "1 Month plan",
    //less than 100 = less
    planTypeParameter: "monthlyPlan",
    planTotalSubscriber: "14",
  },
  {
    id: 2,
    planType: "6 months plan",
    //between 100-500 = between
    planTypeParameter: "halfYearlyPlan",
    planTotalSubscriber: "84",
  },
  {
    id: 3,
    planType: "Annual Plan",
    // more than 500 = more
    planTypeParameter: "annualPlan",
    planTotalSubscriber: "124",
  },
];

// Active Session table data
export const allSessionIdData = [
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startAmount: "₹25",
    startTime: "23:08, 24/08/2023 ",
  },
];

// charging - stations data

export const chargingStationsColumnsData = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "stationName", label: "Station Name", align: "left" },

    {
      id: "chargingPointCount",
      label: "Chargers",
      align: "left",
    },
    { id: "sessionCount", label: "Sessions*", align: "left" },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyConsumed", label: "Energy Consumed* (kWh)", align: "left" },
    { id: "_id", label: "Station ID", align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ];
};

export const chargingOtherStationsColumnsData = [
  { id: "name", label: "Station Name", align: "left" },
  { id: "stationId", label: "Station Id", align: "left" },
  {
    id: "_id",
    label: "Station ID",
    align: "left",
  },
  {
    id: "operatorName",
    label: "Operator Name",
    align: "left",
  },
  {
    id: "city",
    label: "City",
    align: "left",
  },
  { id: "createdAt", label: "Created At", align: "left" },
];

export const chargingOCPIStationsColumnsData = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "stationName", label: "Station Name", align: "left" },
    { id: "network", label: "Network", align: "left" },
    { id: "_id", label: "Station ID", align: "left" },
    { id: "chargers", label: "Chargers", align: "left" },
    { id: "sessions", label: "Sessions*", align: "left" },
    {
      id: "revenue",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyDelivered", label: "Energy Consumed* (kWh)", align: "left" },
  ];
};

// charging station table data
export const chargingStationsData = [
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
    action: "edit-delete",
  },
];

export const chargingOCPIStationsData = [
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "53",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45STG0623",
    chargers: "43",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
  {
    stationName: "Noida City Center",
    network: "Tata Power",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    action: "edit-delete",
  },
];

// Station - Details - Sessions

export const sessionDetailsSessionsColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "_id", label: "Session ID", align: "left" },
    { id: "transactionId", label: "Transaction ID", align: "left" },
    { id: "deviceId", label: "Device ID", align: "left" },
    { id: "connectorId", label: "Connector", align: "left" },
    { id: "energyConsumed", label: "Energy consumed (kWh)", align: "left" },
    { id: "from", label: "Mode", align: "left" },
    {
      id: "enteredAmount",
      label: `Entered Amount* (${currencySymbol})`,
      align: "left",
    },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    {
      id: "refundAmount",
      label: `Refund Amount (${currencySymbol})`,
      align: "left",
    },
    { id: "sessionDuration", label: "Session Duration (h:m:s)", align: "left" },
    { id: "stopReason", label: "Stop Reason", align: "left" },
    { id: "sessionStart", label: "Start Time", align: "left" },
    { id: "sessionStop", label: "End time", align: "left" },
  ];
};

export const sessionDetailsSessionsData = [
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2 ",
    energyConsumed: "2.25 kWh",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "23:08, 24/08/2023 ",
    endTime: "23:27, 24/08/2023",
  },
];

// Review - Data -

export const stationDetailsReviewColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "user", label: "Customer ID", align: "left" },
  { id: "overallRating", label: "Rating", align: "left" },
  { id: "description", label: "Review", align: "left" },
  { id: "ratingReviewImages", label: "Attachments", align: "left" },
  { id: "updated_at", label: "Reviewed on", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const stationDetailsReviewData = [
  {
    userID: "b511ab427066b23ecd",
    rating: "Great",
    review:
      "Lorem ipsum dolor sit amet consectetur. Sollicitudin tellus adipiscing quam facilisi lobortis.",
    attachments: "2",
    reviewedOn: "24/08/2023 23:08 PM",
    action: "view-delete",
  },
  {
    userID: "b511ab427066b23ecd",
    rating: "Bad",
    review:
      "Lorem ipsum dolor sit amet consectetur. Sollicitudin tellus adipiscing quam facilisi lobortis.",
    attachments: "-",
    reviewedOn: "24/08/2023 23:08 PM",
    action: "view-delete",
  },
  {
    userID: "b511ab427066b23ecd",
    rating: "Ok",
    review:
      "Lorem ipsum dolor sit amet consectetur. Sollicitudin tellus adipiscing quam facilisi lobortis.",
    attachments: "-",
    reviewedOn: "24/08/2023 23:08 PM",
    action: "view-delete",
  },
  {
    userID: "b511ab427066b23ecd",
    rating: "Great",
    review:
      "Lorem ipsum dolor sit amet consectetur. Sollicitudin tellus adipiscing quam facilisi lobortis.",
    attachments: "1",
    reviewedOn: "24/08/2023 23:08 PM",
    action: "view-delete",
  },
  {
    userID: "b511ab427066b23ecd",
    rating: "Ok",
    review:
      "Lorem ipsum dolor sit amet consectetur. Sollicitudin tellus adipiscing quam facilisi lobortis.",
    attachments: "-",
    reviewedOn: "24/08/2023 23:08 PM",
    action: "view-delete",
  },
  {
    userID: "b511ab427066b23ecd",
    rating: "Great",
    review:
      "Lorem ipsum dolor sit amet consectetur. Sollicitudin tellus adipiscing quam facilisi lobortis.",
    attachments: "1",
    reviewedOn: "24/08/2023 23:08 PM",
    action: "view-delete",
  },
];

// Chrging - point - data - table

export const stationDetailsChargingPointColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "action", label: "Action", align: "left" },
    { id: "name", label: "Charging Point", align: "left" },
    { id: "deviceId", label: "Device ID", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "chargingPointType", label: "Charger Type", align: "left" },
    { id: "connectorsCount", label: "Connectors", align: "left" },
    { id: "maxPower", label: "Max power (kWh)", align: "left" },
    { id: "chargingSessionsCount", label: "Sessions*", align: "left" },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyConsumed", label: "Energy Consumed* (kWh)", align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
  ];
};

export const stationDetailsChargingPointData = [
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Offline",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Faulted",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    deviceId: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
    maxPower: "200kW",
    sessions: "10",
    revenue: "₹25,564",
    energy: "2.75 kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
];

// charging - point

export const chargingPointColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "action", label: "Action", align: "left" },
    { id: "name", label: "Charging Point", align: "left" },
    { id: "station", label: "Station Name", align: "left" },
    { id: "device_id", label: "Device ID", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "chargingPointType", label: "Charger type", align: "left" },
    { id: "connectors", label: "Connectors", align: "left" },
    { id: "maxPower", label: "Max power (kWh)", align: "left" },
    { id: "chargingSessionsCount", label: "Sessions*", align: "left" },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyConsumed", label: "Energy Consumed* (kWh)", align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
  ];
};

export const chargingPointData = [
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Offline",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Offline",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Faulted",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
  {
    action: "qr-copy-edit-delete",
    name: "1C Urbana 60kW DC",
    station: "Noida City Center",
    deviceID: "45GHDG54",
    status: "Online",
    chargerType: "DC",
    connectors: "2 (CCS -2, CCS-1)",
  },
];

// Connectors

export const connectorData = [
  {
    id: "1",
    status: "Online",
  },
  {
    id: "2",
    status: "offline",
  },
];

// Logs table

export const ChargingPointlogsColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "message", label: "Command", align: "left" },
  { id: "messageId", label: "Message ID", align: "left" },
  { id: "connectorId", label: "Connector ID", align: "left" },
  { id: "type", label: "Log type", align: "left" },
  { id: "createdAt", label: "Recorded on", align: "left" },
];

export const ChargingPointlogsData = [
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "outgoing",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "outgoing",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "outgoing",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "outgoing",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "incoming",
  },
  {
    command: "Remote start transaction",
    messageID: "1028825",
    connectorID: "1",
    logType: "OCPP",
    recordedOn: "09:00:16 AM Today",
    logStatus: "outgoing",
  },
];

// session data charging point

export const chargingPointSessionColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "_id",
      label: "Session ID",
      align: "left",
    },
    { id: "transactionId", label: "Transaction ID", align: "left" },
    { id: "deviceId", label: "Device ID", align: "left" },
    {
      id: "connectorId",
      label: "Connectors",
      align: "left",
    },
    {
      id: "energyConsumed",
      label: "Energy consumed (kWh)",
      align: "left",
    },
    {
      id: "from",
      label: "Mode",
      align: "left",
    },
    {
      id: "enteredAmount",
      label: `Entered Amount* (${currencySymbol})`,
      align: "left",
    },
    {
      id: "chargedAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    {
      id: "refundAmount",
      label: `Refund Amount (${currencySymbol})`,
      align: "left",
    },
    { id: "sessionDuration", label: "Session Duration (h:m:s)", align: "left" },
    { id: "stopReason", label: "Stop Reason", align: "left" },
    {
      id: "sessionStart",
      label: "Start time",
      align: "left",
    },
    {
      id: "sessionStop",
      label: "End time",
      align: "left",
    },
  ];
};

export const chargingPointSessionData = [
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "Direct Payment",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "Direct Payment",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "Direct Payment",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "Direct Payment",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "Direct Payment",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
  {
    sessionID: "669a4bb511ab427066b23ecd",
    transactionID: "b511ab427066b23ecd",
    deviceID: "MMSAHYAC017",
    connector: "02 CCS-2",
    energyConsumed: "2.25 kWh",
    mode: "APP",
    amountCharged: "₹25",
    refund: "₹15",
    startTime: "24/08/2023 23:08 PM",
    endTime: "24/08/2023 23:08 PM",
  },
];

// configuration - data

export const changeAvalaible = [
  {
    name: "Boot Notification",
    value: "BootNotification",
  },
  {
    name: "Diagnostics Status Notification",
    value: "DiagnosticsStatusNotification",
  },
  {
    name: "Firmware Status Notification",
    value: "FirmwareStatusNotification",
  },
  {
    name: "Heart Beat",
    value: "Heartbeat",
  },
  {
    name: "Meter Values",
    value: "MeterValues",
  },
  {
    name: "Status Notification",
    value: "StatusNotification",
  },
];

export const changeAvalaibleType = [
  {
    id: "1",
    name: "Operative",
  },
  {
    id: "2",
    name: "Inoperative",
  },
];

export const selectKeyType = [
  {
    id: "1",
    name: "Predefined",
  },
  {
    id: "2",
    name: "Custom",
  },
];

export const selectConfigurationKey = [
  {
    name: "Heartbeat Interval(in seconds)",
    value: "HeartbeatInterval",
  },
  {
    name: "Connection TimeOut(in seconds)",
    value: "ConnectionTimeOut",
  },
  {
    name: "Clock Aligned Data Interval(in seconds)",
    value: "ClockAlignedDataInterval",
  },
  {
    name: "Allow Offline Tx For UnknownId(boolean)",
    value: "AllowOfflineTxForUnknownId",
  },
  {
    name: "Authorization Cache Enabled(boolean)",
    value: "AuthorizationCacheEnabled",
  },
  {
    name: "Authorize Remote TxRequests(boolean)",
    value: "AuthorizeRemoteTxRequests",
  },
  {
    name: "Blink Repeat(in times)",
    value: "BlinkRepeat",
  },
  {
    name: "Light Intensity(in %)",
    value: "LightIntensity",
  },
  {
    name: "Local Authorize Offline(boolean)",
    value: "LocalAuthorizeOffline",
  },
  {
    name: "Local PreAuthorize(boolean)",
    value: "LocalPreAuthorize",
  },
  {
    name: "Meter Values Aligned Data(comma separated list)",
    value: "MeterValuesAlignedData",
  },
  {
    name: "Meter Values Sampled Data(comma separated list)",
    value: "MeterValuesSampledData",
  },
  {
    name: "Meter Value Sample Interval(in seconds)",
    value: "MeterValueSampleInterval",
  },
  {
    name: "Minimum Status Duration(in seconds)",
    value: "MinimumStatusDuration",
  },
  {
    name: "Reset Retries(in times)",
    value: "ResetRetries",
  },
  {
    name: "Connector Phase Rotation(comma separated list)",
    value: "ConnectorPhaseRotation",
  },
  {
    name: "Stop Transaction On EV Side Disconnect(boolean)",
    value: "StopTransactionOnEVSideDisconnect",
  },
  {
    name: "Stop Transaction On InvalidId(boolean)",
    value: "StopTransactionOnInvalidId",
  },
  {
    name: "Stop Txn Aligned Data(comma separated list)",
    value: "StopTxnAlignedData",
  },
  {
    name: "Stop Txn Sampled Data(comma separated list)",
    value: "StopTxnSampledData",
  },
  {
    name: "Transaction Message Attempts(in times)",
    value: "TransactionMessageAttempts",
  },
  {
    name: "Transaction Message Retry Interval(in seconds)",
    value: "TransactionMessageRetryInterval",
  },
  {
    name: "Unlock Connector On EV Side Disconnect(boolean)",
    value: "UnlockConnectorOnEVSideDisconnect",
  },
  {
    name: "Web Socket Ping Interval(in seconds)",
    value: "WebSocketPingInterval",
  },
  {
    name: "Local Auth List Enabled(boolean)",
    value: "LocalAuthListEnabled",
  },
];

export const resetData = [
  {
    id: "1",
    name: "Soft",
  },
  {
    id: "2",
    name: "Hard",
  },
];

// Tariff - management

export const TariffManagementColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  {
    id: "_id",
    label: "Tariff ID",
    align: "left",
  },
  { id: "name", label: "Tariff Name", align: "left" },
  { id: "electricityCharges", label: "Energy charges", align: "left" },
  { id: "parkingCharges", label: "Parking charges", align: "left" },
  { id: "baseCharges", label: "Base charge", align: "left" },
  { id: "plateformFee", label: "Platform fee (%)", align: "left" },
  { id: "tax", label: "Tax (%)", align: "left" },
  { id: "otherCharges", label: "Other charges ", align: "left" },
  { id: "createdBy", label: "Created by", align: "left" },
  { id: "createdAt", label: "Created At", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const TariffManagementData = [
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "active",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "active",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "inactive",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "active",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "inactive",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "inactive",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "active",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "inactive",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "inactive",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "active",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "inactive",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    name: "Test Tariff",
    energyCharges: "₹15",
    parkingChargers: "₹0",
    baseCharge: "₹0",
    createdBy: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
    tariffStatus: "active",
  },
];

// company management

export const companyMangementColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "name",
      label: "Company Name",
      align: "left",
    },
    { id: "stationsCount", label: "Stations*", align: "left" },
    { id: "chargingPointCount", label: "Chargers*", align: "left" },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "owner", label: "Admin", align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ];
};

export const companyMangementData = [
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    companyName: "Massive Mobility",
    stations: "2",
    chargers: "11",
    totalRevenue: "₹1,45,000",
    admin: "Rakesh Singh",
    createdOn: "24/08/2023 23:08 PM",
  },
];

// comapny management - charging stations

export const companyMangementChargingColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "name",
      label: "Station Name",
      align: "left",
    },
    { id: "_id", label: "Station ID", align: "left" },
    { id: "chargers", label: "Chargers No.", align: "left" },
    { id: "sessions", label: "Sessions*", align: "left" },
    {
      id: "revenue",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyDelivered", label: "Energy Consumed* (kWh)", align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ];
};

export const companyMangementChargingData = [
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
  {
    stationName: "Noida City Center",
    code: "45GHDG54",
    chargers: "25",
    sessions: "617",
    revenue: "₹25,564",
    energyDelivered: "2.75kWh",
    createdOn: "24/08/2023 23:08 PM",
  },
];

// payment Table

export const paymentColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "paymentId",
      label: "Payment ID",
      align: "left",
    },
    { id: "userId", label: "Customer ID", align: "left" },
    { id: "deviceId", label: "Device Id", align: "left" },
    { id: "station", label: "Station Name", align: "left" },
    { id: "amount", label: `Amount (${currencySymbol})`, align: "left" },
    { id: "type", label: "Type", align: "left" },
    { id: "mode", label: "Mode", align: "left" },
    { id: "createdAt", label: "Date", align: "left" },
  ];
};

export const paymentColumnsForCPO = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "sessionID",
      label: "Session ID",
      align: "left",
    },
    { id: "userId", label: "Customer ID", align: "left" },
    { id: "deviceId", label: "Device Id", align: "left" },
    { id: "station", label: "Station Name", align: "left" },
    { id: "amount", label: `Amount (${currencySymbol})`, align: "left" },
    { id: "mode", label: "Mode", align: "left" },
    { id: "createdAt", label: "Date", align: "left" },
  ];
};

export const paymentData = [
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "Refund Amount (₹)",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "Refund Amount (₹)",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "Refund Amount (₹)",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "Refund Amount (₹)",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "Refund Amount (₹)",
  },
  {
    paymentID: "669b61779f8b0709525a6068",
    userID: "85621 54879",
    amount: "₹15",
    type: "Wallet Recharge",
    mode: "App",
    date: "24/08/2023 23:08 PM",
    paymentStatus: "credited",
  },
];

export const adminManagementColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  { id: "org", label: "Organization", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "phoneNumber", label: "Contact No.", align: "left" },
  { id: "role", label: "User role", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const adminManagementData = [
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "active",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "deactived",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "active",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "deactived",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "active",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "deactived",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "deactived",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "active",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "deactived",
  },
  {
    name: "Narendra Gandhi",
    organization: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "active",
  },
];

// statistics - overall - table

//overall
export const statisticsOverAllColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  {
    id: "sessionID",
    label: "Session ID",
    align: "left",
  },
  { id: "transactionID", label: "Transaction ID", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "contactNo", label: "Contact No.", align: "left" },
  { id: "userRole", label: "User role", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const statisticsOverAllData = [
  {
    sessionID: "Narendra Gandhi",
    transactionID: "Massive Mobility",
    email: "narendragandhi@gmail.com",
    contactNo: "+91 84579 15423",
    userRole: "Admin",
    adminStatus: "active",
  },
];

//organization
export const statisticsOrganizationColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "name",
      label: "Company Name",
      align: "left",
    },
    {
      id: "energyConsumed",
      label: "Energy Consumed (kWh)",
      align: "left",
    },

    {
      id: "paidAmount",
      label: `Entered Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "chargingAmount",
      label: `Billed Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "sessionDuration",
      label: "Session Duration ( h:m:s )",
      align: "left",
    },
    {
      id: "refundAmount",
      label: `Refund Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "sessionCount",
      label: "Sessions",
      align: "left",
    },
  ];
};

export const statisticsOrganizationData = [
  {
    name: "Massive Mobility",
    unitConsume: 80,
    amountPaid: 200,
    chargingAmount: 300,
    totalChargingDuration: 10,
    refunds: 10,
    noOfChargingSessions: 2,
  },
  {
    name: "Google",
    unitConsume: 90,
    amountPaid: 210,
    chargingAmount: 310,
    totalChargingDuration: 12,
    refunds: 8,
    noOfChargingSessions: 3,
  },
  {
    name: "Apple",
    unitConsume: 85,
    amountPaid: 195,
    chargingAmount: 305,
    totalChargingDuration: 11,
    refunds: 9,
    noOfChargingSessions: 2,
  },
  {
    name: "Microsoft",
    unitConsume: 75,
    amountPaid: 185,
    chargingAmount: 295,
    totalChargingDuration: 9,
    refunds: 11,
    noOfChargingSessions: 3,
  },
  {
    name: "Amazon",
    unitConsume: 70,
    amountPaid: 180,
    chargingAmount: 290,
    totalChargingDuration: 8,
    refunds: 12,
    noOfChargingSessions: 4,
  },
  {
    name: "Facebook",
    unitConsume: 95,
    amountPaid: 225,
    chargingAmount: 325,
    totalChargingDuration: 13,
    refunds: 7,
    noOfChargingSessions: 2,
  },
  {
    name: "Netflix",
    unitConsume: 88,
    amountPaid: 208,
    chargingAmount: 308,
    totalChargingDuration: 10,
    refunds: 10,
    noOfChargingSessions: 3,
  },
];

//station
export const statisticsStationColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "name",
      label: "Station Name",
      align: "left",
    },
    {
      id: "energyConsumed",
      label: "Energy Consumed (kWh)",
      align: "left",
    },

    {
      id: "paidAmount",
      label: `Entered Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "chargingAmount",
      label: `Billed Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "sessionDuration",
      label: "Session Duration ( h:m:s )",
      align: "left",
    },
    {
      id: "refundAmount",
      label: `Refund Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "sessionCount",
      label: "Sessions",
      align: "left",
    },
  ];
};

export const statisticsStationData = [
  {
    stationName: "Station 1",
    unitConsume: 80,
    amountPaid: 200,
    chargingAmount: 300,
    totalChargingDuration: 10,
    refunds: 10,
    noOfChargingSessions: 2,
  },
  {
    stationName: "Station 2",
    unitConsume: 90,
    amountPaid: 210,
    chargingAmount: 310,
    totalChargingDuration: 12,
    refunds: 8,
    noOfChargingSessions: 3,
  },
  {
    stationName: "Station 3",
    unitConsume: 85,
    amountPaid: 195,
    chargingAmount: 305,
    totalChargingDuration: 11,
    refunds: 9,
    noOfChargingSessions: 2,
  },
  // Add more stations here
];

//charger
export const statisticsChargerColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    {
      id: "name",
      label: "Device ID",
      align: "left",
    },
    {
      id: "energyConsumed",
      label: "Energy Consumed* (kWh)",
      align: "left",
    },

    {
      id: "paidAmount",
      label: `Entered Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "chargingAmount",
      label: `Billed Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "sessionDuration",
      label: "Session Duration ( h:m:s )",
      align: "left",
    },
    {
      id: "refundAmount",
      label: `Refund Amount (${currencySymbol})`,
      align: "left",
    },
    {
      id: "sessionCount",
      label: "Sessions",
      align: "left",
    },
  ];
};
export const statisticsChargerData = [
  {
    deviceId: "Device 1",
    unitConsume: 80,
    amountPaid: 200,
    chargingAmount: 300,
    totalChargingDuration: 10,
    refunds: 10,
    noOfChargingSessions: 2,
  },
  {
    deviceId: "Device 2",
    unitConsume: 90,
    amountPaid: 210,
    chargingAmount: 310,
    totalChargingDuration: 12,
    refunds: 8,
    noOfChargingSessions: 3,
  },
  {
    deviceId: "Device 3",
    unitConsume: 85,
    amountPaid: 195,
    chargingAmount: 305,
    totalChargingDuration: 11,
    refunds: 9,
    noOfChargingSessions: 2,
  },
  // Add more devices here
];

//org data
export const orgData = {
  week: {
    Google: [
      {
        date: "2024-07-01",
        unitConsume: 10,
        amountPaid: 20,
        chargingAmount: 30,
        noOfChargingSessions: 4,
      },
      {
        date: "2024-07-02",
        unitConsume: 15,
        amountPaid: 25,
        chargingAmount: 35,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-03",
        unitConsume: 12,
        amountPaid: 22,
        chargingAmount: 32,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-04",
        unitConsume: 18,
        amountPaid: 28,
        chargingAmount: 38,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-05",
        unitConsume: 20,
        amountPaid: 30,
        chargingAmount: 40,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-06",
        unitConsume: 25,
        amountPaid: 35,
        chargingAmount: 45,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-07",
        unitConsume: 30,
        amountPaid: 40,
        chargingAmount: 50,
        noOfChargingSessions: 10,
      },
    ],
    Microsoft: [
      {
        date: "2024-07-01",
        unitConsume: 20,
        amountPaid: 50,
        chargingAmount: 60,
        noOfChargingSessions: 4,
      },
      {
        date: "2024-07-02",
        unitConsume: 22,
        amountPaid: 52,
        chargingAmount: 62,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-03",
        unitConsume: 24,
        amountPaid: 54,
        chargingAmount: 64,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-04",
        unitConsume: 26,
        amountPaid: 56,
        chargingAmount: 66,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-05",
        unitConsume: 28,
        amountPaid: 58,
        chargingAmount: 68,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-06",
        unitConsume: 30,
        amountPaid: 60,
        chargingAmount: 70,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-07",
        unitConsume: 32,
        amountPaid: 62,
        chargingAmount: 72,
        noOfChargingSessions: 10,
      },
    ],
    Amazon: [
      {
        date: "2024-07-01",
        unitConsume: 50,
        amountPaid: 100,
        chargingAmount: 150,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-02",
        unitConsume: 55,
        amountPaid: 105,
        chargingAmount: 155,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-03",
        unitConsume: 60,
        amountPaid: 110,
        chargingAmount: 160,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-04",
        unitConsume: 65,
        amountPaid: 115,
        chargingAmount: 165,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-05",
        unitConsume: 70,
        amountPaid: 120,
        chargingAmount: 170,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-06",
        unitConsume: 75,
        amountPaid: 125,
        chargingAmount: 175,
        noOfChargingSessions: 10,
      },
      {
        date: "2024-07-07",
        unitConsume: 80,
        amountPaid: 130,
        chargingAmount: 180,
        noOfChargingSessions: 11,
      },
    ],
    Massive: [
      {
        date: "2024-07-01",
        unitConsume: 35,
        amountPaid: 70,
        chargingAmount: 105,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-02",
        unitConsume: 40,
        amountPaid: 80,
        chargingAmount: 120,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-03",
        unitConsume: 45,
        amountPaid: 90,
        chargingAmount: 135,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-04",
        unitConsume: 50,
        amountPaid: 100,
        chargingAmount: 150,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-05",
        unitConsume: 55,
        amountPaid: 110,
        chargingAmount: 165,
        noOfChargingSessions: 10,
      },
      {
        date: "2024-07-06",
        unitConsume: 60,
        amountPaid: 120,
        chargingAmount: 180,
        noOfChargingSessions: 11,
      },
      {
        date: "2024-07-07",
        unitConsume: 65,
        amountPaid: 130,
        chargingAmount: 195,
        noOfChargingSessions: 12,
      },
    ],
    Netflix: [
      {
        date: "2024-07-01",
        unitConsume: 25,
        amountPaid: 60,
        chargingAmount: 85,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-02",
        unitConsume: 30,
        amountPaid: 70,
        chargingAmount: 100,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-03",
        unitConsume: 35,
        amountPaid: 80,
        chargingAmount: 115,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-04",
        unitConsume: 40,
        amountPaid: 90,
        chargingAmount: 130,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-05",
        unitConsume: 45,
        amountPaid: 100,
        chargingAmount: 145,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-06",
        unitConsume: 50,
        amountPaid: 110,
        chargingAmount: 160,
        noOfChargingSessions: 10,
      },
      {
        date: "2024-07-07",
        unitConsume: 55,
        amountPaid: 120,
        chargingAmount: 175,
        noOfChargingSessions: 11,
      },
    ],
    Flipkart: [
      {
        date: "2024-07-01",
        unitConsume: 15,
        amountPaid: 30,
        chargingAmount: 45,
        noOfChargingSessions: 3,
      },
      {
        date: "2024-07-02",
        unitConsume: 20,
        amountPaid: 40,
        chargingAmount: 60,
        noOfChargingSessions: 4,
      },
      {
        date: "2024-07-03",
        unitConsume: 25,
        amountPaid: 50,
        chargingAmount: 75,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-04",
        unitConsume: 30,
        amountPaid: 60,
        chargingAmount: 90,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-05",
        unitConsume: 35,
        amountPaid: 70,
        chargingAmount: 105,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-06",
        unitConsume: 40,
        amountPaid: 80,
        chargingAmount: 120,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-07",
        unitConsume: 45,
        amountPaid: 90,
        chargingAmount: 135,
        noOfChargingSessions: 9,
      },
    ],
  },
  month: {
    Google: Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 100),
      amountPaid: Math.floor(Math.random() * 200),
      chargingAmount: Math.floor(Math.random() * 300),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    Microsoft: Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 200),
      amountPaid: Math.floor(Math.random() * 400),
      chargingAmount: Math.floor(Math.random() * 600),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    Amazon: Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 300),
      amountPaid: Math.floor(Math.random() * 600),
      chargingAmount: Math.floor(Math.random() * 900),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    Massive: Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 200),
      amountPaid: Math.floor(Math.random() * 400),
      chargingAmount: Math.floor(Math.random() * 600),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    Netflix: Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 150),
      amountPaid: Math.floor(Math.random() * 300),
      chargingAmount: Math.floor(Math.random() * 450),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    Flipkart: Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 120),
      amountPaid: Math.floor(Math.random() * 240),
      chargingAmount: Math.floor(Math.random() * 360),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
  },
  year: {
    Google: [
      {
        date: "2023-01-01",
        unitConsume: 3600,
        amountPaid: 7200,
        chargingAmount: 10800,
        noOfChargingSessions: 400,
      },
      {
        date: "2024-01-01",
        unitConsume: 4800,
        amountPaid: 9600,
        chargingAmount: 14400,
        noOfChargingSessions: 500,
      },
    ],
    Microsoft: [
      {
        date: "2023-01-01",
        unitConsume: 7200,
        amountPaid: 18000,
        chargingAmount: 21600,
        noOfChargingSessions: 600,
      },
      {
        date: "2024-01-01",
        unitConsume: 8400,
        amountPaid: 19200,
        chargingAmount: 22800,
        noOfChargingSessions: 700,
      },
    ],
    Amazon: [
      {
        date: "2023-01-01",
        unitConsume: 14400,
        amountPaid: 28800,
        chargingAmount: 43200,
        noOfChargingSessions: 800,
      },
      {
        date: "2024-01-01",
        unitConsume: 16800,
        amountPaid: 33600,
        chargingAmount: 50400,
        noOfChargingSessions: 900,
      },
    ],
    Massive: [
      {
        date: "2023-01-01",
        unitConsume: 9600,
        amountPaid: 19200,
        chargingAmount: 28800,
        noOfChargingSessions: 500,
      },
      {
        date: "2024-01-01",
        unitConsume: 10800,
        amountPaid: 21600,
        chargingAmount: 32400,
        noOfChargingSessions: 600,
      },
    ],
    Netflix: [
      {
        date: "2023-01-01",
        unitConsume: 12000,
        amountPaid: 24000,
        chargingAmount: 36000,
        noOfChargingSessions: 700,
      },
      {
        date: "2024-01-01",
        unitConsume: 13200,
        amountPaid: 26400,
        chargingAmount: 39600,
        noOfChargingSessions: 800,
      },
    ],
    Flipkart: [
      {
        date: "2023-01-01",
        unitConsume: 8400,
        amountPaid: 16800,
        chargingAmount: 25200,
        noOfChargingSessions: 400,
      },
      {
        date: "2024-01-01",
        unitConsume: 9600,
        amountPaid: 19200,
        chargingAmount: 28800,
        noOfChargingSessions: 500,
      },
    ],
  },
};
//station data
export const stationData123 = {
  week: {
    "Station 1": [
      {
        date: "2024-07-01",
        unitConsume: 10,
        amountPaid: 20,
        chargingAmount: 30,
        noOfChargingSessions: 4,
      },
      {
        date: "2024-07-02",
        unitConsume: 15,
        amountPaid: 25,
        chargingAmount: 35,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-03",
        unitConsume: 12,
        amountPaid: 22,
        chargingAmount: 32,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-04",
        unitConsume: 18,
        amountPaid: 28,
        chargingAmount: 38,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-05",
        unitConsume: 20,
        amountPaid: 30,
        chargingAmount: 40,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-06",
        unitConsume: 25,
        amountPaid: 35,
        chargingAmount: 45,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-07",
        unitConsume: 30,
        amountPaid: 40,
        chargingAmount: 50,
        noOfChargingSessions: 10,
      },
    ],
    "Station 2": [
      {
        date: "2024-07-01",
        unitConsume: 20,
        amountPaid: 50,
        chargingAmount: 60,
        noOfChargingSessions: 4,
      },
      {
        date: "2024-07-02",
        unitConsume: 22,
        amountPaid: 52,
        chargingAmount: 62,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-03",
        unitConsume: 24,
        amountPaid: 54,
        chargingAmount: 64,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-04",
        unitConsume: 26,
        amountPaid: 56,
        chargingAmount: 66,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-05",
        unitConsume: 28,
        amountPaid: 58,
        chargingAmount: 68,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-06",
        unitConsume: 30,
        amountPaid: 60,
        chargingAmount: 70,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-07",
        unitConsume: 32,
        amountPaid: 62,
        chargingAmount: 72,
        noOfChargingSessions: 10,
      },
    ],
    "Station 3": [
      {
        date: "2024-07-01",
        unitConsume: 50,
        amountPaid: 100,
        chargingAmount: 150,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-02",
        unitConsume: 55,
        amountPaid: 105,
        chargingAmount: 155,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-03",
        unitConsume: 60,
        amountPaid: 110,
        chargingAmount: 160,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-04",
        unitConsume: 65,
        amountPaid: 115,
        chargingAmount: 165,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-05",
        unitConsume: 70,
        amountPaid: 120,
        chargingAmount: 170,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-06",
        unitConsume: 75,
        amountPaid: 125,
        chargingAmount: 175,
        noOfChargingSessions: 10,
      },
      {
        date: "2024-07-07",
        unitConsume: 80,
        amountPaid: 130,
        chargingAmount: 180,
        noOfChargingSessions: 11,
      },
    ],
    "Station 4": [
      {
        date: "2024-07-01",
        unitConsume: 35,
        amountPaid: 70,
        chargingAmount: 105,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-02",
        unitConsume: 40,
        amountPaid: 80,
        chargingAmount: 120,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-03",
        unitConsume: 45,
        amountPaid: 90,
        chargingAmount: 135,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-04",
        unitConsume: 50,
        amountPaid: 100,
        chargingAmount: 150,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-05",
        unitConsume: 55,
        amountPaid: 110,
        chargingAmount: 165,
        noOfChargingSessions: 10,
      },
      {
        date: "2024-07-06",
        unitConsume: 60,
        amountPaid: 120,
        chargingAmount: 180,
        noOfChargingSessions: 11,
      },
      {
        date: "2024-07-07",
        unitConsume: 65,
        amountPaid: 130,
        chargingAmount: 195,
        noOfChargingSessions: 12,
      },
    ],
    "Station 5": [
      {
        date: "2024-07-01",
        unitConsume: 25,
        amountPaid: 60,
        chargingAmount: 85,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-02",
        unitConsume: 30,
        amountPaid: 70,
        chargingAmount: 100,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-03",
        unitConsume: 35,
        amountPaid: 80,
        chargingAmount: 115,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-04",
        unitConsume: 40,
        amountPaid: 90,
        chargingAmount: 130,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-05",
        unitConsume: 45,
        amountPaid: 100,
        chargingAmount: 145,
        noOfChargingSessions: 9,
      },
      {
        date: "2024-07-06",
        unitConsume: 50,
        amountPaid: 110,
        chargingAmount: 160,
        noOfChargingSessions: 10,
      },
      {
        date: "2024-07-07",
        unitConsume: 55,
        amountPaid: 120,
        chargingAmount: 175,
        noOfChargingSessions: 11,
      },
    ],
    "Station 6": [
      {
        date: "2024-07-01",
        unitConsume: 15,
        amountPaid: 30,
        chargingAmount: 45,
        noOfChargingSessions: 3,
      },
      {
        date: "2024-07-02",
        unitConsume: 20,
        amountPaid: 40,
        chargingAmount: 60,
        noOfChargingSessions: 4,
      },
      {
        date: "2024-07-03",
        unitConsume: 25,
        amountPaid: 50,
        chargingAmount: 75,
        noOfChargingSessions: 5,
      },
      {
        date: "2024-07-04",
        unitConsume: 30,
        amountPaid: 60,
        chargingAmount: 90,
        noOfChargingSessions: 6,
      },
      {
        date: "2024-07-05",
        unitConsume: 35,
        amountPaid: 70,
        chargingAmount: 105,
        noOfChargingSessions: 7,
      },
      {
        date: "2024-07-06",
        unitConsume: 40,
        amountPaid: 80,
        chargingAmount: 120,
        noOfChargingSessions: 8,
      },
      {
        date: "2024-07-07",
        unitConsume: 45,
        amountPaid: 90,
        chargingAmount: 135,
        noOfChargingSessions: 9,
      },
    ],
  },
  month: {
    "Station 1": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 100),
      amountPaid: Math.floor(Math.random() * 200),
      chargingAmount: Math.floor(Math.random() * 300),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    "Station 2": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 200),
      amountPaid: Math.floor(Math.random() * 400),
      chargingAmount: Math.floor(Math.random() * 600),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    "Station 3": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 300),
      amountPaid: Math.floor(Math.random() * 600),
      chargingAmount: Math.floor(Math.random() * 900),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    "Station 4": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 200),
      amountPaid: Math.floor(Math.random() * 400),
      chargingAmount: Math.floor(Math.random() * 600),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    "Station 5": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 150),
      amountPaid: Math.floor(Math.random() * 300),
      chargingAmount: Math.floor(Math.random() * 450),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
    "Station 6": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 120),
      amountPaid: Math.floor(Math.random() * 240),
      chargingAmount: Math.floor(Math.random() * 360),
      noOfChargingSessions: Math.floor(Math.random() * 10),
    })),
  },
  year: {
    "Station 1": [
      {
        date: "2023-01-01",
        unitConsume: 3600,
        amountPaid: 7200,
        chargingAmount: 10800,
        noOfChargingSessions: 400,
      },
      {
        date: "2024-01-01",
        unitConsume: 4800,
        amountPaid: 9600,
        chargingAmount: 14400,
        noOfChargingSessions: 500,
      },
    ],
    "Station 2": [
      {
        date: "2023-01-01",
        unitConsume: 7200,
        amountPaid: 18000,
        chargingAmount: 21600,
        noOfChargingSessions: 600,
      },
      {
        date: "2024-01-01",
        unitConsume: 8400,
        amountPaid: 19200,
        chargingAmount: 22800,
        noOfChargingSessions: 700,
      },
    ],
    "Station 3": [
      {
        date: "2023-01-01",
        unitConsume: 14400,
        amountPaid: 28800,
        chargingAmount: 43200,
        noOfChargingSessions: 800,
      },
      {
        date: "2024-01-01",
        unitConsume: 16800,
        amountPaid: 33600,
        chargingAmount: 50400,
        noOfChargingSessions: 900,
      },
    ],
    "Station 4": [
      {
        date: "2023-01-01",
        unitConsume: 9600,
        amountPaid: 19200,
        chargingAmount: 28800,
        noOfChargingSessions: 500,
      },
      {
        date: "2024-01-01",
        unitConsume: 10800,
        amountPaid: 21600,
        chargingAmount: 32400,
        noOfChargingSessions: 600,
      },
    ],
    "Station 5": [
      {
        date: "2023-01-01",
        unitConsume: 12000,
        amountPaid: 24000,
        chargingAmount: 36000,
        noOfChargingSessions: 700,
      },
      {
        date: "2024-01-01",
        unitConsume: 13200,
        amountPaid: 26400,
        chargingAmount: 39600,
        noOfChargingSessions: 800,
      },
    ],
    "Station 6": [
      {
        date: "2023-01-01",
        unitConsume: 8400,
        amountPaid: 16800,
        chargingAmount: 25200,
        noOfChargingSessions: 400,
      },
      {
        date: "2024-01-01",
        unitConsume: 9600,
        amountPaid: 19200,
        chargingAmount: 28800,
        noOfChargingSessions: 500,
      },
    ],
  },
};

// charger data
export const chargerData = {
  week: {
    "Charger 1": [
      {
        date: "2024-07-01",
        unitConsume: 10,
        amountPaid: 20,
        chargingAmount: 30,
        noOfChargingSessions: 4,
        refunds: 5,
        voltageValue: 248.51,
        currentValue: 0,
        energyValue: 65700.36,
        noOfLiveSessions: 19,
      },
      // ... rest of the data
    ],
    "Charger 2": [
      // ... data for Charger 2
    ],
    // ... data for other chargers
  },
  month: {
    "Charger 1": Array.from({ length: 30 }, (_, i) => ({
      date: `2024-07-${String(i + 1).padStart(2, "0")}`,
      unitConsume: Math.floor(Math.random() * 100),
      amountPaid: Math.floor(Math.random() * 200),
      chargingAmount: Math.floor(Math.random() * 300),
      noOfChargingSessions: Math.floor(Math.random() * 10),
      refunds: 5,
      voltageValue: 248.51,
      currentValue: 0,
      energyValue: 65700.36,
      noOfLiveSessions: Math.floor(Math.random() * 10) + 10,
    })),
    // ... data for other chargers
  },
  year: {
    "Charger 1": [
      {
        date: "2023-01-01",
        unitConsume: 3600,
        amountPaid: 7200,
        chargingAmount: 10800,
        noOfChargingSessions: 400,
        refunds: 5,
        voltageValue: 248.51,
        currentValue: 0,
        energyValue: 65700.36,
        noOfLiveSessions: 19,
      },
      // ... rest of the data
    ],
    // ... data for other chargers
  },
};

//OCPI Management

// ocpi charger
export const ocpiChargerColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "nestedDeviceId", label: "Device ID", align: "left" },
  { id: "nestedOperatorId", label: "Network", align: "left" },
  { id: "nestedStatus", label: "Status", align: "left" },

  { id: "nestedNoOfConnectors", label: "No. of Connector", align: "left" },
  { id: "nestedStandards", label: "Charger type", align: "left" },

  { id: "nestedSessionCount", label: "Sessions*", align: "left" },
  { id: "nestedEnergyConsumed", label: "Energy Consumed (kWh)", align: "left" },
  { id: "nestedBilledAmount", label: "Billed Amount* (₹)", align: "left" },
  { id: "nestedTariffId", label: "Tariff Name", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "nestedLastUpdated", label: "Updated On", align: "left" },
  { id: "registeredOn", label: "Registered On", align: "left" },
];

export const ocpiChargerRowData = [
  {
    deviceID: "45GHDG54",
    network: "Tata Power Corporation",
    status: "Online",
    chargerType: "DC",
    connectors: "2",
    chargingSessionsCount: "5",
    maxPower: "3000",
    chargingAmount: "647",
    energyConsumed: "110",
    cessValue: "13.5",
    tariff: "Test Tariff",
    city: "Noida",
    updatedOn: "24/12/1998",
    registeredOn: "24/10/1998",
  },
  {
    deviceID: "76JHG34L",
    network: "Ather Energy",
    status: "Online",
    chargerType: "AC",
    connectors: "1",
    chargingSessionsCount: "3",
    maxPower: "1500",
    chargingAmount: "300",
    energyConsumed: "50",
    cessValue: "5.5",
    tariff: "Standard Tariff",
    city: "Bangalore",
    updatedOn: "24/12/2021",
    registeredOn: "12/05/2020",
  },
  {
    deviceID: "98KHG57T",
    network: "Indian Oil",
    status: "Online",
    chargerType: "DC",
    connectors: "3",
    chargingSessionsCount: "10",
    maxPower: "3500",
    chargingAmount: "900",
    energyConsumed: "180",
    cessValue: "15.0",
    tariff: "Premium Tariff",
    city: "Delhi",
    updatedOn: "14/12/2024",
    registeredOn: "15/08/2021",
  },
  {
    deviceID: "54JKL89M",
    network: "Reliance",
    status: "Online",
    chargerType: "AC",
    connectors: "2",
    chargingSessionsCount: "8",
    maxPower: "2000",
    chargingAmount: "500",
    energyConsumed: "120",
    cessValue: "12.0",
    tariff: "Economy Tariff",
    city: "Mumbai",
    updatedOn: "24/12/1800",
    registeredOn: "30/01/2019",
  },
  {
    deviceID: "32POI76D",
    network: "Fortum",
    status: "Offline",
    chargerType: "DC",
    connectors: "2",
    chargingSessionsCount: "4",
    maxPower: "3000",
    chargingAmount: "700",
    energyConsumed: "130",
    cessValue: "10.0",
    tariff: "Standard Tariff",
    city: "Hyderabad",
    updatedOn: "4/12/1998",
    registeredOn: "22/07/2022",
  },
  {
    deviceID: "87ASD45X",
    network: "EVgo",
    status: "Offline",
    chargerType: "AC",
    connectors: "1",
    chargingSessionsCount: "6",
    maxPower: "1500",
    chargingAmount: "350",
    energyConsumed: "70",
    cessValue: "8.5",
    tariff: "Basic Tariff",
    city: "Pune",
    updatedOn: "24/09/1999",
    registeredOn: "10/11/2020",
  },
  {
    deviceID: "21WXY78N",
    network: "ChargePoint",
    status: "Faulted",
    chargerType: "DC",
    connectors: "4",
    chargingSessionsCount: "12",
    maxPower: "4000",
    chargingAmount: "1200",
    energyConsumed: "240",
    cessValue: "20.0",
    tariff: "Premium Tariff",
    city: "Chennai",
    updatedOn: "24/12/1998",
    registeredOn: "18/03/2023",
  },
  {
    deviceID: "90POIU23",
    network: "BP Chargemaster",
    status: "Online",
    chargerType: "AC",
    connectors: "2",
    chargingSessionsCount: "7",
    maxPower: "2000",
    chargingAmount: "550",
    energyConsumed: "110",
    cessValue: "10.0",
    tariff: "Standard Tariff",
    city: "Ahmedabad",
    updatedOn: "24/12/1998",
    registeredOn: "29/09/2021",
  },
  {
    deviceID: "45TYUI67",
    network: "Shell Recharge",
    status: "Online",
    chargerType: "DC",
    connectors: "3",
    chargingSessionsCount: "9",
    maxPower: "3500",
    chargingAmount: "800",
    energyConsumed: "150",
    cessValue: "12.5",
    tariff: "Economy Tariff",
    city: "Kolkata",
    updatedOn: "24/12/1998",
    registeredOn: "04/02/2022",
  },
];

// ocpi tariffs
export const ocpiTariffColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "_id", label: "ID", align: "left" },
  { id: "id", label: "Name", align: "left" },
  { id: "updatedAt", label: "Updated Time", align: "left" },
  // { id: "action", label: "Action", align: "left" },
];

export const ocpiTariffRowData = [
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
];

//ocpi network main table
export const ocpiNetworkColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "name", label: "Operator Name", align: "left" },
  { id: "operatorId", label: "Operator ID", align: "left" },
  { id: "ocpiLocationCount", label: "Stations Connected", align: "left" },
  { id: "ocpiEvsesCount", label: "Charging Points", align: "left" },
  { id: "createdAt", label: "Created on", align: "left" },
  { id: "nestedStatus", label: "Status", align: "left" },
];

export const ocpiNetworkRowColumns = [
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "register",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "register",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "register",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.7",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.8",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.9",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
  {
    operatorName: "Tata Power Corporation",
    connectedStation: "2",
    connectedCP: "15",
    CessValue: "1.5",
    createdDate: "23:08, 24/08/2023 ",
    status: "registered",
  },
];

// OCPI MANAGEMENT DETAILS

export const ocpiDetailsChargerColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "nestedDeviceId", label: "Device ID", align: "left" },
  { id: "nestedOperatorId", label: "Network", align: "left" },
  { id: "nestedStatus", label: "Status", align: "left" },

  { id: "nestedNoOfConnectors", label: "No. of Connector", align: "left" },
  { id: "nestedStandards", label: "Charger type", align: "left" },

  { id: "nestedSessionCount", label: "Sessions*", align: "left" },
  { id: "nestedEnergyConsumed", label: "Energy Consumed (kWh)", align: "left" },
  { id: "nestedBilledAmount", label: "Billed Amount* (₹)", align: "left" },
  { id: "tariff", label: "Tariff Name", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "nestedLastUpdated", label: "Updated On", align: "left" },
  { id: "registeredOn", label: "Registered On", align: "left" },
];

export const ocpiDetailsChargerRowData = [
  {
    deviceID: "45GHDG54",
    network: "Tata Power Corporation",
    status: "Online",
    chargerType: "DC",
    connectors: "2",
    chargingSessionsCount: "5",
    maxPower: "3000",
    chargingAmount: "647",
    energyConsumed: "110",
    cessValue: "13.5",
    tariff: "Test Tariff",
    city: "Noida",
    updatedOn: "24/12/1998",
    registeredOn: "24/10/1998",
  },
  {
    deviceID: "76JHG34L",
    network: "Ather Energy",
    status: "Online",
    chargerType: "AC",
    connectors: "1",
    chargingSessionsCount: "3",
    maxPower: "1500",
    chargingAmount: "300",
    energyConsumed: "50",
    cessValue: "5.5",
    tariff: "Standard Tariff",
    city: "Bangalore",
    updatedOn: "24/12/1998",
    registeredOn: "12/05/2020",
  },
  {
    deviceID: "98KHG57T",
    network: "Indian Oil",
    status: "Online",
    chargerType: "DC",
    connectors: "3",
    chargingSessionsCount: "10",
    maxPower: "3500",
    chargingAmount: "900",
    energyConsumed: "180",
    cessValue: "15.0",
    tariff: "Premium Tariff",
    city: "Delhi",
    updatedOn: "24/12/1998",
    registeredOn: "15/08/2021",
  },
  {
    deviceID: "54JKL89M",
    network: "Reliance",
    status: "Online",
    chargerType: "AC",
    connectors: "2",
    chargingSessionsCount: "8",
    maxPower: "2000",
    chargingAmount: "500",
    energyConsumed: "120",
    cessValue: "12.0",
    tariff: "Economy Tariff",
    city: "Mumbai",
    updatedOn: "24/12/1998",
    registeredOn: "30/01/2019",
  },
  {
    deviceID: "32POI76D",
    network: "Fortum",
    status: "Offline",
    chargerType: "DC",
    connectors: "2",
    chargingSessionsCount: "4",
    maxPower: "3000",
    chargingAmount: "700",
    energyConsumed: "130",
    cessValue: "10.0",
    tariff: "Standard Tariff",
    city: "Hyderabad",
    updatedOn: "24/12/1998",
    registeredOn: "22/07/2022",
  },
  {
    deviceID: "87ASD45X",
    network: "EVgo",
    status: "Offline",
    chargerType: "AC",
    connectors: "1",
    chargingSessionsCount: "6",
    maxPower: "1500",
    chargingAmount: "350",
    energyConsumed: "70",
    cessValue: "8.5",
    tariff: "Basic Tariff",
    city: "Pune",
    updatedOn: "24/12/1998",
    registeredOn: "10/11/2020",
  },
  {
    deviceID: "21WXY78N",
    network: "ChargePoint",
    status: "Faulted",
    chargerType: "DC",
    connectors: "4",
    chargingSessionsCount: "12",
    maxPower: "4000",
    chargingAmount: "1200",
    energyConsumed: "240",
    cessValue: "20.0",
    tariff: "Premium Tariff",
    city: "Chennai",
    updatedOn: "24/12/1998",
    registeredOn: "18/03/2023",
  },
  {
    deviceID: "90POIU23",
    network: "BP Chargemaster",
    status: "Online",
    chargerType: "AC",
    connectors: "2",
    chargingSessionsCount: "7",
    maxPower: "2000",
    chargingAmount: "550",
    energyConsumed: "110",
    cessValue: "10.0",
    tariff: "Standard Tariff",
    city: "Ahmedabad",
    updatedOn: "24/12/1998",
    registeredOn: "29/09/2021",
  },
  {
    deviceID: "45TYUI67",
    network: "Shell Recharge",
    status: "Online",
    chargerType: "DC",
    connectors: "3",
    chargingSessionsCount: "9",
    maxPower: "3500",
    chargingAmount: "800",
    energyConsumed: "150",
    cessValue: "12.5",
    tariff: "Economy Tariff",
    city: "Kolkata",
    updatedOn: "24/12/1998",
    registeredOn: "04/02/2022",
  },
];

//OCPI Main Table - Mnaage
export const ocpiManageStationColumns = [
  {
    id: "name",
    label: "Station name (shared chargers)",
    align: "left",
  },
  { id: "locationId", label: "Code", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "network", label: "Network", align: "left" },
  { id: "updatedAt", label: "From Date", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const ocpiManageStationData = [
  {
    OcpiStationName: "Noida City Center",
    code: "HGDH54",
    network: "Massive Mobility",
    status: "active",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    OcpiStationName: "Delhi Metro Station",
    code: "DELT56",
    network: "Urban Chargers",
    status: "active",
    createdAt: "25/08/2023 12:15 PM",
  },
  {
    OcpiStationName: "Gurgaon Sector 45",
    code: "GSEK78",
    network: "City Chargers",
    status: "active",
    createdAt: "26/08/2023 08:30 AM",
  },
  {
    OcpiStationName: "Faridabad Sector 15",
    code: "FSES89",
    network: "Metro Mobility",
    status: "inactive",
    createdAt: "27/08/2023 10:45 AM",
  },
  {
    OcpiStationName: "Ghaziabad Station",
    code: "GZST90",
    network: "Electric Hub",
    status: "active",
    createdAt: "28/08/2023 14:50 PM",
  },
  {
    OcpiStationName: "Greater Noida Park",
    code: "GNPQ12",
    network: "Green Chargers",
    status: "active",
    createdAt: "29/08/2023 16:00 PM",
  },
  {
    OcpiStationName: "Noida Sector 18",
    code: "NS18K3",
    network: "NextGen Mobility",
    status: "active",
    createdAt: "30/08/2023 09:05 AM",
  },
  {
    OcpiStationName: "Delhi Indraprastha",
    code: "DIP45L",
    network: "Urban Power",
    status: "active",
    createdAt: "31/08/2023 11:30 AM",
  },
  {
    OcpiStationName: "Gurgaon Cyber Hub",
    code: "GCH67X",
    network: "Future Mobility",
    status: "active",
    createdAt: "01/09/2023 13:20 PM",
  },
];

export const ocpiAddNewModalColumns = [
  // {
  //   id: "chargerName",
  //   label: "Charger Name",
  //   align: "left",
  // },
  { id: "deviceId", label: "Device ID", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "connectorType", label: "Charger Type", align: "left" },
  // { id: "action", label: "Tariff Select", align: "left" },

  { id: "selectTariff", label: "Select Tariff", align: "left" },
];

export const ocpiAddNewModalData = [
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Online",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Offline",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Faulted",
    chargerType: "AC",
  },
  // Duplicate the first three items for 3-4 times
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Online",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Offline",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Faulted",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Online",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Offline",
    chargerType: "AC",
  },
  {
    chargerName: "1C Urbana 60kW DC",
    deviceId: "HGDH54",
    status: "Faulted",
    chargerType: "AC",
  },
];

// finance - tabel

export const financePaymentHistoryColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "_id", label: "Payment ID", align: "left" },
  { id: "amount_added", label: "Added Amount(₹)", align: "left" },
  { id: "txnTime", label: "Date", align: "left" },
];

export const financeStationBalanceColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "paymentType", label: "Payment Type", align: "left" },
  { id: "amount_added", label: "Billed Amount (₹)", align: "left" },
  { id: "stationId", label: "Session ID", align: "left" },
  { id: "txnTime", label: "Date", align: "left" },
];

export const passbookColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "day", label: "Date", align: "left" },
  { id: "creditAmount", label: "Total Amount Added (₹)", align: "left" },
  { id: "debitAmount", label: "Billed Amount (₹)", align: "left" },
  { id: "startingBalance", label: "Starting Balance (₹)", align: "left" },
  { id: "endingBalance", label: "Ending Balance (₹)", align: "left" },
];

// RFID

export const rfidColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "_id", label: "Request ID", align: "left" },
  { id: "customer", label: "Customer name", align: "left" },
  { id: "phone", label: "Mobile number", align: "left" },
  { id: "createdAt", label: "Request date", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const retailRFID = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "customer", label: "Customer name", align: "left" },
  { id: "phone", label: "Mobile Number", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "expiry_date", label: "Validity", align: "left" },
  { id: "assignedByName", label: "Asigned By", align: "left" },
  { id: "assignedDate", label: "Asigned Date", align: "left" },
];

export const rejectedRFID = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "_id", label: "Request ID", align: "left" },
  { id: "customer", label: "Customer name", align: "left" },
  { id: "phone", label: "Mobile number", align: "left" },
  { id: "rejectedBy", label: "Rejected By", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "updatedAt", label: "Rejected On", align: "left" },
];

export const rejectedRFIDData = [
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
  {
    request_Id: "454GG54JHF",
    customer_name: "Ranbir Kapoor",
    phone: "+91 95641 25684",
    rejectedBy: "Manoj Sharma",
    status: "rejected",
    rejectedOn: "24/08/2023 23:08 PM",
  },
];

//HUB MANAGEMENT

// HM-SESSIONS
export const HubSessionsColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "station", label: "Station Name", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "sessionStart", label: "Start Time", align: "left" },
  { id: "sessionDuration", label: "Duration", align: "left" },
  { id: "energyConsumed", label: "Energy Consumed (kWh)", align: "left" },
  { id: "started_by", label: "Started By", align: "left" },
  { id: "vehicle_number", label: "Vehicle Number", align: "left" },
  { id: "vehicle_entry_time", label: "Vehicle Entry Time", align: "left" },
  { id: "vehicleDuration", label: "Vehicle Duration", align: "left" },
  { id: "deviceId", label: "Device ID", align: "left" },
  { id: "connectorId", label: "Connector ID", align: "left" },
  { id: "parking_num", label: "Parking No.", align: "left" },
  { id: "charging_pass_type", label: "Pass Type", align: "left" },
  { id: "passNumber", label: "Pass Id", align: "left" },
  { id: "purchase_date", label: "Purchase Date", align: "left" },
  { id: "validTill", label: "Valid Till", align: "left" },
  { id: "_id", label: "Session Id", align: "left" },
];

export const HubSessionsData = [
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    energy_consumed: "12kWh",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    charging_pass_type: "weekly",
    started_by: "guard",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
];

export const HubAllSessionsColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "station", label: "Station Name", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "sessionStart", label: "Start Time", align: "left" },
  { id: "sessionStop", label: "End Time", align: "left" },
  { id: "sessionDuration", label: "Duration", align: "left" },
  { id: "energyConsumed", label: "Energy Consumed (kWh)", align: "left" },
  { id: "stopReason", label: "Stop Reason", align: "left" },
  { id: "started_by", label: "Started By", align: "left" },
  { id: "vehicle_number", label: "Vehicle Number", align: "left" },
  { id: "vehicle_entry_time", label: "Vehicle Entry Time", align: "left" },
  { id: "vehicle_out_time", label: "Vehicle Exit Time", align: "left" },
  { id: "vehicleDuration", label: "Vehicle Duration", align: "left" },
  { id: "deviceId", label: "Device ID", align: "left" },
  { id: "connectorId", label: "Connector ID", align: "left" },
  { id: "parking_num", label: "Parking No.", align: "left" },
  { id: "charging_pass_type", label: "Pass Type", align: "left" },
  { id: "passNumber", label: "Pass Id", align: "left" },
  { id: "purchase_date", label: "Purchase Date", align: "left" },
  { id: "validTill", label: "Valid Till", align: "left" },
  { id: "_id", label: "Session Id", align: "left" },
];

export const HubAllSessionsData = [
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
  {
    station: "Spring House",
    deviceId: "454GG54JHF",
    connectorId: "2",
    charging_pass_type: "weekly",
    started_by: "guard",
    energy_consumed: "12kWh",
    stop_reason: "Power Cut Off",
    endTime: "24/08/2023 23:08 PM",
    vehicle_entry_time: "24/08/2023 23:08 PM",
    vehicle_out_time: "24/08/2023 23:08 PM",
    duration: "24/08/2023 23:08 PM",
    parking_num: "7",
    startTime: "24/08/2023 23:08 PM",
    sessionId: "1erfvfgf655hth56",
    vehicle_number: "HR26 BT 2024",
  },
];

// Hub Station

export const HubStationColumn = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "name", label: "Station Name", align: "left" },
  { id: "completed_session", label: "Completed Sessions", align: "left" },
  { id: "hubUsersCount", label: "No. of Gaurds", align: "left" },
  { id: "stationIncharge", label: "Station In Charge", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "totalCPCount", label: "Chargers", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export const HubStationData = [
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
  {
    _id: "fjsdfhsh35435",
    station: "Wave City Center",
    completed_session: "Guard Session 1",
    num_of_gaurds: "2",
    station_in_charge: "Umesh",
    city: "Noida",
    chargers: "10/10",
  },
];

// Pass holder

export const HubPassHolderColumn = [
  { id: "sr", label: "S.NO", align: "left" },
  // { id: "name", label: "Pass Holder Name", align: "left" },
  { id: "phone", label: "Mobile Number", align: "left" },
  { id: "stationName", label: "Station Name", align: "left" },
  { id: "city", label: "City", align: "left" },
  { id: "passName", label: "Pass Name", align: "left" },
  { id: "passId", label: "Pass Number", align: "left" },
  { id: "vehicleNumber", label: "Vehicle Number", align: "left" },
  { id: "purchaseDate", label: "Purchase Date", align: "left" },
  { id: "validTill", label: "Valid Till", align: "left" },
];

export const HubPassHolderData = [
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Sahil",
    station: "Wave City Center",
    phone: "+91 7878787887",
    pass_name: "New Pass",
    pass_number: "CR7",
    city: "Gurgaon",
    valid_till: "24/08/2023 23:08 PM",
    vehicle_number: "DL22 SG 2000",
    createdAt: "24/08/2023 23:08 PM",
  },
];

// Charging Passes
export const HubChargingPassesColumn = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "name", label: "Pass Name", align: "left" },
    { id: "stationId", label: "Station Name", align: "left" },
    { id: "vehicleType", label: "Vehicle Type", align: "left" },
    { id: "passType", label: "Pass Type", align: "left" },
    { id: "passPrice", label: `Pass Price (${currencySymbol})`, align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
    { id: "updatedAt", label: "Updated At", align: "left" },
  ];
};

export const HubChargingPassesData = [
  {
    name: "12hr Pass",
    pass_type: "12hr",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Weekly Pass",
    pass_type: "Weekly",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "12hr Pass",
    pass_type: "12hr",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Weekly Pass",
    pass_type: "Weekly",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "12hr Pass",
    pass_type: "12hr",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Weekly Pass",
    pass_type: "Weekly",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "12hr Pass",
    pass_type: "12hr",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Weekly Pass",
    pass_type: "Weekly",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "12hr Pass",
    pass_type: "12hr",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Weekly Pass",
    pass_type: "Weekly",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "12hr Pass",
    pass_type: "12hr",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
  {
    name: "Weekly Pass",
    pass_type: "Weekly",
    station: "Wave City Center",
    pass_price: "20",
    createdAt: "24/08/2023 23:08 PM",
    updatedAt: "24/08/2023 23:08 PM",
  },
];

// Collection

export const HubDailyCollectionColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "stationId", label: "Station Name", align: "left" },
    { id: "hubUser", label: "Guard Name", align: "left" },
    {
      id: "collectedAmount",
      label: `Guard Collection (${currencySymbol})`,
      align: "left",
    },
    { id: "passesCount", label: "No. of Passes (SOLD)", align: "left" },
    {
      id: "passValue",
      label: `Value of Passes (${currencySymbol})`,
      align: "left",
    },
    { id: "city", label: "City", align: "left" },
    { id: "createdAt", label: "Date", align: "left" },
  ];
};

export const HubDailyCollectionData = [
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_collection: "100",
    guard_name: "Mukesh",
    num_of_passes: "40",
    valuePass: "100",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
];

export const HubAllCollectionColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "station", label: "Station Name", align: "left" },
    { id: "guardName", label: "Guard Name", align: "left" },
    {
      id: "cashAmount",
      label: `Pass Value (${currencySymbol})`,
      align: "left",
    },
    { id: "passType", label: "Pass Type", align: "left" },
    { id: "passId", label: "Pass Number", align: "left" },
    { id: "passHolderName", label: "Pass Holder Name", align: "left" },
    { id: "passHolderPhone", label: "Pass Holder Mobile No.", align: "left" },
    { id: "city", label: "City", align: "left" },
    { id: "createdAt", label: "Purchase Date", align: "left" },
  ];
};

export const HubAllCollectionData = [
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
  {
    station: "Spring House",
    guard_name: "Mukesh",
    pass_value: "100",
    pass_type: "12hr",
    passNumber: "Test34567",
    passHolderName: "Dinesh",
    passHolderPhone: "+9144567897632",
    city: "Noida",
    date: "24/08/2023 23:08 PM",
  },
];

export const HubGaurdColumns = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "name", label: "Name", align: "left" },
    { id: "phone", label: "Phone", align: "left" },
    { id: "city", label: "City", align: "left" },
    { id: "stationName", label: "Station Name", align: "left" },
    {
      id: "collectedAmount",
      label: `Collection (${currencySymbol})*`,
      align: "left",
    },
    { id: "action", label: "Action", align: "left" },
  ];
};

export const HubGaurdData = [
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://qph.cf2.quoracdn.net/main-qimg-5fd3f2e70a30c0491affe3e41bcdd173-lq",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://i1.sndcdn.com/artworks-y1WcOJwtzfhThB5i-kDG3rg-t500x500.jpg",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
  {
    name: "Mukesh",
    phone: "+91 5647398765",
    station: "Wave City Center",
    guardImg:
      "https://dragonball.guru/wp-content/uploads/2021/03/goku-profile-e1616173641804-400x400.png",
    collection: "12000",
    city: "Gurgaon",
  },
];

export const HubGuardDetailsColumn = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "stationId", label: "Station Name", align: "left" },
  { id: "checkInTime", label: "Shift Start", align: "left" },
  { id: "checkOutTime", label: "Shift End", align: "left" },
  { id: "duration", label: "Duration", align: "left" },
  { id: "createdAt", label: "Date", align: "left" },
];

export const HubGuardDetailsData = [
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
  {
    station: "Wave City Center",
    shift_start: "24/08/2023 23:08 PM",
    shift_End: "24/08/2023 23:08 PM",
    duration: "Wave City Center",
    createdAt: "24/08/2023 23:08 PM",
  },
];

const dummyData = [
  {
    id: 1,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 3,
  },
  {
    id: 2,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 2,
  },
  {
    id: 3,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 1,
  },
  {
    id: 1,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 3,
  },
  {
    id: 2,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 2,
  },
  {
    id: 3,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 1,
  },
  {
    id: 1,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 3,
  },
  {
    id: 2,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 2,
  },
  {
    id: 3,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 1,
  },
  {
    id: 1,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 3,
  },
  {
    id: 2,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 2,
  },
  {
    id: 3,
    deviceId: "FSHDSJ455J",
    connectorId: "3",
    connectorType: "Socket",
    parkingNumber: 1,
  },
];

// one shift transaction
export const HubOneShiftTransaction = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "cashAmount", label: "Pass Value", align: "left" },
    { id: "passType", label: `Pass Type (${currencySymbol})`, align: "left" },
    { id: "passId", label: "Pass Number", align: "left" },
    { id: "passHolderName", label: "Pass Holder Name", align: "left" },
    { id: "passHolderPhone", label: "Pass Holder Mobile No.", align: "left" },
    { id: "city", label: "City", align: "left" },
    { id: "createdAt", label: "Purchase Date", align: "left" },
  ];
};

// ocpi tariffs
export const ocpiOneNetworkAllTariffColumns = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "_id", label: "ID", align: "left" },
  { id: "id", label: "Name", align: "left" },
  { id: "updatedAt", label: "Updated Time", align: "left" },
  // { id: "action", label: "Action", align: "left" },
];

export const ocpiOneNetworkAllTariffRowData = [
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
  {
    tariffId: "669b61779f8b0709525a6068",
    tariffName: "Test Tariff",
    lastUpdated: "23:08, 24/08/2023 ",
    action: "delete",
  },
];

export const allChargingLocationsOcpiColumnData = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "name", label: "Station Name", align: "left" },

    {
      id: "chargingPointCount",
      label: "Chargers",
      align: "left",
    },
    { id: "sessionCount", label: "Sessions*", align: "left" },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyConsumed", label: "Energy Consumed* (kWh)", align: "left" },
    { id: "_id", label: "Station ID", align: "left" },
    { id: "last_updated", label: "Created At", align: "left" },
  ];
};

export const getOneNetworkAllLocationsOcpiColumnData = () => {
  const currencySymbol = GetCurrencySymbol();
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "name", label: "Station Name", align: "left" },

    {
      id: "chargingPointCount",
      label: "Chargers",
      align: "left",
    },
    { id: "sessionCount", label: "Sessions*", align: "left" },
    {
      id: "chargingAmount",
      label: `Billed Amount* (${currencySymbol})`,
      align: "left",
    },
    { id: "energyConsumed", label: "Energy Consumed* (kWh)", align: "left" },
    { id: "_id", label: "Station ID", align: "left" },
    { id: "last_updated", label: "Created At", align: "left" },
  ];
};

export const ocpiLogsTableColumnData = [
  { id: "sr", label: "S.NO", align: "left" },
  { id: "operatorId", label: "Operator Id", align: "left" },
  { id: "url", label: "URL", align: "left" },
  { id: "createdAt", label: "Recorded on", align: "left" },
];

export const ocpiAssignedTariffsColumnData = () => {
  return [
    { id: "sr", label: "S.NO", align: "left" },
    { id: "id", label: "Tariff Id", align: "left" },
    { id: "nestedTariffName", label: "Tariff Name", align: "left" },
    { id: "roaming_fee", label: "Roaming Fee", align: "left" },
    { id: "base_charge", label: "Base Charge", align: "left" },
    { id: "type", label: "Tariff Type", align: "left" },
    { id: "currency", label: "Currency", align: "left" },
    { id: "createdAt", label: "Created At", align: "left" },
    // { id: "action", label: "Action", align: "left" },
  ];
};
