import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography, Button, IconButton } from "@mui/material";
import MainHeading from "../../../utils/MainHeading";
import {
  createHubStation,
  getAllStationsHub,
  updateHubStation,
} from "../../../redux/action/hubManagement/hubManagementSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  // height: 700,
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  outline: "none",
  p: 3,
};

const CreateHubStationModal = ({
  open,
  handleClose,
  data,
  stationId,
  updating,
  newCheck,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [parkingNumbers, setParkingNumbers] = useState({});
  // const [updating, setUpdating] = useState(false);
  const rowsPerPage = 7;
  const dispatch = useDispatch();

  const handleNext = () => {
    if ((currentPage + 1) * rowsPerPage < data.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const displayedData = data?.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    if (newCheck) {
      // Initialize parking numbers when the data changes
      const initialParkingNumbers = {};
      data.forEach((item, index) => {
        initialParkingNumbers[item._id] =
          item.parkingNumber !== undefined
            ? item.parkingNumber
            : updating
            ? ""
            : index + 1;
      });
      setParkingNumbers(initialParkingNumbers);
    }
  }, [data, newCheck, updating]);

  const handleParkingNumberChange = (id, value) => {
    if (newCheck) {
      const numericValue = Number(value);

      // Prevent negative values
      if (numericValue < 0 || isNaN(numericValue)) {
        toast.error("Parking number cannot be negative.");
        return;
      }

      setParkingNumbers((prev) => ({
        ...prev,
        [id]:
          value.trim() === "" ? (updating ? "" : parkingNumbers[id]) : value,
      }));
    }
  };

  const handleAddStation = async () => {
    // Prepare the data to be sent using all data
    const connectors = data.map((item) => ({
      deviceId: item?.deviceId,
      connector: item?._id,
      parkingNumber: parkingNumbers[item._id]?.toString() || "", // Ensure parkingNumber is a string
    }));

    const payload = {
      stationId,
      connectors: connectors,
    };

    try {
      if (updating) {
        console.log("Updating station with ID:", stationId);
        const result = await dispatch(
          updateHubStation({ id: stationId, payload })
        );
        toast.success("Station updated successfully:", result);
        dispatch(getAllStationsHub());
        handleClose();
      } else {
        const result = await dispatch(createHubStation(payload));
        toast.success("Station added successfully:", result);
        dispatch(getAllStationsHub());
        handleClose();
      }
    } catch (error) {
      toast.error("Error adding station:", error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          {/* Table Columns */}
          <MainHeading
            text={updating ? "Update Hub Station" : "Add Hub Station"}
          />
          <Box
            sx={{
              background: "#292D33",
              padding: "16px",
              outline: 0,
              borderRadius: "4px",
              display: "flex",
              mt: "16px",
            }}
          >
            {[
              "S.NO",
              "Device Id",
              "Connector Id",
              "Connector Type",
              "Parking Number",
            ].map((header) => (
              <Box
                key={header}
                sx={{ width: header === "S.NO" ? "6%" : "25%" }}
              >
                <Typography
                  color={"#fff"}
                  fontFamily={"mySecondFont"}
                  fontSize={"16px"}
                >
                  {header}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Table Rows */}
          {displayedData?.map((item, index) => {
            const globalIndex = currentPage * rowsPerPage + index + 1;

            return (
              <Box
                key={item.id}
                sx={{
                  padding: "16px",
                  borderRadius: "4px",
                  display: "flex",
                }}
              >
                <Box sx={{ width: "6%" }}>
                  <Typography
                    color={"#333"}
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    textAlign={"start"}
                  >
                    {globalIndex}
                  </Typography>
                </Box>
                <Box sx={{ width: "23.5%" }}>
                  <Typography
                    color={"#333"}
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    textAlign={"start"}
                  >
                    {item?.deviceId}
                  </Typography>
                </Box>
                <Box sx={{ width: "23.5%" }}>
                  <Typography
                    color={"#333"}
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    textAlign={"start"}
                    paddingLeft={"4px"}
                  >
                    {item?.connectorId}
                  </Typography>
                </Box>
                <Box sx={{ width: "23.5%" }}>
                  <Typography
                    color={"#333"}
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    textAlign={"start"}
                    paddingLeft={"4px"}
                  >
                    {item?.connectorType}
                  </Typography>
                </Box>
                <Box sx={{ width: "23.5%" }}>
                  <input
                    type="text"
                    value={
                      parkingNumbers[item._id] ||
                      (updating && !item.parkingNumber ? "" : globalIndex)
                    } // Controlled input
                    onChange={(e) =>
                      handleParkingNumberChange(item._id, e.target.value)
                    }
                    onFocus={(e) => e.target.select()}
                    style={{
                      width: "100%",
                      padding: "8px 12px",
                      outline: "none",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      fontFamily: "mySecondFont",
                    }}
                  />
                </Box>
              </Box>
            );
          })}

          {/* Pagination Arrows */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "32px",
            }}
          >
            <Box>
              <Button
                sx={{
                  padding: "12px 16px",
                  background: "#e50000",
                  color: "#fff",
                  fontFamily: "mySecondFont",
                  ":hover": {
                    background: "#e50000",
                  },
                }}
                onClick={handleAddStation}
              >
                {updating ? "Update Station" : "Add Station"}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handlePrevious} disabled={currentPage === 0}>
                <span className="material-symbols-outlined">
                  arrow_back_ios
                </span>
              </IconButton>
              <IconButton
                onClick={handleNext}
                disabled={(currentPage + 1) * rowsPerPage >= data.length}
              >
                <span className="material-symbols-outlined">
                  arrow_forward_ios
                </span>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateHubStationModal;
