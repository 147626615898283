import React from "react";
import TopGraph from "./Overview/TopGraph";
import { Box, Grid } from "@mui/material";
import TimeUpCard from "./Overview/TimeUpCard";
import DirectSession from "../charging_stations_details/Overview/DirectSession";
import AlertBoxCard from "./Overview/AlertBoxCard";
import { useSelector } from "react-redux";

const OverviewBox = ({
  data,
  selectedTime,
  startTime,
  endTime,
  previousDashboardData,
}) => {
  const user = useSelector((state) => state?.profile?.profile);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Top - graph - component */}
        {user?.role === "CPO_Admin" ? (
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid
              item
              md={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid container spacing={2}>
                <Grid item md={12} spacing={2}>
                  <TopGraph
                    data={data}
                    previousDashboardData={previousDashboardData}
                    selectedTime={selectedTime}
                    startTime={startTime}
                    endTime={endTime}
                  />
                </Grid>
                <Grid item md={12}>
                  <DirectSession
                    data={data}
                    previousDashboardData={previousDashboardData}
                    selectedTime={selectedTime}
                    startTime={startTime}
                    endTime={endTime}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
              <Grid container spacing={2}>
                <Grid item md={12} spacing={2}>
                  <TopGraph
                    data={data}
                    previousDashboardData={previousDashboardData}
                    selectedTime={selectedTime}
                    startTime={startTime}
                    endTime={endTime}
                  />
                </Grid>
                <Grid item md={12}>
                  <DirectSession
                    data={data}
                    previousDashboardData={previousDashboardData}
                    selectedTime={selectedTime}
                    startTime={startTime}
                    endTime={endTime}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TimeUpCard
                    data={data}
                    previousDashboardData={previousDashboardData}
                    selectedTime={selectedTime}
                  />
                </Grid>
                {/* <Grid item md={12}>
                  <AlertBoxCard />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default OverviewBox;
