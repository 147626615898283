import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import HandshakeTopBox from "./Handshake/HandshakeTopBox/HandshakeTopBox";
import HandshakeBottomBox from "./Handshake/HandshakeBottomBox/HandshakeBottomBox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getOneNetworkHandshakeLogs } from "../../redux/action/ocpiManagement/ocpiSlice";

const HandshakeMainBox = ({
  versionsList,
  handshakeStatus,
  networkDetail,
  operatorId
}) => {
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();

  const { ocpiHandshakeLogs, loading, error } = useSelector(
    (state) => state.ocpi
  );

  useEffect(() => {
    if (operatorId) {
      fetchLogs();
    }
  }, [dispatch, operatorId, offset, limit]);

  const fetchLogs = () => {
    if (operatorId) {
      dispatch(
        getOneNetworkHandshakeLogs({
          operatorId,
          offset,
          limit,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <HandshakeTopBox
            versionsList={versionsList}
            handshakeStatus={handshakeStatus}
            networkDetail={networkDetail}
          />
        </Grid>

        <Grid item md={12}>
          <HandshakeBottomBox 
            ocpiHandshakeLogs={ocpiHandshakeLogs} 
            operatorId={operatorId}
            onFetchDetails={fetchLogs} // Pass fetch function as prop
          />
        </Grid>
      </Grid>
    </Box>
  );
};



export default HandshakeMainBox;