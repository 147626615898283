import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/charging_sessions_details/TopBar";
import Breadcrumbs from "../../components/breadcrumbs/BreadCrumbs";

const ChargingSessionsDetails = () => {
  return (
    <Box>
      {/* Top - Bar */}
      <TopBar />
    </Box>
  );
};

export default ChargingSessionsDetails;
