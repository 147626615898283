import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { resetData } from "../../../../utils/DummyData";

const Reset = ({ onInputChange, formValues }) => {
  const handleChange = (event) => {
    onInputChange("changeAvalaibility", event.target.value);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
          id="demo-simple-select-label"
        >
          Select Reset Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formValues.changeAvalaibility || ""}
          label="Select Reset Type"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            //   padding: "8px 16px",
            width: "100%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "mySecondFont",
          }}
        >
          {resetData.map((item) => {
            return (
              <MenuItem sx={{ fontFamily: "myThirdFont" }} value={item.name}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Reset;
