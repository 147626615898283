// import React from "react";
// import { PieChart, Pie, Cell } from "recharts";

// const SemiDoughnutChart = ({ data, width, height }) => {
//   return (
//     <PieChart width={width} height={height}>
//       <Pie
//         data={data}
//         dataKey="value"
//         cx="50%"
//         cy="50%"
//         outerRadius={100}
//         innerRadius={80}
//         startAngle={180}
//         endAngle={0}
//         fill="#8884d8"
//         stroke="none"
//       >
//         {data.map((entry, index) => (
//           <Cell key={`cell-${index}`} fill={entry.color} />
//         ))}
//       </Pie>
//     </PieChart>
//   );
// };

// export default SemiDoughnutChart;

import React from "react";
import { Box } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const SemiDoughnutChart = ({ data, preDefinedColors }) => {
  // Check if all data values are zero
  const totalValue = data?.reduce(
    (acc, entry) => acc + entry.collectedAmount,
    0
  );

  // If totalValue is zero, create a single gray segment
  const chartData =
    totalValue === 0
      ? [{ collectedAmount: 1, color: "#D3D3D3" }] // Gray color for zero data
      : data;

  return (
    <Box sx={{ width: "100%", height: "160px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={chartData}
            dataKey="collectedAmount"
            cx="50%"
            cy="90%"
            outerRadius={135}
            innerRadius={120}
            startAngle={180}
            endAngle={0}
            stroke="none"
          >
            {chartData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={totalValue === 0 ? "#D3D3D3" : preDefinedColors[index]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SemiDoughnutChart;
