import React, { useState } from "react";
import { TextCopy } from "../../utils/TextCopy/TextCopy";
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import Heading from "../../utils/Heading";

const InfoBox = ({ label, value, icon }) => {
  //Copy Text
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied); // Use the TextCopy function
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              onClick={handleCopy}
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const RegisterationDetails = ({ networkDetail, handshakeStatus }) => {
  console.log("kgkgkg", networkDetail);

  const endpoints = networkDetail?.ocpiDetails?.versionDetails?.endpoints || [];

  const initiatedByUsFlag = handshakeStatus
  console.log("khkhgkgkg",initiatedByUsFlag )

  return (
    <Box>
      <Grid container spacing={2} sx={{ alignItems: "stretch" }}>
        {/* Box 1 */}
        <Grid item md={4}>
          <Box
            sx={{
              padding: "16px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <span
                  style={{
                    fontSize: "24px",
                    color: "#292D33",
                    fontWeight: "300",
                  }}
                  className="material-symbols-outlined"
                >
                  info
                </span>
                <Heading text={"Handshake Details"} />
              </Box>
              <Divider sx={{ mt: "8px" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "16px",
              }}
            >
              <InfoBox
                label="Token A initiated by"
                value={
                  handshakeStatus?.success
                    ? handshakeStatus?.initiatedByUs
                      ? "Massive Mobility"
                      : networkDetail?.name
                    : null  
                }
              />
              <InfoBox
                label="Token A initiated on"
                value={handshakeStatus?.handshake}
              />
              <InfoBox
                label="Version Shared"
                value={networkDetail?.ocpiDetails?.versionDetails?.version}
              />
              <InfoBox
                label="Handshake Date"
                value={networkDetail?.ocpiDetails?.updatedAt}
              />
            </Box>
          </Box>
        </Grid>

        {/* Box 2 */}
        <Grid item md={4}>
          <Box
            sx={{
              padding: "16px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <span
                  style={{
                    fontSize: "24px",
                    color: "#292D33",
                    fontWeight: "300",
                  }}
                  className="material-symbols-outlined"
                >
                  info
                </span>
                <Heading text={"Station Details"} />
              </Box>
              <Divider sx={{ mt: "8px" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "16px",
              }}
            >
              <InfoBox
                label={"No. of stations permit shared"}
                value={networkDetail?.ocpiDetails?.stationsAssigned}
              />
              <InfoBox
                label={"No. of charging points permit shared"}
                value={networkDetail?.ocpiDetails?.evsesAssigned}
              />
              <InfoBox
                label={"No. of stations permit received"}
                value={networkDetail?.ocpiDetails?.stationsGiven}
              />
              <InfoBox
                label={"No. of charging points permit received"}
                value={networkDetail?.ocpiDetails?.evsesGiven}
              />
            </Box>
          </Box>
        </Grid>

        {/* Box 3 */}
        <Grid item md={4}>
          <Box
            sx={{
              padding: "16px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <span
                  style={{
                    fontSize: "24px",
                    color: "#292D33",
                    fontWeight: "300",
                  }}
                  className="material-symbols-outlined"
                >
                  info
                </span>
                <Heading text={"Handshake Endpoints"} />
              </Box>
              <Divider sx={{ mt: "8px" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "16px",
              }}
            >
              {endpoints.map((endpoint) => (
                <Box
                  key={endpoint.identifier}
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "4px" }}
                  >
                    <Typography
                      fontSize={"14px"} // Changed to 14px
                      fontFamily={"mySecondFont"}
                      color={"#727272"}
                      textAlign={"left"}
                    >
                      {`Identifier:`}
                    </Typography>
                    <Typography
                      fontSize={"14px"} // Changed to 14px
                      fontFamily={"mySecondFont"}
                      color={"#2F2F2F"}
                      textAlign={"left"}
                    >
                      {`${endpoint.identifier}`}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "4px" }}
                  >
                    <Typography
                      fontSize={"14px"} // Changed to 14px
                      fontFamily={"mySecondFont"}
                      color={"#727272"}
                      textAlign={"left"}
                    >
                      {`Role:`}
                    </Typography>
                    <Typography
                      fontSize={"14px"} // Changed to 14px
                      fontFamily={"mySecondFont"}
                      color={"#2F2F2F"}
                      textAlign={"left"}
                    >
                      {`${endpoint.role}`}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "4px" }}
                  >
                    <Typography
                      fontSize={"14px"} // Changed to 14px
                      fontFamily={"mySecondFont"}
                      color={"#727272"}
                      textAlign={"left"}
                    >
                      {`URL: `}
                    </Typography>
                    <Typography
                      fontSize={"14px"} // Changed to 14px
                      fontFamily={"mySecondFont"}
                      color={"#2F2F2F"}
                      textAlign={"left"}
                    >
                      {`${endpoint.url}`}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterationDetails;
