import { Box, Skeleton } from "@mui/material";
import React from "react";

const SkeletonValueCard = () => {
  return (
    <Box
      sx={{
        padding: "12px", // Padding around the card
        borderRadius: "8px", // Rounded corners
        background: "#fff", // White background
        border: "1px solid #ddd", // Light border around the card
        maxHeight: "153px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        {/* Icon with Circle Background */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(41, 45, 51, 0.08)",
              width: { xs: "28px", md: "32px" },
              height: { xs: "28px", md: "32px" },
              borderRadius: "50%",
              marginRight: "8px",
            }}
          >
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              animation="wave"
            />
          </Box>
          {/* Title and Amount Skeleton */}
          <Box>
            <Skeleton
              sx={{ width: { xs: "100px", md: "150px" } }}
              height="20px"
              animation="wave"
            />
            <Skeleton
              sx={{ width: { xs: "60px", md: "80px" } }}
              height="30px"
              animation="wave"
            />
          </Box>
        </Box>

        {/* Percentage Change Skeleton */}
        <Skeleton
          sx={{ width: { xs: "50px", md: "80px" } }}
          height="30px"
          animation="wave"
        />
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          alignItems: "flex-start",
          mt: "8px",
        }}
      >
        <Skeleton
          sx={{ width: { xs: "80px", md: "100px" } }}
          height="20px"
          animation="wave"
        />
        <Skeleton
          sx={{ width: { xs: "50px", md: "100px" } }}
          height="20px"
          animation="wave"
        />
      </Box>
    </Box>
  );
};

export default SkeletonValueCard;
