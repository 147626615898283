// src/utils/GraphZoomButton.js
import React, { useState } from "react";
import Button from "@mui/material/Button";
import GraphViewModal from "../components/Modals/GraphViewModal";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { Box } from "@mui/material";

const GraphZoomButton = ({ GraphComponent, graphProps }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Zoom In
      </Button> */}
      <Box sx={{ textAlign: "left" }}>
        <Button
          onClick={handleOpen}
          sx={{
            color: "black",
            "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
          }}
        >
          <CropFreeIcon sx={{ color: "black" }} />
        </Button>
      </Box>

      <GraphViewModal
        open={open}
        onClose={handleClose}
        GraphComponent={GraphComponent}
        graphProps={graphProps}
      />
    </>
  );
};

export default GraphZoomButton;
