import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../custom/CustomTable";
import { useNavigate } from "react-router-dom";
import HubTimeFilter from "../../Filters/HubTimeFilter";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import { TimeProvider } from "../../../Hooks/TimeContext";
import { MyRoutes } from "../../../routes/routes";

const GaurdTableBox = ({ columns, row }) => {
  const navigate = useNavigate();

  const handleAddBtn = () => {
    navigate(MyRoutes.add_new_guard);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Filter rows based on search query
  const filteredRows = row.filter((item) => {
    return Object.keys(item).some((key) =>
      String(item[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <StationProvider>
      <TimeProvider>
        <Box
          sx={{
            padding: "24px",
            borderRadius: "8px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "36px" }}>
              <HubTableFilter
                tabTitle={"Guard"}
                setSearchQuery={setSearchQuery}
              />
              <HubTimeFilter tabTitle={"Guard"} />
            </Box>
            <Button
              onClick={handleAddBtn}
              sx={{
                background: "#222",
                padding: "8px 24px",
                width: "120px",
                borderRadius: "4px",
                color: "#FCFCFC",
                mb: "12px",
                fontFamily: "mySecondFont",
                letterSpacing: "1.12px",
                textWrap: "nowrap",
                ":hover": {
                  background: "#222",
                },
              }}
            >
              + ADD NEW
            </Button>
          </Box>

          {/* Table */}
          <CustomTable
            columns={columns}
            rows={filteredRows}
            modalHeading={"Guards"}
            statsHeading={"true"}
            isRowClickable={true}
            actionType={"edit"}
            clickableColumns={["name"]}
            columnClickPaths={{
              name: "/hub_management/guard_details",
            }}
            columnKeyMappings={{
              name: "_id",
            }}
          />
        </Box>
      </TimeProvider>
    </StationProvider>
  );
};

export default GaurdTableBox;
