import { Box } from "@mui/material";
import React from "react";
import ValueCard from "./ValueCard";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import SkeletonValueCard from "../Skeleton/SkeletonValueCard";

const ValueCardBox = ({
  data,
  customers,
  previousDashboardData,
  selectedTime,
  customerData,
  previousCustomerData,
  cardTypeProp,
  walletData,
  previousWalletData,
  hubSession,
  loading,
}) => {
  const currencySymbol = GetCurrencySymbol();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flexGrow: 1,
        height: "100%",
      }}
    >
      {loading ? (
        <SkeletonValueCard /> // Show Skeleton when loading is true
      ) : (
        <ValueCard
          title={hubSession ? "Hub Sessions" : "Total Utilization (hrs)"}
          data={data}
          previousDashboardData={previousDashboardData}
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
      {/* {hubSession ? (
        <ValueCard
          title={"Total Hub Energy (kWh)"}
          data={data}
          previousDashboardData={previousDashboardData}
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      ) : (
        ""
      )} */}

      {loading ? (
        <SkeletonValueCard /> // Show Skeleton when loading is true
      ) : (
        <ValueCard
          title={hubSession ? "Total Hub Passes Sold" : "New customers"}
          data={data}
          previousDashboardData={previousDashboardData}
          previousCustomersData={previousCustomerData}
          selectedTime={selectedTime}
          customers={customerData}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}

      {loading ? (
        <SkeletonValueCard /> // Show Skeleton when loading is true
      ) : (
        <ValueCard
          title={
            hubSession
              ? "Total Value of Passes Sold"
              : `Wallet Recharge (${currencySymbol})`
          }
          data={hubSession ? data : walletData}
          previousDashboardData={
            hubSession ? previousDashboardData : previousWalletData
          }
          selectedTime={selectedTime}
          cardTypeProp={cardTypeProp}
          hubSession={hubSession}
        />
      )}
    </Box>
  );
};

export default ValueCardBox;
