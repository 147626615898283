import { Box, Grid } from "@mui/material";
import React from "react";
import RegisterInfoBox from "./Overview/RegisterInfoBox";
import AddTariffSnackBar from "../OcpiUiComponents/AddTariffSnackBar";

const OverviewBox = ({handshakeStatus, networkDetail}) => {
  console.log("zzzzaa", handshakeStatus)
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}

      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12}>
            <RegisterInfoBox handshakeStatus={handshakeStatus}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewBox;
