import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CustomTransition from "../custom/CustomTransition";
import MainHeading from "../../utils/MainHeading";
import { Button, Divider, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Dropbox from "../dropbox/Dropbox";
import { useDropzone } from "react-dropzone";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "16px 12px 32px 32px",
};

const BulkUploadStationModal = ({
  open,
  handleClose,
  heading,
  handleUpload,
  data,
}) => {
  const [files, setFiles] = React.useState([]);

  const acceptedFilesType = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // .xlsx
    "application/vnd.ms-excel": [], // .xls
    "application/vnd.google-apps.spreadsheet": [], // Google Sheets
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="material-symbols-outlined"
            >
              cancel
            </span>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ maxWidth: "176px", width: "100%" }}
              src="/images/bulkUpload.png"
              alt=""
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              mt: "20px",
              alignItems: "center",
            }}
          >
            <MainHeading text={`Bulk Upload Stations`} />
            <Typography
              display={"flex"}
              color={"#2F2F2F"}
              fontFamily={"myThirdFont"}
            >
              Before upload check out the sample file&nbsp;
              <a href="/excel/otherStationDummyData.xlsx">Download</a>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Grid item md={3} mt={4}>
              <Dropbox
                acceptedFiles={acceptedFiles}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                files={files}
                setFiles={setFiles}
                acceptedFilesType={acceptedFilesType}
              />
            </Grid>
          </Box>

          <Box sx={{ mt: "24px", display: "flex", gap: "8px", width: "100%" }}>
            <Button
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUpload(files)}
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E50000",
                width: "50%",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: "#E50000",
                },
              }}
            >
              Bulk Upload
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BulkUploadStationModal;
