import { Box, Divider, Typography, Grid } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const amenitiesIconMapping = {
  Restaurant: "restaurant",
  Hotel: "hotel",
  Mall: "local_mall",
  Hospital: "local_hospital",
  Garage: "build",
  ATM: "atm",
  Cafe: "local_cafe",
};

const Ameneties = ({ data }) => {
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            local_convenience_store
          </span>
          <Heading text={"Amenities"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      <Grid container spacing={2} sx={{ mt: "16px" }}>
        {data?.amenities.map((item, index) => {
          const icon = amenitiesIconMapping[item] || "info";
          return (
            <Grid item xs={1} sm={6} md={6} key={index}>
              <Box
                sx={{
                  padding: "12px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "88px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{ color: "#666666", fontWeight: "300" }}
                  className="material-symbols-outlined"
                >
                  {icon}
                </span>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                >
                  {item}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Ameneties;
