import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import CustomTable from "../../custom/CustomTable";
import { financePaymentHistoryColumns } from "../../../utils/DummyData";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletHistory } from "../../../redux/action/financeManager/financeManagerSlice";
import MainHeading from "../../../utils/MainHeading";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable"; // AutoTable plugin for jsPDF

const TableBox = ({ selectedStationName, walletHistory }) => {
  // const dispatch = useDispatch();
  // const { walletHistory, status, error } = useSelector(
  //   (state) => state.financeStations
  // );

  // useEffect(() => {
  //   dispatch(fetchWalletHistory());
  // }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const sortedData = [...walletHistory].sort(
    (a, b) => new Date(b.txnTime) - new Date(a.txnTime)
  );

  const downloadExcel = () => {
    // Map the column headers (labels) based on financePaymentHistoryColumns
    const headers = financePaymentHistoryColumns.map((col) => col.label);

    // Filter walletHistory data to match the columns being displayed
    const filteredData = sortedData?.map((item, index) =>
      financePaymentHistoryColumns.reduce((acc, col) => {
        if (col.id === "sr") {
          acc[col.label] = index + 1; // Add serial number with label
        } else if (col.id === "txnTime") {
          acc[col.label] = formatDate(item[col.id]); // Format the date
        } else {
          acc[col.label] = item[col.id]; // Add the value with the label as key
        }
        return acc;
      }, {})
    );

    // Create a new workbook and add a worksheet with filtered data
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData, { header: headers });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Wallet History");

    // Generate buffer and save the file
    const fileName = `wallet_history_${
      selectedStationName || "all_stations"
    }.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Create a modified version of columns for the PDF (change 'Credited Amount(₹)' to 'Amount')
    const modifiedColumns = financePaymentHistoryColumns.map((col) => {
      if (col.id === "amount_added") {
        return { ...col, label: "Credited Amount" }; // Change label to 'Amount' for PDF
      }
      return col;
    });

    // Filter walletHistory data to match the modified columns being displayed
    const filteredData = sortedData?.map((item, index) =>
      modifiedColumns.reduce((acc, col) => {
        if (col.id === "sr") {
          acc[col.label] = index + 1; // Add serial number with label
        } else if (col.id === "txnTime") {
          acc[col.label] = formatDate(item[col.id]); // Format the date
        } else {
          acc[col.label] = item[col.id]; // Add the value with label as key
        }
        return acc;
      }, {})
    );

    // Define the table columns using modified labels
    const columns = modifiedColumns.map((col) => ({
      header: col.label, // Use 'label' as the header for the PDF
      dataKey: col.label, // Use 'label' as the key for the data as well
    }));

    // Add the table to the PDF
    doc.autoTable({
      columns,
      body: filteredData,
      margin: { top: 10 },
    });

    // Save the PDF with the station name
    const fileName = `wallet_history_${
      selectedStationName || "all_stations"
    }.pdf`;
    doc.save(fileName);
  };

  return (
    <Box
      sx={{
        padding: "0px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <MainHeading text={selectedStationName || "No station selected"} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Button
            sx={{
              padding: "8px 12px",
              border: "1px solid rgba(34, 34, 34, 0.50)",
              borderRadius: "4px",
              color: "#2F2F2F",
              fontFamily: "mySecondFont",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={downloadExcel}
          >
            <img
              style={{ marginRight: "4px" }}
              src="/images/excel.png"
              alt=""
            />
            Excel
            <span
              style={{ fontSize: "16px", fontWeight: "400", marginLeft: "4px" }}
              className="material-symbols-outlined"
            >
              download
            </span>
          </Button>
          <Button
            sx={{
              padding: "8px 12px",
              border: "1px solid rgba(34, 34, 34, 0.50)",
              borderRadius: "4px",
              color: "#2F2F2F",
              fontFamily: "mySecondFont",
              fontSize: "14px",
            }}
            onClick={downloadPDF}
          >
            <img style={{ marginRight: "4px" }} src="/images/pdf.png" alt="" />
            PDF
            <span
              style={{ fontSize: "16px", fontWeight: "400", marginLeft: "4px" }}
              className="material-symbols-outlined"
            >
              download
            </span>
          </Button>
        </Box>
      </Box>
      <CustomTable
        columns={financePaymentHistoryColumns}
        rows={walletHistory}
        modalHeading={"Payment History"}
      />
    </Box>
  );
};

export default TableBox;
