import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import { Button } from "@mui/material";
import MainHeading from "../../../utils/MainHeading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "36px 32px",
};

const ConfirmAmountAddModal = ({
  open,
  handleClose,
  selectedStationName,
  amount,
}) => {
  console.log("Amount in Modal:", amount); // Log the received amount

  React.useEffect(() => {
    console.log(amount);
  }, [amount]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Box>
            <img src="/images/confirm.png" alt="Confirmation" />
          </Box>
          <MainHeading text={"Money Added Successfully"} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Heading text={"Amount Added"} />
            <Typography
              color={"#0C0C0C"}
              fontFamily={"mySecondFont"}
              fontSize={"clamp(1.375rem, 1.2453rem + 0.6289vw, 2rem)"}
              textAlign={"center"}
            >
              ₹{amount || "N/A"}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "8px",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Typography
              color={"#2F2F2F"}
              fontFamily={"myThirdFont"}
              fontSize={"16px"}
              textAlign={"center"}
            >
              Station/ Hub Name
            </Typography>
            <Typography
              color={"#2F2F2F"}
              fontFamily={"mySecondFont"}
              fontSize={"18px"}
              textAlign={"center"}
            >
              {selectedStationName || "N/A"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Button
              onClick={handleClose}
              sx={{
                padding: "12px 24px",
                borderRadius: "8px",
                border: "1px solid #808080",
                width: "416px",
                color: "#222",
                fontFamily: "mySecondFont",
              }}
            >
              BACK TO HOME
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmAmountAddModal;
