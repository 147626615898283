import { Box, Divider } from "@mui/material";
import React, { useEffect } from "react";
import Heading from "../../utils/Heading";
import StatusChip from "../Chips/StatusChip";
import StationMap from "./StationMap";
import { fetchAllStations } from "../../redux/action/stations/stationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const MapBox = () => {
  const dispatch = useDispatch();
  const { stations, loading, error } = useSelector((state) => state.stations);
  useEffect(() => {
    dispatch(fetchAllStations());
  }, [dispatch]);

  return (
    <Box sx={{ padding: "24px", background: "#fff", borderRadius: "8px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <span className="material-symbols-outlined">location_on</span>
          <Heading text={"Charging stations map"} />
        </Box>
        <StatusChip text={"Live"} />
      </Box>
      <Divider sx={{ mt: "8px", mb: "24px" }} />

      {/* Google - Map */}

      <StationMap data={stations} loading={loading} />
      {/* <MapView/> */}
    </Box>
  );
};

export default MapBox;
