import React from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const [inputPage, setInputPage] = useState(page + 1);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    setInputPage(1);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
    setInputPage(page); // Updates input field as well
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
    setInputPage(page + 2);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    setInputPage(Math.ceil(count / rowsPerPage));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputPage(
      value ? Math.max(1, Math.min(value, Math.ceil(count / rowsPerPage))) : ""
    );
  };

  const handleInputBlur = (event) => {
    const value = Number(inputPage);
    if (value && value !== page + 1) {
      onPageChange(event, value - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft fontSize="small" />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight fontSize="small" />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon fontSize="small" />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Space between elements
          alignItems: "center", // Stretch items to the same height
          width: "100%", // Full width container
          gap: "10px", // Space between elements
        }}
      >
        <Typography>Go to page</Typography>
        <TextField
          value={inputPage}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          variant="outlined"
          size="small"
          sx={{ flex: 1, width: "120px" }} // Responsive width
          inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
        />
        <button
          style={{
            flex: 1,
            width: "100%",
            padding: "10px 0",
            color: "#fff",
            cursor: "pointer",
            backgroundColor: "#292D33",
          }}
        >
          Go
        </button>{" "}
        {/* Responsive padding */}
      </Box>
    </div>
  );
};

export default TablePaginationActions;
