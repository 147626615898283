import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Initial state for payments
const paymentsInitialState = {
  payments: [],
  loading: false,
  error: null,
};

// get all payments
export const getAllPayments = createAsyncThunk(
  "payments/getAllPayments",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllPayments, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const paymentSlice = createSlice({
  name: "payments",
  initialState: paymentsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getAllPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default paymentSlice.reducer;
