import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Initial state moved outside the slice
const initialState = {
  rfidRequests: [],
  rejectedRFIDRequests: [],
  selectedRow: null,
  loading: false,
  error: null,
  assignedRFID: null,
  rfidDetails: [],
  rfidAvailability: null,
  assignedRFID: null,
};

// Thunks for fetching RFID Requests
export const getAllRFIDRequests = createAsyncThunk(
  "rfid/getAllRFIDRequests",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.getAllRFIDRequests}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.forms;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getAllRFIDRejectRequests = createAsyncThunk(
  "rfid/getAllRFIDRejectRequests",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAllRFIDRejectRequests}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.forms;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// New thunk for accepting RFID requests
export const acceptRFIDRequest = createAsyncThunk(
  "rfid/acceptRFIDRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.RFIDAccept}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return any relevant data if needed
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// New thunk for assigning RFID
export const assignRFID = createAsyncThunk(
  "rfid/assignRFID",
  async (requestData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.assignRFID}`, // Use the new endpoint here
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// New thunk for rejecting RFID requests
export const rejectRFID = createAsyncThunk(
  "rfid/rejectRFID",
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.rejectRFID}/${id}/${reason}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return any relevant data if needed
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// New thunk for fetching RFID details
export const fetchRFIDDetails = createAsyncThunk(
  "rfid/fetchRFIDDetails",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.rfidDetails}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.details; // Return the RFID details
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// New thunk for checking RFID availability
export const checkRFIDAvailability = createAsyncThunk(
  "rfid/checkRFIDAvailability",
  async (serialNum, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.rfidAvailableCheck}/${serialNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// New thunk for assigning RFID
export const assignNewRFID = createAsyncThunk(
  "rfid/assignNewRFID",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${endPoints.rfidAssign}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Return the RFID details
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Slice for RFID Requests
const rfidSlice = createSlice({
  name: "rfid",
  initialState, // Use the initialState here
  reducers: {
    setSelectedRowDataRedux(state, action) {
      state.selectedRow = action.payload; // Update the selected row
    },
    setAssignedRFID(state, action) {
      state.assignedRFID = action.payload; // New action to set assigned RFID
    },
  },
  extraReducers: (builder) => {
    // Handle getAllRFIDRequests
    builder
      .addCase(getAllRFIDRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRFIDRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.rfidRequests = action.payload;
      })
      .addCase(getAllRFIDRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle getAllRFIDRejectRequests
    builder
      .addCase(getAllRFIDRejectRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRFIDRejectRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.rejectedRFIDRequests = action.payload;
      })
      .addCase(getAllRFIDRejectRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(acceptRFIDRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(acceptRFIDRequest.fulfilled, (state, action) => {
        state.loading = false;
        // You can update the state here based on the acceptance response if needed
      })
      .addCase(acceptRFIDRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // Handle assignRFID
    builder
      .addCase(assignRFID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignRFID.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally handle the response here
        state.assignedRFID = action.payload.rfid; // Save the RFID in the store
      })
      .addCase(assignRFID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(rejectRFID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectRFID.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update your state based on the response
      })
      .addCase(rejectRFID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle fetchRFIDDetails
    builder
      .addCase(fetchRFIDDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRFIDDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.rfidDetails = action.payload;
      })
      .addCase(fetchRFIDDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle checkRFIDAvailability
    builder
      .addCase(checkRFIDAvailability.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkRFIDAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.rfidAvailability = action.payload; // Save availability status
      })
      .addCase(checkRFIDAvailability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(assignNewRFID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignNewRFID.fulfilled, (state, action) => {
        state.loading = false;
        state.assignedRFID = action.payload; // Save the assigned RFID in the state
      })
      .addCase(assignNewRFID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Save error message if the request fails
      });
  },
});

export const { setSelectedRowDataRedux, setAssignedRFID } = rfidSlice.actions;

export default rfidSlice.reducer;
