import React from "react";
import { Box, Typography } from "@mui/material";
import TimeFilter from "../Filters/TimeFilter";
import { useSelector } from "react-redux";

const TopHeader = ({
  setDurationType,
  setStartTime,
  setEndTime,
  setPrevStartTime,
  setPrevEndTime,
}) => {
  const profile = useSelector((state) => state?.profile?.profile);
  return (
    <Box
      sx={{
        padding: "16px 32px",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "24px",
        marginLeft: "-32px",
        marginRight: "-24px",
        marginTop: "-22px",
      }}
    >
      <Typography
        fontFamily={"mySecondFont"}
        fontSize={"20px"}
        textTransform={"capitalize"}
      >
        Hi, {profile ? profile.name : "Guest"}
      </Typography>
      {/* Time - filter */}
      <TimeFilter
        setDurationType={setDurationType}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setPrevStartTime={setPrevStartTime}
        setPrevEndTime={setPrevEndTime}
      />
    </Box>
  );
};

export default TopHeader;
