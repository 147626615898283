import { Box } from "@mui/material";
import React from "react";
import TopGraphBox from "../../dashboard/TopGraphBox";

const TopGraph = ({ data, startTime, endTime, selectedTime, previousDashboardData }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <TopGraphBox
        data={data}
        previousDashboardData = {previousDashboardData}
        selectedTime={selectedTime}
        startTime={startTime}
        endTime={endTime}
      />
    </Box>
  );
};

export default TopGraph;
