import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createChargingPass,
  getAllChargingPasses,
  getAllHubStation,
} from "../../../redux/action/hubManagement/hubManagementSlice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const ChargingPassesFormModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { hubStations, loading, error } = useSelector(
    (state) => state.hubManagement
  );

  React.useEffect(() => {
    dispatch(getAllHubStation());
  }, [dispatch]);

  console.log(hubStations);

  const [formData, setFormData] = React.useState({
    name: "",
    passType: "",
    vehicleType: "",
    passPrice: "",
    energyCap: "",
    stationId: "",
    timeCap: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "passPrice" || name === "energyCap" || name === "timeCap"
          ? Number(value)
          : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await dispatch(createChargingPass(formData)).unwrap();
      console.log("Charging pass created successfully:", response);
      toast.success("Charging Pass Create Successfully");
      dispatch(getAllChargingPasses());
      setFormData("");
      handleClose(); // Close the modal after successful submission
    } catch (error) {
      // Check if the error is related to passType
      if (
        error?.errors?.passType?.message &&
        error.errors.passType.message.includes(
          "is not a valid enum value for path `passType`"
        )
      ) {
        toast.error(
          `Pass Type "${formData.passType}" already exists for this station.`
        );
      } else {
        toast.error(error.message);
      }
      console.error("Error creating charging pass:", error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="material-symbols-outlined"
            >
              cancel
            </span>
          </Box>

          {/* Heading - Modal */}
          <Box sx={{ marginBottom: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span className="material-symbols-outlined">add_card</span>
              <Heading text={"Add New Charging Pass"} />
            </Box>
            <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
          </Box>

          <Grid container spacing={2}>
            {/* First Row: Pass Name and Pass Type */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Pass Name
              </label>
              <TextField
                name="name"
                placeholder="Enter pass name here"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Pass Type
              </label>
              <FormControl fullWidth>
                <Select
                  name="passType"
                  displayEmpty
                  value={formData.passType}
                  onChange={handleChange}
                  sx={{
                    height: "48px",
                    color: "#1C1C1C",
                    fontFamily: "myThirdFont",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                  renderValue={(selected) => {
                    if (!selected || selected.length === 0) {
                      return <em>Select Pass Type</em>;
                    }
                    // Map the backend value to display-friendly text
                    const displayText =
                      selected === "1week" ? "Weekly" : selected;
                    return displayText;
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Pass Type
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"1hr"}>
                    1hr
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"2hr"}>
                    2hr
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"3hr"}>
                    3hr
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"4hr"}>
                    4hr
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"12hr"}>
                    12hr
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"24hr"}>
                    24hr
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value={"1week"}>
                    Weekly
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: "24px" }} />
          <Grid container spacing={2}>
            {/* Second Row: Vehicle Type and Pass Price */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Vehicle Type
              </label>
              <FormControl fullWidth>
                <Select
                  name="vehicleType"
                  displayEmpty
                  value={formData.vehicleType}
                  onChange={handleChange}
                  sx={{
                    height: "48px",
                    color: "#1C1C1C",
                    fontFamily: "myThirdFont",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                  renderValue={(selected) => {
                    if (!selected || selected.length === 0) {
                      return <em>Select Vehicle Type</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Vehicle Type
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value="L3">
                    E-rickshaw/L3
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "mySecondFont" }} value="L5">
                    E-auto/L5
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "mySecondFont" }}
                    value="2wheeler"
                  >
                    2-wheelers
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "mySecondFont" }}
                    value="4wheeler"
                  >
                    4-wheelers
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Pass Price (₹)
              </label>
              <TextField
                type="number"
                name="passPrice"
                placeholder="Enter price here"
                fullWidth
                value={formData.passPrice}
                onChange={handleChange}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: "24px" }} />
          <Grid container spacing={2}>
            {/* Third Row: Energy Cap and Stations */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Energy Cap (kWh)
              </label>
              <TextField
                type="number"
                name="energyCap"
                placeholder="Enter energy cap here"
                fullWidth
                value={formData.energyCap}
                onChange={handleChange}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Station
              </label>
              <FormControl fullWidth>
                <Select
                  name="stationId"
                  displayEmpty
                  value={formData.stationId}
                  onChange={handleChange}
                  sx={{
                    height: "48px",
                    color: "#1C1C1C",
                    fontFamily: "myThirdFont",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Station</em>;
                    }
                    // Display the name based on selected ID
                    const selectedStation = hubStations.find(
                      (station) => station._id === selected
                    );
                    return selectedStation ? (
                      selectedStation.name
                    ) : (
                      <em>Select Station</em>
                    );
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Station
                  </MenuItem>
                  {hubStations?.map((item) => {
                    return (
                      <MenuItem
                        key={item._id}
                        sx={{ fontFamily: "mySecondFont" }}
                        value={item._id} // Use the station ID as the value
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: "24px" }} />
          <Grid container spacing={2}>
            {/* Third Row: Energy Cap and Stations */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                Time Cap (hrs)
              </label>
              <TextField
                type="number"
                name="timeCap"
                placeholder="Enter time cap here"
                fullWidth
                value={formData.timeCap}
                onChange={handleChange}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: "36px",
              display: "flex",
              gap: "8px",
              width: "100%",
              mb: "16px",
            }}
          >
            <Button
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E50000",
                width: "250px",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: "#E50000",
                },
              }}
              onClick={handleSubmit} // Add the click handler
            >
              Add Charging Pass
            </Button>
            <Button
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChargingPassesFormModal;
