import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: icon ? "space-between" : "start",
        alignItems: "center",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"start"}
        textTransform={"capitalize"}
      >
        {value}
      </Typography>
      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const LocationBox = ({ data }) => {

  const handleMapClick = () => {
    if (data?.loc?.coordinates) {
      const [latitude, longitude, ] = data.loc.coordinates;
      const url = `https://www.google.com/maps?q=${longitude},${latitude}`;
      window.open(url, "_blank");
    } else {
      // Optionally handle cases where coordinates are missing
      console.error("Coordinates are not available.");
    }
  };

  


  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <span
              style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
              className="material-symbols-outlined"
            >
              location_on
            </span>
            <Heading text={"Location"} />
          </Box>
          <Button
           onClick={handleMapClick} // Add click handler here
            sx={{
              padding: "2px 8px",
              borderRadius: "4px",
              border: "1px solid #c0c0c0",
              fontFamily: "mySecondFont",
              color: "#222",
              fontSize: "14px",
            }}
          >
            Map{" "}
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                paddingLeft: "4px",
              }}
              className="material-symbols-outlined"
            >
              Visibility
            </span>
          </Button>
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* Location */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Address" value={data?.fullAddress} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Pin code" value={data?.pinCode} />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="City/Town" value={data?.city} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="State" value={data?.state} />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Country" value={data?.country} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Latitude" value={data?.loc?.coordinates[1]} />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Longitude" value={data?.loc?.coordinates[0]} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LocationBox;
