import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/ocpi_management/TopBar";

const OcpiManagement = () => {
  return (
    <Box>
      {/* Top - Bar */}
      <TopBar />
    </Box>
  );
};

export default OcpiManagement;
