import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../utils/Heading";
import { Link } from "react-router-dom";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import { MyRoutes } from "../../routes/routes";

const TopPerformingSessionEnergy = ({ topPerformData, loading, dataFrom }) => {
  const topPerformSessionEnergy =
    topPerformData?.topPerformerSessions_Energy || [];

  const currencySymbol = GetCurrencySymbol();

  return (
    <Box
      sx={{
        padding: "24px 24px 28px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src="/images/stars.png" alt="" />
        <Heading text={"Top Sessions (Energy)"} />
      </Box>
      <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />

      {/* Loading state */}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "150px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {topPerformSessionEnergy.length === 0 ? (
            <Typography
              sx={{
                color: "#AFAFAF",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                mt: "18px",
              }}
            >
              No Sessions available
            </Typography>
          ) : (
            topPerformSessionEnergy.map((item, index) => (
              <Link
                key={item._id}
                to={`${MyRoutes.chargingSessions}/${item._id}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    padding: "12px",
                    borderLeft:
                      index === 0
                        ? "1px solid #FFB02E"
                        : index === 1
                        ? "1px solid #D3883E"
                        : "1px solid #BEBEBE",
                    background:
                      index === 0
                        ? ""
                        : index === 1
                        ? "rgba(211, 136, 62, 0.1)"
                        : "rgba(190, 190, 190, 0.1)",
                    boxShadow: "0px 1px 4px 0px rgba(41, 45, 51, 0.25)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    "&:hover": {
                      background:
                        index === 0
                          ? "rgba(255, 176, 46, 0.2)"
                          : index === 1
                          ? "rgba(211, 136, 62, 0.2)"
                          : "rgba(190, 190, 190, 0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <img
                      width={"20px"}
                      src={
                        index === 1
                          ? "/images/top2.png"
                          : index === 2
                          ? "/images/top3.png"
                          : "/images/top1.png"
                      }
                      alt=""
                    />
                    <Typography
                      color={"#2f2f2f"}
                      fontSize={"16px"}
                      fontFamily={"mySecondFont"}
                    >
                      {dataFrom === "stationDetail"
                        ? item?.deviceId
                        : item?.station?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "24px" }}
                  >
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <span
                        style={{ color: "#666666", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        bolt
                      </span>
                      <Typography
                        color={"#2F2F2F"}
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                      >
                        {item?.energyConsumed} kWh
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          border: "1px solid #666",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "myFirstFont",
                          color: "#333",
                        }}
                      >
                        {currencySymbol}
                      </Box>
                      <Typography
                        color={"#2F2F2F"}
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                      >
                        {currencySymbol}
                        {item?.billedAmount}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      {/* <span
                        style={{ color: "#666666", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        ev_station
                      </span> */}
                      <span
                        style={{ color: "#666666", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        chronic
                      </span>
                      <Typography
                        color={"#2F2F2F"}
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                      >
                        {item?.sessionDuration + " h:m:s"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export default TopPerformingSessionEnergy;
