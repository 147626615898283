import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
// import TableBox from "./TableBox";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";

import StatusChip from "../Chips/StatusChip";
import DetailsBox from "./DetailsBox";
import OcpiChargerBox from "./OcpiChargerBox";
import {
  getOneNetworkAllLocationsOcpiColumnData,
  ocpiAssignedTariffsColumnData,
  ocpiDetailsChargerColumns,
  ocpiDetailsChargerRowData,
  ocpiOneNetworkAllTariffColumns,
  ocpiOneNetworkAllTariffRowData,
  ocpiTariffColumns,
  ocpiTariffRowData,
  sessionDetailsSessionsColumns,
  sessionDetailsSessionsData,
} from "../../utils/DummyData";
import ManageBox from "./ManageBox";
import UnregisteredManageBox from "./UnregisteredManageBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/action/dashboard/dashboardSlice";
import { Handshake } from "@mui/icons-material";
import HandshakeMainBox from "./HandshakeMainBox";
import RegisterationDetails from "./RegisterationDetails";
import { useNavigate } from "react-router-dom";
import {
  getDefaultTariffList,
  getHandshakeStatus,
  getOcpiTokenA,
  getOcpiVersionsList,
  getOneNetworkAllLocations,
  getOneNetworkAllTariffs,
  getOneNetworkHandshakeLogs,
  getOneNetworksAllEvsesChargers,
  getOneOcpiNetworkDetail,
} from "../../redux/action/ocpiManagement/ocpiSlice";
import OcpiTariffBox from "./OcpiTariffBox";
import { MyRoutes } from "../../routes/routes";
import OcpiLocationBox from "./OcpiLocationBox";
import AddTariffSnackBar from "../OcpiUiComponents/AddTariffSnackBar";
import AssignedAllTariffs from "./AssignedAllTariffs";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [registered, setRegistered] = useState(false);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [operatorId, setOperatorId] = useState(null);

  const navigate = useNavigate();

  const [formData, setFormData] = useState(null);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const dispatch = useDispatch();

  const {
    versions,
    handshakeStatus,
    networkDetail,
    oneNetworksAllEvsesChargers,
    oneNetworkAllTariffs,
    oneNetworkAllLocations,

    ocpiHandshakeLogs,

    loading,
    error,
  } = useSelector((state) => state.ocpi);

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setOperatorId(id);
    }
  }, []);

  useEffect(() => {
    if (operatorId) {
      dispatch(getOneOcpiNetworkDetail(operatorId));
      dispatch(getHandshakeStatus(operatorId));
      dispatch(getOneNetworksAllEvsesChargers(operatorId));
      dispatch(getOneNetworkAllTariffs(operatorId));
      dispatch(getOneNetworkAllLocations(operatorId));
    }
  }, [dispatch, operatorId]);

  useEffect(() => {
    dispatch(getOcpiVersionsList());
  }, [dispatch]);

  //Nested Data

  const evsesOneNetworkAllChargersData = oneNetworksAllEvsesChargers?.map(
    (form) => {
      // Extract the sessionCount from the first connector (if available)
      const nestedSessionCount =
        form.connectors && form.connectors.length > 0
          ? form.connectors[0].sessions
            ? form.connectors[0].sessions.sessionCount
            : 0
          : 0;

      const nestedBilledAmount =
        form.connectors && form.connectors.length > 0
          ? form.connectors[0].sessions
            ? form.connectors[0].sessions.totalCostInclVat
            : 0
          : 0;

      const nestedEnergyConsumed =
        form.connectors && form.connectors.length > 0
          ? form.connectors[0].sessions
            ? form.connectors[0].sessions.totalKwh
            : 0
          : 0;

      const standards = form.connectors
        ? [
            ...new Set(form.connectors.map((connector) => connector.standard)),
          ].join(", ")
        : "";

      return {
        nestedDeviceId: form.evse_id,
        nestedStatus: form.status,
        nestedOperatorId: form.operatorId,
        nestedNoOfConnectors: form.connectors ? form.connectors.length : 0,
        // nestedChargerType: form.connectors  ?
        nestedSessionCount: nestedSessionCount,
        nestedBilledAmount: nestedBilledAmount,
        nestedLastUpdated: form.last_updated,
        nestedEnergyConsumed: nestedEnergyConsumed,
        nestedStandards: standards,
      };
    }
  );

  //Default tariffs API call because of snackbar conditional check
  const { defaultTariffs } = useSelector((state) => state.ocpi);

  console.log("rrrssrr233szzz", defaultTariffs);

  useEffect(() => {
    if (operatorId) {
      dispatch(getDefaultTariffList(operatorId));
    }
  }, [dispatch, operatorId]);

  const defaultTariffsNestedData = defaultTariffs?.map((form) => {
    const tariffName =
      form.tariff_alt_text && form.tariff_alt_text.length > 0
        ? form.tariff_alt_text[0].text
        : "N/A"; // Default value if no tariff name is found

    return {
      sr: form?.id,
      id: form?.id,
      nestedTariffName: tariffName,
      roaming_fee: form?.roaming_fee,
      base_charge: form?.base_charge,
      type: form?.elements[0]?.price_components[0]?.type,
      currency: form?.currency,
      createdAt: form?.createdAt,
    };
  });

  // Check if any tariff has `isDefault: true`
  const hasDefaultTariff = defaultTariffs?.some(
    (tariff) => tariff.isDefault === true
  );

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <OverviewBox
            handshakeStatus={handshakeStatus}
            networkDetail={networkDetail}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Details",
      content: (
        <Box>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <DetailsBox networkDetail={networkDetail} />
        </Box>
      ),
      icon: <CustomIcon icon={"info"} />,
    },
    {
      label: "Manage",
      content: (
        <Box>
          <ManageBox operatorId={operatorId} networkDetail={networkDetail} />
        </Box>
      ),
      icon: <CustomIcon icon={"battery_charging_50"} />,
    },
    {
      label: "3rd party OCPI Chargers",
      content: (
        <Box>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <OcpiChargerBox
            columns={ocpiDetailsChargerColumns}
            // rows={ocpiDetailsChargerRowData}
            rows={evsesOneNetworkAllChargersData}
            isRowClickable={false}
            networkDetail={networkDetail}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "Locations",
      content: (
        <Box>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <OcpiLocationBox
            columns={getOneNetworkAllLocationsOcpiColumnData()}
            // rows={ocpiDetailsChargerRowData}
            rows={oneNetworkAllLocations}
            isRowClickable={false}
            networkDetail={networkDetail}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "Handshake",
      content: (
        <Box>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <HandshakeMainBox
            versionsList={versions}
            operatorId={operatorId}
            handshakeStatus={handshakeStatus}
            networkDetail={networkDetail}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "Registration details",
      content: (
        <>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <RegisterationDetails
            networkDetail={networkDetail}
            handshakeStatus={handshakeStatus}
          />
        </>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "3rd party Tariffs",
      content: (
        <Box>
          {!hasDefaultTariff && (
            <AddTariffSnackBar networkDetail={networkDetail} />
          )}
          <OcpiTariffBox
            columns={ocpiOneNetworkAllTariffColumns}
            rows={oneNetworkAllTariffs}
            isRowClickable={false}
            customAction={"tariff"}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
    {
      label: "Assigned Tariffs",
      content: (
        <Box>
          <AssignedAllTariffs
            // columns={ocpiAssignedTariffsColumnData}
            columns={ocpiAssignedTariffsColumnData()}
            rows={defaultTariffsNestedData}
            isRowClickable={false}
            customAction={"tariff"}
            networkDetail={networkDetail}
          />
        </Box>
      ),
      icon: <CustomIcon icon={"ev_station"} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangePage = () => {
    navigate(MyRoutes.add_company, {
      state: {
        orgData: networkDetail?._id,
        edit: true,
      },
    });
  };

  const handleTariffModal = () => {
    alert("t cicked");
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                overflow: "hidden", // Hide any overflow to keep the image contained
              }}
            >
              <img
                src={networkDetail?.companyLogo?.url}
                alt="Company Logo"
                style={{
                  width: "100%", // Make image take up full width of the container
                  height: "100%", // Make image take up full height of the container
                  objectFit: "cover", // Ensure the image covers the container while maintaining aspect ratio
                }}
              />
            </Box>
            <MainHeading text={networkDetail?.name} />
            {/* <StatusChip text={"Available"} /> */}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              onClick={handleChangePage}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                border: "1px solid #E50000",
                maxWidth: "175px",
                width: "100%",
                color: "#E50000",
                fontWeight: "600",
                letterSpacing: "1.12px",
              }}
            >
              <span
                style={{
                  paddingRight: "8px",
                  fontSize: "24px",
                  fontWeight: "200",
                }}
                className="material-symbols-outlined"
              >
                edit
              </span>
              EDIT DETAILS
            </Button>
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
