import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import {
  companyMangementColumns,
  companyMangementData,
} from "../../utils/DummyData";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrg } from "../../redux/action/companyManagement/companyManagementSlice";
import { MyRoutes } from "../../routes/routes";

const CompanyManagementTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredCmOrgs, setFilteredCmOrgs] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const { orgs, loading, error } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getAllOrg());
  }, [dispatch]);

  const handleOnClick = () => {
    navigate(MyRoutes.add_company);
  };

  useEffect(() => {
    if (orgs?.length > 0) {
      setFilteredCmOrgs(orgs);
    }
  }, [orgs]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredCmOrgs(filteredData);
  };

  const myFilters = [
    {
      value: "name",
      title: "Company Name",
    },
    {
      value: "owner",
      title: "Session ID",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];

  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "12px",
        }}
      >
        <TableFilters
          data={orgs}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
        <Button
          sx={{
            background: "#222",
            padding: "8px 24px",
            width: "142px",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontFamily: "mySecondFont",
            letterSpacing: "1.12px",
            ":hover": {
              background: "#222",
            },
          }}
          onClick={handleOnClick}
        >
          + Add New
        </Button>
      </Box>

      {/* Table */}
      <CustomTable
        columns={companyMangementColumns()}
        // rows={filteredCmOrgs}
        rows={orgs}
        actionType={"edit-delete"}
        isRowClickable={true}
        clickableColumns={["name"]}
        columnClickPaths={{ name: "/companies_management" }}
        modalHeading={"Companies Management"}
        columnKeyMappings={{
          name: "_id",
        }}
        statsHeading={"true"}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default CompanyManagementTable;
