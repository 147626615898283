import React, { useEffect, useState } from "react";
import LogsTableBox from "../chargingPointsDetails/Logs/LogsTableBox";
import LogsDetails from "../chargingPointsDetails/Logs/LogsDetails";
import { Box, Grid } from "@mui/material";
import { fetchOneSessionLogs } from "../../redux/action/sessions/sessionsSlice";
import { useDispatch, useSelector } from "react-redux";

const LogsBox = ({ sessionDetail , loading}) => {
  const [selectedLog, setSelectedLog] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [logsFilter, setLogsFilter] = useState("createdAt");
  const { sessionLogs, error } = useSelector(
    (state) => state.sessions
  );

  useEffect(() => {
    if (sessionDetail?._id) {
      dispatch(
        fetchOneSessionLogs({
          sessionId: sessionDetail._id,
          page,
          limit,
          logsFilter,
        })
      );
    }
  }, [dispatch, sessionDetail, page, limit, logsFilter]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={7} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <LogsTableBox
              data={sessionLogs?.logs}
              logsCount={sessionLogs?.logsCount}
              lastLog={sessionLogs?.lastLog}
              page={page}
              limit={limit}
              setPage={setPage}
              setLogsFilter={setLogsFilter}
              logsFilter={logsFilter}
              onRowClick={setSelectedLog}
              liveLogBtn={false}
              modalHeading={"Logs"}
              loading={loading}
            />
          </Box>
        </Grid>
        <Grid item md={5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <LogsDetails logData={selectedLog} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogsBox;
