import React from "react";
import VerifyEmailPage from "../../../components/auth/verifyEmail/VerifyEmailPage";
import { Box } from "@mui/material";

const VerifyEmail = () => {
  return (
    <Box>
      <VerifyEmailPage />
    </Box>
  );
};

export default VerifyEmail;
