import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const ConfigurationListBox = ({ selectedConfig, onRadioChange }) => {
  const list = [
    "Change Availability",
    "Change Configuration",
    "Remote Start",
    "Remote Stop",
    "Get Diagnostics",
    "Update Firmware",
    "Reset",
    "Trigger Message",
    "Unlock Connector",
  ];

  const handleRadioChange = (event) => {
    onRadioChange(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: "24px 24px 50px 24px",
        background: "#fff",
        borderRadius: "8px",
        height: "calc(100vh - 332px)",
        overflowY: "auto",
      }}
    >
      <Typography
        color={"#2F2F2F"}
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        textAlign={"start"}
      >
        Please select your preferred configuration
      </Typography>
      <RadioGroup value={selectedConfig} onChange={handleRadioChange}>
        <Box sx={{ mt: "16px", display: "flex", flexDirection: "column" }}>
          {list.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                padding: "12px 16px",
                background:
                  selectedConfig === item
                    ? "rgba(41, 45, 51, 0.10)"
                    : "transparent",
                borderBottom: "1px solid #C0C0C0",
              }}
            >
              <FormControl sx={{ width: "24px" }}>
                <FormControlLabel value={item} control={<Radio />} />
              </FormControl>
              <Typography
                color={"#2F2F2F"}
                fontSize={"16px"}
                fontFamily={"myThirdFont"}
              >
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      </RadioGroup>
    </Box>
  );
};

export default ConfigurationListBox;
