import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import {
  chargingOCPIStationsColumnsData,
  chargingOtherStationsColumnsData,
  chargingStationsColumnsData,
} from "../../utils/DummyData";
import TableBox from "./TableBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStations } from "../../redux/action/stations/stationSlice";
import { fetchAllOtherStations } from "../../redux/action/stations/otherStationSlice";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const showAddButton = selectedTab !== 1;

  const dispatch = useDispatch();
  const { stations, loading, error } = useSelector((state) => state.stations);
  const { otherStations } = useSelector((state) => state.otherStations);
  const { profile } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(fetchAllStations());
    dispatch(fetchAllOtherStations());
  }, [dispatch]);

  // Helper function to sort stations
  const sortStations = (stationsList) => {
    return [...stationsList].sort((a, b) => {
      const nameA = a.name || ""; // Default to empty string if undefined
      const nameB = b.name || ""; // Default to empty string if undefined
      return nameA.localeCompare(nameB);
    });
  };

  // Filter and sort stations based on type
  const filterAndSortStationsByType = (type) => {
    return sortStations(
      stations.filter(
        (station) => station.stationType?.toLowerCase() === type.toLowerCase()
      )
    );
  };

  // Prepare filtered and sorted data
  const filtered1CStations = filterAndSortStationsByType("1c");
  const filteredOCPIStations = filterAndSortStationsByType("ocpi");
  const filteredOtherStations = sortStations(
    stations.filter(
      (station) => !["1c", "ocpi"].includes(station.stationType?.toLowerCase())
    )
  );

  const tabsData = [
    {
      label: "1C Stations",
      content: (
        <Box>
          <TableBox
            columns={chargingStationsColumnsData()}
            rows={stations}
            isRowClickable={true}
            showAddButton={showAddButton}
            loading={loading}
          />
        </Box>
      ),
    },
    ...(profile?.role !== "CPO_Admin"
      ? [
          {
            label: "OCPI Stations",
            content: (
              <Box>
                <TableBox
                  columns={chargingOCPIStationsColumnsData()}
                  rows={stations}
                  isRowClickable={true}
                  showAddButton={showAddButton}
                  loading={loading}
                />
              </Box>
            ),
          },
          {
            label: "Other Stations",
            content: (
              <Box>
                <TableBox
                  columns={chargingOtherStationsColumnsData}
                  rows={otherStations}
                  isRowClickable={false}
                  showBulkUploadButton={true}
                  loading={loading}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "8px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "20px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box sx={{ display: "none" }}>
          <MainHeading text={"Charging Stations"} />
        </Box>
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
