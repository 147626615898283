import { Box, InputBase, Typography, CircularProgress } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { MyRoutes } from "../../routes/routes";

const NearbyDevices = ({ data, loading }) => {
  const [showNearbyDevices, setShowNearbyDevices] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const containerRef = useRef(null); // Ref to the whole box container
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle clicks outside of the component
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowNearbyDevices(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Helper function to get styled box based on status
  const getStatusBoxStyles = (status) => {
    switch (status) {
      case 'active':
        return {
          background: "linear-gradient(0deg, rgba(2, 121, 0, 0.05) 0%, rgba(2, 121, 0, 0.05) 100%)",
          hoverBackground: "linear-gradient(0deg, rgba(2, 121, 0, 0.1) 0%, rgba(2, 121, 0, 0.1) 100%)",
          dotColor: "#027900"
        };
      case 'inactive':
        return {
          background: "linear-gradient(0deg, rgba(121, 0, 0, 0.05) 0%, rgba(121, 0, 0, 0.05) 100%)",
          hoverBackground: "linear-gradient(0deg, rgba(121, 0, 0, 0.1) 0%, rgba(121, 0, 0, 0.1) 100%)",
          dotColor: "#790000"
        };
      case 'maintenance':
        return {
          background: "linear-gradient(0deg, rgba(41, 45, 51, 0.05) 0%, rgba(41, 45, 51, 0.05) 100%)",
          hoverBackground: "linear-gradient(0deg, rgba(193, 116, 0, 0.1) 0%, rgba(193, 116, 0, 0.1) 100%)",
          dotColor: "#292D33"
        };
      default:
        return {};
    }
  };

  // Function to handle navigation
  const handleBoxClick = (id) => {
    navigate(MyRoutes.charging_stations + `/${id}`);
  };

  // Function to render each station box
  const renderStationBox = (station, statusStyles) => (
    <Box
      key={station._id}
      onClick={() => handleBoxClick(station._id)} // Add onClick handler
      sx={{
        width: "100%",
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        gap: "12px",
        boxShadow: "0px 2px 4px 0px rgba(41, 45, 51, 0.25)",
        marginBottom: "8px",
        background: statusStyles.background,
        "&:hover": {
          backgroundImage: statusStyles.hoverBackground,
          cursor: 'pointer' // Show pointer cursor on hover
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            width: "8px",
            height: "8px",
            backgroundColor: statusStyles.dotColor,
            textAlign: "right",
            borderRadius: "50%",
          }}
        ></Box>
      </Box>
      <Typography
        fontFamily={"mySecondFont"}
        fontSize={"16px"}
        fontWeight={"600"}
        lineHeight={"24px"}
        color={"#262626"}
        sx={{
          marginLeft: "8px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {station.stationName}
      </Typography>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        fontWeight={"400"}
        lineHeight={"20px"}
        color={"#727272"}
        sx={{
          marginLeft: "8px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
        }}
      >
        {station.address}
      </Typography>
    </Box>
  );

  // Function to filter and sort stations
  const getFilteredAndSortedStations = () => {
    const query = searchQuery.toLowerCase();
    const filteredStations = data.filter(station =>
      station?.stationName?.toLowerCase().includes(query) ||
      station?.address?.toLowerCase().includes(query)
    );

    const sortedStations = filteredStations.sort((a, b) =>
      a.stationName.toLowerCase().includes(query) ? -1 : 1
    );

    return sortedStations;
  };

  // Organize stations by status
  const activeStations = getFilteredAndSortedStations().filter(station => station.status === 'active');
  const inactiveStations = getFilteredAndSortedStations().filter(station => station.status === 'inactive');
  const offlineStations = getFilteredAndSortedStations().filter(station => station.status === 'maintenance');

  return (
    <Box ref={containerRef}>
      <InputBase
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update search query
        onFocus={() => setShowNearbyDevices(true)}
        placeholder="Search by location, station name"
        sx={{
          color: "rgba(28, 28, 28, 0.50)",
          fontFamily: "myThirdFont",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          marginBottom: "12px",
          textAlign: "left",
          borderRadius: "4px",
          padding: "10px 16px",
          border: "1px solid #C0C0C0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "288px",
          height: "48px",
          color: "#1C1C1C",
        }}
      />

      {/* Show CircularProgress while loading */}
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px', // Adjust as needed
            border: "2px solid red"
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        showNearbyDevices && (
          <Box
            sx={{
              maxHeight: "400px", // Set a maximum height
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            {/* Render Active Stations */}
            {activeStations.length > 0 && (
              <Box sx={{ marginBottom: 2 }}>
                {activeStations.map(station => renderStationBox(station, getStatusBoxStyles('active'))) }
              </Box>
            )}

            {/* Render Inactive Stations */}
            {inactiveStations.length > 0 && (
              <Box sx={{ marginBottom: 2 }}>
                {inactiveStations.map(station => renderStationBox(station, getStatusBoxStyles('inactive'))) }
              </Box>
            )}

            {/* Render Offline Stations */}
            {offlineStations.length > 0 && (
              <Box sx={{ marginBottom: 2 }}>
                {offlineStations.map(station => renderStationBox(station, getStatusBoxStyles('maintenance'))) }
              </Box>
            )}
          </Box>
        )
      )}
    </Box>
  );
};

export default NearbyDevices;
