import { Box, Button, Divider } from "@mui/material";
import React from "react";
import Heading from "../../../../utils/Heading";
import FetchDetailsBox from "./FetchDetailsBox";
import CachedIcon from '@mui/icons-material/Cached';
import OcpiLogsBox from "../../OcpiLogsBox";

const HandshakeBottomBox = ({ ocpiHandshakeLogs, operatorId, onFetchDetails }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {/* Heading */}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/ev_charger_icon.png" alt="" />
            <Heading text={"token logs"} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              onClick={onFetchDetails} // Call the function on button click
              sx={{
                background: "#222",
                padding: "8px 16px",
                width: "200px",
                borderRadius: "4px",
                color: "#FCFCFC",
                fontFamily: "mySecondFont",
                fontSize: "14px",
                letterSpacing: "1.12px",
                ":hover": {
                  background: "#222",
                },
              }}
            >
              Fetch Details <span style={{margin:"0 4px"}}><CachedIcon/></span> 
            </Button>
          </Box>
        </Box>
        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      <Box sx={{textAlign:"right", fontSize:"14px", fontFamily:"myThirdFont"}}>
        Last fetched on 23:08 PM, 24/08/2024
      </Box>

      <Box>
        <OcpiLogsBox ocpiHandshakeLogs={ocpiHandshakeLogs} operatorId={operatorId} />
      </Box>
    </Box>
  );
};

export default HandshakeBottomBox;
