import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CustomTransition from "../custom/CustomTransition";
import MainHeading from "../../utils/MainHeading";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "16px 12px 32px 32px",
};

const DeleteModal = ({ open, handleClose, heading, handleDelete, data }) => {
  const deleteTitle = data?.name || data?.stationName || data?._id;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="material-symbols-outlined"
            >
              cancel
            </span>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ maxWidth: "176px", width: "100%" }}
              src="/images/delete.png"
              alt=""
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              mt: "20px",
              alignItems: "center",
            }}
          >
            <MainHeading text={`Delete ${heading}`} />
            <Typography
              display={"flex"}
              color={"#2F2F2F"}
              fontFamily={"myThirdFont"}
            >
              Are you sure you want to delete&nbsp;
              <Typography
                color={"#2F2F2F"}
                fontFamily={"myFirstFont"}
              >{`"${deleteTitle}"`}</Typography>
              <Typography color={"#2F2F2F"} fontFamily={"mySecondFont"}>
                {/* {`"${}"`} ? */}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ mt: "24px", display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                borderRadius: "4px",
                padding: "8px 16px",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                width: "320px",
                background: "rgba(229, 0, 0, 0.05)",
                borderLeft: "1px solid #E50000",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <span
                    style={{ color: "#e50000", fontWeight: "200" }}
                    className="material-symbols-outlined"
                  >
                    warning
                  </span>
                  <Typography
                    fontFamily={"mySecondFont"}
                    fontSize={"16px"}
                    color={"#e50000"}
                  >
                    Warning
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  gap: "8px",
                }}
              >
                <Typography
                  textAlign={"start"}
                  color={"#2F2F2F"}
                  fontSize={"12px"}
                  fontFamily={"myThirdFont"}
                >
                 This action is permanent and cannot be restored.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: "24px", display: "flex", gap: "8px", width: "100%" }}>
            <Button
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E50000",
                width: "50%",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: "#E50000",
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
