import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const UpdateFirmware = ({ onInputChange, formValues }) => {
  const [startDate, setStartDate] = useState(formValues.startDate || null);
  const [stopDate, setStopDate] = useState(formValues.stopDate || null);

  const handleLocation = (event) => {
    onInputChange("location", event.target.value);
  };

  const handleRetries = (event) => {
    onInputChange("retries", event.target.value);
  };
  const handleRetryInterval = (event) => {
    onInputChange("retryInterval", event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onInputChange("startDate", date);
  };

  const handleStopDateChange = (date) => {
    setStopDate(date);
    onInputChange("stopDate", date);
  };

  let newDate = (
    <Typography sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>
      Retrive Date/Time
    </Typography>
  );
  let oldDate = (
    <Typography sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>
      Stop Date/Time
    </Typography>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          style={{
            width: "50%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            outline: "none",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "4px 12px",
          }}
          value={formValues.location || ""}
          placeholder="Enter Location (Directory URI)"
          onChange={handleLocation}
          type="text"
        />
        <input
          style={{
            width: "50%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            outline: "none",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "4px 12px",
          }}
          value={formValues.retries || ""}
          type="text"
          onChange={handleRetries}
          placeholder="Retries"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          style={{
            width: "50%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            outline: "none",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "4px 12px",
          }}
          value={formValues.retryInterval || ""}
          type="text"
          onChange={handleRetryInterval}
          placeholder="Retry Interval"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={{ width: "50%" }}
            renderInput={(props) => <TextField {...props} />}
            label={newDate}
            value={startDate}
            onChange={handleStartDateChange}
            disableFuture
          />
        </LocalizationProvider>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            sx={{ width: "50%" }}
            label={oldDate}
            value={stopDate}
            onChange={handleStopDateChange}
            disableFuture
          />
        </LocalizationProvider>
      </Box> */}
    </Box>
  );
};

export default UpdateFirmware;
