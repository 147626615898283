import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const RemoteStart = ({ onInputChange, formValues, data }) => {
  const handleChange = (event) => {
    onInputChange("connectorID", event.target.value);
  };

  const handleChangeType = (event) => {
    onInputChange("messageId", event.target.value);
  };

  return (
    <Box
      sx={{ display: "flex", gap: "16px", alignItems: "center", width: "100%" }}
    >
      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
          id="demo-simple-select-label"
        >
          Select Connector ID
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formValues.connectorID || ""}
          label=" Select Connector ID"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            //   padding: "8px 16px",
            width: "100%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "mySecondFont",
          }}
        >
          {data?.connectors?.map((item) => {
            return (
              <MenuItem value={item.connectorId}>{item.connectorId}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* <input
        style={{
          width: "100%",
          height: "48px",
          color: "#1C1C1C",
          fontFamily: "myThirdFont",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #ccc",
          padding: "4px 12px",
        }}
        value={formValues.messageId || ""}
        placeholder="Enter Message Id"
        onChange={handleChangeType}
        type="text"
      /> */}
    </Box>
  );
};

export default RemoteStart;
