import React from "react";
import BasicDetails from "./Details/BasicDetails";
import OCPPDetails from "./Details/OCPPDetails";
import { Box, Grid, Typography } from "@mui/material";
import SpecificationDetails from "./Details/SpecificationDetails";

const DetailsBox = ({ data }) => {
  if (!data) {
    return <Typography>No data available</Typography>;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <BasicDetails data={data} />
          </Box>
        </Grid>
        <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <SpecificationDetails data={data} />
          </Box>
        </Grid>
        <Grid item md={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <OCPPDetails data={data} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailsBox;
