// export const TextCopy = (textToCopy, setCopied) => {
//   navigator.clipboard
//     .writeText(textToCopy) // Copy the text to clipboard
//     .then(() => {
//       setCopied(true); // Show "Copied!" tooltip
//       setTimeout(() => setCopied(false), 900); // Hide tooltip after 0.9 seconds
//     })
//     .catch((err) => {
//       console.error("Failed to copy text: ", err);
//     });
// };

export const TextCopy = (textToCopy, setCopied) => {
  console.log("Attempting to copy text:", textToCopy); // Debugging info
  navigator.clipboard
    .writeText(textToCopy) // Copy the text to clipboard
    .then(() => {
      console.log("Text copied successfully."); // Debugging info
      setCopied(true); // Show "Copied!" tooltip
      setTimeout(() => setCopied(false), 900); // Hide tooltip after 0.9 seconds
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err); // Error logging
    });
};

