import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Page A", uv: 4000, pv: 2400 },
  { name: "Page B", uv: 3000, pv: 1398 },
  { name: "Page C", uv: 2000, pv: 9800 },
  { name: "Page D", uv: 2780, pv: 3908 },
  { name: "Page E", uv: 1890, pv: 4800 },
  { name: "Page F", uv: 2390, pv: 3800 },
  { name: "Page G", uv: 3490, pv: 4300 },
];

const ShadedDoubleLineGraph = ({ barGraphType }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 10,
        }}
      >
        <CartesianGrid
          stroke="#DDDDDD"
          strokeDasharray="5 3"
          vertical={false}
        />

        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={barGraphType === "network" ? "#2F78E6" : "#7F73C6"}
              stopOpacity={0.25}
            />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={barGraphType === "network" ? "#FF5454" : "#F8610C"}
              stopOpacity={0.25}
            />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="name"
          stroke="#AFAFAF" // Axis color
          tick={{
            fontSize: 14, // Font size for X-axis labels
            fontFamily: "mySecondFont", // Font family for X-axis labels
            fill: "#666", // Color for X-axis labels
          }}
        />
        <YAxis
          yAxisId="left"
          stroke="#AFAFAF" // Axis color
          tick={{
            fontSize: 14, // Font size for Y-axis labels
            fontFamily: "mySecondFont", // Font family for Y-axis labels
            fill: "#666", // Color for Y-axis labels
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#AFAFAF" // Axis color
          tick={{
            fontSize: 14, // Font size for Y-axis labels
            fontFamily: "mySecondFont", // Font family for Y-axis labels
            fill: "#666", // Color for Y-axis labels
          }}
        />
        <Tooltip />
        <Legend />

        <Area
          type="monotone"
          dataKey="pv"
          stroke={barGraphType === "network" ? "#2F78E6" : "#7F73C6"}
          fill="url(#colorPv)"
          fillOpacity={1}
          yAxisId="left" // Associate this Area with the left Y axis
        />
        <Area
          type="monotone"
          dataKey="uv"
          stroke={barGraphType === "network" ? "#F8610C" : "#FF5454"}
          fill="url(#colorUv)"
          fillOpacity={1}
          yAxisId="right" // Associate this Area with the right Y axis
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ShadedDoubleLineGraph;
