import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";

const AddTariffSnackBar = ({ networkDetail }) => {
  const navigate = useNavigate();
  const handleOpenAddTariffPage = () => {
    navigate(MyRoutes.addNewOcpiTariff, {
      state: {
        networkDetail: networkDetail,
        // edit: true
      },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FBD9D9",
        padding: "16px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box sx={{ color: "#790000" }}>
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "48px", display: "flex", alignItems: "center" }}
          >
            info
          </span>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{
              color: "#790000",
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Tariff & station not added
          </Typography>
          <Typography
            sx={{
              color: "#2F2F2F",
              fontFamily: "myThirdFont",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            Please add Tariff & station to initiate the OCPI Handshake
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button
          onClick={handleOpenAddTariffPage}
          sx={{
            borderRadius: "4px",
            padding: "12px 24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#E50000",
            width: "100%",
            color: "#fff",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          <span
            style={{
              paddingRight: "8px",
              fontSize: "24px",
              fontWeight: "200",
            }}
            className="material-symbols-outlined"
          >
            edit
          </span>
          ADD TARIFF
        </Button>
      </Box>
    </Box>
  );
};

export default AddTariffSnackBar;
