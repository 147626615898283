import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../custom/CustomTable";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";

const ActiveSession = ({ columns, row, isActive }) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter rows based on search query
  const filteredRows = row.filter((item) => {
    return Object.keys(item).some((key) =>
      String(item[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <StationProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {/* filters */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <TableFilters /> */}
          <HubTableFilter
            setSearchQuery={setSearchQuery}
            isActive={isActive}
            tabTitle={"HubActiveSessions"}
          />
        </Box>

        {/* Table */}
        <CustomTable
          columns={columns}
          rows={filteredRows}
          modalHeading={"Hub Active Sessions"}
        />
      </Box>
    </StationProvider>
  );
};

export default ActiveSession;
