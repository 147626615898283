import { Box } from "@mui/material";
import React from "react";
import MapBox from "../../components/charging_stations_map/MapBox";

const ChargingStationsMap = () => {
  return (
    <Box>
      <MapBox />
    </Box>
  );
};

export default ChargingStationsMap;
