import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../../custom/CustomTable";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable"; // AutoTable plugin for jsPDF
import { financeStationBalanceColumns } from "../../../utils/DummyData";
import MainHeading from "../../../utils/MainHeading";

const PaymentHistoryTable = ({ columns, row, selectedStationName }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const sortedData = [...row].sort(
    (a, b) => new Date(b.txnTime) - new Date(a.txnTime)
  );

  const downloadExcel = () => {
    // Filter walletHistory data to match the columns being displayed
    const filteredData = sortedData?.map((item, index) =>
      financeStationBalanceColumns.reduce((acc, col) => {
        if (col.id === "sr") {
          acc[col.id] = index + 1; // Add serial number
        } else if (col.id === "txnTime") {
          acc[col.id] = formatDate(item[col.id]);
        } else {
          acc[col.id] = item[col.id];
        }
        return acc;
      }, {})
    );

    // Create a new workbook and add a worksheet with filtered data
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "Wallet History");

    // Generate buffer and save the file
    const fileName = `wallet_history_${
      selectedStationName || "all_stations"
    }.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Filter walletHistory data to match the columns being displayed
    const filteredData = sortedData?.map((item, index) =>
      financeStationBalanceColumns.reduce((acc, col) => {
        if (col.id === "sr") {
          acc[col.id] = index + 1; // Add serial number
        } else if (col.id === "txnTime") {
          acc[col.id] = formatDate(item[col.id]);
        } else {
          acc[col.id] = item[col.id];
        }
        return acc;
      }, {})
    );

    // Define the table columns
    const columns = financeStationBalanceColumns.map((col) => ({
      header: col.label, // Use 'label' as the header for the PDF
      dataKey: col.id, // Use 'id' to map the data keys
    }));

    // Add the table to the PDF
    doc.autoTable({
      columns,
      body: filteredData,
      margin: { top: 10 },
    });

    // Save the PDF with station name
    const fileName = `wallet_history_${
      selectedStationName || "all_stations"
    }.pdf`;
    doc.save(fileName);
  };

  return (
    <Box>
      <Box
        sx={{
          padding: "0px 24px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <MainHeading text={selectedStationName || "No station selected"} />
            {!selectedStationName ? (
              <Typography textAlign={"start"} fontFamily={"myThirdFont"}>
                (Select a Station to View the Hub Activity)
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              sx={{
                padding: "8px 12px",
                border: "1px solid rgba(34, 34, 34, 0.50)",
                borderRadius: "4px",
                color: "#2F2F2F",
                fontFamily: "mySecondFont",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
              onClick={downloadExcel}
            >
              <img
                style={{ marginRight: "4px" }}
                src="/images/excel.png"
                alt=""
              />
              Excel
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginLeft: "4px",
                }}
                className="material-symbols-outlined"
              >
                download
              </span>
            </Button>
            <Button
              sx={{
                padding: "8px 12px",
                border: "1px solid rgba(34, 34, 34, 0.50)",
                borderRadius: "4px",
                color: "#2F2F2F",
                fontFamily: "mySecondFont",
                fontSize: "14px",
              }}
              onClick={downloadPDF}
            >
              <img
                style={{ marginRight: "4px" }}
                src="/images/pdf.png"
                alt=""
              />
              PDF
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginLeft: "4px",
                }}
                className="material-symbols-outlined"
              >
                download
              </span>
            </Button>
          </Box>
        </Box>
        <CustomTable
          columns={columns}
          rows={row}
          modalHeading={"Transactions"}
        />
      </Box>
    </Box>
  );
};

export default PaymentHistoryTable;
