import { Box, Typography } from "@mui/material";
import React from "react";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import { useLocation } from "react-router-dom";

const ValueChip = ({
  data,
  previousDashboardData,
  title,
  previousPaymentWalletData,
  orgPaymentData,
  previousOrgPaymentData,
  customers,
  previousCustomersData,
}) => {
  const location = useLocation();
  const hubStatsOverview = location.pathname.includes("/hub_management");

  // Helper function to convert duration string to seconds
  const convertToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const convertDurationToHours = (duration) => {
    if (!duration || typeof duration !== "string" || !duration.includes(":")) {
      console.warn("Invalid duration format:", duration);
      return "0.00";
    }

    const [hours, minutes, seconds] = duration.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.warn("Invalid duration components:", duration);
      return "0.00";
    }

    const totalHours = hours + minutes / 60 + seconds / 3600;

    return totalHours.toFixed(2); // Round to two decimal places
  };

  // Helper function to calculate percentage change
  const calculatePercentageChange = (current, previous, fallbackValue = 0) => {
    // Convert values to numbers to avoid issues with type coercion
    const currentValue = Number(current);
    const previousValue = Number(previous);

    // Check if previous data is missing or zero
    if (isNaN(previousValue) || previousValue === 0) {
      if (isNaN(currentValue) || currentValue === 0) {
        return "0.00%"; // Both are zero
      }
      return "N/A"; // Previous data is missing or zero but current data is available
    }

    // Calculate percentage change
    const percentageChange =
      ((currentValue - previousValue) / previousValue) * 100;

    // Return formatted result
    return percentageChange.toFixed(2) + "%";
  };

  // Calculate results using the helper function
  const resultTotalRevenue = calculatePercentageChange(
    data?.totalRevenue,
    previousDashboardData?.totalRevenue
  );

  const resultTotalEnergy = calculatePercentageChange(
    data?.totalEnergy,
    previousDashboardData?.totalEnergy
  );

  const resultDirectSession = calculatePercentageChange(
    data?.totalSessionCount_Upi,
    previousDashboardData?.totalSessionCount_Upi
    // Fallback value for Direct Session
  );

  const resultTotalAppSession = calculatePercentageChange(
    data?.totalSessionCount_App,
    previousDashboardData?.totalSessionCount_App
  );

  const resultTotalDirectHubSession = calculatePercentageChange(
    data?.totalSessionCount_Guard,
    previousDashboardData?.totalSessionCount_Guard
  );

  const resultTotalDirectRFIDSession = calculatePercentageChange(
    data?.totalSessionCount_Rfid,
    previousDashboardData?.totalSessionCount_Rfid
  );

  const resultTotalDirectOCPISession = calculatePercentageChange(
    data?.totalSessionCount_Ocpi,
    previousDashboardData?.totalSessionCount_Ocpi
  );

  const resultTotalDuration = calculatePercentageChange(
    convertDurationToHours(data?.totalDuration),
    convertDurationToHours(previousDashboardData?.totalDuration)
  );

  const resultTotalSession = calculatePercentageChange(
    data?.totalSessionCount,
    previousDashboardData?.totalSessionCount
  );
  const resultTotalWalletRecharge = calculatePercentageChange(
    data?.totalWalletRecharge,
    previousPaymentWalletData?.totalWalletRecharge
  );

  const resultTotalWalletRechargeDashboard = calculatePercentageChange(
    data?.totalWalletRecharge,
    previousDashboardData?.totalWalletRecharge
  );

  const resultTotalWalletRechargePayment = calculatePercentageChange(
    data?.totalWalletAmountAdded,
    previousPaymentWalletData?.totalWalletAmountAdded
  );

  const resultTotalCashback = calculatePercentageChange(
    data?.totalWalletCashbackAmount,
    previousPaymentWalletData?.totalWalletCashbackAmount
  );

  const resultTotalRevenuePayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );
  const resultTotalRfundPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultTotalAmountFromAppPayment = calculatePercentageChange(
    orgPaymentData,
    previousOrgPaymentData
  );

  const resultNewUsers = calculatePercentageChange(
    customers?.customersCount,
    previousCustomersData?.customersCount
  );

  // Hub - Management

  const resultHubSession = calculatePercentageChange(
    data?.totalSessionCount_Guard,
    previousDashboardData?.totalSessionCount_Guard
  );

  const resultHubPassSold = calculatePercentageChange(
    data?.totalPassCount,
    previousDashboardData?.totalPassCount
  );

  const resultHubPassValue = calculatePercentageChange(
    data?.totalPassValue,
    previousDashboardData?.totalPassValue
  );

  const resultRFIDSession = calculatePercentageChange(
    data?.totalSessionCount_Rfid,
    previousDashboardData?.totalSessionCount_Rfid
  );

  const resultOCPISession = calculatePercentageChange(
    data?.totalSessionCount_Ocpi,
    previousDashboardData?.totalSessionCount_Ocpi
  );

  const resultPassCount = calculatePercentageChange(
    data?.hubCashTransactionStats?.overallPassDetails?.totalPassCount,
    previousDashboardData?.hubCashTransactionStats?.overallPassDetails
      ?.totalPassCount
  );

  const resultPassValue = calculatePercentageChange(
    data?.hubCashTransactionStats?.overallPassDetails?.totalPassValue,
    previousDashboardData?.hubCashTransactionStats?.overallPassDetails
      ?.totalPassValue
  );

  const resultHubEnergy = calculatePercentageChange(
    data?.hubChargingSessionsStats?.overallChargingSessionsDetails
      ?.energyConsumed,
    previousDashboardData?.hubChargingSessionsStats
      ?.overallChargingSessionsDetails?.energyConsumed
  );

  const resultTotalHubSession = calculatePercentageChange(
    data?.hubChargingSessionsStats?.overallChargingSessionsDetails
      ?.completedSessionsCount,
    previousDashboardData?.hubChargingSessionsStats
      ?.overallChargingSessionsDetails?.completedSessionsCount
  );

  const currencySymbol = GetCurrencySymbol();

  // Ensure data exists and has a profit field
  const getValueByTitle = (title) => {
    switch (title) {
      case "Total Billed Amount":
        return resultTotalRevenue;
      case "Hub Sessions":
        return resultHubSession;
      case "Total Hub Sessions":
        return resultTotalHubSession;
      case "Total Hub Passes Sold":
        return resultHubPassSold;
      case "Total Value of Passes Sold":
        return resultHubPassValue;
      case "Total Energy Consumed (kWh)":
        return hubStatsOverview ? resultHubEnergy : resultTotalEnergy;
      case "Direct Session":
        return resultDirectSession;
      case "App Session":
        return resultTotalAppSession;
      case "Hub Session":
        return resultTotalDirectHubSession;
      case "RFID Session":
        return resultTotalDirectRFIDSession;
      case "OCPI Session":
        return resultTotalDirectOCPISession;
      case "Total uptime (hrs)":
        return resultTotalDuration;
        case "Total Utilization (hrs)":
          return resultTotalDuration;
      case "Total Sessions":
        return resultTotalSession;
      case "Wallet Recharge":
        return resultTotalWalletRecharge;
      case `Wallet Recharge (${currencySymbol})`:
        return resultTotalWalletRechargeDashboard;
      case "Cashbacks":
        return resultTotalCashback;
      case "New customers":
        return resultNewUsers;
      //payment
      case "Refund Amount":
        return resultTotalRfundPayment;
      case "Total Billed Amount (INR)":
        return resultTotalRevenuePayment;
      case "Direct Amount (QR)":
        return resultTotalRfundPayment;
      case "Amount from App":
        return resultTotalAmountFromAppPayment;
      case "RFID":
        return resultRFIDSession;
      case "OCPI":
        return resultOCPISession;
      case "Total Sold Pass Count":
        return resultPassCount;
      case "Total Sold Pass Value":
        return resultPassValue;
      default:
        return data?.value || "N/A";
    }
  };

  // Get the profit value based on the title
  const profitValue = getValueByTitle(title);

  // Helper function to determine the color based on the profit value
  const getProfitColor = (profit) => {
    if (typeof profit === "string") {
      return profit.startsWith("-") ? "#790000" : "#027900";
    }
    return "#027900"; // Default color if profit is not a string
  };

  // Determine the color based on the profit value
  const profitColor = getProfitColor(profitValue);

  // Handle zero percentage differently
  const displayValue = profitValue === "0.00%" ? "Nil" : profitValue;

  return (
    <Box
      sx={{
        padding: "2px 8px",
        background: `rgba(${
          profitColor === "#790000" ? "121, 0, 0, 0.10" : "2, 121, 0, 0.10"
        })`,
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        fontFamily={"mySecondFont"}
        color={profitColor}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {displayValue}
        <span
          style={{
            fontSize: "16px",
            marginLeft: "4px",
            fontWeight: "600",
          }}
          className="material-symbols-outlined"
        >
          {typeof profitValue === "string" && profitValue.startsWith("-")
            ? "trending_down"
            : "trending_up"}
        </span>
      </Typography>
    </Box>
  );
};

export default ValueChip;
