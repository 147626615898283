import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "../../../breadcrumbs/BreadCrumbs";
import Heading from "../../../../utils/Heading";
import MainHeading from "../../../../utils/MainHeading";
import CustomTabs from "../../../custom/CustomTabs";
import Details from "./Details";
import Attendence from "./Attendence";
import {
  HubGuardDetailsColumn,
  HubGuardDetailsData,
} from "../../../../utils/DummyData";
import { useDispatch } from "react-redux";
import {
  getOneGuardAttendance,
  getOneGuardDetails,
} from "../../../../redux/action/hubManagement/hubManagementSlice";
import { useSelector } from "react-redux";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [guardId, setGuardId] = useState(null);

  const { guardAttendance, guardDetails, loading, error } = useSelector(
    (state) => state.hubManagement
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setGuardId(id);
    }
  }, []);

  useEffect(() => {
    if (guardId) {
      dispatch(getOneGuardAttendance(guardId));
      dispatch(getOneGuardDetails(guardId));
    }
  }, [dispatch, guardId]);

  console.log(guardAttendance);

  const tabsData = [
    {
      label: "Attendence",
      content: (
        <Attendence columns={HubGuardDetailsColumn} row={guardAttendance} />
      ),
    },
    {
      label: "Details",
      content: <Details data={guardDetails} />,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          {/* <Box sx={{ marginBottom: "24px" }}>
            <Breadcrumbs />
          </Box> */}

          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Heading text={"Guard Name:"} />
            <MainHeading text={guardDetails?.name} /> {/* Placeholder text */}
          </Box>
        </Box>
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
