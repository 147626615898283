import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import ValueCard from "../../dashboard/ValueCard";
import ValueChip from "../../Chips/ValueChip";
import DoubleLineGraph from "../../Graphs/DoubleLineGraph";
import GraphZoomButton from "../../../utils/GraphZoomButton";

export const overallTotalSessionData = {
  id: 1,
  profit: "5.5",
  compare: "56",
};
const overallCompareColor = overallTotalSessionData.compare.startsWith("-")
  ? "#790000"
  : "#027900";

const DirectSession = ({
  data,
  selectedTime,
  startTime,
  endTime,
  previousDashboardData,
}) => {
  const totalSessionData = [
    {
      id: 1,
      sessionType: "Direct",
      sessionName: "Direct Session",
      sessionCount: data?.totalSessionCount_Upi,
      profit: "30.5",
      compare: "25",
    },
    {
      id: 2,
      sessionType: "App",
      sessionName: "App Session",
      sessionCount: data?.totalSessionCount_App,
      profit: "-20",
      compare: "-17",
    },
  ];

  const formatValue = (value) => {
    const valueStr = String(value); // Convert value to string
    if (valueStr.startsWith("-")) {
      return valueStr;
    }
    return `+${valueStr}`;
  };

  const totalSessions = data?.totalSessionCount;

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  // Calculate comparison values
  const compareDirectSession =
    data?.totalSessionCount_Upi - previousDashboardData?.totalSessionCount_Upi;
  const compareAppSession =
    data?.totalSessionCount_App - previousDashboardData?.totalSessionCount_App;
  const compareHubSessions =
    data?.totalSessionCount_Guard -
    previousDashboardData?.totalSessionCount_Guard;
  const compareHRFIDSessions =
    data?.totalSessionCount_Rfid -
    previousDashboardData?.totalSessionCount_Rfid;
  const compareOCPISessions =
    data?.totalSessionCount_Ocpi -
    previousDashboardData?.totalSessionCount_Ocpi;

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  return (
    // <Box
    //   sx={{
    //     padding: "16px",
    //     background: "#fff",
    //     width: "100%",
    //     borderRadius: "8px",
    //   }}
    // >
    //   DirectSession
    // </Box>

    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={4} sx={12}>
          {/* value cards */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ValueCard
              data={data}
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
              title="Total Sessions"
              topGraphBox="true"
              cardTypeProp={"dashboard"}
            />

            {/* Direct Session and App Session */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {/* Direct Session Box */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#FF974B", // Direct session color
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      Direct Session
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_Upi}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    <ValueChip
                      title={"Direct Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  </Typography>
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareDirectSession),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareDirectSession)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                </Box>
              </Box>

              {/* App Session Box */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#69A5FF", // App session color
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      App Session
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_App}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    <ValueChip
                      title={"App Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  </Typography>
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareAppSession),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareAppSession)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                </Box>
              </Box>

              {/* Hub Sesssion Box */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#1AC95D",
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      Hub Session
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_Guard}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    <ValueChip
                      title={"Hub Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  </Typography>
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareHubSessions),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareHubSessions)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                </Box>
              </Box>

              {/* RFID Sesssion Box */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#FF6969",
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      RFID Session
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_Rfid}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    <ValueChip
                      title={"RFID Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  </Typography>
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareHRFIDSessions),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareHRFIDSessions)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                </Box>
              </Box>

              {/* OCPI Sesssion Box */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "#AB6AF0",
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "myThirdFont",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                      }}
                    >
                      OCPI Session
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F2F2F",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {data?.totalSessionCount_Ocpi}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#666",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "myThirdFont",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    <ValueChip
                      title={"OCPI Session"}
                      data={data} // Passing the whole data object
                      previousDashboardData={previousDashboardData} // Pass previous data
                    />
                  </Typography>
                  <Typography
                    fontFamily={"myThirdFont"}
                    color={"#666"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    <span
                      style={{
                        color: getCompareColor(compareOCPISessions),
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {formatValue(compareOCPISessions)}
                    </span>{" "}
                    than {getComparisonText()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} sx={12}>
          <Box>
            <DoubleLineGraph
              data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
              // graphTitle="No. of Direct sessions vs App sessions"
                graphTitle="Charging Session Analysis"
              gr_type={"chargingStation_ChargingPoints"}
            />
          </Box>
          <GraphZoomButton
            GraphComponent={DoubleLineGraph}
            graphProps={{ data, selectedTime, startTime, endTime }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DirectSession;
