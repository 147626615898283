import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../../utils/MainHeading";
import { useDispatch, useSelector } from "react-redux";
import { fetchFinanceStations } from "../../../redux/action/financeManager/financeManagerSlice";
import StationsDetails from "./StationsDetails";

const HomeForm = () => {
  const [sortColumn, setSortColumn] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // State to control the page view
  const [selectedStation, setSelectedStation] = useState(null);
  const dispatch = useDispatch();
  const { stations, status, error } = useSelector(
    (state) => state.financeStations
  );

  useEffect(() => {
    dispatch(fetchFinanceStations());
  }, [dispatch]);

  // Handle change event for Select component
  const handleSortChange = (event) => {
    setSortColumn(event.target.value);
  };

  // Handle click event for the Proceed button
  const handleProceed = () => {
    if (sortColumn) {
      const stationData = stations.find(
        (station) => station.name === sortColumn
      );
      setSelectedStation(stationData);
      setIsSubmitted(true); // Set state to show the new page
    }
  };

  const handleBack = () => {
    setIsSubmitted(false);
    setSelectedStation(null);
  };

  // Component to show after proceed
  const NewPage = () => (
    <StationsDetails station={selectedStation} onBack={handleBack} />
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isSubmitted ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            // padding: "24px",
          }}
        >
          <MainHeading text={"Hub/ Station"} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              color={"#2F2F2F"}
              fontSize={"16px"}
              fontFamily={"myThirdFont"}
              textAlign={"start"}
            >
              Please choose the Station/ Hub
            </Typography>
            <FormControl fullWidth>
              <Select
                displayEmpty
                value={sortColumn}
                onChange={handleSortChange}
                sx={{
                  padding: "12px 8px 12px 16px",
                  width: "416px",
                  height: "48px",
                  color: "#1C1C1C",
                  fontFamily: "myThirdFont",
                  textAlign: "left",
                  "& .MuiSelect-icon": {
                    color: "#1C1C1C", // Customize icon color
                  },
                }}
              >
                <MenuItem
                  sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
                  value=""
                >
                  <span>Select Station</span> {/* Placeholder text */}
                </MenuItem>
                {stations?.map((item) => (
                  <MenuItem
                    key={item?.id}
                    value={item?.name}
                    sx={{ fontFamily: "mySecondFont" }}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={handleProceed}
              sx={{
                padding: "12px 24px",
                borderRadius: "4px",
                background: "#E50000",
                maxWidth: "416px",
                width: "100%",
                mt: "8px",
                color: "#fff",
                fontFamily: "mySecondFont",
                ":hover": {
                  background: "#E50000",
                },
              }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      ) : (
        <NewPage />
      )}
    </Box>
  );
};

export default HomeForm;
