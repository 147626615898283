import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import LineGraph from "../Graphs/LineGraph";
import StopReasonBarGraph from "../Graphs/StopReasonBarGraph";
import Heading from "../../utils/Heading";

const StopReasonBox = ({
  data,
  selectedTime,
  endTime,
  startTime,
  loading,
}) => {

  console.log("gggggggd", data)
  return (
    <Grid item md={12}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img src="/images/stars.png" alt="" />
          <Heading text={"Stop Reason Analysis"} />
        </Box>
        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />

        <StopReasonBarGraph
          data={data}
          selectedTime={selectedTime}
          loading={loading}
          startTime={startTime}
          endTime={endTime}
        />
      </Box>
    </Grid>
  );
};

export default StopReasonBox;
