import * as React from "react";
import CustomTransition from "../custom/CustomTransition";
import Heading from "../../utils/Heading";
import {
  Backdrop,
  Grid,
  Box,
  Modal,
  Button,
  Divider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createTariff,
  getTariffs,
  updateTariff,
} from "../../redux/action/tariff/createTariffSlice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const AddNewTariff = ({ open, handleClose, heading, data }) => {
  const dispatch = useDispatch();
  const tariffs = useSelector((state) => state.tariff.tariffs);

  const validateFormData = (formData) => {
    const requiredFields = ["name", "currency", "electricityCharges"];

    // Check for empty required fields
    for (const field of requiredFields) {
      if (
        formData[field] === undefined ||
        formData[field].toString().trim() === ""
      ) {
        toast.error(
          `Please fill in the ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`
        );
        return false;
      }
    }

    // Validate that specific fields are positive values
    const positiveFields = [
      "electricityCharges",
      // "baseCharges",
      // "parkingCharges",
      // "plateformFee",
      // "tax",
      // "otherCharges",
    ];

    for (const field of positiveFields) {
      const value = parseFloat(formData[field]);
      if (isNaN(value)) {
        toast.error(
          `${field.replace(/([A-Z])/g, " $1").toLowerCase()} must be a number`
        );
        return false;
      }
      if (value < 0) {
        toast.error(
          `${field
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()} must be a positive value`
        );
        return false;
      }
    }

    // If all checks pass, return true
    return true;
  };

  const [formValues, setFormValues] = React.useState({
    name: "",
    currency: "",
    electricityCharges: "",
    baseCharges: 0,
    parkingCharges: 0,
    status: true,
    plateformFee: 0,
    tax: 0,
    otherCharges: 0,
  });

  React.useEffect(() => {
    if (data) {
      setFormValues({
        name: data.name || "",
        currency: data.currency || "",
        electricityCharges: data.electricityCharges || 0,
        baseCharges: data.baseCharges || 0,
        parkingCharges: data.parkingCharges || 0,
        status: data.status ?? true,
        plateformFee: data.plateformFee || 0,
        tax: data.tax || 0,
        otherCharges: data.otherCharges || 0,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    let { name, value, type } = e.target;

    if (type == "number") {
      value = +value;
    }
    setFormValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //in the below code we have refresh issue
  // const handleOpenAddButton = async () => {

  //   if (!validateFormData(formValues)) {
  //     return; // Stop the submit process if validation fails
  //   }

  //   try {
  //     if (data && data._id) {
  //       const updatedTariff = await dispatch(
  //         updateTariff({ id: data._id, formValues })
  //       ).unwrap();
  //       toast.success("Tariff updated successfully.");
  //     } else {
  //       const newTariff = await dispatch(createTariff(formValues)).unwrap();
  //       toast.success("Tariff created successfully.");
  //     }
  //     handleClose(); // Close the modal on success
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  const handleOpenAddButton = async () => {
    if (!validateFormData(formValues)) {
      return; // Stop the submit process if validation fails
    }

    try {
      if (data && data._id) {
        await dispatch(updateTariff({ id: data._id, formValues })).unwrap();
        toast.success("Tariff updated successfully.");
      } else {
        await dispatch(createTariff(formValues)).unwrap();
        toast.success("Tariff created successfully.");
      }
      dispatch(getTariffs()); // Fetch the latest tariffs after update
      handleClose(); // Close the modal on success
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Determine the currency icon based on selected currency
  const currencyIcon = () => {
    switch (formValues.currency) {
      case "INR":
        return "currency_rupee"; // Icon for INR
      case "USD":
        return "attach_money"; // Icon for USD
      case "GBP":
        return "currency_pound"; // Icon for GBP
      default:
        return "currency_rupee"; // Default icon
    }
  };

  const handleCancel = () => {
    // Reset form data
    setFormValues({
      name: "",
      currency: "",
      electricityCharges: "",
      baseCharges: 0,
      parkingCharges: 0,
      status: true,
      plateformFee: 0,
      tax: 0,
      otherCharges: 0,
    });
    // Close the modal
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src="/images/tariff-icon.png" alt="" />
              <Heading text={data ? "Edit tariff" : "Add new Tariff"} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "" }}>
              <span
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                className="material-symbols-outlined"
              >
                cancel
              </span>
            </Box>
          </Box>
          <Divider
            sx={{ background: "#ddd", margin: "8px 0px", marginBottom: "24px" }}
          />

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={8}>
                <Box>
                  <label
                    style={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      marginBottom: "8px",
                      display: "block",
                    }}
                  >
                    Tariff Name*
                  </label>
                  <TextField
                    style={{
                      color: "rgba(47, 47, 47, 0.50)",
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      //   padding: "8px 16px",
                      // width: "120px",
                    }}
                    placeholder="Enter name here"
                    fullWidth
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Currency*
                </label>
                <FormControl fullWidth>
                  <Select
                    name="currency"
                    value={formValues.currency}
                    onChange={handleInputChange}
                    // sx={{
                    //   color: "rgba(47, 47, 47, 0.50)",
                    //   fontFamily: "myThirdFont",

                    //   fontWeight: 400,
                    //   lineHeight: "24px",
                    // }}

                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      color: "#1C1C1C",
                      fontFamily: "myThirdFont",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    <MenuItem value="">Select the Currency</MenuItem>
                    <MenuItem value="INR">INR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }} />

            {/* Fields Electricity, Base and Parking Charges */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Electricity Charges*
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Electricity charge"
                  fullWidth
                  name="electricityCharges"
                  value={formValues.electricityCharges}
                  onChange={handleInputChange}
                  type={"Number"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Base Charges
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Base Charge here"
                  fullWidth
                  name="baseCharges"
                  value={formValues.baseCharges}
                  // value = {formValues.baseCharges === 0 ? "" : formValues.baseCharges}
                  onChange={handleInputChange}
                  type={"Number"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Parking Charges
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Parking Charge"
                  fullWidth
                  name="parkingCharges"
                  value={formValues.parkingCharges}
                  // value = {formValues.parkingCharges === "" ? 0 : formValues.parkingCharges}
                  onChange={handleInputChange}
                  type="Number"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }} />

            {/* Fields, Platform, Tax and Other Charges */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Platform fee (%)
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Platform Fee"
                  fullWidth
                  name="plateformFee"
                  value={formValues.plateformFee}
                  // value = {formValues.plateformFee === "" ? 0 : formValues.plateformFee}
                  onChange={handleInputChange}
                  type="Number"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Tax (%)
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Tax% here"
                  fullWidth
                  name="tax"
                  value={formValues.tax}
                  // value = {formValues.tax === "" ? 0 : formValues.tax}
                  onChange={handleInputChange}
                  type="Number"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Other charges
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Other Charge"
                  fullWidth
                  name="otherCharges"
                  // value={formValues.otherCharges}
                  value={
                    formValues.otherCharges === "" ? 0 : formValues.otherCharges
                  }
                  onChange={handleInputChange}
                  type="Number"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={5}>
                <Box>
                  <label
                    style={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      marginBottom: "8px",
                      display: "block",
                    }}
                  >
                    Status
                  </label>
                  <FormControl fullWidth>
                    <Select
                      name="status"
                      value={formValues.status}
                      onChange={handleInputChange}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "48px",
                        color: "#1C1C1C",
                        fontFamily: "myThirdFont",
                        lineHeight: "24px",
                      }}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: "72px", display: "flex", gap: "8px", width: "100%" }}>
            <Button
              onClick={handleOpenAddButton}
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E50000",
                width: "25%",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: "#E50000",
                },
              }}
            >
              {data ? "Update" : "Add Tariff"}
            </Button>
            <Button
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
              // onClick={handleClose}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddNewTariff;
