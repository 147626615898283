import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

// Utility function to format phone numbers
export const formatPhoneNumber = (rawPhone) => {
  try {
    const phoneNumber = parsePhoneNumber(rawPhone, "IN"); // Use appropriate default country code
    if (isValidPhoneNumber(rawPhone, "IN")) {
      return `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;
    } else {
      console.warn("Invalid phone number format:", rawPhone);
      return rawPhone; // Return the original raw phone number if invalid
    }
  } catch (error) {
    console.error("Error parsing phone number:", error);
    return rawPhone; // Return the original raw phone number if parsing fails
  }
};
