import { Box, Grid } from "@mui/material";
import React from "react";
import HandshakeLeftBox from "./HandshakeLeftBox";
import HandshakeRightBox from "./HandshakeRightBox";

const HandshakeTopBox = ({ versionsList, handshakeStatus, networkDetail }) => {
  // const handleSubmit
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Box
          sx={{
            padding: "24px",
            borderRadius: "8px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            height: "100%",
          }}
        >
          <HandshakeLeftBox
            networkDetail={networkDetail}
            versionsList={versionsList}
            handshakeStatus={handshakeStatus}
          />
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box
          sx={{
            padding: "24px",
            borderRadius: "8px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            height: "100%",
          }}
        >
          <HandshakeRightBox    networkDetail={networkDetail}     versionsList={versionsList}/>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HandshakeTopBox;
