import React, { useEffect } from "react";
import {
  Box,
  Modal,
  Select,
  MenuItem,
  Button,
  FormControl,
  Typography,
} from "@mui/material";
import {
  getAllChargingPasses,
  getAllGuards,
  getAllHubSessions,
  getAllHubStation,
  getAllPassHolders,
  getAllStationsHub,
  getAllTransaction,
  getShiftCollection,
} from "../../../redux/action/hubManagement/hubManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { useStationContext } from "../../../Hooks/StationContext";
import { useTimeContext } from "../../../Hooks/TimeContext";

const HubTableFilterModal = ({
  open,
  handleClose,
  selectedStation,
  setSelectedStation,
  selectedCity,
  setSelectedCity,
  tabTitle,
  isActive,
  stationId,
}) => {
  const dispatch = useDispatch();
  const { setStationIds } = useStationContext();
  const { startTime, endTime, setStartTime, setEndTime } = useTimeContext();
  // Select the necessary state from the Redux store
  const { hubStations, hubCities, loading, error } = useSelector(
    (state) => state.hubManagement
  );

  // Fetch all hub stations when the modal opens
  useEffect(() => {
    if (open) {
      dispatch(getAllHubStation());
    }
  }, [dispatch, open]);

  // Filter stations based on the selected city
  const filteredStations = selectedCity
    ? hubStations.filter((station) => station.city === selectedCity)
    : hubStations;

  const handleApply = () => {
    const stationIds = selectedStation
      ? [selectedStation] // If a station is selected, send that one
      : filteredStations.map((station) => station._id); // If no station, send all for the selected city

    setStationIds(stationIds);

    const startMillis = startTime ? startTime.valueOf() : null;
    const endMillis = endTime ? endTime.valueOf() : null;

    // Dispatch the action based on tabTitle
    if (tabTitle === "PassHolder") {
      dispatch(
        getAllPassHolders({
          stationIds: { stationIds: stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    } else if (tabTitle === "HubStations") {
      dispatch(
        getAllStationsHub({
          stationIds: { stationIds: stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    } else if (tabTitle === "HubActiveSessions") {
      dispatch(
        getAllHubSessions({
          activeCheck: isActive,
          stationIds: { stationIds: stationIds },
        })
      );
    } else if (tabTitle === "HubAllSessions") {
      dispatch(
        getAllHubSessions({
          activeCheck: isActive,
          stationIds: stationId ? { stationIds: [stationId] } : { stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    } else if (tabTitle === "Guard") {
      dispatch(
        getAllGuards({
          stationIds: { stationIds: stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    } else if (tabTitle === "ChargingPass") {
      dispatch(
        getAllChargingPasses({
          stationIds: { stationIds: stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    } else if (tabTitle === "AllTransaction") {
      dispatch(
        getAllTransaction({
          stationIds: { stationIds: stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    } else if (tabTitle === "ShiftCollection") {
      dispatch(
        getShiftCollection({
          stationIds: { stationIds: stationIds },
          startDate: startMillis,
          endDate: endMillis,
        })
      );
    }

    // Close the modal after applying filters
    handleClose();
  };

  const handleClear = () => {
    setSelectedStation("");
    setSelectedCity("");
    setStartTime(null);
    setEndTime(null);
    // Optionally reset the station context if needed
    setStationIds([]);

    // Close the modal after clearing filters
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="filter-modal-title"
      aria-describedby="filter-modal-description"
    >
      <Box
        sx={{
          width: 300,
          bgcolor: "white",
          p: 4,
          borderRadius: "8px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
          boxShadow: 24,
        }}
      >
        <Typography id="filter-modal-title" variant="h6" component="h2">
          Apply Filters
        </Typography>

        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}

        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <Select
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.target.value);
              setSelectedStation(""); // Reset station when city changes
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ fontFamily: "mySecondFont" }}
          >
            <MenuItem sx={{ fontFamily: "mySecondFont" }} value="">
              Select City
            </MenuItem>
            {hubCities.map((city, index) => (
              <MenuItem
                key={index}
                sx={{ fontFamily: "mySecondFont" }}
                value={city}
              >
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            value={selectedStation}
            onChange={(e) => setSelectedStation(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ fontFamily: "mySecondFont" }}
          >
            <MenuItem sx={{ fontFamily: "mySecondFont" }} value="">
              Select Station
            </MenuItem>
            {filteredStations.map((station) => (
              <MenuItem
                key={station._id}
                sx={{ fontFamily: "mySecondFont" }}
                value={station._id}
              >
                {station.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Button
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "120px",
              height: "42px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#222",
              },
              "&.Mui-disabled": {
                backgroundColor: "#555",
                color: "#999",
                cursor: "not-allowed",
                opacity: 0.6,
              },
            }}
            onClick={handleApply}
            // disabled={loading || !selectedCity || !selectedStation}
          >
            Apply
          </Button>
          <Button
            sx={{
              background: "#f44336",
              padding: "8px 24px",
              width: "120px",
              height: "42px",
              borderRadius: "4px",
              color: "#fff",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              ":hover": {
                background: "#d32f2f",
              },
            }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default HubTableFilterModal;
