import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import {
  changeAvalaible,
  changeAvalaibleType,
} from "../../../../utils/DummyData";

const ChangeAvailability = ({ onInputChange, formValues, data }) => {
  console.log(data);
  const handleChange = (event) => {
    onInputChange("changeAvalaibility", event.target.value);
  };

  const handleChangeType = (event) => {
    onInputChange("changeType", event.target.value);
  };

  return (
    <Box
      sx={{ display: "flex", gap: "16px", alignItems: "center", width: "100%" }}
    >
      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
          id="demo-simple-select-label"
        >
          Select Connector ID
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formValues.changeAvalaibility || ""}
          label=" Select Connector ID"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            //   padding: "8px 16px",
            width: "100%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "mySecondFont",
          }}
        >
          {data?.connectors?.map((item) => {
            return (
              <MenuItem value={item.connectorId}>{item.connectorId}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
          id="demo-simple-select-label"
        >
          Select Availability Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formValues.changeType || ""}
          label="Select Availability Type"
          onChange={handleChangeType}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            //   padding: "8px 16px",
            width: "100%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "mySecondFont",
          }}
        >
          {changeAvalaibleType.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ChangeAvailability;
