import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import GalleryCard from "./GalleryCard";

const GalleryBox = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "200" }}
            className="material-symbols-outlined"
          >
            gallery_thumbnail
          </span>
          <Heading text={"Gallery"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* Gallery - Cards */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {data.stationImgs.map((imgData, index) => (
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            sx={{ display: "flex", flexDirection: "column" }}
            key={imgData._id} // Use a unique key from imgData
          >
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <GalleryCard item={imgData.image.url} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GalleryBox;
