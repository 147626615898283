import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/admin_management/TopBar";
import TableBox from "../../components/admin_management/TableBox";

const AdminManagement = () => {
  return (
    <Box>
      <TopBar />

      {/* table */}
      <TableBox />
    </Box>
  );
};

export default AdminManagement;
