import { Box } from "@mui/material";
import React from "react";
import HomeForm from "../../../components/FinanceManager/Home/HomeForm";

const Home = () => {
  return (
    <Box
      sx={{
        padding: "32px",
        background: "#fff",
        borderRadius: "8px",
        height: "calc(100vh - 140px)",
      }}
    >
      <HomeForm />
    </Box>
  );
};

export default Home;
