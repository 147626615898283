import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import {
  selectConfigurationKey,
  selectKeyType,
} from "../../../../utils/DummyData";

const ChangeConfig = ({ onInputChange, formValues }) => {
  const handleChange = (event) => {
    onInputChange("selectKeyType", event.target.value);
  };

  const handleChangeType = (event) => {
    onInputChange("configurationKey", event.target.value);
  };

  const handleChangeCustomKey = (event) => {
    onInputChange("customConfigurationKey", event.target.value);
  };

  const handleChangeValue = (event) => {
    onInputChange("configurationValue", event.target.value);
  };

  // Determine if inputs should be disabled
  const isPredefinedKey = formValues.selectKeyType === "Predefined"; // Adjust this condition based on your predefined key type
  const isCustomKey = formValues.selectKeyType === "Custom";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel
            sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
            id="demo-simple-select-label"
          >
            Select Key Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formValues.selectKeyType || ""}
            label="Select Key Type"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              width: "100%",
              height: "48px",
              color: "#1C1C1C",
              fontFamily: "mySecondFont",
            }}
            disabled={isPredefinedKey} // Disable if Select Key Type is 'Predefined'
          >
            {selectKeyType.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel
            sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
            id="demo-simple-select-label"
          >
            Select Availability Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formValues.configurationKey || ""}
            label="Select Availability Type"
            onChange={handleChangeType}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              width: "100%",
              height: "48px",
              color: "#1C1C1C",
              fontFamily: "mySecondFont",
            }}
            disabled={isCustomKey}
          >
            {selectConfigurationKey.map((item) => (
              <MenuItem
                sx={{ fontFamily: "mySecondFont" }}
                key={item.id}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          style={{
            width: "50%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            outline: "none",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "4px 12px",
          }}
          value={formValues.customConfigurationKey || ""}
          placeholder="Enter Custom Configuration Key"
          onChange={handleChangeCustomKey}
          type="text"
          disabled={isPredefinedKey} // Disable if Select Key Type is 'Predefined'
        />
        <input
          style={{
            width: "50%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            outline: "none",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "4px 12px",
          }}
          value={formValues.configurationValue || ""}
          type="text"
          onChange={handleChangeValue}
          placeholder="Enter Value"
        />
      </Box>
    </Box>
  );
};

export default ChangeConfig;
