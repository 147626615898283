import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import Heading from "../../../utils/Heading";
import {TextCopy} from "../../../utils/TextCopy/TextCopy";

const InfoBox = ({ label, value, icon }) => {
  
  //Copy Text
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied); // Use the TextCopy function
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              onClick={handleCopy}
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const BasicDetails = ({data}) => {
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            info
          </span>
          <Heading text={"Basic details"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Company ID" value={data?.operatorId} icon="content_copy" />
        <InfoBox label="Registered as" value={data?.name} />
        <InfoBox label="GSTIN" value={data?.gstin} icon="content_copy" />
        <InfoBox label="Type" value={data?.orgType?.toUpperCase()} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InfoBox label="CESS (1C)" value="1.5%" />
          <InfoBox label="CESS (Tata power)" value="1.25%" />
        </Box>
      </Box>
    </Box>
  );
};

export default BasicDetails;
