import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ValueCard from "../../dashboard/ValueCard";
import { valueCardData, walletRechargeData } from "../../../utils/DummyData";
import DoubleLineGraph from "../../Graphs/DoubleLineGraph";
import LineGraph from "../../Graphs/LineGraph";
import SemiDoughnutChart from "../../Graphs/SemiDoughnutChart";
import DoughnutChart from "../../Graphs/DoughnutChart";
import GraphZoomButton from "../../../utils/GraphZoomButton";

const BottomBox = ({
  data,
  selectedTime,
  startTime,
  endTime,
  previousPaymentWalletData,
}) => {
  const filterValueCardData = valueCardData.filter(
    (card) =>
      card.title === "Total Billed Amount" || card.title === "Refund Amount"
  );

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={6} sx={12}>
          <Box>
            <LineGraph
              graphTitle="Wallet Recharge"
              data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
            
          </Box>
          <Box sx={{  }}>
            <GraphZoomButton
              GraphComponent={LineGraph}
              graphProps={{ data, selectedTime, startTime, endTime }}
            />
          </Box>
        </Grid>

        <Grid item md={3} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <ValueCard
                title={"Wallet Recharge"}
                data={data}
                selectedTime={selectedTime}
                previousPaymentWalletData={previousPaymentWalletData}
                topGraphBox="true"
              />
              <ValueCard
                title={"Cashbacks"}
                data={data}
                selectedTime={selectedTime}
                previousPaymentWalletData={previousPaymentWalletData}
                topGraphBox="true"
              />
            </Box>
          </Box>
        </Grid>

        <Grid item md={3} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <DoughnutChart
              data={walletRechargeData}
              rechargeData={data}
              centerData="true"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BottomBox;
