import Dashboard from "../pages/Dashboard/Dashboard";
import ChargingSessions from "../pages/Charging-Sessions/ChargingSessions";
import ChargingStations from "../pages/Charging-Stations/ChargingStations";
import ChargingStationDetails from "../pages/ChargingStationDetails/ChargingStationDetails";
import AddNewChargingStation from "../pages/AddNewChargingStation/AddNewChargingStation";
import ChargingPoints from "../pages/ChargingPoints/ChargingPoints";
import ChargingPointsDetails from "../pages/ChargingPointsDetails/ChargingPointsDetails";
import ChargingSessionsDetails from "../pages/ChargingSessionsDetails/ChargingSessionsDetails";
import ChargingStationsMap from "../pages/ChargingStationsMap/ChargingStationsMap";
import TariffManagement from "../pages/TariffManagement/TariffManagement";
import CompanyManagement from "../pages/CompanyManagement/CompanyManagement";
import AddNewCompany from "../pages/AddNewCompany/AddNewCompany";
import CompanyManagementDetails from "../pages/CompanyManagementDetails/CompanyManagementDetails";
import Payment from "../pages/Payment/Payment";
import AdminManagement from "../pages/AdminManagement/AdminManagement";
import Login from "../pages/Login/Login";
import Statistics from "../pages/Statistics/Statistics";
import CPODashboard from "../pages/CPODashboard/CPODashboard";
import RoleBasedRoute from "./RoleBasedRoute";
import VerifyEmail from "../pages/AuthPages/VerifyEmail/VerifyEmail";
import ResetPassword from "../pages/AuthPages/ResetPassword/ResetPassword";
import CreatePassword from "../pages/AuthPages/CreatePassword/CreatePassword";
import AddNewChargingPoint from "../pages/AddNewChargingPoint/AddNewChargingPoint";
import OcpiManagement from "../pages/OcpiManagement/OcpiManagement";
import OcpiManagementDetails from "../pages/OcpiManagementDetails/OcpiManagementDetails";
import Home from "../pages/FinanceManager/Home/Home";
import PaymentHistory from "../pages/FinanceManager/PaymentHistory/PaymentHistory";
import { Navigate } from "react-router-dom";
import RfidManagement from "../pages/RFID/RfidManagement";
import HubManagement from "../pages/Hub-Management/HubManagement";
import AddNewGuardForm from "../pages/Hub-Management/Add-New-Guard/AddNewGuardForm";
import GuardDetails from "../pages/Hub-Management/GuardDetails/GuardDetails";
import AddNewOcpiTariff from "../pages/AddNewOcpiTariff/AddNewOcpiTariff";

// Useable routes
export let MyRoutes = {};

class Routes {
  routes = {};
  countryCode = "";
  constructor(countryCode) {
    this.countryCode = countryCode;
    this.routes = {
      dashboard: `/${countryCode}/dashboard`,
      cpo_dashboard: `/${countryCode}/cpo_dashboard`,

      // session routes
      chargingSessions: `/${countryCode}/charging_sessions`,
      chargingSessionsDetails: `/${countryCode}/charging_sessions/:id`,

      // stations routes
      charging_stations: `/${countryCode}/charging_stations`,
      addNewChargingStation: `/${countryCode}/charging_stations/add_new_charging_station`,
      chargingStationDetails: `/${countryCode}/charging_stations/:id`,
      charging_station_charging_points: `/${countryCode}/charging_stations/charging_points/:id`,
      charging_station_charging_sessions: `/${countryCode}/charging_stations/charging_sessions/:id`,

      // chargingPoints routes
      charging_points: `/${countryCode}/charging_points`,
      addNewChargingPoint: `/${countryCode}/add_new_charging_point`,
      charging_points_details: `/${countryCode}/charging_points/:id`,
      charging_point_charging_sessions: `/${countryCode}/charging_points/charging_sessions/:id`,

      // rfid routes
      rfid_management: `/${countryCode}/rfid_management`,

      // map routes
      stations_map: `/${countryCode}/stations_map`,

      // payment routes
      payment: `/${countryCode}/payment`,

      // company-management routes
      companies_management: `/${countryCode}/companies_management`,
      add_company: `/${countryCode}/companies_management/add_new_company`,
      company_details: `/${countryCode}/companies_management/:id`,
      company_management_stations: `/${countryCode}/companies_management/charging_stations/:id`,

      // tariff routes
      tariff_management: `/${countryCode}/tariff_management`,

      // statistics routes
      statistics: `/${countryCode}/statistics`,

      // ocpi routes
      ocpi_management: `/${countryCode}/ocpi_management`,

      ocpi_management_details: `/${countryCode}/ocpi_management_details/:id`,

      addNewOcpiTariff: `/${countryCode}/add_new_ocpi_tariff`,

      // admin routes
      admin_management: `/${countryCode}/admin_management`,

      // hub-management routes
      hub_management: `/${countryCode}/hub_management`,
      hub_management_charging_sessions: `/${countryCode}/hub_management/charging_sessions/:id`,
      hub_management_charging_stations: `/${countryCode}/hub_management/charging_stations/:id`,
      hub_management_charging_points: `/${countryCode}/hub_management/charging_points/:id`,
      add_new_guard: `/${countryCode}/hub_management/add_new_guard`,
      guard_details: `/${countryCode}/hub_management/guard_details/:id`,
      // for performance  : -
      hub_management_charging_stations_performance: `/${countryCode}/hub_management/charging_stations`,

      //finance manager role
      financeHome: `/${countryCode}/home`,
      finance_payment_history: `/${countryCode}/payment_history`,
    };
    MyRoutes = this.routes;
  }

  getRoutes() {
    const country = this.countryCode;
    return [
      {
        path: "/",
        element: <Navigate to={this.routes.dashboard} />,
        exact: true,
      },
      {
        path: this.routes.dashboard,
        element: <RoleBasedRoute element={Dashboard} />,
        exact: true,
      },
      {
        path: this.routes.cpo_dashboard,
        element: <CPODashboard />,
        exact: true,
      },
      {
        path: this.routes.chargingSessions,
        element: <ChargingSessions />,
        exact: true,
      },
      {
        path: this.routes.chargingSessionsDetails,
        element: <ChargingSessionsDetails />,
        exact: true,
      },
      {
        path: this.routes.charging_stations,
        element: <ChargingStations />,
        exact: true,
      },
      {
        // path: `${country}/charging_stations/:id`,
        path: this.routes.chargingStationDetails,
        element: <ChargingStationDetails />,
        exact: true,
      },
      {
        // path: `${country}/add_new_charging_station`,
        path: this.routes.addNewChargingStation,
        element: <AddNewChargingStation />,
        exact: true,
      },

         {
        // path: `${country}/add_new_charging_station`,
        path: this.routes.addNewOcpiTariff,
        element: <AddNewOcpiTariff/>,
        exact: true,
      },

      {
        path: this.routes.charging_station_charging_points,
        element: <ChargingPointsDetails />,
        exact: true,
      },
      {
        path: this.routes.charging_station_charging_sessions,
        element: <ChargingSessionsDetails />,
        exact: true,
      },
      {
        path: this.routes.addNewChargingPoint,
        element: <AddNewChargingPoint />,
        exact: true,
      },
      {
        path: this.routes.charging_points,
        element: <ChargingPoints />,
        exact: true,
      },
      {
        path: this.routes.charging_points_details,
        element: <ChargingPointsDetails />,
        exact: true,
      },
      {
        path: this.routes.charging_point_charging_sessions,
        element: <ChargingSessionsDetails />,
        exact: true,
      },
      {
        path: this.routes.rfid_management,
        element: <RfidManagement />,
        exact: true,
      },
      {
        path: this.routes.stations_map,
        element: <ChargingStationsMap />,
        exact: true,
      },
      {
        path: this.routes.tariff_management,
        element: <TariffManagement />,
        exact: true,
      },
      {
        path: this.routes.companies_management,
        element: <CompanyManagement />,
        exact: true,
      },
      {
        path: this.routes.add_company,
        element: <AddNewCompany />,
        exact: true,
      },
      {
        path: this.routes.company_details,
        element: <CompanyManagementDetails />,
        exact: true,
      },

      {
        path: this.routes.company_management_stations,
        element: <ChargingStationDetails />,
        exact: true,
      },
      {
        path: this.routes.payment,
        element: <Payment />,
        exact: true,
      },
      {
        path: this.routes.admin_management,
        element: <AdminManagement />,
        exact: true,
      },
      {
        path: "/login",
        element: <Navigate to={this.routes.dashboard} />,
      },
      {
        path: this.routes.statistics,
        element: <Statistics />,
        exact: true,
      },
      {
        path: `${country}/verify_email`,
        element: <VerifyEmail />,
        exact: true,
      },
      {
        path: `${country}/reset_password`,
        element: <ResetPassword />,
        exact: true,
      },
      {
        path: `${country}/create_new_password`,
        element: <CreatePassword />,
        exact: true,
      },
      {
        path: this.routes.ocpi_management,
        element: <OcpiManagement />,
        exact: true,
      },
      {
        path: this.routes.ocpi_management_details,
        element: <OcpiManagementDetails />,
        exact: true,
      },
      {
        path: this.routes.financeHome,
        element: <Home />,
        exact: true,
      },
      {
        path: this.routes.finance_payment_history,
        element: <PaymentHistory />,
        exact: true,
      },
      {
        path: this.routes.hub_management,
        element: <HubManagement />,
        exact: true,
      },
      {
        path: this.routes.hub_management_charging_sessions,
        element: <ChargingSessionsDetails />,
        exact: true,
      },
      {
        path: this.routes.hub_management_charging_stations,
        element: <ChargingStationDetails />,
        exact: true,
      },
      {
        path: this.routes.hub_management_charging_stations_performance,
        element: <ChargingStationDetails />,
        exact: true,
      },
      {
        path: this.routes.hub_management_charging_points,
        element: <ChargingPointsDetails />,
        exact: true,
      },
      {
        path: this.routes.add_new_guard,
        element: <AddNewGuardForm />,
        exact: true,
      },
      {
        path: this.routes.guard_details,
        element: <GuardDetails />,
        exact: true,
      },
      {
        path: "*",
        element: "not found",
      },
    ];
  }
}

export const convertCountryRoute = (country) => {
  return new Routes(country).getRoutes();
};
