import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { formatDateTime } from '../../../utils/FormatDateTime';
import * as XLSX from "xlsx";
import LiveLogTable from '../../custom/LiveLogTable';
import { ChargingPointlogsColumns, ocpiLogsTableColumnData } from '../../../utils/DummyData';

const OcpiLogsTableBox = ({  onRowClick,
    liveLogBtn,
    data,
    logsCount,
    lastLog,
    handleLiveData,
    getOneData,
    page,
    limit,
    logsFilter,
    setLogsFilter,
    setPage,
    setLimit,
    modalHeading,
    loading,}) => {
    const { liveLogs } = useSelector((state) => state.chargingPoints);

    const [selectedRow, setSelectedRow] = useState(null);
    const [logsEnabled, setLogsEnabled] = useState(false);
  
    const dispatch = useDispatch();
  
    let deviceId = getOneData?.device_id;
    const createdAt = lastLog?.createdAt ? formatDateTime(lastLog.createdAt) : {};

  console.log("hdshds", page, limit)
    // if (!data) {
    //   return <Typography>No data available123</Typography>;
    // }
  
    const handleChange = (event) => {
      setLogsFilter(event.target.value);
    };
  
    const handleRowClick = (rowData, rowIndex) => {
      setSelectedRow(rowIndex);
      if (onRowClick) {
        onRowClick(rowData);
      }
    };
  
    const handleExport = () => {
      if (data.length === 0) {
        toast.error("No logs available"); // Show toast notification
        return;
      }
  
      // Create a new workbook and add a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
  
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Logs");
  
      // Export the workbook
      XLSX.writeFile(wb, "LogsData.xlsx");
    };
  
    const handleLiveLogs = () => {
      setLogsEnabled(!logsEnabled);
    };
  
      // Render loading state or data
      if (loading) {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width:"100%",
              background:"#FFF"
            }}
          >
            <CircularProgress />
          </Box>
        );
      }
    
      if (!data) {
        return <Typography>No data available</Typography>;
      }
  
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "calc(100vh - 332px)",
          padding: "16px",
          background: "#fff",
          borderRadius: "8px",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControl>
            {/* const [filteredStations, setFilteredStations] = useState([]); */}
            <InputLabel
              sx={{
                fontSize: "14px",
                fontFamily: "myThirdFont",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="demo-simple-select-label"
            >
              Sort By
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={logsFilter}
              label="Sort By"
              onChange={handleChange}
              sx={{
                width: "134px",
                height: "48px",
                color: "#1C1C1C",
                fontFamily: "mySecondFont",
              }}
            >
              <MenuItem value={"createdAt"}>Recorded On</MenuItem>
              <MenuItem value={"logType"}>Log Type</MenuItem>
              <MenuItem value={"command"}>Command</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: "8px" }}>
            {liveLogBtn === true ? (
              <Button
                sx={{
                  padding: "4px 12px",
                  border: "1px solid #292D33",
                  borderRadius: "4px",
                  fontSize: "14px",
                  color: "#292D33",
                  fontFamily: "mySecondFont",
                  height: "34px",
                  textTransform: "unset",
                }}
                onClick={handleLiveLogs}
              >
                {logsEnabled ? "Disable live logs" : "Enable live logs"}
                <span
                  style={{ paddingLeft: "4px" }}
                  className="material-symbols-outlined"
                >
                  not_started
                </span>
              </Button>
            ) : (
              ""
            )}
  
            <Button
              sx={{
                padding: "4px 12px",
                border: "1px solid #292D33",
                borderRadius: "4px",
                fontSize: "14px",
                color: "#292D33",
                fontFamily: "mySecondFont",
                height: "34px",
                textTransform: "unset",
              }}
              onClick={handleExport}
            >
              <span
                style={{ paddingRight: "4px" }}
                className="material-symbols-outlined"
              >
                download
              </span>
              Export
            </Button>
          </Box>
        </Box>
  
        {/* Table */}
        {console.log("itslivelogs2", liveLogs)}
        <Box sx={{ flex: 1, mt: "12px", overflowY: "auto" }}>
          <LiveLogTable
            columns={ocpiLogsTableColumnData}
            rows={data}
            logsCount={logsCount}
            liveLogs={liveLogs ? liveLogs : []}
            isRowClickable={true}
            onRowClick={handleRowClick}
            selectedRow={selectedRow}
            specialColumns={["message"]}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0); // Reset to first page on rows per page change
            }}
            modalHeading={modalHeading}
            loading={loading}
          />
        </Box>
      </Box>
    );
}

export default OcpiLogsTableBox
