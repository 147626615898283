import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Heading from "../../utils/Heading";
import Dropbox from "../dropbox/Dropbox";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {
  createOrg,
  createOrgDoc,
  deleteOneOrgDoc,
  getAllOrg,
  getOneOrg,
  updateOrg,
} from "../../redux/action/companyManagement/companyManagementSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-number-input";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// const InputField = ({
//   label,
//   placeholder,
//   value,
//   onChange,
//   name,
//   type = "text",
// }) => (
//   <Box
//     sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
//   >
//     <Typography
//       textAlign="start"
//       fontSize="14px"
//       color="#2f2f2f"
//       fontFamily="myThirdFont"
//       outline="none"
//     >
//       {label}
//     </Typography>
//     {type === "phone" ? (
//       <PhoneInput
//         international
//         defaultCountry="IN"
//         value={value}
//         className={"input-phone-number"}
//         onChange={onChange}
//         placeholder={placeholder}
//         style={{
//           color: "rgba(47, 47, 47, 0.50)",
//           fontFamily: "myThirdFont",
//           fontSize: "16px",
//           fontWeight: 400,
//           lineHeight: "24px",
//           position: "relative",
//           width: "100%",
//           height: "48px",
//           borderRadius: "4px",
//           border: "1px solid #ddd",
//           padding: "0 12px",
//           boxSizing: "border-box",
//           backgroundColor: "#fff",
//         }}
//       />
//     ) : (
//       <input
//         type={type}
//         style={{
//           padding: "12px 16px",
//           borderRadius: "4px",
//           border: "1px solid #cbcbcb",
//           color: "rgba(47, 47, 47, 0.50)",
//           fontFamily: "myThirdFont",
//           fontSize: "16px",
//           height: "48px",
//           outline: "none",
//         }}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         name={name}
//       />
//     )}
//   </Box>
// );

const InputField = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  type = "text",
}) => {
  const handlePhoneKeyDown = (event) => {

    const phoneNumber = value || "";
  
    // If the number of characters is already 15, prevent any more input
    if (phoneNumber.length >= 18 && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <Typography
        textAlign="start"
        fontSize="14px"
        color="#2f2f2f"
        fontFamily="myThirdFont"
        outline="none"
      >
        {label}
      </Typography>
      {type === "phone" ? (
        <PhoneInput
          international
          defaultCountry="IN"
          value={value}
          className={"input-phone-number"}
          onChange={onChange}
          placeholder={placeholder}
          style={{
            color: "rgba(47, 47, 47, 0.50)",
            fontFamily: "myThirdFont",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            position: "relative",
            width: "100%",
            height: "48px",
            borderRadius: "4px",
            border: "1px solid #ddd",
            padding: "0 12px",
            boxSizing: "border-box",
            backgroundColor: "#fff",
          }}
          onKeyDown={handlePhoneKeyDown} // Apply the onKeyDown handler
        />
      ) : (
        <input
          type={type}
          style={{
            padding: "12px 16px",
            borderRadius: "4px",
            border: "1px solid #cbcbcb",
            color: "rgba(47, 47, 47, 0.50)",
            fontFamily: "myThirdFont",
            fontSize: "16px",
            height: "48px",
            outline: "none",
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
        />
      )}
    </Box>
  );
};

const SelectField = ({ label, value, handleChange, menuItems }) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <FormControl fullWidth>
      <InputLabel
        sx={{ fontSize: "14px", fontFamily: "myThirdFont", textAlign: "start" }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        sx={{
          height: "48px",
          fontFamily: "myThirdFont !important",
          textAlign: "start",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const TextareaField = ({ label, placeholder, value, onChange, name }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <textarea
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "100px",
        outline: "none",
        resize: "none",
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
    />
  </Box>
);

const Section = ({ icon, title, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
        <Heading text={title} />
      </Box>
      <Divider sx={{ mt: "8px" }} />
    </Box>
    {children}
  </Box>
);

const AddNewCompanyForm = () => {
  const location = useLocation();
  const { state } = location;
  const oneOrgData = state?.orgData;
  const [files, setFiles] = useState([]);
  const options = useMemo(
    () =>
      countryList()
        .getData()
        .map((option) => ({
          value: option.label, // Set value and label to be the same
          label: option.label,
        })),
    []
  );

  const [formData, setFormData] = useState({
    name: "",
    gstin: "",
    phoneNo: "",
    email: "",
    country: "",
    pinCode: "",
    city: "",
    state: "",
    fullAddress: "",
    accountNumber: "",
    stripe_publishable_key: "",
    stripe_secret_key: "",
    redirectUrl: "",
    ifscCode: "",
    billingCountry: "",
    billingPinCode: "",
    billingCity: "",
    orgType: "",
    billingState: "",
    billingFullAddress: "",
    orgDocs: [],
    companyLogo: null,
  });

  const [orgData, setOrgData] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [docFiles, setDocFiles] = useState([]);
  const [orgDocs, setDocuments] = useState([]);
  const [isSameAsCompanyAddress, setIsSameAsCompanyAddress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (oneOrgData) {
      // Fetch organization details if oneOrgData is provided
      dispatch(getOneOrg(oneOrgData))
        .unwrap()
        .then((data) => {
          // Populate form with fetched data
          setFormData({
            name: data.name || "",
            gstin: data.gstin || "",
            phoneNo: data.phoneNo || "",
            email: data.email || "",
            country: data.country || "",
            pinCode: data.pinCode || "",
            city: data.city || "",
            state: data.state || "",
            fullAddress: data.fullAddress || "",
            accountNumber: data.accountNumber || "",
            stripe_publishable_key: data.stripe_publishable_key || "",
            stripe_secret_key: data.stripe_secret_key || "",
            redirectUrl: data.redirectUrl || "",
            ifscCode: data.ifscCode || "",
            billingCountry: data.billingCountry || "",
            billingPinCode: data.billingPinCode || "",
            billingCity: data.billingCity || "",
            orgType: data.orgType || "",
            billingState: data.billingState || "",
            billingFullAddress: data.billingFullAddress || "",
            orgDocs: data.orgDocs || [],
            companyLogo: data.companyLogo || null,
          });

          // Setting images
          setFiles(data.companyLogo ? [data.companyLogo] : []);

          setDocuments(
            data.orgDocs.map((doc) => ({
              id: doc._id, // Ensure this matches the structure of your document object
              name: doc.name,
              date: new Date(doc.createdAt).toLocaleDateString(),
            }))
          );

          if (data.billingCountry === data.country) {
            setIsSameAsCompanyAddress(true);
          } else {
            setIsSameAsCompanyAddress(false);
          }
        })
        .catch((error) => {
          toast.error("Error fetching organization details");
        });
    }
  }, [oneOrgData, dispatch]);

  const handleAddImage = async () => {
    // Ensure that documentName and docFiles are valid
    if (!documentName || !docFiles || docFiles.length === 0) {
      console.error("Document name or files are missing");
      return; // Prevent adding an empty document
    }

    const uploadData = new FormData();
    uploadData.append("name", documentName);

    if (Array.isArray(docFiles)) {
      docFiles.forEach((file) => {
        uploadData.append("document", file);
      });
    } else {
      console.error("docFiles is not an array:", docFiles);
    }

    try {
      const response = await dispatch(createOrgDoc(uploadData));
      const newDocumentId = response.payload._id; // Adjust according to the actual API response

      // Add new document only if a valid ID is returned
      if (newDocumentId) {
        const newDocument = {
          id: newDocumentId,
          name: documentName,
          date: new Date().toLocaleDateString(),
        };

        setDocuments([...orgDocs, newDocument]);
        setFormData((prev) => ({
          ...prev,
          orgDocs: [...prev.orgDocs, newDocumentId],
        }));

        // Clear the fields after successful addition
        setDocumentName("");
        setDocFiles([]);
      }
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
  });

  const countryMenuItems = [
    { value: "india", label: "India" },
    { value: "australia", label: "Australia" },
    { value: "new-zeland", label: "New Zealand" },
    { value: "south-africa", label: "South Africa" },
    { value: "china", label: "China" },
  ];

  const orgMenuItem = [
    { value: "1c", label: "1C" },
    { value: "cpo", label: "CPO" },
    { value: "ocpi", label: "OCPI" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name) => (event) => {
    setFormData((prev) => ({ ...prev, [name]: event.target.value }));
  };

  const validateFormData = (formData) => {
    // Define required fields
    const requiredFields = [
      "name",
      "phoneNo",
      "email",
      "country",
      "pinCode",
      "city",
      "state",
      "fullAddress",
    ];

    // Check for empty required fields
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].toString().trim() === "") {
        toast.error(
          `Please fill in the ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`
        );
        return false;
      }
    }

    // Validate contactNumber for general phone number format
    const phoneNo = formData.phoneNo.toString().trim();
    // Allow phone numbers with spaces, dashes, or plus sign
    const phoneRegex = /^[+\d\s-]+$/;
    if (!phoneRegex.test(phoneNo)) {
      toast.error("Contact number must be valid");
      return false;
    }

    if (phoneNo.length > 15) {
      toast.error("Phone number must not exceed 15 digits");
      return false;
    }

    // Validate email format using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return false;
    }

    // All validations passed
    return true;
  };

  const handleAddCompany = () => {
    if (!validateFormData(formData)) {
      return;
    }

    const payload = {
      ...formData,
      orgDocs: formData.orgDocs.reduce((acc, docId, index) => {
        acc[index] = docId;
        return acc;
      }, []),
    };
    if (oneOrgData) {
      // Update existing organization
      dispatch(updateOrg({ payload, id: oneOrgData })).then(() => {
        toast.success("Company updated successfully");
        navigate(-1); // Navigate after successful update
      });
    } else {
      // Create new organization
      dispatch(createOrg(payload)).then(() => {
        toast.success("Company created successfully");
        navigate(-1); // Navigate after successful creation
      });
    }
  };

  const handleDeleteDocument = (documentId) => {
    dispatch(deleteOneOrgDoc({ orgDocId: documentId, orgId: oneOrgData }));
    setDocuments((prevDocuments) =>
      prevDocuments.filter((document) => document.id !== documentId)
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      orgDocs: prevFormData.orgDocs.filter((id) => id !== documentId),
    }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsSameAsCompanyAddress(checked);

    if (checked) {
      setFormData((prev) => ({
        ...prev,
        billingCountry: prev.country,
        billingPinCode: prev.pinCode,
        billingCity: prev.city,
        billingState: prev.state,
        billingFullAddress: prev.fullAddress,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        billingCountry: "",
        billingPinCode: "",
        billingCity: "",
        billingState: "",
        billingFullAddress: "",
      }));
    }
  };

  const acceptedFilesType = {
    "image/jpeg": [],
    "image/png": [],
    "image/jpg": [],
  };

  return (
    <Box
      sx={{
        padding: "24px 24px 78px 24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      {/* Basic Details */}
      <Section icon="ev_station" title="Basic Details">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Company Name"
                placeholder="Enter the name here"
                value={formData.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                type="text"
                label="GSTIN"
                placeholder="Enter the GST Number here"
                value={formData.gstin}
                onChange={handleInputChange}
                name="gstin"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <SelectField
                label="Organization type"
                value={formData.orgType}
                handleChange={handleSelectChange("orgType")}
                menuItems={orgMenuItem}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography
              fontFamily={"myThirdFont"}
              color={"#2F2F2F"}
              fontSize={"14px"}
              textAlign={"start"}
            >
              Company logo (optional)
            </Typography>
            <Box sx={{ width: "30%" }}>
              <Dropbox
                acceptedFiles={acceptedFiles}
                // getInputProps={getInputProps}
                // getRootProps={getRootProps}
                // files={files}
                // setFiles={setFiles}
                setFormData={setFormData}
                files={formData.companyLogo}
                acceptedFilesType={acceptedFilesType}
              />
            </Box>
          </Box>
        </Box>
      </Section>

      {/* Contact Details */}
      <Section icon="ev_station" title="Contact Details">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                type="phone"
                label="Contact Number"
                placeholder="Enter the number here"
                value={formData.phoneNo}
                onChange={(value) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    phoneNo: value,
                  }))
                }
                name="phoneNo"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                type="email"
                label="Email"
                placeholder="Enter the number here"
                value={formData.email}
                onChange={handleInputChange}
                name="email"
              />
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/* Address Details */}
      <Section icon="location_on" title="Address Details">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <SelectField
                label="Country"
                value={formData.country}
                handleChange={handleSelectChange("country")}
                // menuItems={countryMenuItems}
                menuItems={options}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Pin code"
                placeholder="Enter the pin code here"
                value={formData.pinCode}
                onChange={handleInputChange}
                name="pinCode"
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="City/Town"
                placeholder="Enter the city/town here"
                value={formData.city}
                onChange={handleInputChange}
                name="city"
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="State"
                placeholder="Enter the state here"
                value={formData.state}
                onChange={handleInputChange}
                name="state"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={6} xs={12}>
              <TextareaField
                label="Full Address"
                placeholder="Enter the full address here"
                value={formData.fullAddress}
                onChange={handleInputChange}
                name="fullAddress"
              />
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/* Payment details */}
      <Section icon="ev_station" title="Payment details">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Bank account number"
                placeholder="Enter the name here"
                value={formData.accountNumber}
                onChange={handleInputChange}
                name="accountNumber"
                type="number"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="IFSC code"
                placeholder="Enter the name here"
                value={formData.ifscCode}
                onChange={handleInputChange}
                name="ifscCode"
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Stripe Publishable Key"
                placeholder="Enter the stripe publishable key"
                value={formData.stripe_publishable_key}
                onChange={handleInputChange}
                name="stripe_publishable_key"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Stripe Secret Key"
                placeholder="Enter the stripe secret key"
                value={formData.stripe_secret_key}
                onChange={handleInputChange}
                name="stripe_secret_key"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Redirect URL"
                placeholder="Enter the redirect URL"
                value={formData.redirectUrl}
                onChange={handleInputChange}
                name="redirectUrl"
              />
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/* Billing address */}
      <Section icon="location_on" title="Billing address">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              {...label}
              checked={isSameAsCompanyAddress}
              onChange={handleCheckboxChange}
            />
            <Typography
              color={"#2F2F2F"}
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
            >
              Same as company address
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Grid container columnSpacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <SelectField
                  label="Country"
                  value={formData.billingCountry}
                  handleChange={handleSelectChange("billingCountry")}
                  menuItems={options}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <InputField
                  label="Pin code"
                  placeholder="Enter the pin code here"
                  value={formData.billingPinCode}
                  onChange={handleInputChange}
                  name="billingPinCode"
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <InputField
                  label="City/Town"
                  placeholder="Enter the city/town here"
                  value={formData.billingCity}
                  onChange={handleInputChange}
                  name="billingCity"
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <InputField
                  label="State"
                  placeholder="Enter the state here"
                  value={formData.billingState}
                  onChange={handleInputChange}
                  name="billingState"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item md={6} xs={12}>
                <TextareaField
                  label="Full Address"
                  placeholder="Enter the full address here"
                  value={formData.billingFullAddress}
                  onChange={handleInputChange}
                  name="billingFullAddress"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Section>

      {/* Documents (optional) */}
      <Section icon="location_on" title="Documents (optional)">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2}>
            <Grid item md={3}>
              <Dropbox
                acceptedFiles={acceptedFiles}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                files={docFiles}
                setFiles={setDocFiles}
              />
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <TextareaField
                  placeholder="Enter Document Name"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    sx={{
                      padding: "4px 16px",
                      border: "1px solid #e50000",
                      borderRadius: "4px",
                      color: "#E50000",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    onClick={handleAddImage}
                  >
                    + ADD
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={7}>
              <Box
                sx={{
                  display: "flex",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  background: "rgba(41, 45, 51, 0.10)",
                }}
              >
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"10%"}
                >
                  S.No
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"20%"}
                >
                  Date
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"start"}
                  width={"40%"}
                >
                  Document Name
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  textAlign={"center"}
                  width={"30%"}
                >
                  Action
                </Typography>
              </Box>
              {orgDocs.map((doc, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      padding: "12px 16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"10%"}
                    >
                      {index + 1}
                    </Typography>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"20%"}
                    >
                      {doc.date}
                    </Typography>
                    <Typography
                      color={"#2F2F2F"}
                      fontFamily={"mySecondFont"}
                      fontSize={"14px"}
                      textAlign={"start"}
                      width={"40%"}
                    >
                      {doc.name}
                    </Typography>
                    <Box
                      sx={{
                        width: "30%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        gap: "4px",
                      }}
                    >
                      <Button
                        onClick={() => handleDeleteDocument(doc.id)}
                        sx={{
                          borderRadius: "4px",
                          border: "1px solid #C0C0C0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px",
                          cursor: "pointer",
                          width: "32px",
                          minWidth: "unset",
                        }}
                      >
                        <span
                          style={{ color: "#790000", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          delete
                        </span>
                      </Button>
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Section>

      <Button
        sx={{
          padding: "12px 24px",
          borderRadius: "4px",
          background: "#e50000",
          color: "#fff",
          fontFamily: "mySecondFont",
          fontSize: "16px",
          ":hover": {
            background: "#e50000",
          },
          maxWidth: "264px",
          width: "100%",
        }}
        onClick={handleAddCompany}
      >
        {/* {orgData ? "Update" : "Add Company"} */}
        {oneOrgData ? "Update" : "Add Company"}
      </Button>
    </Box>
  );
};

export default AddNewCompanyForm;
