import React from "react";
import { Box, Divider, Typography, Grid, Button } from "@mui/material";
import Heading from "../../../utils/Heading";
import GaugeMeter from "../../Graphs/GaugeMeter";
import StatusChip from "../../Chips/StatusChip";

const LoadBalance = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      {/* Heading-Load Balance */}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/loadBalanceIcon.png" alt="" />
            <Heading text={"Load balance"} />
          </Box>
          <StatusChip text={"Live"} chipStatus={"live"} />
        </Box>

        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      {/* Component-Gauge Meter */}
      <Box sx={{}}>
        <GaugeMeter />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box>
          <span
            style={{
              paddingRight: "8px",
              fontSize: "24px",
            }}
            className="material-symbols-outlined"
          >
            bolt
          </span>
        </Box>
        <Typography
          sx={{
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
          }}
        >
          Max Power Supply: 344kW
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadBalance;
