import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Dropbox from "../../../dropbox/Dropbox";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createGuard,
  getAllHubStation,
  getOneGuardDetails,
  updateOneGuard,
} from "../../../../redux/action/hubManagement/hubManagementSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const InputField = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  type = "text",
}) => {
  const handleChange = (e) => {
    let newValue = e.target.value;

    // If type is "tel" and the field is "phone", limit input to 10 digits
    if (type === "tel" && name === "phone") {
      newValue = newValue.replace(/\D/g, ""); // Only allow digits
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10); // Limit to 10 digits
      }
    }

    // If type is "text" and the field is "aadharNo", limit input to 12 digits
    if (name === "aadharNo") {
      newValue = newValue.replace(/\D/g, ""); // Only allow digits
      if (newValue.length > 12) {
        newValue = newValue.slice(0, 12); // Limit to 12 digits
      }
    }

    // If type is "text" and name is "state", filter out invalid characters
    if (type === "text" && name === "state") {
      newValue = newValue.replace(/[^A-Za-z\s]/g, ""); // Only allow letters and spaces
    }

    onChange({ target: { name, value: newValue } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <Typography
        textAlign="start"
        fontSize="14px"
        color="#2f2f2f"
        fontFamily="myThirdFont"
      >
        {label}
      </Typography>
      <input
        type={type}
        name={name}
        value={value || ""}
        onChange={handleChange}
        style={{
          padding: "12px 16px",
          borderRadius: "4px",
          border: "1px solid #cbcbcb",
          color: "rgba(47, 47, 47, 0.50)",
          fontFamily: "myThirdFont",
          fontSize: "16px",
          height: "48px",
          outline: "none",
        }}
        placeholder={placeholder}
      />
    </Box>
  );
};

const SelectField = ({
  label,
  name,
  value,
  handleChange,
  menuItems,
  disabled,
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="#2f2f2f"
      fontFamily="myThirdFont"
    >
      {label}
    </Typography>
    <FormControl fullWidth>
      <InputLabel sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={(e) => handleChange(name, e.target.value)}
        sx={{ height: "48px", fontFamily: "myThirdFont !important" }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item?._id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const Section = ({ icon, title, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
        <Typography variant="h6">{title}</Typography>{" "}
        {/* Use Typography for title */}
      </Box>
      <Divider sx={{ mt: "8px" }} />
    </Box>
    {children}
  </Box>
);

const AddNewGuardFormDetails = () => {
  const [formData, setFormData] = useState({
    stationId: "",
    name: "",
    phone: "",
    email: "",
    aadharNo: "",
    address: "",
    language: "",
    profilePic: null,
    aadharFront: null,
    aadharBack: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const oneGuardData = state?.guardData;

  console.log(oneGuardData);
  const dispatch = useDispatch();

  const { hubStations, loading, error } = useSelector(
    (state) => state.hubManagement
  );

  const langData = [
    {
      name: "English",
      _id: "en",
    },
    {
      name: "Hindi",
      _id: "hi",
    },
  ];

  React.useEffect(() => {
    dispatch(getAllHubStation());
  }, [dispatch]);

  const acceptedFilesType = {
    "image/jpeg": [],
    "image/png": [],
    "image/jpg": [],
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDrop = (file, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: file[0], // Store the first file
    }));
  };

  useEffect(() => {
    if (oneGuardData) {
      // Fetch guard details if oneGuardData is provided
      dispatch(getOneGuardDetails(oneGuardData))
        .unwrap()
        .then((data) => {
          console.log("Fetched guard data:", data);
          // Populate form with fetched data
          setFormData({
            stationId: data?.stationId?._id,
            name: data.name,
            phone: data.phone,
            email: data.email,
            language: data.language,
            aadharNo: data.aadharNo,
            address: data.address,
            profilePic: data?.profile_pic,
            aadharFront: data.aadharFront,
            aadharBack: data.aadharBack,
          });
        })
        .catch((error) => {
          console.error("Error fetching guard details:", error);
          toast.error("Failed to fetch guard details.");
        });
    }
  }, [dispatch, oneGuardData]);

  const handleSubmit = async () => {
    console.log(formData);
    try {
      const phoneWithCode = formData.phone.startsWith("+91")
        ? formData.phone
        : `+91${formData.phone}`;
      if (oneGuardData) {
        await dispatch(
          updateOneGuard({
            guardId: oneGuardData,
            formData: { ...formData, phone: phoneWithCode },
          })
        ).unwrap();
        toast.success("Guard updated successfully");
      } else {
        await dispatch(
          createGuard({ ...formData, phone: phoneWithCode })
        ).unwrap();
        toast.success("Guard added successfully");
      }
      navigate(-1); // Navigate back after success
    } catch (error) {
      if (Array.isArray(error.message)) {
        error.message.forEach((msg) => toast.error(msg));
      } else {
        toast.error("An error occurred");
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "24px 24px 36px 24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      {/* Basic Details */}
      <Section icon="ev_station" title="Basic Details">
        <Grid container columnSpacing={2} rowSpacing={6}>
          <Grid item md={4} sm={6} xs={12}>
            <SelectField
              label="Stations"
              name="stationId"
              value={formData.stationId}
              handleChange={handleSelectChange}
              menuItems={hubStations}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Guard Name"
              placeholder="Enter guard name here"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Guard Mobile Number"
              placeholder="Enter guard mobile number here"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              type="tel"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Guard Email Id"
              placeholder="Enter guard email here"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              type="email"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Guard Aadhar No."
              placeholder="Enter guard Aadhar number here"
              name="aadharNo"
              value={formData.aadharNo}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputField
              label="Guard Address"
              placeholder="Enter guard address here"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <SelectField
              label="Language"
              name="language"
              value={formData.language}
              handleChange={handleSelectChange}
              menuItems={langData}
            />
          </Grid>
        </Grid>
      </Section>

      {/* Upload Documents */}
      <Section icon="cloud_upload" title="Upload Documents">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              textAlign="start"
              fontSize="14px"
              color="#2f2f2f"
              fontFamily="myThirdFont"
            >
              Guard Image
            </Typography>
            <Dropbox
              acceptedFiles={acceptedFilesType}
              setFormData={setFormData}
              files={formData.profilePic}
              acceptedFilesType={acceptedFilesType}
              fieldName="profilePic"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              textAlign="start"
              fontSize="14px"
              color="#2f2f2f"
              fontFamily="myThirdFont"
            >
              Aadhar Front
            </Typography>
            <Dropbox
              acceptedFiles={acceptedFilesType}
              setFormData={setFormData}
              files={formData.aadharFront}
              acceptedFilesType={acceptedFilesType}
              fieldName="aadharFront"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              textAlign="start"
              fontSize="14px"
              color="#2f2f2f"
              fontFamily="myThirdFont"
            >
              Aadhar Back
            </Typography>
            <Dropbox
              acceptedFiles={acceptedFilesType}
              setFormData={setFormData}
              files={formData.aadharBack}
              acceptedFilesType={acceptedFilesType}
              fieldName="aadharBack"
            />
          </Grid>
        </Grid>
      </Section>

      {/* Add Station Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={handleSubmit}
          sx={{
            padding: "12px 24px",
            background: "#E50000",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontSize: "16px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "700",
            letterSpacing: "1.28px",
            ":hover": {
              background: "#E50000",
            },
          }}
        >
          {oneGuardData ? "Update" : "+ADD GUARD"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddNewGuardFormDetails;
