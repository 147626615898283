import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageModal = ({ open, handleClose, imageSrc }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogContent
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{ position: "absolute", right: 16, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <img
        src={imageSrc}
        alt="Enlarged Guard"
        style={{
          width: "100%",
          maxHeight: "80vh",
          objectFit: "contain",
          padding: "8px",
        }}
      />
    </DialogContent>
  </Dialog>
);

export default ImageModal;
