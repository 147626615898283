import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Initial state for OCPI networks
const ocpiInitialState = {
  networks: [],
  loading: false,
  error: null,
  networkDetail: null,

  //versions before token A
  versions: [],
  handshakeStatus: null,
  tokenA: null,

  //Versions after token A
  versionsAfterTokenA: [],
  tokenB: null, // Added state for Token B

  //manage
  locations: [],
  evseData: [],

  chargersToBeAdded: null,
  assignedEvses: [],
  allTariffs: [],
  allThirdPartyChargers: [],
  oneNetworksAllEvsesChargers: null,
  oneNetworkAllTariffs: [],
  allLocationsData: [],
  oneNetworkAllLocations: [],

  ocpiHandshakeLogs: [],

  allOcpiTariffs: [],
  defaultTariffs: [],
};

// get all OCPI networks
export const getAllOcpiNetworks = createAsyncThunk(
  "ocpi/getAllOcpiNetworks",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllOcpiNetworks, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust this based on the actual structure of your response
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all OCPI Locations
export const getAllOcpiLocations = createAsyncThunk(
  "ocpi/getAllOcpiLocations",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllOcpiLocations, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust this based on the actual structure of your response
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get one network all locations by ID
export const getOneNetworkAllLocations = createAsyncThunk(
  "ocpi/getOneNetworkAllLocations",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneNetworkAllLocations}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Get one OCPI network detail by ID
export const getOneOcpiNetworkDetail = createAsyncThunk(
  "ocpi/getOneOcpiNetworkDetail",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneOcpiNetworkDetail}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Get OCPI versions list
export const getOcpiVersionsList = createAsyncThunk(
  "ocpi/getOcpiVersionsList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getOcpiVersionsList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("versionssss", response.data.data);
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.data);
    }
  }
);

// Get Handshake Status
export const getHandshakeStatus = createAsyncThunk(
  "ocpi/getHandshakeStatus",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getHandshakeStatus}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Post OCPI Token A
export const getOcpiTokenA = createAsyncThunk(
  "ocpi/getOcpiTokenA",
  async ({ operatorId, roles, version }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getOcpiTokenA}/${operatorId}`,
        { roles, version },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your response structure
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data.data : error.message
      );
    }
  }
);

// Post OCPI Versions List from Token A
export const getOcpiVersionFromTokenA = createAsyncThunk(
  "ocpi/getOcpiVersionFromTokenA",
  async ({ operatorId, url, tokenA }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getOcpiVersionsFromTokenA}/${operatorId}`,
        { url, tokenA },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data.data : error.message
      );
    }
  }
);

// New thunk for making a GET request to get the versions using the user-entered OCPI URL and Token A
export const fetchOcpiVersionForTokenA = createAsyncThunk(
  "ocpi/fetchOcpiVersionForTokenA",
  async ({ ocpiUrl, tokenA }, { rejectWithValue }) => {
    try {
      const response = await axios.get(ocpiUrl, {
        headers: {
          Authorization: `token ${tokenA}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Get OCPI Token B
export const getOcpiTokenB = createAsyncThunk(
  "ocpi/getOcpiTokenB",
  async ({ operatorId, roles, version, tokenA, url }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getOcpiTokenB}/${operatorId}`, // Adjust endpoint as needed
        { tokenA, roles, version, url },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your response structure
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data.data : error.message
      );
    }
  }
);

// New thunk for getting the stations list
export const getAllOcpiLocationsList = createAsyncThunk(
  "ocpi/getAllOcpiLocationsList",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAllOcpiLocationsList}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this based on the actual structure of your response
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Get EVSE of one location by ID
export const getEvseOfOneLocation = createAsyncThunk(
  "ocpi/getEvseOfOneLocation",
  async ({ locationId, operatorId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getEvseOfOneLocation}/${locationId}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this based on the actual structure of your response
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// New thunk for adding/updating OCPI chargers
export const getOcpiAddAndUpdateOcpiChargers = createAsyncThunk(
  "ocpi/getOcpiAddAndUpdateOcpiChargers",
  async ({ operatorId, evseIds, stationId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getOcpiAddAndUpdateOcpiChargers}/${stationId}/${operatorId}`,
        { evseIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your response structure
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAssignedOcpiEvses = createAsyncThunk(
  "ocpi/getAssignedOcpiEvses",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAssignedOcpiEvses}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your response structure
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// get all OCPI 3rd Party Tariffs
export const getAllOcpiTariffs = createAsyncThunk(
  "ocpi/getAllOcpiTariffs",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllOcpiTariffs, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust this based on the actual structure of your response
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all OCPI 3rd Party Chargers
export const getAllThirdPartyChargers = createAsyncThunk(
  "ocpi/getAllThirdPartyChargers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllThirdPartyChargers, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust this based on the actual structure of your response
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gt all evses of single network
export const getOneNetworksAllEvsesChargers = createAsyncThunk(
  "ocpi/getOneNetworksAllEvsesChargers",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneNetworksAllEvsesChargers}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

//Gt all Tariffs of single network
export const getOneNetworkAllTariffs = createAsyncThunk(
  "ocpi/getOneNetworkAllTariffs",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneNetworkAllTariffs}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Get one network all locations by ID
export const getOneNetworkHandshakeLogs = createAsyncThunk(
  "ocpi/getOneNetworkHandshakeLogs",
  async ({ operatorId, limit, offset }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneNetworkHandshakeLogs}/${operatorId}?limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create OCPI Tariff
export const createOcpiTariff = createAsyncThunk(
  "ocpi/createOcpiTariff",
  async ({ formData, operatorId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.createOcpiTariff}/${operatorId}`,
        formData, // Send formData as the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust according to the actual response structure
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getDefaultTariffList = createAsyncThunk(
  "ocpi/getDefaultTariffList",
  async (operatorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getDefaultTariffList}/${operatorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const ocpiSlice = createSlice({
  name: "ocpi",
  initialState: ocpiInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllOcpiNetworks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOcpiNetworks.fulfilled, (state, action) => {
        console.log("API3333", action.payload); // Log the API data
        state.loading = false;
        state.networks = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getAllOcpiNetworks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getAllOcpiLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOcpiLocations.fulfilled, (state, action) => {
        console.log("API3333", action.payload); // Log the API data
        state.loading = false;
        state.allLocationsData = Array.isArray(action.payload)
          ? action.payload
          : [];
      })
      .addCase(getAllOcpiLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getAllOcpiTariffs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOcpiTariffs.fulfilled, (state, action) => {
        console.log("API3333", action.payload); // Log the API data
        state.loading = false;
        state.allTariffs = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getAllOcpiTariffs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getAllThirdPartyChargers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllThirdPartyChargers.fulfilled, (state, action) => {
        console.log("API3333", action.payload); // Log the API data
        state.loading = false;
        state.allThirdPartyChargers = Array.isArray(action.payload)
          ? action.payload
          : [];
      })
      .addCase(getAllThirdPartyChargers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOneOcpiNetworkDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneOcpiNetworkDetail.fulfilled, (state, action) => {
        console.log("Network Detail API Data:", action.payload); // Log the API data for a single network
        state.loading = false;
        state.networkDetail = action.payload; // Store the network detail
      })
      .addCase(getOneOcpiNetworkDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOneNetworksAllEvsesChargers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneNetworksAllEvsesChargers.fulfilled, (state, action) => {
        console.log("Network Detail API Data:", action.payload); // Log the API data for a single network
        state.loading = false;
        state.oneNetworksAllEvsesChargers = action.payload; // Store the network detail
      })
      .addCase(getOneNetworksAllEvsesChargers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOcpiVersionsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOcpiVersionsList.fulfilled, (state, action) => {
        console.log("OCPI Versions List API Data:", action.payload); // Log the versions data
        state.loading = false;
        console.log("bbbbbbbbb", action.payload);
        state.versions = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getOcpiVersionsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getHandshakeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHandshakeStatus.fulfilled, (state, action) => {
        console.log("Handshake Status API Data:", action.payload);
        state.loading = false;
        // state.tokenA = action.payload;
        state.handshakeStatus = action.payload;
      })
      .addCase(getHandshakeStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOcpiTokenA.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOcpiTokenA.fulfilled, (state, action) => {
        state.loading = false;
        // state.tokenA = action.payload;
        state.handshakeStatus = action.payload;
      })
      .addCase(getOcpiTokenA.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOcpiVersionForTokenA.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOcpiVersionForTokenA.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Fetched OCPI Data:", action.payload); // Log the fetched data
        state.versionsAfterTokenA = action.payload;
      })
      .addCase(fetchOcpiVersionForTokenA.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      })
      .addCase(getOcpiTokenB.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOcpiTokenB.fulfilled, (state, action) => {
        state.loading = false;
        state.tokenB = action.payload; // Update state with the token B
      })
      .addCase(getOcpiTokenB.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      })
      // New cases for getting the stations list
      .addCase(getAllOcpiLocationsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOcpiLocationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.locations = Array.isArray(action.payload) ? action.payload : []; // You may need to add this property to your initial state
      })
      .addCase(getAllOcpiLocationsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEvseOfOneLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEvseOfOneLocation.fulfilled, (state, action) => {
        console.log("EVSE Data API Data:", action.payload);
        state.loading = false;
        state.evseData = action.payload; // Store EVSE data in state
      })
      .addCase(getEvseOfOneLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOcpiAddAndUpdateOcpiChargers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOcpiAddAndUpdateOcpiChargers.fulfilled, (state, action) => {
        state.loading = false;
        state.chargersToBeAdded = action.payload; // Store the response if needed
      })
      .addCase(getOcpiAddAndUpdateOcpiChargers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      })

      .addCase(getAssignedOcpiEvses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssignedOcpiEvses.fulfilled, (state, action) => {
        state.loading = false;
        state.assignedEvses = action.payload;
      })
      .addCase(getAssignedOcpiEvses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      })

      .addCase(getOneNetworkAllTariffs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneNetworkAllTariffs.fulfilled, (state, action) => {
        console.log("Network Detail API Data:", action.payload); // Log the API data for a single network
        state.loading = false;
        state.oneNetworkAllTariffs = action.payload; // Store the network detail
      })
      .addCase(getOneNetworkAllTariffs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOneNetworkAllLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneNetworkAllLocations.fulfilled, (state, action) => {
        console.log("Network Detail API Data:", action.payload); // Log the API data for a single network
        state.loading = false;

        state.oneNetworkAllLocations = Array.isArray(action.payload)
          ? action.payload
          : [];
      })
      .addCase(getOneNetworkAllLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getOneNetworkHandshakeLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneNetworkHandshakeLogs.fulfilled, (state, action) => {
        console.log("Network Detail API Data:", action.payload);
        state.loading = false;
        state.ocpiHandshakeLogs = action.payload;
      })
      .addCase(getOneNetworkHandshakeLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createOcpiTariff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOcpiTariff.fulfilled, (state, action) => {
        state.loading = false;
        state.allOcpiTariffs.push(action.payload);
      })
      .addCase(createOcpiTariff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getDefaultTariffList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDefaultTariffList.fulfilled, (state, action) => {
        state.loading = false;
        state.defaultTariffs = action.payload;
      })
      .addCase(getDefaultTariffList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ocpiSlice.reducer;
