import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../custom/CustomTable";
import TableFilters from "../../charging-sessions/TableFilters";
import ChargingPassesFormModal from "../../Modals/HubManagement/ChargingPassesFormModal";
import HubTableFilter from "../../Filters/HubTableFilter";
import HubTimeFilter from "../../Filters/HubTimeFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import { TimeProvider } from "../../../Hooks/TimeContext";

const ChargingPasses = ({ columns, row }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [searchQuery, setSearchQuery] = useState("");

  // Filter rows based on search query
  const filteredRows = row.filter((item) => {
    return Object.keys(item).some((key) =>
      String(item[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <>
      <StationProvider>
        <TimeProvider>
          <Box
            sx={{
              padding: "24px",
              borderRadius: "8px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "36px" }}>
                <HubTableFilter
                  tabTitle={"ChargingPass"}
                  setSearchQuery={setSearchQuery}
                />
                <HubTimeFilter tabTitle={"ChargingPass"} />
              </Box>
              <Button
                sx={{
                  background: "#222",
                  padding: "8px 24px",
                  width: "120px",
                  borderRadius: "4px",
                  color: "#FCFCFC",
                  fontFamily: "mySecondFont",
                  letterSpacing: "1.12px",
                  textWrap: "nowrap",
                  ":hover": {
                    background: "#222",
                  },
                }}
                onClick={handleOpen}
              >
                + ADD NEW
              </Button>
            </Box>

            {/* Table */}
            <CustomTable
              columns={columns}
              rows={filteredRows}
              modalHeading={"Hub Charging Passes"}
              isRowClickable={true}
              actionType={"edit-delete"}
            />
          </Box>
        </TimeProvider>
      </StationProvider>

      {/* Charging Passes Form Modal */}
      <ChargingPassesFormModal open={open} handleClose={handleClose} />
    </>
  );
};

export default ChargingPasses;
