import { Box } from "@mui/material";
import React from "react";
import DoughnutChart from "../DoughnutChart";
import { appStatsData } from "../../../utils/DummyData";
import DoughnutCharHub from "./DoughnutCharHub";

const VisitUserGraph = ({
  data,
  previousDashboardData,
  selectedTime,
  startTime,
  endTime,
}) => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <DoughnutCharHub data={data} centerData="" title="Total User Visit" />
    </Box>
  );
};

export default VisitUserGraph;
