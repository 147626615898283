import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableBox from "./TableBox";
import {
  financePaymentHistoryColumns,
  financeStationBalanceColumns,
  passbookColumns,
} from "../../../utils/DummyData";
import PaymentHistoryTable from "./PaymentHistoryTable";
import CustomTabs from "../../custom/CustomTabs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDailySheetPassbook,
  fetchOneStationPaymentHistory,
  fetchWalletHistory,
} from "../../../redux/action/financeManager/financeManagerSlice";
import DailySheetTable from "./DailySheetTable";

const TopBar = ({ selectedStationName }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const dispatch = useDispatch();
  const {
    walletHistory,
    stationPaymentHistory,
    stations,
    dailySheetPassbook,
    status,
    error,
  } = useSelector((state) => state.financeStations);

  useEffect(() => {
    dispatch(fetchWalletHistory());
  }, [dispatch]);

  // // Transform the dailySheetPassbook data into table-compatible rows
  // const transformPassbookData = (data) => {
  //   return Object.keys(data).map((date, index) => ({
  //     sr: index + 1, // S.NO
  //     paymentType: data[date].creditAmount || 0, // Total Amount Credited
  //     amount_added: data[date].debitAmount || 0, // Total Amount Used
  //     date: date, // Date (from the key of the object)
  //   }));
  // };

  // Fetch payment history and daily sheet passbook based on selected station's mobile number
  useEffect(() => {
    if (selectedStationName) {
      // Find the selected station and fetch its payment history
      const selectedStation = stations.find(
        (station) => station.name === selectedStationName
      );

      if (selectedStation && selectedStation?.contactNumber) {
        const contactNumber = selectedStation?.contactNumber;

        // Dispatch to fetch payment history
        dispatch(fetchOneStationPaymentHistory(contactNumber));

        // Dispatch to fetch daily sheet passbook
        dispatch(fetchAllDailySheetPassbook(contactNumber));
      }
    }
  }, [dispatch, selectedStationName, stations]);

  const tabsData = [
    {
      label: "Payment History",
      content: (
        <TableBox
          columns={financePaymentHistoryColumns}
          selectedStationName={selectedStationName}
          walletHistory={walletHistory}
        />
      ),
    },
    {
      label: "Hub Activity",
      content: (
        <PaymentHistoryTable
          columns={financeStationBalanceColumns}
          row={stationPaymentHistory}
          selectedStationName={selectedStationName}
        />
      ),
    },
    {
      label: "Daily Sheet",
      content: (
        <DailySheetTable
          columns={passbookColumns}
          row={dailySheetPassbook}
          selectedStationName={selectedStationName}
        />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        borderRadius: "8px",
        width: "100%",
        height: "calc(100vh - 140px)",
        overflowY: "auto",
      }}
    >
      {/* Custom Tabs */}
      <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />

      {/* Tab content displayed below the tabs */}
      <Box sx={{ mt: 2 }}>{tabsData[selectedTab].content}</Box>
    </Box>
  );
};

export default TopBar;
