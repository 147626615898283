import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import Heading from "../../../../utils/Heading";
import ImageModal from "../../../Modals/HubManagement/ImageModal";

const GaurdDocBox = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleOpenModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedImage("");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          gallery_thumbnail
        </span>
        <Heading text={"Guard Documents"} />
      </Box>
      <Divider sx={{ mt: "8px" }} />

      <Box
        sx={{ mt: "16px", display: "flex", alignItems: "center", gap: "24px" }}
      >
        <img
          onClick={() => handleOpenModal(data?.profile_pic?.url)}
          style={{ width: "100px", objectFit: "cover", cursor: "pointer" }}
          src={data?.profile_pic?.url}
          alt="Profile"
        />
        <img
          onClick={() => handleOpenModal(data?.aadharFront?.url)}
          style={{ width: "100px", objectFit: "cover", cursor: "pointer" }}
          src={data?.aadharFront?.url}
          alt="Aadhar Front"
        />
        <img
          onClick={() => handleOpenModal(data?.aadharBack?.url)}
          style={{ width: "100px", objectFit: "cover", cursor: "pointer" }}
          src={data?.aadharBack?.url}
          alt="Aadhar Back"
        />
      </Box>

      <ImageModal
        open={modalOpen}
        handleClose={handleCloseModal}
        imageSrc={selectedImage}
      />
    </Box>
  );
};

export default GaurdDocBox;
