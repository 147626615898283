import {
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Heading from "../../utils/Heading";
import CustomTransition from "../custom/CustomTransition";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={value === "Acitve" ? "#027900" : "#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>

      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const PaymentModal = ({ open, onClose, row }) => {
  useEffect(() => {
    console.log(row);
  }, [row]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      TransitionComponent={CustomTransition}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={onClose}
              className="material-symbols-outlined"
            >
              currency_rupee_circle
            </span>
            <Heading text={row?.paymentId} />
          </Box>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>
        <Divider sx={{ mt: "8px", mb: "48px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <InfoBox label={"Transaction ID (OCPP)"} value={row?.paymentId} />
          <InfoBox label={"Bill ID"} value={row?.paymentId} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography
              color={"#2f2f2f"}
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
            >
              Invoice
            </Typography>
            <Button
              sx={{
                padding: "4px 12px",
                borderRadius: "4px",
                border: "1px solid #c0c0c0",
                fontSize: "14px",
                fontFamily: "mySecondFont",
                color: "#e50000",
                maxWidth: "100px",
                width: "100%",
              }}
            >
              Invoice
            </Button>
          </Box>
          <InfoBox label={"Charger point"} value={row?.paymentId} />
          <InfoBox label={"Charging station"} value={row?.paymentId} />
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentModal;
