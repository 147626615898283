// import React, { useEffect } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { Box, Button } from "@mui/material";
// import FilterListIcon from "@mui/icons-material/FilterList";

// const TimeFilter = ({ setDurationType, setStartTime, setEndTime }) => {
//   const [selectedValue, setSelectedValue] = React.useState("today");
//   const [startDate, setStartDate] = React.useState(null);
//   const [endDate, setEndDate] = React.useState(null);
//   const [startTime, setStartTimeState] = React.useState(null);
//   const [endTime, setEndTimeState] = React.useState(null);

//   useEffect(() => {
//     // Initialize default values for today
//     const now = new Date();
//     const defaultStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
//     const defaultEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

//     setStartTime(defaultStartTime);
//     setEndTime(defaultEndTime);
//     setDurationType("today");
//   }, [setStartTime, setEndTime, setDurationType]);

//   // Handle Change for dropdown
//   const handleChange = (event) => {
//     const value = event.target.value;
//     setSelectedValue(value);
//     setDurationType(value);

//     const now = new Date();
//     let newStartTime, newEndTime;

//     if (value === "today") {
//       newStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
//       newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
//     } else if (value === "yesterday") {
//       const yesterday = new Date(now);
//       yesterday.setDate(now.getDate() - 1);
//       newStartTime = new Date(yesterday.setHours(0, 0, 0, 0)).valueOf();
//       newEndTime = new Date(yesterday.setHours(23, 59, 59, 999)).valueOf();
//     } else if (value === "week") {
//       const startOfWeek = new Date(now);
//       startOfWeek.setDate(now.getDate() - 6); // 7 days before today
//       newStartTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
//       newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
//     } else if (value === "month") {
//       const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
//       const endOfMonth = new Date(
//         now.getFullYear(),
//         now.getMonth() + 1,
//         0,
//         23,
//         59,
//         59,
//         999
//       );
//       newStartTime = startOfMonth.valueOf();
//       newEndTime = endOfMonth.valueOf();
//     } else if (value === "year") {
//       const startOfYear = new Date(now.getFullYear(), 0, 1);
//       const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
//       newStartTime = startOfYear.valueOf();
//       newEndTime = endOfYear.valueOf();
//     } else if (value === "customRange") {
//       newStartTime = startDate ? startDate.valueOf() : null;
//       if (endDate) {
//         const endOfDay = new Date(endDate);
//         endOfDay.setHours(23, 59, 59, 999);
//         newEndTime = endOfDay.valueOf();
//       } else {
//         newEndTime = null;
//       }
//     }

//     if (newStartTime !== undefined && newEndTime !== undefined) {
//       setStartTime(newStartTime);
//       setEndTime(newEndTime);
//     }
//   };

//   // Handle apply for custom range or specific time selection after selecting dates/times
//   const handleApply = () => {
//     if (selectedValue === "customRange") {
//       const customStartTime = startDate ? startDate.valueOf() : null;
//       let customEndTime = null;

//       if (endDate) {
//         const endOfDay = new Date(endDate);
//         endOfDay.setHours(23, 59, 59, 999);
//         customEndTime = endOfDay.valueOf();
//       }

//       setStartTime(customStartTime);
//       setEndTime(customEndTime);
//     } else if (selectedValue === "today") {
//       const selectedStartTime = startTime ? startTime.valueOf() : null;
//       const selectedEndTime = endTime ? endTime.valueOf() : null;

//       setStartTime(selectedStartTime);
//       setEndTime(selectedEndTime);
//     }
//   };

//   const isApplyButtonEnabled =
//     selectedValue === "today" || selectedValue === "customRange";
//   const isToday = selectedValue === "today";

//   return (
//     <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
//       <Box>
//         <FormControl>
//           <Select
//             value={selectedValue}
//             onChange={handleChange}
//             displayEmpty
//             IconComponent={FilterListIcon}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: "120px",
//               height: "48px",
//               color: "#1C1C1C",
//               fontFamily: "mySecondFont",
//               textAlign: "left",
//             }}
//           >
//             <MenuItem value="today">Today</MenuItem>
//             <MenuItem value="yesterday">Yesterday</MenuItem>
//             <MenuItem value="week">Week</MenuItem>
//             <MenuItem value="month">Month</MenuItem>
//             <MenuItem value="year">Year</MenuItem>
//             <MenuItem value="customRange">Custom Range</MenuItem>
//           </Select>
//         </FormControl>
//       </Box>

//       {(selectedValue === "customRange" ||
//         selectedValue === "today") && (
//         <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
//           {isToday && (
//             <>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <TimePicker
//                     label="Start Time"
//                     value={startTime}
//                     onChange={(newValue) => setStartTimeState(newValue)}
//                   />
//                 </LocalizationProvider>
//               </Box>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <TimePicker
//                     label="End Time"
//                     value={endTime}
//                     onChange={(newValue) => setEndTimeState(newValue)}
//                   />
//                 </LocalizationProvider>
//               </Box>
//             </>
//           )}
//           {selectedValue === "customRange" && (
//             <>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DatePicker
//                     label="Start Date"
//                     value={startDate}
//                     onChange={(newValue) => setStartDate(newValue)}
//                   />
//                 </LocalizationProvider>
//               </Box>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DatePicker
//                     label="End Date"
//                     value={endDate}
//                     onChange={(newValue) => setEndDate(newValue)}
//                   />
//                 </LocalizationProvider>
//               </Box>
//             </>
//           )}
//         </Box>
//       )}

//       <Button
//         sx={{
//           background: "#222",
//           padding: "8px 24px",
//           width: "120px",
//           borderRadius: "4px",
//           color: "#FCFCFC",
//           fontFamily: "mySecondFont",
//           letterSpacing: "1.12px",
//           ":hover": {
//             background: "#222",
//           },
//           "&.Mui-disabled": {
//             backgroundColor: "#555",
//             color: "#999",
//             cursor: "not-allowed",
//             opacity: 0.6,
//           },
//         }}
//         disabled={!isApplyButtonEnabled}
//         onClick={handleApply}
//       >
//         APPLY
//       </Button>
//     </Box>
//   );
// };

// export default TimeFilter;

//Time filter before RESET
// import React, { useEffect } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { Box, Button } from "@mui/material";
// import FilterListIcon from "@mui/icons-material/FilterList";

// const TimeFilter = ({
//   setDurationType,
//   setStartTime,
//   setEndTime,
//   setPrevStartTime,
//   setPrevEndTime,
// }) => {
//   const [selectedValue, setSelectedValue] = React.useState("today");
//   const [startDate, setStartDate] = React.useState(null);
//   const [endDate, setEndDate] = React.useState(null);
//   const [startTime, setStartTimeState] = React.useState(null);
//   const [endTime, setEndTimeState] = React.useState(null);

//   useEffect(() => {
//     const now = new Date();
//     const defaultStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
//     const defaultEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

//     setStartTime(defaultStartTime);
//     setEndTime(defaultEndTime);
//     setDurationType("today");

//     setPrevStartTime(defaultStartTime - 24 * 60 * 60 * 1000);
//     setPrevEndTime(defaultEndTime - 24 * 60 * 60 * 1000);
//   }, [
//     setStartTime,
//     setEndTime,
//     setDurationType,
//     setPrevStartTime,
//     setPrevEndTime,
//   ]);

//   const computePreviousTimes = (value) => {
//     const now = new Date();
//     let prevStart, prevEnd;

//     switch (value) {
//       case "today":
//         prevStart = new Date(now.setDate(now.getDate() - 1)).setHours(
//           0,
//           0,
//           0,
//           0
//         );
//         prevEnd = new Date(now.setDate(now.getDate())).setHours(
//           23,
//           59,
//           59,
//           999
//         );
//         break;
//       case "yesterday":
//         prevStart = new Date(now.setDate(now.getDate() - 2)).setHours(
//           0,
//           0,
//           0,
//           0
//         );
//         prevEnd = new Date(now.setDate(now.getDate() - 0)).setHours(
//           23,
//           59,
//           59,
//           999
//         );
//         break;
//       case "week":
//         const previousStartDate = new Date(now);
//         previousStartDate.setDate(now.getDate() - 13);
//         const prevStartTime = new Date(
//           previousStartDate.setHours(0, 0, 0, 0)
//         ).valueOf();
//         const previousEndDate = new Date(now);
//         previousEndDate.setDate(now.getDate() - 7);
//         const prevEndTime = new Date(
//           previousEndDate.setHours(23, 59, 59, 999)
//         ).valueOf();
//         prevStart = prevStartTime;
//         prevEnd = prevEndTime;
//         break;
//       case "month":
//         // Get the start and end of the previous month
//         const startOfPreviousMonth = new Date(
//           now.getFullYear(),
//           now.getMonth() - 1,
//           1
//         );
//         const endOfPreviousMonth = new Date(
//           now.getFullYear(),
//           now.getMonth(),
//           0,
//           23,
//           59,
//           59,
//           999
//         );
//         prevStart = startOfPreviousMonth.valueOf();
//         prevEnd = endOfPreviousMonth.valueOf();
//         break;
//         case "year":
//           // Get the start and end of the previous year
//           const startOfPreviousYear = new Date(now.getFullYear() - 1, 0, 1);
//           const endOfPreviousYear = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999);
//           prevStart = startOfPreviousYear.valueOf();
//           prevEnd = endOfPreviousYear.valueOf();
//           break;

//           //  case "customRange":
//           // // Ensure startDate and endDate are valid Date objects
//           // if (startDate && endDate) {
//           //   const startDateMs = new Date(startDate).valueOf();
//           //   const endDateMs = new Date(endDate).valueOf();
//           //   const duration = endDateMs - startDateMs;
//           //   prevStart = startDateMs - duration;
//           //   prevEnd = endDateMs - duration;
//           // } else {
//           //   prevStart = null;
//           //   prevEnd = null;
//           // }
//           // break;
//       default:
//         prevStart = null;
//         prevEnd = null;
//     }

//     return { prevStartTime: prevStart, prevEndTime: prevEnd };
//   };

//   const handleChange = (event) => {
//     const value = event.target.value;
//     setSelectedValue(value);
//     setDurationType(value);

//     const now = new Date();
//     let newStartTime, newEndTime;

//     if (value === "today") {
//       newStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
//       newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
//     } else if (value === "yesterday") {
//       const yesterday = new Date(now);
//       yesterday.setDate(now.getDate() - 1);
//       newStartTime = new Date(yesterday.setHours(0, 0, 0, 0)).valueOf();
//       newEndTime = new Date(yesterday.setHours(23, 59, 59, 999)).valueOf();
//     } else if (value === "week") {
//       const startOfWeek = new Date(now);
//       startOfWeek.setDate(now.getDate() - 6);
//       newStartTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
//       newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
//     } else if (value === "month") {
//       const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
//       const endOfMonth = new Date(
//         now.getFullYear(),
//         now.getMonth() + 1,
//         0,
//         23,
//         59,
//         59,
//         999
//       );
//       newStartTime = startOfMonth.valueOf();
//       newEndTime = endOfMonth.valueOf();
//     } else if (value === "year") {
//       const startOfYear = new Date(now.getFullYear(), 0, 1);
//       const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
//       newStartTime = startOfYear.valueOf();
//       newEndTime = endOfYear.valueOf();
//     } else if (value === "customRange") {
//       newStartTime = startDate ? new Date(startDate).valueOf() : null;
//       if (endDate) {
//         const endOfDay = new Date(endDate);
//         endOfDay.setHours(23, 59, 59, 999);
//         newEndTime = endOfDay.valueOf();
//       } else {
//         newEndTime = null;
//       }
//     }

//     if (newStartTime !== undefined && newEndTime !== undefined) {
//       setStartTime(newStartTime);
//       setEndTime(newEndTime);

//       const { prevStartTime, prevEndTime } = computePreviousTimes(value);

//       setPrevStartTime(prevStartTime);
//       setPrevEndTime(prevEndTime);
//     }
//   };

//   const handleApply = () => {
//     if (selectedValue === "customRange") {
//       const customStartTime = startDate ? startDate.valueOf() : null;
//       let customEndTime = null;

//       if (endDate) {
//         const endOfDay = new Date(endDate);
//         endOfDay.setHours(23, 59, 59, 999);
//         customEndTime = endOfDay.valueOf();
//       }

//       setStartTime(customStartTime);
//       setEndTime(customEndTime);
//     } else if (selectedValue === "today") {
//       const selectedStartTime = startTime ? startTime.valueOf() : null;
//       const selectedEndTime = endTime ? endTime.valueOf() : null;

//       setStartTime(selectedStartTime);
//       setEndTime(selectedEndTime);
//     }

//     console.log("Applied Duration Type:", selectedValue);
//     console.log(
//       "Applied Start Time:",
//       startDate ? startDate.valueOf() : startTime
//     );
//     console.log("Applied End Time:", endDate ? endDate.valueOf() : endTime);
//     // console.log("Previous Start Time:", prevStartTime);
//     // console.log("Previous End Time:", prevEndTime);
//   };

//   const isApplyButtonEnabled =
//     selectedValue === "today" || selectedValue === "customRange";
//   const isToday = selectedValue === "today";

//   return (
//     <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
//       <Box>
//         <FormControl>
//           <Select
//             value={selectedValue}
//             onChange={handleChange}
//             displayEmpty
//             IconComponent={FilterListIcon}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: "120px",
//               height: "48px",
//               color: "#1C1C1C",
//               fontFamily: "mySecondFont",
//               textAlign: "left",
//             }}
//           >
//             <MenuItem
//               sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
//               value="today"
//             >
//               Today
//             </MenuItem>
//             <MenuItem
//               sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
//               value="yesterday"
//             >
//               Yesterday
//             </MenuItem>
//             <MenuItem
//               sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
//               value="week"
//             >
//               Last 7 Days
//             </MenuItem>
//             <MenuItem
//               sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
//               value="month"
//             >
//               Current Month
//             </MenuItem>
//             <MenuItem
//               sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
//               value="year"
//             >
//               Current Year
//             </MenuItem>
//             <MenuItem
//               sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
//               value="customRange"
//             >
//               Custom Range
//             </MenuItem>
//           </Select>
//         </FormControl>
//       </Box>

//       {(selectedValue === "customRange" || selectedValue === "today") && (
//         <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
//           {isToday && (
//             <>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <TimePicker
//                     label="Start Time"
//                     value={startTime}
//                     onChange={(newValue) => setStartTimeState(newValue)}
//                   />
//                 </LocalizationProvider>
//               </Box>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <TimePicker
//                     label="End Time"
//                     value={endTime}
//                     onChange={(newValue) => setEndTimeState(newValue)}
//                     minTime={startTime ? startTime : null} // Add conditional restriction
//                   />
//                 </LocalizationProvider>
//               </Box>
//             </>
//           )}
//           {selectedValue === "customRange" && (
//             <>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DatePicker
//                     label="Start Date"
//                     value={startDate}
//                     onChange={(newValue) => setStartDate(newValue)}
//                   />
//                 </LocalizationProvider>
//               </Box>
//               <Box sx={{ width: "144px" }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DatePicker
//                     label="End Date"
//                     value={endDate}
//                     onChange={(newValue) => setEndDate(newValue)}
//                     minDate={startDate ? startDate.add(0, 'day') : null}
//                   />
//                 </LocalizationProvider>
//               </Box>
//             </>
//           )}
//         </Box>
//       )}

//       <Button
//         sx={{
//           background: "#222",
//           padding: "8px 24px",
//           width: "120px",
//           borderRadius: "4px",
//           color: "#FCFCFC",
//           fontFamily: "mySecondFont",
//           letterSpacing: "1.12px",
//           ":hover": {
//             background: "#222",
//           },
//           "&.Mui-disabled": {
//             backgroundColor: "#555",
//             color: "#999",
//             cursor: "not-allowed",
//             opacity: 0.6,
//           },
//         }}
//         disabled={!isApplyButtonEnabled}
//         onClick={handleApply}
//       >
//         APPLY
//       </Button>
//     </Box>
//   );
// };

// export default TimeFilter;

import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, Slide, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const TimeFilter = ({
  setDurationType,
  setStartTime,
  setEndTime,
  setPrevStartTime,
  setPrevEndTime,
}) => {
  const [selectedValue, setSelectedValue] = React.useState("today");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startTime, setStartTimeState] = React.useState(null);
  const [endTime, setEndTimeState] = React.useState(null);
  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {
    const now = new Date();
    const defaultStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
    const defaultEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

    setStartTime(defaultStartTime);
    setEndTime(defaultEndTime);
    setDurationType("today");

    setPrevStartTime(defaultStartTime - 24 * 60 * 60 * 1000);
    setPrevEndTime(defaultEndTime - 24 * 60 * 60 * 1000);
  }, [
    setStartTime,
    setEndTime,
    setDurationType,
    setPrevStartTime,
    setPrevEndTime,
  ]);

  const computePreviousTimes = (value) => {
    const now = new Date();
    let prevStart, prevEnd;

    switch (value) {
      case "today":
        prevStart = new Date(now.setDate(now.getDate() - 1)).setHours(
          0,
          0,
          0,
          0
        );
        prevEnd = new Date(now.setDate(now.getDate())).setHours(
          23,
          59,
          59,
          999
        );
        break;
      case "yesterday":
        const nowDate = new Date();

        // Set the date to the previous day (yesterday)
        const yesterday = new Date(nowDate.setDate(nowDate.getDate() - 2));

        // Set the start of yesterday
        prevStart = new Date(yesterday.setHours(0, 0, 0, 0)).valueOf();

        // Set the end of yesterday
        prevEnd = new Date(yesterday.setHours(23, 59, 59, 999)).valueOf();
        break;
      case "week":
        const previousStartDate = new Date(now);
        previousStartDate.setDate(now.getDate() - 13);
        const prevStartTime = new Date(
          previousStartDate.setHours(0, 0, 0, 0)
        ).valueOf();
        const previousEndDate = new Date(now);
        previousEndDate.setDate(now.getDate() - 7);
        const prevEndTime = new Date(
          previousEndDate.setHours(23, 59, 59, 999)
        ).valueOf();
        prevStart = prevStartTime;
        prevEnd = prevEndTime;
        break;
      case "month":
        const startOfPreviousMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const endOfPreviousMonth = new Date(
          now.getFullYear(),
          now.getMonth(),
          0,
          23,
          59,
          59,
          999
        );
        prevStart = startOfPreviousMonth.valueOf();
        prevEnd = endOfPreviousMonth.valueOf();
        break;
      case "year":
        const startOfPreviousYear = new Date(now.getFullYear() - 1, 0, 1);
        const endOfPreviousYear = new Date(
          now.getFullYear() - 1,
          11,
          31,
          23,
          59,
          59,
          999
        );
        prevStart = startOfPreviousYear.valueOf();
        prevEnd = endOfPreviousYear.valueOf();
        break;
      default:
        prevStart = null;
        prevEnd = null;
    }

    return { prevStartTime: prevStart, prevEndTime: prevEnd };
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setDurationType(value);

    const now = new Date();
    let newStartTime, newEndTime;

    if (value === "today") {
      newStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
      newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
    } else if (value === "yesterday") {
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      newStartTime = new Date(yesterday.setHours(0, 0, 0, 0)).valueOf();
      newEndTime = new Date(yesterday.setHours(23, 59, 59, 999)).valueOf();
    } else if (value === "week") {
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - 6);
      newStartTime = new Date(startOfWeek.setHours(0, 0, 0, 0)).valueOf();
      newEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();
    } else if (value === "month") {
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );
      newStartTime = startOfMonth.valueOf();
      newEndTime = endOfMonth.valueOf();
    } else if (value === "year") {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
      newStartTime = startOfYear.valueOf();
      newEndTime = endOfYear.valueOf();
    } else if (value === "customRange") {
      newStartTime = startDate ? startDate.valueOf() : null;
      if (endDate) {
        const endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        newEndTime = endOfDay.valueOf();
      } else {
        newEndTime = null;
      }
    }

    if (newStartTime !== undefined && newEndTime !== undefined) {
      setStartTime(newStartTime);
      setEndTime(newEndTime);

      const { prevStartTime, prevEndTime } = computePreviousTimes(value);

      setPrevStartTime(prevStartTime);
      setPrevEndTime(prevEndTime);
    }
  };

  const handleApply = () => {
    if (selectedValue === "customRange") {
      const customStartTime = startDate ? startDate.valueOf() : null;
      let customEndTime = null;

      if (endDate) {
        const endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        customEndTime = endOfDay.valueOf();
      }

      setStartTime(customStartTime);
      setEndTime(customEndTime);
    } else if (selectedValue === "today") {
      const selectedStartTime = startTime ? startTime.valueOf() : null;
      const selectedEndTime = endTime ? endTime.valueOf() : null;

      setStartTime(selectedStartTime);
      setEndTime(selectedEndTime);
    }

    console.log("Applied Duration Type:", selectedValue);
    console.log(
      "Applied Start Time:",
      startDate ? startDate.valueOf() : startTime
    );
    console.log("Applied End Time:", endDate ? endDate.valueOf() : endTime);
    // console.log("Previous Start Time:", prevStartTime);
    // console.log("Previous End Time:", prevEndTime);

    if (startTime && endTime) {
      // Logic to apply the time selection...
      setShowResetButton(true);
    } else {
      // Optionally show an alert or error message
      console.log("Please select both start and end times.");
    }
  };

  const handleReset = () => {
    const now = new Date();
    const defaultStartTime = new Date(now.setHours(0, 0, 0, 0)).valueOf();
    const defaultEndTime = new Date(now.setHours(23, 59, 59, 999)).valueOf();

    setStartTime(defaultStartTime);
    setEndTime(defaultEndTime);
    setPrevStartTime(defaultStartTime - 24 * 60 * 60 * 1000);
    setPrevEndTime(defaultEndTime - 24 * 60 * 60 * 1000);
    setStartDate(null);
    setEndDate(null);
    setStartTimeState(null);
    setEndTimeState(null);
    setSelectedValue("today");
    setDurationType("today");

    setShowResetButton(false);
  };

  const isApplyButtonEnabled =
    selectedValue === "today" || selectedValue === "customRange";
  const isToday = selectedValue === "today";

  const todayMaxDate = dayjs(); // Get today's date

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <Box>
        <FormControl>
          <Select
            value={selectedValue}
            onChange={handleChange}
            displayEmpty
            IconComponent={FilterListIcon}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "120px",
              height: "48px",
              color: "#1C1C1C",
              fontFamily: "mySecondFont",
              textAlign: "left",
            }}
          >
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value="today"
            >
              Today
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value="yesterday"
            >
              Yesterday
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value="week"
            >
              Last 7 Days
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value="month"
            >
              Current Month
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value="year"
            >
              Current Year
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value="customRange"
            >
              Custom Range
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {(selectedValue === "customRange" || selectedValue === "today") && (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {isToday && (
            <>
              <Box sx={{ width: "144px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(newValue) => setStartTimeState(newValue)}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ width: "144px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="End Time"
                    value={endTime}
                    onChange={(newValue) => setEndTimeState(newValue)}
                    minTime={startTime ? startTime : null} // Add conditional restriction
                  />
                </LocalizationProvider>
              </Box>
              {showResetButton && (
                <Slide
                  direction="down"
                  in={showResetButton}
                  mountOnEnter
                  unmountOnExit
                >
                  <Button
                    sx={{
                      padding: "8px 12px",
                      width: "70px",
                      borderRadius: "4px",
                      fontSize: "12px",
                      color: "#790000",
                      fontFamily: "mySecondFont",
                      letterSpacing: "1.12px",

                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleReset}
                  >
                    <RestartAltIcon />
                    RESET
                  </Button>
                </Slide>
              )}
            </>
          )}
          {/* {selectedValue === "customRange" && (
            <>
              <Box sx={{ width: "144px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    maxDate={todayMaxDate} // Restrict end date to today or earlier
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ width: "144px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate ? startDate.add(0, "day") : null}
                    maxDate={todayMaxDate} // Restrict end date to today or earlier
                  />
                </LocalizationProvider>
              </Box>
            </>
          )} */}

          {selectedValue === "customRange" && (
  <>
    <Box sx={{ width: "144px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="Start Date & Time"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          maxDateTime={todayMaxDate} // Restrict to today or earlier
        />
      </LocalizationProvider>
    </Box>
    <Box sx={{ width: "144px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="End Date & Time"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          minDateTime={startDate ? startDate.add(0, "minute") : null}
          maxDateTime={todayMaxDate} // Restrict to today or earlier
        />
      </LocalizationProvider>
    </Box>
  </>
)}
        </Box>
      )}

      <Button
        sx={{
          background: "#222",
          padding: "8px 24px",
          width: "120px",
          borderRadius: "4px",
          color: "#FCFCFC",
          fontFamily: "mySecondFont",
          letterSpacing: "1.12px",
          ":hover": {
            background: "#222",
          },
          "&.Mui-disabled": {
            backgroundColor: "#555",
            color: "#999",
            cursor: "not-allowed",
            opacity: 0.6,
          },
        }}
        disabled={!isApplyButtonEnabled}
        onClick={handleApply}
      >
        APPLY
      </Button>
    </Box>
  );
};

export default TimeFilter;
