import { Box, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  resendEmailOTP,
  resendOTP,
  verifyEmailOTP,
  verifyOTP,
} from "../../../redux/action/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { convertCountryRoute, MyRoutes } from "../../../routes/routes";
import { useSelector } from "react-redux";
import { countryCodeToCountry } from "../../../utils/currency/countryCodeToCountry";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(""); // State for OTP input
  const [timer, setTimer] = useState(30);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const { otpVerified, user } = useSelector((state) => state.auth);

  const getCountryCode = (phoneNumber) => {
    if (!phoneNumber) return ""; // Check if phoneNumber is defined
    const match = phoneNumber.match(/^(\+\d{1,2})/); // Match the country code
    return match ? match[1] : ""; // Return the country code or an empty string
  };

  // Function to handle OTP verification
  const handleVerifyOtp = () => {
    if (otp.length === 4 && (state?.phone || state?.email)) {
      const otpNumber = parseInt(otp, 10); // Convert OTP to number
      if (isNaN(otpNumber)) {
        console.error("Invalid OTP format");
        return;
      }

      const payload = state?.loginWithOtpProp
        ? { email: state.email, otp: otpNumber }
        : { phoneNumber: state.phone, otp: otpNumber };

      dispatch(verifyEmailOTP(payload))
        .unwrap()
        .then((user) => {
          const countyCheck = user?.user?.phoneNumber;
          const countryCode = getCountryCode(countyCheck);

          // Use the countryCodeToCountry mapping to dynamically map the country code
          const countryCodeMapped =
            countryCodeToCountry[countryCode] || countryCode;
          convertCountryRoute(countryCodeMapped);
          navigate(MyRoutes.dashboard);
        })
        .catch((err) => {
          console.error(
            "Failed to verify OTP:",
            err.message || "Verification failed"
          );
          toast.error("Invalid OTP. Please try again."); // Show error message
        });
    } else {
      console.error(
        "Please enter a valid OTP and ensure email/phone number is available"
      );
      toast.error("Please enter a valid OTP."); // Show error message
    }
  };

  // Handle Enter key press to submit OTP
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleVerifyOtp();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [otp, state]); // Dependencies include otp and state

  // Function to restart timer and resend OTP
  const restartTimer = () => {
    setTimer(30); // Reset the timer
    const resendPayload = state?.loginWithOtpProp ? state?.email : state?.phone;
    dispatch(resendEmailOTP(resendPayload))
      .unwrap()
      .then(() => {
        const message = state?.loginWithOtpProp
          ? "OTP resent to your email!"
          : "OTP resent to your phone!";
        toast.success(message);
      })
      .catch((err) => {
        console.error(
          "Failed to resend OTP:",
          err.message || "Error resending OTP"
        );
        toast.error("Failed to resend OTP. Please try again."); // Show error message
      });
  };

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const handleBack = () => {
    navigate("/");
  };

  const identifier = state?.loginWithOtpProp ? state?.email : state?.phone;
  const maskedIdentifier = state?.loginWithOtpProp
    ? `xxxx${identifier?.slice(-4)}`
    : `xxxxxx${identifier?.slice(-4)}`;

  return (
    <Box
      sx={{
        backgroundImage: `url("/images/auth-bg-final.jpg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "#FFFFFF",
          padding: "32px",
          borderRadius: "8px",
          width: "480px",
          height: "500px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <img src="/images/1C-login-logo.png" alt="logo" />
        </Box>

        <Typography
          sx={{
            color: "#2F2F2F",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "16px",
            textTransform: "uppercase",
            mb: "16px",
          }}
        >
          {state?.loginWithOtpProp
            ? "Login to 1C CMS"
            : "Two step verification"}
        </Typography>

        {!state?.loginWithOtpProp && (
          <Typography
            sx={{
              color: "#0C0C0C",
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "24px",
              fontWeight: 600,
              mb: "12px",
            }}
          >
            Verify Mobile Number
          </Typography>
        )}

        <Typography
          sx={{
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            fontSize: "18px",
            fontWeight: 500,
            mb: "4px",
          }}
        >
          Enter OTP sent to {maskedIdentifier}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>&nbsp;&nbsp;&nbsp; </span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "64px",
              height: "64px",
              color: "#7A7A7A",
              borderRadius: "8px",
              border: "1px solid #2F2F2F",
              marginTop: "8px",
              fontSize: "clamp(1rem, 0.9741rem + 0.1258vw, 1.125rem)",
              fontFamily: "mySecondFont",
            }}
          />
        </Box>

        <Typography
          fontSize={"14px"}
          color={"#2F2F2F"}
          fontFamily={"myThirdFont"}
          mt={"16px"}
          textAlign={"center"}
        >
          Didn’t receive OTP?{" "}
          {timer === 0 ? (
            <span
              onClick={restartTimer}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#e50000",
              }}
            >
              Resend OTP
            </span>
          ) : (
            <span style={{ color: "#2F2F2F" }}>Resend OTP in 00:{timer}s</span>
          )}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <Button
            onClick={handleVerifyOtp}
            sx={{
              width: "100%",
              background: "#000000",
              padding: "12px 24px",
              color: "#FCFCFC",
              "&:hover": { background: "#2F2F2F" },
            }}
          >
            Verify
          </Button>

          <Button
            onClick={handleBack}
            sx={{
              width: "100%",
              background: "#FFF",
              padding: "12px 24px",
              color: "#222222",
              border: "1px solid #CBCBCB",
              "&:hover": { background: "#EEEEEE" },
            }}
          >
            BACK
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyEmailPage;
