import React from "react";
import StationStatistics from "./StationStatistics/StationStatistics";
import { Box } from "@mui/material";

const StationsBox = () => {
  return (
    <Box>
      <StationStatistics />
    </Box>
  );
};

export default StationsBox;
