import { Box, Divider } from "@mui/material";
import React from "react";

import ProgressBar from "../../Graphs/ProgressBar";
import Heading from "../../../utils/Heading";

const RatingBox = ({data}) => {
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img src="/images/reviews.png" alt="" />
          <Heading text={"Ratings"} />
        </Box>
        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>
      <Box sx={{ marginTop: "12px", marginBottom: "12px" }}>
        <ProgressBar dashboardProgBar={"false"} ratingData={data} />
      </Box>
    </Box>
  );
};

export default RatingBox;
