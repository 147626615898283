import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/RFID/TopBar";

const RfidManagement = () => {
  return (
    <Box>
      <TopBar />
    </Box>
  );
};

export default RfidManagement;
