import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Initial state for tariff
const tariffInitialState = {
  tariffs: [],
  loading: false,
  error: null,
};

// get tariffs
export const getTariffs = createAsyncThunk(
  "tariff/getTariffs",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getTariff, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// create - tariff
export const createTariff = createAsyncThunk(
  "tariff/createTariff",
  async (formValues, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(endPoints.createTariff, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// delete - tariff
export const deleteTariff = createAsyncThunk(
  "tariff/deleteTariff",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${endPoints.deleteTariff}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// update - tariff
export const updateTariff = createAsyncThunk(
  "tariff/updateTariff",
  async ({ id, formValues }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateTariff}/${id}`,
        formValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const tariffSlice = createSlice({
  name: "tariff",
  initialState: tariffInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTariffs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTariffs.fulfilled, (state, action) => {
        state.loading = false;
        state.tariffs = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getTariffs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createTariff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTariff.fulfilled, (state, action) => {
        state.loading = false;
        state.tariffs.push(action.payload); // Add the newly created tariff
      })
      .addCase(createTariff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteTariff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTariff.fulfilled, (state, action) => {
        state.loading = false;
        state.tariffs = state.tariffs.filter(
          (tariff) => tariff._id !== action.payload
        ); // Remove the deleted tariff
      })
      .addCase(deleteTariff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTariff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTariff.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.tariffs.findIndex(
          (tariff) => tariff._id === action.payload._id
        );
        if (index !== -1) {
          state.tariffs[index] = action.payload; // Update the existing tariff
        }
      })
      .addCase(updateTariff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default tariffSlice.reducer;
