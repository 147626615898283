// import React from "react";
// import { Box, Typography } from "@mui/material";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";

// // New dataset for the single line graph
// const data = [
//   {
//     name: "Mon",
//     walletAmount: 10,
//   },
//   {
//     name: "Tue",
//     walletAmount: 20,
//   },
//   {
//     name: "Wed",
//     walletAmount: 30,
//   },
//   {
//     name: "Thu",
//     walletAmount: 25,
//   },
//   {
//     name: "Fri",
//     walletAmount: 40,
//   },
//   {
//     name: "Sat",
//     walletAmount: 35,
//   },
//   {
//     name: "Sun",
//     walletAmount: 50,
//   },
// ];

// const LineGraph = ({ graphTitle }) => {
//   return (
//     <Box sx={{ width: "100%", height: 312, padding: "16px 0" }}>
//       <Typography
//         sx={{
//           textAlign: "center",
//           marginBottom: "8px",
//           fontFamily: "myThirdFont",
//           fontWeight: "600",
//           fontSize: "14px",
//           color: "#666",
//           fontWeight: "500",
//           lineHeight: "16px",
//         }}
//       >
//         {graphTitle}
//       </Typography>
//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart
//           data={data}
//           margin={{
//             top: 10,
//             right: 24,
//             left: 10,
//             bottom: 10,
//           }}
//         >
//           <CartesianGrid strokeDasharray="5 3" vertical={false} />
//           <YAxis
//             tick={{
//               fontSize: "12px",
//               fontFamily: "myThirdFont",
//               fill: "#666",
//               fontWeight: "500",
//               lineHeight: "16px",
//             }}
//             axisLine={false} // Hide Y-axis line
//           />
//           <XAxis
//             dataKey="name"
//             tick={{
//               fontSize: "14px",
//               fontFamily: "mySecondFont",
//               fill: "#666",
//               fontWeight: "500",
//               lineHeight: "16px",
//             }}
//             axisLine={false} // Hide X-axis line
//           />
//           <Tooltip />
//           <Line
//             type="monotone"
//             dataKey="walletAmount"
//             stroke="#2271E8" // Color of the line
//             activeDot={{ r: 3 }} // Style of the active dot
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default LineGraph;

import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const LineGraph = ({ graphTitle, data, selectedTime, startTime, endTime }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "week") {
      for (let i = 0; i < 7; i++) {
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: dayjs().subtract(i, "day").format("ddd"),
        });
      }
      dates.reverse();
    } else if (selectedTime === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      // Generate hour-wise data for yesterday
      for (let i = 0; i < 24; i++) {
        const hour = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .add(i, "hour")
          .format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    const combinedData = dates.map(({ date, label }) => {
      const dailyData = Array.isArray(data.statsReport)
        ? data.statsReport.find((item) => item._id === date) || {}
        : {};

      const dailyHubSessionData = Array.isArray(data.chargingSessions)
        ? data.chargingSessions.find((item) => item._id === date) || {}
        : {};

      return {
        date: label,
        walletRecharge: dailyData.totalWalletAmountAdded || 0,
        hubSessions: dailyHubSessionData?.totalVehicleEntered || 0,
      };
    });

    return combinedData;
  };

  const chartData = getData();

  return (
    <Box sx={{ width: "100%", height: 312, padding: "16px 0" }}>
      <Typography
        sx={{
          textAlign: "center",
          marginBottom: "8px",
          fontFamily: "myThirdFont",
          fontWeight: "600",
          fontSize: "14px",
          color: "#666",
          fontWeight: "500",
          lineHeight: "16px",
        }}
      >
        {graphTitle}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 10,
            right: 24,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="5 3" vertical={false} />
          <YAxis
            tick={{
              fontSize: "12px",
              fontFamily: "myThirdFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
          <XAxis
            dataKey="date"
            tick={{
              fontSize: "14px",
              fontFamily: "mySecondFont",
              fill: "#666",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            axisLine={false}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey={
              graphTitle === "Hub Sessions" ? "hubSessions" : "walletRecharge"
            }
            stroke="#2271E8"
            activeDot={{ r: 3 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default LineGraph;
