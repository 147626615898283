import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/company_management/TopBar";
import CompanyManagementTable from "../../components/company_management/CompanyManagementTable";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";

const CompanyManagement = () => {
  const navigate = useNavigate();
  useScrollToTop(navigate);
  return (
    <Box>
      {/* TopBar */}
      <TopBar />

      {/* Company Management Table Box */}

      <CompanyManagementTable />
    </Box>
  );
};

export default CompanyManagement;
