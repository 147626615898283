import React from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import { useLocation } from "react-router-dom";

const ComposedGraph = ({ data, selectedTime, startTime, endTime }) => {
  const location = useLocation();
  const currencySymbol = GetCurrencySymbol();
  const hubStatsOverview = location.pathname.includes("/hub_management");

  const formatLegendLabel = (value) => {
    switch (value) {
      case "totalRevenue":
        return `Total Revenue (${currencySymbol})`;
      case "totalEnergy":
        return "Total Energy (kWh)";
      case "totalPassCount":
        return "Total Pass Count";
      case "totalPassValue":
        return "Total Pass Value";
      default:
        return value;
    }
  };

  //with today as distributed data
  const generateDates = (selectedTime, startTime, endTime) => {
    let dates = [];
    if (selectedTime === "week") {
      for (let i = 0; i < 7; i++) {
        const day = dayjs().subtract(i, "day").format("ddd");
        dates.push({
          date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
          label: day,
        });
      }
      dates.reverse();
    } else if (selectedTime === "month") {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");
      let currentDate = startOfMonth;

      while (
        currentDate.isBefore(endOfMonth) ||
        currentDate.isSame(endOfMonth)
      ) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.date().toString(),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "year") {
      const startOfYear = dayjs().startOf("year");
      const endOfYear = dayjs().endOf("year");
      let currentDate = startOfYear;

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
        dates.push({
          date: currentDate.format("YYYY-MM"),
          label: monthNames[currentDate.month()],
        });
        currentDate = currentDate.add(1, "month");
      }
    } else if (selectedTime === "customRange" && startTime && endTime) {
      let currentDate = dayjs(startTime);
      const end = dayjs(endTime);
      while (currentDate.isBefore(end) || currentDate.isSame(end)) {
        dates.push({
          date: currentDate.format("YYYY-MM-DD"),
          label: currentDate.format("YYYY-MM-DD"),
        });
        currentDate = currentDate.add(1, "day");
      }
    } else if (selectedTime === "today") {
      for (let i = 0; i < 24; i++) {
        const hour = dayjs().startOf("day").add(i, "hour").format("HH:00");
        dates.push({
          date: dayjs()
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    } else if (selectedTime === "yesterday") {
      // Generate hour-wise data for yesterday
      for (let i = 0; i < 24; i++) {
        const hour = dayjs()
          .subtract(1, "day")
          .startOf("day")
          .add(i, "hour")
          .format("HH:00");
        dates.push({
          date: dayjs()
            .subtract(1, "day")
            .startOf("day")
            .add(i, "hour")
            .format("YYYY-MM-DD HH:00"),
          label: hour,
        });
      }
    }
    return dates;
  };

  //With today as aggregated labels and data
  // const generateDates = (selectedTime, startTime, endTime) => {
  //   let dates = [];
  //   if (selectedTime === "week") {
  //     for (let i = 0; i < 7; i++) {
  //       const day = dayjs().subtract(i, "day").format("ddd");
  //       dates.push({
  //         date: dayjs().subtract(i, "day").format("YYYY-MM-DD"),
  //         label: day,
  //       });
  //     }
  //     dates.reverse();
  //   } else if (selectedTime === "month") {
  //     const startOfMonth = dayjs().startOf("month");
  //     const endOfMonth = dayjs().endOf("month");
  //     let currentDate = startOfMonth;

  //     while (
  //       currentDate.isBefore(endOfMonth) ||
  //       currentDate.isSame(endOfMonth)
  //     ) {
  //       dates.push({
  //         date: currentDate.format("YYYY-MM-DD"),
  //         label: currentDate.date().toString(),
  //       });
  //       currentDate = currentDate.add(1, "day");
  //     }
  //   } else if (selectedTime === "year") {
  //     const startOfYear = dayjs().startOf("year");
  //     const endOfYear = dayjs().endOf("year");
  //     let currentDate = startOfYear;

  //     const monthNames = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];

  //     while (currentDate.isBefore(endOfYear) || currentDate.isSame(endOfYear)) {
  //       dates.push({
  //         date: currentDate.month() + 1,
  //         label: monthNames[currentDate.month()],
  //       });
  //       currentDate = currentDate.add(1, "month");
  //     }
  //   } else if (selectedTime === "customRange" && startTime && endTime) {
  //     let currentDate = dayjs(startTime);
  //     const end = dayjs(endTime);
  //     while (currentDate.isBefore(end) || currentDate.isSame(end)) {
  //       dates.push({
  //         date: currentDate.format("YYYY-MM-DD"),
  //         label: currentDate.format("YYYY-MM-DD"),
  //       });
  //       currentDate = currentDate.add(1, "day");
  //     }
  //   } else if (selectedTime === "today") {
  //     dates.push({
  //       date: dayjs().format("YYYY-MM-DD"),
  //       label: "Today",
  //     });
  //   } else if (selectedTime === "yesterday") {
  //     dates.push({
  //       date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  //       label: "Yesterday",
  //     });
  //   }
  //   return dates;
  // };

  const getData = () => {
    if (!selectedTime || !data) return [];

    const dates = generateDates(selectedTime, startTime, endTime);

    const combinedData = dates.map(({ date, label }) => {
      let monthlyData = {};

      if (selectedTime === "year") {
        const month = date; // Assign the date to the month variable

        if (hubStatsOverview) {
          monthlyData =
            data?.hubCashTransactionStats?.passDetailsByDates?.find(
              (item) => item._id === month
            ) || {};
        } else {
          monthlyData = data?.stats?.find((item) => item._id === month) || {};
        }
      } else {
        if (hubStatsOverview) {
          monthlyData =
            data?.hubCashTransactionStats?.passDetailsByDates?.find(
              (item) => item._id === date
            ) || {};
        } else {
          monthlyData = data?.stats?.find((item) => item._id === date) || {};
        }
      }

      const totalRevenue = parseFloat(monthlyData.totalRevenue) || 0;
      const energyConsumed = parseFloat(monthlyData.energyConsumed) || 0;

      const totalPassCount = parseFloat(monthlyData.passCount) || 0;
      const totalPassValue = parseFloat(monthlyData.passValue) || 0;

      // Formatted values for kWh and revenue
      const formattedEnergyConsumed = (energyConsumed / 1000).toFixed(2);
      const formattedRevenue = totalRevenue.toFixed(2);
      return {
        date: label,
        totalRevenue: parseFloat(formattedRevenue),
        totalEnergy: parseFloat(formattedEnergyConsumed),
        totalPassCount: totalPassCount,
        totalPassValue: totalPassValue,
      };
    });
    return combinedData;
  };

  const chartData = getData();

  return (
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 10,
        }}
      >
        <CartesianGrid
          stroke="#DDDDDD"
          strokeDasharray="5 3"
          vertical={false}
        />

        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#E50000" stopOpacity={1} />
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="date"
          scale="auto"
          padding={{ left: 0 }}
          tick={{
            fontSize: "14px",
            fontFamily: "mySecondFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />

        <YAxis
          yAxisId="left"
          tick={{
            fontSize: "12px",
            fontFamily: "myThirdFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />
        <Bar
          dataKey={hubStatsOverview ? "totalPassCount" : "totalRevenue"}
          barSize={60}
          fill="#9ABCEF"
          yAxisId="left"
        />

        <YAxis
          yAxisId="right"
          orientation="right"
          tick={{
            fontSize: "12px",
            fontFamily: "myThirdFont",
            fill: "#666",
            fontWeight: "500",
            lineHeight: "16px",
          }}
          axisLine={false}
        />
        <Area
          type="monotone"
          dataKey={hubStatsOverview ? "totalPassValue" : "totalEnergy"}
          stroke="black"
          fillOpacity={0.5}
          fill="url(#colorUv)"
          yAxisId="right"
          dot={{ stroke: "black", strokeWidth: 1, r: 2 }}
        />

        <Tooltip />
        <Legend
          wrapperStyle={{
            padding: 10,
            fontSize: "14px",
            fontFamily: "mySecondFont",
            color: "red",
          }}
          verticalAlign="top"
          align="center"
          iconSize={20}
          formatter={formatLegendLabel}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedGraph;
