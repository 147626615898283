import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import { Box, Button } from "@mui/material";
import CustomTable from "../custom/CustomTable";
import { fetchAllStations } from "../../redux/action/stations/stationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";
import BulkUploadStationModal from "../Modals/BulkUploadStationModal";
import {
  bulkUploadOtherStations,
  fetchAllOtherStations,
} from "../../redux/action/stations/otherStationSlice";
import { toast } from "react-toastify";

const TableBox = ({
  rows,
  columns,
  isRowClickable,
  showAddButton,
  showBulkUploadButton = false,
  loading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stations = useSelector((state) => state.stations.stations);

  const [filteredStations, setFilteredStations] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [bulkUploadModelOpen, setBulkUploadModelOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAllStations());
  }, [dispatch]);

  const handlePageChange = () => {
    navigate(MyRoutes.addNewChargingStation);
  };

  useEffect(() => {
    if (stations.length > 0) {
      setFilteredStations(stations);
    }
  }, [stations]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredStations(filteredData);
  };

  const myFilters = [
    {
      value: "stationName",
      title: "Station Name",
    },
    {
      value: "_id",
      title: "Station ID",
    },
    {
      value: "createdAt",
      title: "Sort by Date",
    },
  ];

  const handleUpload = async (files) => {
    const response = await dispatch(
      bulkUploadOtherStations({ file: files[0] })
    ).unwrap();

    toast(response?.message);
    setBulkUploadModelOpen(false);
    dispatch(fetchAllOtherStations());
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={stations}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />

        {showBulkUploadButton && (
          <Button
            onClick={() => setBulkUploadModelOpen(true)}
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "160px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              textWrap: "nowrap",
              ":hover": {
                background: "#222",
              },
            }}
          >
            + Bulk Upload
          </Button>
        )}

        {showAddButton && (
          <Button
            onClick={handlePageChange}
            sx={{
              background: "#222",
              padding: "8px 24px",
              width: "120px",
              borderRadius: "4px",
              color: "#FCFCFC",
              fontFamily: "mySecondFont",
              letterSpacing: "1.12px",
              textWrap: "nowrap",
              ":hover": {
                background: "#222",
              },
            }}
          >
            + ADD NEW
          </Button>
        )}
      </Box>

      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        modalHeading={"Charging Stations"}
        actionType={"edit-delete"}
        rowClickPath={"/charging_stations"}
        clickableColumns={["stationName"]}
        columnClickPaths={{
          stationName: "/charging_stations",
        }}
        columnKeyMappings={{
          stationName: "_id",
        }}
        statsHeading={"true"}
        loading={loading}
        sortColumn={sortColumn}
      />

      <BulkUploadStationModal
        open={bulkUploadModelOpen}
        handleUpload={handleUpload}
        handleClose={() => setBulkUploadModelOpen(false)}
      />
    </Box>
  );
};

export default TableBox;
