import { Box } from "@mui/material";
import React from "react";
import DoughnutCharHub from "./DoughnutCharHub";

const PassValueGraph = ({ data, passType }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      {/* <DoughnutChart /> */}
      <DoughnutCharHub
        data={data}
        centerData=""
        passType={passType}
        title="Total Pass Holders"
      />
    </Box>
  );
};

export default PassValueGraph;
