import React from "react";
import { Box, Divider, Typography, Skeleton } from "@mui/material";

const DeviceStatusSkeleton = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      {/* Header */}
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              animation="wave"
            />
            <Skeleton variant="text" width={100} height={28} animation="wave" />
          </Box>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={50}
            height={24}
            sx={{ borderRadius: "12px" }}
          />
        </Box>
        <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
      </Box>

      {/* Progress Bar */}
      <Box sx={{ marginTop: "24px", marginBottom: "12px" }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
          animation="wave"
        />
      </Box>

      {/* Device Status Cards - online, faulted, offline */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginBottom: "20px",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: "8px",
        }}
      >
        {[1, 2, 3].map((item) => (
          <Box
            key={item}
            sx={{
              flex: 1,
              padding: "16px 24px",
              boxShadow: "0px 2px 4px 0px rgba(41, 45, 51, 0.25);",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="circular"
                width={16}
                height={16}
                animation="wave"
                sx={{ marginRight: "8px" }}
              />
              <Skeleton
                variant="text"
                width={60}
                height={24}
                animation="wave"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="text"
                width={40}
                height={32}
                animation="wave"
              />
            </Box>
          </Box>
        ))}
      </Box>

      {/* Active Session Button */}
      <Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={48}
          sx={{
            borderRadius: "4px",
            boxShadow: "0px 2px 4px 0px rgba(41, 45, 51, 0.25)",
          }}
        />
      </Box>
    </Box>
  );
};

export default DeviceStatusSkeleton;
