import React, { createContext, useContext, useState } from "react";

const StationContext = createContext();

export const StationProvider = ({ children }) => {
  const [stationIds, setStationIds] = useState([]);

  return (
    <StationContext.Provider value={{ stationIds, setStationIds }}>
      {children}
    </StationContext.Provider>
  );
};

export const useStationContext = () => {
  const context = useContext(StationContext);

  if (context === undefined) {
    // Return default values to prevent breaking
    return { stationIds: [], setStationIds: () => {} };
  }

  return context;
};
