// SkeletonAppStats.js
import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonAppStats = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Skeleton variant="text" height={30} width="50%" animation="wave" />
          <Skeleton
            variant="rectangular"
            height={60}
            width="100%"
            animation="wave"
          />
        </Box>
        <Box sx={{}}>
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            animation="wave"
          />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", padding: "16px 0" }}
      >
        <Skeleton
          variant="circular"
          width={100}
          height={100}
          animation="wave"
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Skeleton variant="text" width="20%" animation="wave" />
        <Skeleton variant="text" width="20%" animation="wave" />
        <Skeleton variant="text" width="20%" animation="wave" />
      </Box>
    </Box>
  );
};

export default SkeletonAppStats;
