import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Heading from "../../../../utils/Heading";
import { TextCopy } from "../../../../utils/TextCopy/TextCopy";
import axios from "axios"; // Import axios
import {
  fetchOcpiData,
  fetchOcpiVersionForTokenA,
  getOcpiTokenB,
} from "../../../../redux/action/ocpiManagement/ocpiSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const InputField = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  type = "text",
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="#2f2f2f"
      fontFamily="myThirdFont"
    >
      {label}
    </Typography>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "48px",
        outline: "none",
        WebkitAppearance: "none",
        MozAppearance: "textfield",
      }}
      placeholder={placeholder}
    />
  </Box>
);

const versionOptions = [
  { version: "2.2" },
  { version: "2.1" },
  { version: "2.0" },
];

const InfoBox = ({ label, icon, propType, value, canGenerate, onGenerate }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied); // Use the value prop to copy
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontFamily={"myThirdFont"}
          fontSize={"14px"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {label}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
      {propType === "token" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "rgba(2, 121, 0, 0.10)",
              padding: "12px 16px",
              overflow: "hidden", // Prevent overflow
              whiteSpace: "normal", // Allow wrapping
              textOverflow: "ellipsis", // Add ellipsis if text is too long
            }}
          >
            <Typography
              fontSize={"16px"}
              fontFamily={"mySecondFont"}
              color={value ? "#027900" : "rgba(47, 47, 47, 0.50)"}
              textAlign={"start"}
              noWrap={false} // Ensure wrapping is allowed
            >
              {value || "Click on generate to view token"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
            color={"#2F2F2F"}
            textAlign={"start"}
            sx={{ textWrap: "wrap" }}
          >
            {value}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const HandshakeRightBox = ({ networkDetail,     versionsList}) => {
  const dispatch = useDispatch();
  const [ocpiUrl, setOcpiUrl] = useState("");
  const [tokenA, setTokenA] = useState("");
  const [showVersionBox, setShowVesionBox] = useState(true);
  const [operatorId, setOperatorId] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    option1: false,
    option2: false,
  });
  const [selectedVersion, setSelectedVersion] = useState("");

  const { versionsAfterTokenA, tokenB, loading, error } = useSelector(
    (state) => state.ocpi
  );
  console.log("qyyyyy23333");

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setOperatorId(id);
    }
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };

  const handleSubmit = () => {
    // Convert ocpiUrl to string
    const formattedOcpiUrl = String(ocpiUrl).trim();

    // Validate URL
    try {
      new URL(formattedOcpiUrl); // This will throw an error if the URL is invalid
    } catch (e) {
      // alert("Please enter a valid OCPI URL.");
      toast.error("Please enter a valid OCPI URL.");
      return;
    }

    console.log("OCPI URL:", formattedOcpiUrl);
    console.log("Token A:", tokenA);
    setShowVesionBox(true);

    dispatch(fetchOcpiVersionForTokenA({ ocpiUrl: formattedOcpiUrl, tokenA }))
      .unwrap()
      .then(() => {
        alert("Form submitted successfully");
      })
      .catch((error) => {
        console.error("Error during the request:", error);
        alert("Error submitting the form");
      });
  };

  const handleGenerateTokenB = () => {
    const selectedRoles = [];
    if (selectedOptions.option1) selectedRoles.push("CPO");
    if (selectedOptions.option2) selectedRoles.push("EMSP");

    const version = selectedVersion || "2.2.1";

    const formattedOcpiUrl = String(ocpiUrl).trim();

    dispatch(
      getOcpiTokenB({
        operatorId,
        roles: selectedRoles,
        version,
        tokenA,
        url: formattedOcpiUrl,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Registeration Successful");
      })
      .catch((error) => {
        console.error("Error generating Token B:", error);
        toast.error("Registeration UnSuccessful");
      });
  };

  const isFormValid = ocpiUrl.trim() !== "" && tokenA.trim() !== "";
  const canGenerateToken =
    (selectedOptions.option1 || selectedOptions.option2) && selectedVersion && tokenA && ocpiUrl;

  // Map the dynamic versionsList to version options
  const versionOptions = versionsList.map((versionObj) => ({
    version: versionObj.version,
  }));

  return (
    <Box sx={{ background: "#fff", borderRadius: "8px", width: "100%" }}>
      <Grid container columnSpacing={2}>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "8px",
              }}
            >
              {/* Heading */}
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <img src="/images/ev_charger_icon.png" alt="" />
                    <Heading text={`Register with ${networkDetail?.name}`} />
                  </Box>
                </Box>
                <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
              </Box>

              {/* //Version Box */}
              {showVersionBox ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      // justifyContent: "space-between",
                      alignItems: "flex-start",
                      padding: "24px 0",
                    }}
                  >
                    <Box>
                      <InfoBox
                        label={
                          "Please select at least one option to generate a new OCPI token"
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <label>
                          <Checkbox
                            name="option1"
                            checked={selectedOptions.option1}
                            onChange={handleOptionChange}
                          />
                          CPO
                        </label>
                        <label>
                          <Checkbox
                            name="option2"
                            checked={selectedOptions.option2}
                            onChange={handleOptionChange}
                          />
                          eMSP
                        </label>
                      </Box>
                    </Box>

                    <Box>
                      <Typography
                        textAlign={"left"}
                        fontSize={"14px"}
                        fontFamily={"myThirdFont"}
                        color="#2F2F2F"
                      >
                        Versions Dropdown for OCPI
                      </Typography>
                      <Select
                        value={selectedVersion}
                        onChange={handleVersionChange}
                        displayEmpty
                        sx={{
                          width: "232px",
                          height: "40px",
                          color: "#1C1C1C",
                          fontFamily: "myThirdFont",
                        }}
                      >
                        <MenuItem value="" disabled>
                          Versions
                        </MenuItem>
                        {versionOptions.map((org) => (
                          <MenuItem key={org.version} value={org.version}>
                            {org.version}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                </Box>
              ) : null}

              {/* OCPI URL AND TOKEN INPUT */}
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <InputField
                  label="OCPI URL"
                  placeholder="Enter OCPI URL here"
                  value={ocpiUrl}
                  onChange={(e) => setOcpiUrl(e.target.value)}
                  name="ocpiUrl"
                />
                <InputField
                  label="Token A"
                  placeholder="Enter Token A here"
                  value={tokenA}
                  onChange={(e) => setTokenA(e.target.value)}
                  name="tokenA"
                />
                <Box
                  sx={{ display: "flex", justifyContent: "left", mt: "24px" }}
                >
                  <Button
                    onClick={handleGenerateTokenB}
                    disabled={!canGenerateToken}
                    sx={{
                      borderRadius: "4px",
                      padding: "12px 24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E50000",
                      opacity: !canGenerateToken ? "0.25" : "1",
                      width: "250px",
                      color: "#fff",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "14px",
                      fontWeight: "600",
                      "&:hover": {
                        backgroundColor: canGenerateToken
                          ? "#c70000"
                          : "#E50000",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#E50000",
                        color: "#fff",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HandshakeRightBox;
























// import {
//   Box,
//   Button,
//   Checkbox,
//   Divider,
//   Grid,
//   MenuItem,
//   Select,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import Heading from "../../../../utils/Heading";
// import { TextCopy } from "../../../../utils/TextCopy/TextCopy";
// import axios from "axios"; // Import axios
// import {
//   fetchOcpiData,
//   fetchOcpiVersionForTokenA,
//   getOcpiTokenB,
// } from "../../../../redux/action/ocpiManagement/ocpiSlice";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";

// const InputField = ({
//   label,
//   placeholder,
//   value,
//   onChange,
//   name,
//   type = "text",
// }) => (
//   <Box
//     sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
//   >
//     <Typography
//       textAlign="start"
//       fontSize="14px"
//       color="#2f2f2f"
//       fontFamily="myThirdFont"
//     >
//       {label}
//     </Typography>
//     <input
//       type={type}
//       name={name}
//       value={value}
//       onChange={onChange}
//       style={{
//         padding: "12px 16px",
//         borderRadius: "4px",
//         border: "1px solid #cbcbcb",
//         color: "rgba(47, 47, 47, 0.50)",
//         fontFamily: "myThirdFont",
//         fontSize: "16px",
//         height: "48px",
//         outline: "none",
//         WebkitAppearance: "none",
//         MozAppearance: "textfield",
//       }}
//       placeholder={placeholder}
//     />
//   </Box>
// );

// const versionOptions = [
//   { version: "2.2" },
//   { version: "2.1" },
//   { version: "2.0" },
// ];

// const InfoBox = ({ label, icon, propType, value, canGenerate, onGenerate }) => {
//   const [copied, setCopied] = useState(false);

//   const handleCopy = () => {
//     TextCopy(value, setCopied); // Use the value prop to copy
//   };

//   return (
//     <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: icon ? "space-between" : "start",
//           alignItems: "center",
//         }}
//       >
//         <Typography
//           fontFamily={"myThirdFont"}
//           fontSize={"14px"}
//           color={"#2F2F2F"}
//           textAlign={"start"}
//         >
//           {label}
//         </Typography>
//         {icon && (
//           <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
//             <span
//               style={{
//                 fontSize: "24px",
//                 color: "#292D33",
//                 fontWeight: "200",
//                 cursor: "pointer",
//               }}
//               className="material-symbols-outlined"
//               onClick={handleCopy}
//             >
//               {icon}
//             </span>
//           </Tooltip>
//         )}
//       </Box>
//       {propType === "token" ? (
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "row",
//             gap: "8px",
//             width: "100%",
//           }}
//         >
//           <Box
//             sx={{
//               width: "100%",
//               background: "rgba(2, 121, 0, 0.10)",
//               padding: "12px 16px",
//               overflow: "hidden", // Prevent overflow
//               whiteSpace: "normal", // Allow wrapping
//               textOverflow: "ellipsis", // Add ellipsis if text is too long
//             }}
//           >
//             <Typography
//               fontSize={"16px"}
//               fontFamily={"mySecondFont"}
//               color={value ? "#027900" : "rgba(47, 47, 47, 0.50)"}
//               textAlign={"start"}
//               noWrap={false} // Ensure wrapping is allowed
//             >
//               {value || "Click on generate to view token"}
//             </Typography>
//           </Box>
//         </Box>
//       ) : (
//         <Box>
//           <Typography
//             fontSize={"16px"}
//             fontFamily={"mySecondFont"}
//             color={"#2F2F2F"}
//             textAlign={"start"}
//             sx={{ textWrap: "wrap" }}
//           >
//             {value}
//           </Typography>
//         </Box>
//       )}
//     </Box>
//   );
// };

// const HandshakeRightBox = ({networkDetail}) => {
//   const dispatch = useDispatch();
//   const [ocpiUrl, setOcpiUrl] = useState("");
//   const [tokenA, setTokenA] = useState("");
//   const [showVersionBox, setShowVesionBox] = useState(false);
//   const [operatorId, setOperatorId] = useState(null);
//   const [selectedOptions, setSelectedOptions] = useState({
//     option1: false,
//     option2: false,
//   });
//   const [selectedVersion, setSelectedVersion] = useState("");

//   const { versionsAfterTokenA, tokenB, loading, error } = useSelector(
//     (state) => state.ocpi
//   );
//   console.log("qyyyyy23333", versionsAfterTokenA);

//   useEffect(() => {
//     const extractIdFromUrl = () => {
//       const url = window.location.pathname;
//       const parts = url.split("/");
//       return parts[parts.length - 1];
//     };

//     const id = extractIdFromUrl();
//     if (id) {
//       setOperatorId(id);
//     }
//   }, []);

//   const handleOptionChange = (event) => {
//     setSelectedOptions({
//       ...selectedOptions,
//       [event.target.name]: event.target.checked,
//     });
//   };

//   const handleVersionChange = (event) => {
//     setSelectedVersion(event.target.value);
//   };

//   const handleSubmit = () => {
//     // Convert ocpiUrl to string
//     const formattedOcpiUrl = String(ocpiUrl).trim();
  
//     // Validate URL
//     try {
//       new URL(formattedOcpiUrl); // This will throw an error if the URL is invalid
//     } catch (e) {
//       // alert("Please enter a valid OCPI URL.");
//       toast.error("Please enter a valid OCPI URL.")
//       return; 
//     }
  
//     console.log("OCPI URL:", formattedOcpiUrl);
//     console.log("Token A:", tokenA);
//     setShowVesionBox(true);
  
//     dispatch(fetchOcpiVersionForTokenA({ ocpiUrl: formattedOcpiUrl, tokenA }))
//       .unwrap()
//       .then(() => {
//         alert("Form submitted successfully");
//       })
//       .catch((error) => {
//         console.error("Error during the request:", error);
//         alert("Error submitting the form");
//       });
//   };
  

//   const handleGenerateTokenB = () => {
//     const selectedRoles = [];
//     if (selectedOptions.option1) selectedRoles.push("CPO");
//     if (selectedOptions.option2) selectedRoles.push("EMSP");
  
//     const version = selectedVersion || "2.2.1"; 
  

//     const formattedOcpiUrl = String(ocpiUrl).trim();
  
//     dispatch(getOcpiTokenB({ operatorId, roles: selectedRoles, version, tokenA, url: formattedOcpiUrl }))
//     .unwrap()
//     .then(() => {
//       toast.success("Registeration Unsuccessful");
//     })
//     .catch((error) => {
//       console.error("Error generating Token B:", error);
//       toast.error("Registeration Successful");
//     });
//   };
  

//   const isFormValid = ocpiUrl.trim() !== "" && tokenA.trim() !== "";
//   const canGenerateToken =
//     (selectedOptions.option1 || selectedOptions.option2) && selectedVersion;

//   // Map the dynamic versionsList to version options
//   const versionOptions = 
//   versionsAfterTokenA && versionsAfterTokenA.data
//     ? versionsAfterTokenA.data.map((versionObj) => ({
//         version: versionObj.version,
//       }))
//     : [];

//   return (
//     <Box sx={{ background: "#fff", borderRadius: "8px", width: "100%" }}>
//       <Grid container columnSpacing={2}>
//         <Grid item md={12} xs={12}>
//           <Box
//             sx={{
//               flexGrow: 1,
//               display: "flex",
//               flexDirection: "column",
//               gap: "24px",
//             }}
//           >
//             <Box
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 borderRadius: "8px",
//               }}
//             >
//               <Box>
//                 <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                   <Box
//                     sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                   >
//                     <img src="/images/ev_charger_icon.png" alt="" />
//                     <Heading text={`Register with ${networkDetail?.name}`} />
//                   </Box>
//                 </Box>
//                 <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
//               </Box>
//               <Box
//                 sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
//               >
//                 <InputField
//                   label="OCPI URL"
//                   placeholder="Enter OCPI URL here"
//                   value={ocpiUrl}
//                   onChange={(e) => setOcpiUrl(e.target.value)}
//                   name="ocpiUrl"
//                 />
//                 <InputField
//                   label="Token A"
//                   placeholder="Enter Token A here"
//                   value={tokenA}
//                   onChange={(e) => setTokenA(e.target.value)}
//                   name="tokenA"
//                 />
//                 <Box sx={{ display: "flex", justifyContent: "right" }}>
//                   <Button
//                     onClick={handleSubmit}
//                     disabled={!isFormValid}
//                     sx={{
//                       borderRadius: "4px",
//                       padding: "12px 24px",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       background: "#E50000",
//                       opacity: !isFormValid ? "0.25" : "1",
//                       width: "160px",
//                       color: "#fff",
//                       fontFamily: "'Montserrat', sans-serif",
//                       fontSize: "14px",
//                       fontWeight: "600",
//                       "&:hover": {
//                         backgroundColor: isFormValid ? "#c70000" : "#E50000",
//                       },
//                       "&.Mui-disabled": {
//                         backgroundColor: "#E50000",
//                         color: "#fff",
//                       },
//                     }}
//                   >
//                     Proceed
//                   </Button>
//                 </Box>
//               </Box>

//               {/* //Version Box */}
//               {showVersionBox ? (
//                 <Box>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                       gap: "8px",
//                       // justifyContent: "space-between",
//                       alignItems: "flex-start",
//                       padding: "24px 0",
//                     }}
//                   >
//                     <Box>
//                       <InfoBox
//                         label={
//                           "Please select at least one option to generate a new OCPI token"
//                         }
//                       />
//                       <Box
//                         sx={{
//                           display: "flex",
//                           flexDirection: "row",
//                           justifyContent: "start",
//                           alignItems: "center",
//                           gap: "16px",
//                         }}
//                       >
//                         <label>
//                           <Checkbox
//                             name="option1"
//                             checked={selectedOptions.option1}
//                             onChange={handleOptionChange}
//                           />
//                           CPO
//                         </label>
//                         <label>
//                           <Checkbox
//                             name="option2"
//                             checked={selectedOptions.option2}
//                             onChange={handleOptionChange}
//                           />
//                           eMSP
//                         </label>
//                       </Box>
//                     </Box>

//                     <Box>
//                       <Typography
//                         textAlign={"left"}
//                         fontSize={"14px"}
//                         fontFamily={"myThirdFont"}
//                         color="#2F2F2F"
//                       >
//                         Versions Dropdown for OCPI
//                       </Typography>
//                       <Select
//                         value={selectedVersion}
//                         onChange={handleVersionChange}
//                         displayEmpty
//                         sx={{
//                           width: "232px",
//                           height: "40px",
//                           color: "#1C1C1C",
//                           fontFamily: "myThirdFont",
//                         }}
//                       >
//                         <MenuItem value="" disabled>
//                           Versions
//                         </MenuItem>
//                         {versionOptions.map((org) => (
//                           <MenuItem key={org.version} value={org.version}>
//                             {org.version}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </Box>
//                   </Box>
//                   <Box
//                     sx={{ display: "flex", justifyContent: "left", mt: "24px" }}
//                   >
//                     <Button
//                       onClick={handleGenerateTokenB}
//                       disabled={!canGenerateToken}
//                       sx={{
//                         borderRadius: "4px",
//                         padding: "12px 24px",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         background: "#E50000",
//                         opacity: !canGenerateToken ? "0.25" : "1",
//                         width: "250px",
//                         color: "#fff",
//                         fontFamily: "'Montserrat', sans-serif",
//                         fontSize: "14px",
//                         fontWeight: "600",
//                         "&:hover": {
//                           backgroundColor: canGenerateToken
//                             ? "#c70000"
//                             : "#E50000",
//                         },
//                         "&.Mui-disabled": {
//                           backgroundColor: "#E50000",
//                           color: "#fff",
//                         },
//                       }}
//                     >
//                      Submit
//                     </Button>
//                   </Box>
//                 </Box>
//               ) : null}
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default HandshakeRightBox;
