import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Connectors from "./Connectors/Connectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchChargingPointConnectors } from "../../redux/action/chargingPointSlice/chargingPointSlice";

const ConnectorsBox = ({ data, chargingPointId }) => {
  const [connectorId, setConnectorId] = useState(null);
  const { connectors, loading, error } = useSelector(
    (state) => state.chargingPoints
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // Extract organization ID from URL
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setConnectorId(id);
    }
  });

  // Handler for refreshing data
  const handleRefresh = () => {
    if (chargingPointId) {
      dispatch(fetchChargingPointConnectors(chargingPointId));
    }
  };

  if (!data) {
    return <Typography>No data available</Typography>;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleRefresh}
          sx={{
            padding: "8px 16px",
            border: "1px solid #c0c0c0",
            borderRadius: "4px",
            color: "#292D33",
            fontFamily: "mySecondFont",
            textTransform: "capitalize",
          }}
        >
          Refresh{" "}
          <span
            style={{ paddingLeft: "4px" }}
            className="material-symbols-outlined"
          >
            cached
          </span>
        </Button>
      </Box>
      {data?.map((item) => {
        return (
          <Connectors
            item={item}
            loading={loading}
            chargingPointId={chargingPointId}
          />
        );
      })}
    </Box>
  );
};

export default ConnectorsBox;
