import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const SkeletonTotalSession = () => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        minHeight: "100px",
        width: "100%",
      }}
    >
      {/* Left Vertical Bar Skeleton */}
      <Box
        sx={{
          width: "24px",
          backgroundColor: "rgba(76, 175, 80, 0.5)", // Green color for the bar
          borderRadius: "4px",
          marginRight: "16px",
        }}
      />

      {/* Main Content Skeleton */}
      <Box sx={{ flex: 1 }}>
        {/* Total Sessions Skeleton */}
        <Skeleton
          variant="text"
          width="100px"
          height="20px"
          sx={{ marginBottom: "16px" }}
          animation="wave"
        />

        {/* Each Session Type Row Skeleton */}
        <Stack spacing={2}>
          {[...Array(5)].map((_, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* Left Side (Icon + Label) */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* Colored Circle Skeleton */}
                <Skeleton
                  variant="circular"
                  width={16}
                  height={16}
                  animation="wave"
                  sx={{ marginRight: "8px" }}
                />
                {/* Session Label Skeleton */}
                <Skeleton
                  variant="text"
                  width="100px"
                  height="20px"
                  animation="wave"
                />
              </Box>

              {/* Right Side (Count + Percentage Change) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                {/* Session Count Skeleton */}
                <Skeleton
                  variant="text"
                  width="30px"
                  height="20px"
                  animation="wave"
                />
                {/* Percentage Change Skeleton */}
                <Skeleton
                  variant="text"
                  width="50px"
                  height="20px"
                  animation="wave"
                />
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default SkeletonTotalSession;
