import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import { useLocation } from "react-router-dom";

const SessionsBox = ({ columns, rows, isRowClickable, loading }) => {
  const [filteredSessionList, setFilteredSessionList] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const location = useLocation();
  const hubSession = location.pathname.includes("/hub_management");

  useEffect(() => {
    if (rows.length > 0) {
      setFilteredSessionList(rows);
    }
  }, [rows]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredSessionList(filteredData);
  };

  const myFilters = [
    {
      value: "_id",
      title: "Session ID",
    },
    {
      value: "deviceId",
      title: "Device ID",
    },
    {
      value: "energyConsumed",
      title: "Energy Consumed",
    },
    {
      value: "chargingAmount",
      title: "Charging Amount",
    },
    {
      value: "sessionStart",
      title: "Sort by Start Time",
    },
    {
      value: "sessionStop",
      title: "Sort by End Time",
    },
  ];

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters
          data={rows}
          onFilterChange={handleFilterChange}
          filters={myFilters}
        />
      </Box>

      <CustomTable
        columns={columns}
        rows={filteredSessionList}
        isRowClickable={isRowClickable}
        clickableColumns={["_id"]}
        columnClickPaths={{
          _id: hubSession
            ? "/hub_management/charging_sessions"
            : "/charging_stations/charging_sessions",
        }}
        columnKeyMappings={{
          _id: "_id",
        }}
        modalHeading={"Sessions"}
        loading={loading}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default SessionsBox;
