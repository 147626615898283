import {
  Backdrop,
  Box,
  Button,
  Divider,
  MenuItem,
  Modal,
  Select,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getAssignedOcpiEvses,
  getEvseOfOneLocation,
  getOcpiAddAndUpdateOcpiChargers
} from "../../../redux/action/ocpiManagement/ocpiSlice";
import {
  ocpiAddNewModalColumns
} from "../../../utils/DummyData";
import Heading from "../../../utils/Heading";
import CustomTransition from "../../custom/CustomTransition";
import OcpiCustomTable from "../../custom/OcpiCustomTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1000px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
  height: "auto",
  maxHeight: "90vh",
  overflowY: "auto",
};

const dummyOrganizations = [
  { name: "Station A" },
  { name: "Station B" },
  { name: "Station C" },
  { name: "Station D" },
  { name: "Station E" },
];

const OcpiAddNewModal = ({
  open,
  handleClose,
  heading,
  checkUpdate,
  locations,
  operatorId,
  onUpdateSuccess,
  data,
  evsesEditData,

  defaultTariffs,
  assignedEvses,
  newData,
}) => {
  console.log("lmkjnhb", newData);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false); // State for Update button
  const [evseData, setEvseData] = useState([]);

  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);

  const [selectedTariffs, setSelectedTariffs] = useState({});


  //  // Reset state when modal opens/closes
  //  useEffect(() => {
  //   if (open) {
  //     // If we have newData (editing mode), initialize selectedTariffs
  //     if (newData?.length) {
  //       const tariffMap = {};
  //       newData.forEach(item => {
  //         tariffMap[item.evseId] = item.tariffId;
  //       });
  //       setSelectedTariffs(tariffMap);
  //     }
  //   } else {
  //     resetStateOnCancel();
  //   }
  // }, [open, newData]);




  const dispatch = useDispatch();

  const resetState = () => {
    setSelectedLocation("");
    setSelectedRows([]);
    setIsUpdateButtonEnabled(false);
    setEvseData([]);
  };

  const handleChange = (event) => {
    const locationId = event.target.value; // Get the selected location ID
    setSelectedLocation(locationId);
    console.log("Selected Location ID:", locationId, operatorId);

    dispatch(getEvseOfOneLocation({ locationId, operatorId }))
      .unwrap() // Optionally unwrap the result to handle success and error directly
      .then((EvseData) => {
        console.log("EVSE Data:", EvseData); // Handle the fetched data
        setEvseData(EvseData); // Update state with fetched EVSE data
      })
      .catch((error) => {
        console.error("Error fetching EVSE data:", error); // Handle the error
      });
  };

  useEffect(() => {
    if (open && checkUpdate) {
      // Get the selected location ID

      dispatch(
        getEvseOfOneLocation({ locationId: evsesEditData?._id, operatorId })
      )
        .unwrap()
        .then((EvseData) => {
          console.log("EVSE Data:", EvseData);
          setEvseData(EvseData);
        })
        .catch((error) => {
          console.error("Error fetching EVSE data:", error);
        });
    }
  }, [open, checkUpdate, operatorId, selectedLocation, dispatch]);

  // const handleCancel = () => {
  //   setSelectedLocation(""); // Reset the selected organization on cancel
  //   handleClose();
  // };
  const handleCancel = () => {
    resetStateOnCancel(); // Reset the form state on cancel
    handleClose(); // Close the modal
  };

  const handleRowSelectionChange = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
    // Enable the update button if at least one row is selected
    setIsUpdateButtonEnabled(newSelectedRows.length > 0);

  };



  const handleUpdate = () => {
    // Create an array of objects with evseId and tariffId
    const evseData = selectedRows.map((row) => {
      const deviceId = row.deviceId; // Get the deviceId from the row
      // const tariffId = selectedTariffs[deviceId];

      // const tariffId = selectedTariffs[deviceId] || "";
      const tariffId = selectedTariffs[deviceId] || defaultTariffs.find(t => t.isDefault)?.id || "";
      return { evseId: deviceId, tariffId: tariffId }; // Create the object structure
    });

    console.log("Selected EVSE Data:", evseData); // Log the newly structured data

    // Prepare the data for the API call
    const payload = {
      operatorId,
      stationId: checkUpdate ? evsesEditData?._id : selectedLocation,
      evseIds: evseData, // Use the new structure here
    };

    // Dispatch the thunk to add/update OCPI chargers
    dispatch(getOcpiAddAndUpdateOcpiChargers(payload))
      .unwrap()
      .then((response) => {
        console.log("Update Response:", response);
        evsesEditData
          ? toast.success("OCPI Evses updated successfully!")
          : toast.success("OCPI evses added successfully!");
        if (onUpdateSuccess) {
          onUpdateSuccess();
        }
      })
      .catch((error) => {
        console.error("Error updating OCPI evses:", error);
        toast.error("Failed to update OCPI evses. Please try again.");
      });

    dispatch(getAssignedOcpiEvses(operatorId));

    resetState();
    handleClose();
  };

  const handleTariffChange = (deviceId, tariffId) => {
    setSelectedTariffs((prev) => ({
      ...prev,
      [deviceId]: tariffId, // Update the tariff for the specific deviceId
    }));
  };

  

  const resetStateOnCancel = () => {
    setSelectedLocation(""); // Reset selected location
    setSelectedRows([]); // Clear selected rows
    setIsUpdateButtonEnabled(false); // Disable update button
    setEvseData([]); // Clear EVSE data
    setSelectedDeviceIds([]); // Clear selected device IDs
    setSelectedTariffs({}); // Clear selected tariffs
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src="/images/tariff-icon.png" alt="" />
              <Heading text={"Add New OCPI"} />
            </Box>
            <Box sx={{ display: "flex" }}>
              <span
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                className="material-symbols-outlined"
              >
                cancel
              </span>
            </Box>
          </Box>
          <Divider
            sx={{ background: "#ddd", margin: "8px 0px", marginBottom: "24px" }}
          />
          {checkUpdate ? (
            <Box sx={{ mb: "12px" }}>
              <Typography
                textAlign={"start"}
                fontSize={"14px"}
                fontFamily={"myThirdFont"}
                color="#2F2F2F"
              >
                Station Name
              </Typography>
              <Typography
                textAlign={"start"}
                fontSize={"16px"}
                fontFamily={"myFirstFont"}
                color="#2F2F2F"
              >
                {evsesEditData?.name}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                marginBottom: "16px",
              }}
            >
              <Typography
                textAlign={"left"}
                fontSize={"14px"}
                fontFamily={"myThirdFont"}
                color="#2F2F2F"
              >
                Select the station to be added
              </Typography>
              <Select
                value={selectedLocation}
                onChange={handleChange}
                displayEmpty
                sx={{
                  width: "232px",
                  height: "40px",
                  color: "#1C1C1C",
                  fontFamily: "myThirdFont",
                }}
              >
                <MenuItem value="" disabled>
                  Stations
                </MenuItem>
                {/* {locations.map((location) => (
                <MenuItem key={location._id} value={location._id}>
                  {location.name}
                </MenuItem>
              ))} */}
                {locations
                  .filter((location) => !location.isActive) // Filter for inactive locations
                  .map((location) => (
                    <MenuItem key={location._id} value={location._id}>
                      {location.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          )}

          {/* Fixed height for the table container */}
          <Box
            sx={{
              flex: 1,
              maxHeight: "360px",
              overflowY: "auto",
              backgroundColor: "rgba(41, 45, 51, 0.05)",
              padding: "16px",
            }}
          >
            <OcpiCustomTable
              columns={ocpiAddNewModalColumns}
              // rows={ocpiAddNewModalData}
              rows={evseData}
              // isRowClickable={true}
              // rowClickPath={"/charging_stations"}
              clickableColumns={["deviceId"]}
              columnClickPaths={{
                deviceId: "/charging_stations",
              }}
              columnKeyMappings={{
                deviceId: "deviceId",
              }}
              statsHeading={"false"}
              showCheckbox={true}
              onRowSelectionChange={handleRowSelectionChange}
              modalHeading={"OCPI Stations"}
              //default tariffs list
              tariffIds={defaultTariffs}
              onTariffChange={handleTariffChange}
              //fetch evses tariffs
              // evseData={evseData}
              newData={newData}
            />
          </Box>

          <Box sx={{ mt: "16px", display: "flex", gap: "8px", width: "100%" }}>
            <Button
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: isUpdateButtonEnabled ? "#E50000" : "#ccc",
                width: "25%",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: isUpdateButtonEnabled ? "#E50000" : "#ccc",
                },
              }}
              disabled={!isUpdateButtonEnabled}
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button
              onClick={handleCancel}
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default OcpiAddNewModal;
