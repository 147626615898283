import * as React from "react";
import CustomTransition from "../custom/CustomTransition";
import Heading from "../../utils/Heading";
import {
  Backdrop,
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  Divider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  fetchAdminOrgs,
  fetchAdminRoles,
  fetchAllUsers,
  updateUser,
} from "../../redux/action/adminManagement/adminSlice";
import { toast } from "react-toastify";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Import the default styles

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const AddNewUserModal = ({ open, handleClose, heading, data }) => {
  const dispatch = useDispatch();
  const { roles, users, orgs, loading, error } = useSelector(
    (state) => state.admin
  );

  // Fetch users on component mount
  React.useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const [formData, setFormData] = React.useState({
    name: "",
    org: "",
    phoneNumber: "",
    email: "",
    role: "",
    active: false,
  });

  React.useEffect(() => {
    dispatch(fetchAdminRoles());
    dispatch(fetchAdminOrgs());
  }, [dispatch]);

  React.useEffect(() => {
    if (data) {
      setFormData({
        name: data?.name || "",
        // org: data.org || "",
        org: data.org?._id || "", // Extracting the _id from org object
        phoneNumber: data.phoneNumber || "",
        email: data.email || "",
        role: data.role || "",
        active: data.active ?? true,
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateFormData = (formData) => {
    const requiredFields = [
      "name",
      "org",
      "phoneNumber",
      "email",
      "role",
      "active",
    ];

    for (const field of requiredFields) {
      if (
        formData[field] === undefined ||
        formData[field].toString().trim() === ""
      ) {
        toast.error(
          `Please fill in the ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`
        );
        return false;
      }
    }

    const phoneNumber = formData.phoneNumber.toString().trim();
    // Simple regex check to ensure it starts with + and contains numbers
    const phoneNumberPattern = /^\+\d+$/;

    if (!phoneNumberPattern.test(phoneNumber)) {
      toast.error("Please enter a valid phone number with country code");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return false;
    }

    return true;
  };

  const handleOpenAddButton = async () => {
    if (!validateFormData(formData)) {
      return; // Stop the submit process if validation fails
    }

    // Check if the phone number already exists
    const phoneNumber = users.some(
      (user) =>
        user.phoneNumber.toLowerCase() === formData.phoneNumber.toLowerCase() &&
        user._id !== data?._id
    );

    if (phoneNumber) {
      toast.error("A user with this phone number already exists.");
      return; // Stop the submit process if the email already exists
    }

    // Check if the email already exists
    const emailExists = users.some(
      (user) =>
        user.email.toLowerCase() === formData.email.toLowerCase() &&
        user._id !== data?._id
    );

    if (emailExists) {
      toast.error("A user with this email already exists.");
      return; // Stop the submit process if the email already exists
    }

    try {
      if (data && data._id) {
        // Call the updateUser API
        await dispatch(
          updateUser({ userId: data._id, userData: formData })
        ).unwrap();
        toast.success("Admin updated successfully.");
      } else {
        // Call the createUser API
        await dispatch(createUser(formData)).unwrap();
        toast.success("Admin created successfully.");
      }
      dispatch(fetchAllUsers()); // Fetch the updated list of users
      handleClose(); // Close the form or modal
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };
  const organizationMenuItems = orgs?.map((item) => ({
    value: item?._id,
    label: item?.name,
  }));

  const handleCancel = () => {
    // Reset form data
    setFormData({
      name: "",
      org: "",
      phoneNumber: "",
      email: "",
      role: "",
      active: false,
    });
    // Close the modal
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        TransitionComponent={CustomTransition}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* Close Button */}
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="material-symbols-outlined"
            >
              cancel
            </span>
          </Box>

          {/* Heading - Modal */}
          <Box sx={{ marginBottom: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src="/images/add-new-user.png" alt="" />
              <Heading text={data ? "Edit User details" : "Add new User"} />
            </Box>
            <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
          </Box>

          <Box>
            <Grid container spacing={2}>
              {/* First Row: 6-6 */}
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  User Name
                </label>
                <TextField
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "48px",
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    padding: "0",
                  }}
                  placeholder="Enter the name here"
                  fullWidth
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Organization
                </label>
                <FormControl fullWidth>
                  <Select
                    name="org"
                    value={formData.org}
                    onChange={handleChange}
                    menuItems={organizationMenuItems}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      color: "#1C1C1C",
                      fontFamily: "myThirdFont",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    {orgs.map((item) => {
                      return (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ mt: "24px" }} />
            <Grid container spacing={2}>
              {/* Second Row: 6-6 */}
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Contact Number
                </label>
                {/* <TextField
                  type="number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },

                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">call</span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter the number here"
                  fullWidth
                /> */}

                <PhoneInput
                  international
                  defaultCountry="IN"
                  className={"input-phone-number"}
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      phoneNumber: value,
                    }))
                  }
                  placeholder="Enter the number here"
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    position: "relative",
                    width: "100%",
                    height: "48px",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                    padding: "0 12px",
                    boxSizing: "border-box",
                    backgroundColor: "#fff",
                  }}
                  // inputClass={{
                  //   background: "lightblue",
                  //   margin: "12px 24px",
                  // }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Email
                </label>
                <TextField
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    position: "relative",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">mail</span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter the email here"
                  fullWidth
                  type="email"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: "24px" }} />
            <Grid container spacing={2}>
              {/* Third Row: 4-4-4 */}

              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Admin Profile
                </label>
                <FormControl fullWidth>
                  <Select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    defaultValue=""
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      color: "#1C1C1C",
                      fontFamily: "myThirdFont",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    <MenuItem value="">Select the role</MenuItem>
                    {roles?.data?.length > 0 &&
                      roles.data[0].subroles?.map((role) => (
                        <MenuItem key={role._id} value={role.role}>
                          {role.role}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Status
                </label>
                <FormControl fullWidth>
                  <Select
                    defaultValue="active"
                    name="active"
                    value={formData.active}
                    onChange={handleChange}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      color: "#1C1C1C",
                      fontFamily: "myThirdFont",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Cancel Proceed Button */}
          <Box sx={{ mt: "36px", display: "flex", gap: "8px", width: "100%" }}>
            <Button
              onClick={handleOpenAddButton}
              sx={{
                borderRadius: "4px",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E50000",
                width: "25%",
                color: "#fff",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                ":hover": {
                  background: "#E50000",
                },
              }}
            >
              {data ? "Update" : "Add User"}
            </Button>
            <Button
              sx={{
                borderRadius: "4px",
                border: "1px solid #292D33",
                padding: "8px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
                color: "#292D33",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "14px",
                fontWeight: "600",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddNewUserModal;
