import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeader from "../../components/dashboard/TopHeader";
import AlertBox from "../../components/dashboard/AlertBox";
import TopGraphBox from "../../components/dashboard/TopGraphBox";
import TopPerformStationBox from "../../components/dashboard/TopPerformStationBox";
import AcDcBox from "../../components/dashboard/AcDcBox";
import DeviceStatus from "../../components/dashboard/DeviceStatus";
import TotalSession from "../../components/dashboard/TotalSession";
import AppStats from "../../components/dashboard/AppStats";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCustomers,
  fetchDashboardData,
  fetchLiveDeviceStatus,
  fetchStopReasonDashboardData,
} from "../../redux/action/dashboard/dashboardSlice";
import ValueCardBox from "../../components/dashboard/ValueCardBox";
import {
  fetchChargingPointStats,
  fetchStationStats,
  fetchWalletDetails,
} from "../../redux/action/statistics/statisticsSlice";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";
import TopPerformingSessionEnergy from "../../components/dashboard/TopPerformingSessionEnergy";
import TopPerformingSessionDuration from "../../components/dashboard/TopPerformingSessionDuration";
import SkeletonTotalSession from "../../components/Skeleton/SkeletonTotalSession";
import DeviceStatusSkeleton from "../../components/Skeleton/DeviceStatusSkeleton";
import TopPerformingSkeleton from "../../components/Skeleton/TopPerformingSkeleton";
import AcDcBoxSkeleton from "../../components/Skeleton/AcDcBoxSkeleton";
import SkeletonAppStats from "../../components/Skeleton/SkeletonAppStats";
import ProgressBar from "../../components/Graphs/ProgressBar";
import StopReasonBox from "../../components/dashboard/StopReasonBox";

const Dashboard = () => {
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const { profile } = useSelector((state) => state.profile);

  const navigate = useNavigate();
  useScrollToTop(navigate);

  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const [currentDashboardData, setCurrentDashboardData] = useState(null);
  const [currentChargingPointStats, setCurrentChargingPointStats] =
    useState(null);
  const [currentCustomerData, setCurrentCustomerData] = useState(null);
  const [currentWalletDetails, setCurrentWalletDetails] = useState(null);

  const dispatch = useDispatch();
  const {
    data,
    customers,
    liveDeviceStatus,
    status,
    error,
    loading,
    loadingCurrentDashboard,
    loadingPreviousDashboard,

    stopReasonData

    
  } = useSelector((state) => state.dashboard);

  const isLoading = loadingCurrentDashboard || loadingPreviousDashboard;

  const { stationStats, chargingPointStats } = useSelector(
    (state) => state.statistics
  );

  const { walletDetails } = useSelector((state) => state.statistics);

  console.log("kkhhh", stopReasonData)

  // UseEffect for Time Filter to get the data of Current Time
  useEffect(() => {
    const fetchData = async () => {
      if (durationType && startTime && endTime) {
        try {
          // Fetch and handle data
          const dummyData = await dispatch(
            fetchDashboardData({
              durationType,
              startTime,
              endTime,
              type: "current",
            })
          ).unwrap();

          setCurrentDashboardData(dummyData);

          const customerData = await dispatch(
            fetchAllCustomers({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          setCurrentCustomerData(customerData);

          await dispatch(
            fetchStationStats({
              durationType,
              startTime,
              endTime,
            })
          );

          const chargingPointData = await dispatch(
            fetchChargingPointStats({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();

          setCurrentChargingPointStats(chargingPointData);

          const walletDummyData = await dispatch(
            fetchWalletDetails({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();
          setCurrentWalletDetails(walletDummyData);

          await dispatch(fetchLiveDeviceStatus());
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    };

    fetchData();
  }, [durationType, startTime, endTime, dispatch]);

  useEffect(()=>{
    if (durationType && startTime && endTime){
      dispatch(fetchStopReasonDashboardData({
        durationType,
        startTime,
        endTime
      }))
    }
  }, [durationType, startTime, endTime, dispatch])

  // UseEffect for Time Filter to get the data of Previous Time
  useEffect(() => {
    if (durationType && prevStartTime && prevEndTime) {
      dispatch(fetchAllCustomers());

      dispatch(
        fetchStationStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );


      dispatch(
        fetchWalletDetails({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );

      dispatch(fetchLiveDeviceStatus());
    }
  }, [durationType, prevStartTime, prevEndTime, dispatch]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentDashboardData !== null
    ) {
      dispatch(
        fetchDashboardData({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
          type: "previous",
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentDashboardData,
  ]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentChargingPointStats !== null
    ) {
      dispatch(
        fetchChargingPointStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentChargingPointStats,
  ]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentCustomerData !== null
    ) {
      dispatch(
        fetchAllCustomers({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [durationType, prevStartTime, prevEndTime, dispatch, currentCustomerData]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentWalletDetails !== null
    ) {
      dispatch(
        fetchWalletDetails({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [
    durationType,
    prevStartTime,
    prevEndTime,
    dispatch,
    currentWalletDetails,
  ]);

  return (
    <>
      {/* Top header */}
      <TopHeader
        setDurationType={setDurationType}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setPrevStartTime={setPrevStartTime}
        setPrevEndTime={setPrevEndTime}
      />

      {/* dashboard */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Top Graph and alert box */}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <TopGraphBox
                //Current data
                data={currentDashboardData}
                loading={isLoading}
                //Previous data
                previousDashboardData={data}
                selectedTime={durationType}
                startTime={startTime}
                endTime={endTime}
                hubSession={true}
              />
            </Box>
          </Grid>
          <Grid item md={3} sx={{ display: "flex", flexDirection: "column" }}>
            {profile?.role === "CPO_Admin" ? (
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <AlertBox />
              </Box>
            ) : (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <ValueCardBox
                  //Current data
                  data={currentDashboardData}
                  //Previous data
                  previousDashboardData={data}
                  //Customers Data
                  customerData={currentCustomerData}
                  previousCustomerData={customers}
                  selectedTime={durationType}
                  customers={customers}
                  walletData={currentWalletDetails}
                  previousWalletData={walletDetails}
                  cardTypeProp={"dashboard"}
                  // hubData={data}
                  hubSession={true}
                  loading={isLoading}
                />
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Device Status and Cards */}
        <Grid
          container
          columnSpacing={"16px"}
          rowSpacing={"16px"}
          sx={{ height: "100%" }}
        >
          <Grid item md={3.5} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <ValueCardBox
                //Current data
                data={currentDashboardData}
                //Previous data
                previousDashboardData={data}
                //Customers Data
                customerData={currentCustomerData}
                previousCustomerData={customers}
                selectedTime={durationType}
                customers={customers}
                walletData={currentWalletDetails}
                previousWalletData={walletDetails}
                cardTypeProp={"dashboard"}
                loading={isLoading}
              />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {isLoading ? (
                <SkeletonTotalSession /> // Show Skeleton when loading is true
              ) : (
                <TotalSession //Current data
                  data={currentDashboardData}
                  //Previous data
                  previousDashboardData={data}
                  statsType="dashboardStats"
                  selectedTime={durationType}
                  loading={isLoading}
                />
              )}
            </Box>
          </Grid>
          <Grid item md={4.5} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {isLoading ? (
                <DeviceStatusSkeleton /> // Show Skeleton when loading is true
              ) : (
                <DeviceStatus data={liveDeviceStatus} />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* Top Performing Stations and App stats*/}
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {isLoading ? (
                <TopPerformingSkeleton /> // Show Skeleton when loading is true
              ) : (
                <TopPerformStationBox
                  topPerformerStation={
                    currentDashboardData?.topPerformerStations_Energy || []
                  }
                  type={"energy"}
                />
              )}
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {/* <DoughnutChart data={walletRechargeData} centerData="true" donut_type="dashboard" /> */}
              {isLoading ? (
                <TopPerformingSkeleton /> // Show Skeleton when loading is true
              ) : (
                <TopPerformStationBox
                  topPerformerStation={
                    currentDashboardData?.topPerformerStations_Duration || []
                  }
                  type={"duration"}
                />
              )}
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {isLoading ? (
                <AcDcBoxSkeleton /> // Show Skeleton when loading is true
              ) : (
                <AcDcBox
                  chargingPoints={currentDashboardData?.fastSlowData || {}}
                  previousChargingPoints={data?.fastSlowData || {}}
                  selectedTime={durationType}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {isLoading ? (
                <TopPerformingSkeleton /> // Show Skeleton when loading is true
              ) : (
                <TopPerformingSessionEnergy
                  topPerformData={currentDashboardData}
                  loading={isLoading}
                />
              )}
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {isLoading ? (
                <TopPerformingSkeleton /> // Show Skeleton when loading is true
              ) : (
                <TopPerformingSessionDuration
                  topPerformData={currentDashboardData}
                  loading={isLoading}
                />
              )}
            </Box>
          </Grid>

          <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {/* <AlertBox /> */}
              {isLoading ? (
                <SkeletonAppStats /> // Show Skeleton when loading is true
              ) : (
                <AppStats
                  // data={walletDetails}
                  customers={currentCustomerData}
                  // walletData={currentWalletDetails}
                  // previousWalletData={walletDetails}
                  selectedTime={durationType}
                  //Current data
                  data={currentDashboardData}
                  //Previous data
                  previousDashboardData={data}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={12} sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width:"100%" }}>
              {/* <AlertBox /> */}
              {isLoading ? (
                <SkeletonAppStats /> // Show Skeleton when loading is true
              ) : (
                <>
                  <Box sx={{ width:"100%"}}>
                    <StopReasonBox
                     data={stopReasonData}
                     selectedTime={durationType}
                     loading={isLoading}

                     startTime={startTime}
                     endTime={endTime}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
