import { useLocation } from "react-router-dom";
import { countryToCurrency } from "../currency/countryToCurrency";
import { currencySymbols } from "../currency/currencySymbols";

export const GetCurrencySymbol = () => {
  const location = useLocation();

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters
  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

  return currencySymbol; // Return the symbol directly, not JSX
};
