import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const LogsDetails = ({ logData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        width: "100%",
        background: "#fff",
        borderRadius: "8px",
        height: "calc(100vh - 332px)",
        overflowY: "auto", // Allows scrolling within this box
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <span className="material-symbols-outlined">code</span>
        <Heading text={"Log details"} />
      </Box>
      <Divider sx={{ mt: "8px", mb: "16px" }} />
      {logData ? (
        <Typography
          fontFamily={"myThirdFont"}
          fontSize={"14px"}
          textAlign={"start"}
        >
          {/* Display log details here. Example: */}
          <pre>{JSON.stringify(logData, null, 2)}</pre>
        </Typography>
      ) : (
        <Typography>No log selected</Typography>
      )}
    </Box>
  );
};

export default LogsDetails;
