import { Box } from "@mui/material";
import React from "react";
import MainHeading from "../../utils/MainHeading";

const TopBar = () => {
  return (
    <Box
      sx={{
        padding: "24px 32px",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "24px",
        marginLeft: "-32px",
        marginRight: "-24px",
        marginTop: "-22px",
      }}
    >
      <MainHeading text={"Tariff Management"} />
    </Box>
  );
};

export default TopBar;
