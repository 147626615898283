import React from 'react'
import ResetPasswordPage from '../../../components/auth/resetPasswordPage/ResetPasswordPage'
import { Box } from '@mui/material'

const ResetPassword = () => {
  return (
    <Box>
      <ResetPasswordPage/>
    </Box>
  )
}

export default ResetPassword
