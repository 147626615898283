//  localhost redirection
// sitekey="6Lfr8B8qAAAAAEUCc0eJVdFTx_vJ-C9P93S--7ZL"

import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, loginUserWithOtp } from "../../../redux/action/authSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { convertCountryRoute, MyRoutes } from "../../../routes/routes";
import { countryCodeToCountry } from "../../../utils/currency/countryCodeToCountry";
import { toast } from "react-toastify";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, user } = useSelector((state) => state.auth);
  const [credentials, setCredentials] = useState({
    email: "",
    stayLoggedIn: false,
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const getCountryCode = (phoneNumber) => {
    if (!phoneNumber) return ""; // Check if phoneNumber is defined
    const match = phoneNumber.match(/^(\+\d{1,2})/); // Match the country code
    return match ? match[1] : ""; // Return the country code or an empty string
  };

  useEffect(() => {
    if (user && user.userInfo?.jwtToken) {
      localStorage.setItem("token", user.userInfo.jwtToken);
      const countyCheck = user?.userInfo?.user?.phoneNumber;
      const countryCode = getCountryCode(countyCheck);

      // Use the countryCodeToCountry mapping to dynamically map the country code
      const countryCodeMapped =
        countryCodeToCountry[countryCode] || countryCode;
      console.log("Country Code Mapped:", countryCodeMapped);

      convertCountryRoute(countryCodeMapped);
      navigate(MyRoutes.dashboard);
    } else {
      // Clear the token from localStorage if there's no user
      // localStorage.removeItem("token");
      // Navigate to the login page if there's no user
      // navigate("/login");
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Dispatch the login action and wait for the result
    const resultAction = await dispatch(loginUserWithOtp(credentials));

    // Check if the login was successful
    if (loginUserWithOtp.fulfilled.match(resultAction)) {
      // Navigate only if login was successful
      toast.success("OTP Sent Successfully");
      navigate("/verify_email", {
        state: { loginWithOtpProp: true, email: credentials.email },
      });
    } else {
      // Display the error message
      console.error("Login failed:", resultAction.payload);
      toast.error(
        resultAction.payload?.message || "Login failed. Please try again."
      );
    }
  };

  //Recaptcha
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleForgotPasswordButton = () => {
    navigate("/reset_password");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url("/images/auth-bg-final.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "#FFFFFF",
            padding: "32px",
            borderRadius: "8px",
            height: "350px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Box sx={{ width: "100%", paddingBottom: "16px" }}>
              <img src="/images/1C-login-logo.png" alt="logo" />
            </Box>
            <Typography
              sx={{
                color: "#0C0C0C",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "32px",
              }}
            >
              Welcome to 1C CMS!
            </Typography>

            <Typography
              sx={{
                color: "#0C0C0C",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              Please login with your 1C CMS credentials
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  textAlign: "start",
                  marginBottom: "8px",
                  fontSize: "16px",
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                }}
              >
                Email
              </Typography>
              <input
                name="email"
                value={credentials.email}
                onChange={handleChange}
                variant="outlined"
                style={{
                  width: "416px",
                  height: "48px",
                  borderRadius: "4px",
                  border: "1px solid #CBCBCB",
                  outline: "none",
                  padding: "0 16px",
                  fontFamily: "myThirdFont",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                mt: "8px",
                mb: "12px",
                gap: "4px",
              }}
            >
              <Checkbox
                {...label}
                name="stayLoggedIn" // Ensure this matches your state
                checked={credentials.stayLoggedIn} // Use checked instead of value
                onChange={handleChange}
                sx={{
                  fontSize: "10px",
                  width: "16px",
                  height: "16px",
                  color: "#e50000", // Default color (unchecked)
                  "&.Mui-checked": {
                    color: "#e50000", // Color when checked
                  },
                  "&.MuiCheckbox-root": {
                    backgroundColor: "transparent", // To ensure no background when unchecked
                  },
                  "&.Mui-checked:before": {
                    backgroundColor: "#e50000", // Background when checked
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.1rem",
                  },
                }}
              />

              <Typography
                fontFamily={"mySecondFont"}
                color={"#333"}
                fontSize={"12px"}
              >
                STAY LOGIN
              </Typography>
            </Box>

            {/* <Box>
              <Typography
                sx={{
                  textAlign: "start",
                  marginBottom: "8px",
                  fontSize: "16px",
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                }}
              >
                Password
              </Typography>
              <input
                label="Password"
                type="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                variant="outlined"
                style={{
                  width: "416px",
                  height: "48px",
                  borderRadius: "4px",
                  border: "1px solid #CBCBCB",
                  outline: "none",
                  padding: "0 16px",
                }}
              />
              {error && (
                <Box sx={{ borderRadius: "5px" }}>
                  <Typography
                    color="error"
                    sx={{
                      padding: "4px 0px",
                      textTransform: "none",
                      fontFamily: "mySecondFont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      textAlign: "left",
                      marginTop: "8px",
                    }}
                  >
                    {error.message === "Unauthorized"
                      ? "Please enter both Email and Password"
                      : error.message}
                  </Typography>
                </Box>
              )}
            </Box> */}

            {/* <Box>
              <ReCAPTCHA
                //Production link redirection
                sitekey="6Ld9fDIqAAAAALYr4HxLZDuQsq7Ee4pEmYLg0EsO"
                onChange={handleRecaptchaChange}
              />
            </Box> */}
            <Box>
              <Button
                type="submit"
                sx={{
                  width: "100%",
                  background: "#000000",
                  padding: "12px 24px",
                  color: "#FCFCFC",
                  fontFamily: "mySecondFont",
                  justifyContent: "center",
                  alignItems: "center",
                  letterSpacing: "1.28px",
                  mt: "16px",

                  "&:hover": {
                    background: "#2F2F2F",
                  },
                }}
                disabled={loading}
              >
                {loading ? "Logging in..." : "Proceed"}
              </Button>

              {/* <Typography
                sx={{
                  padding: 0,
                  textTransform: "none",
                  background: "none",
                  color: "#E50000",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  textAlign: "right",
                  cursor: "pointer",

                  marginTop: "12px",
                }}
                // onClick={() => alert("forgot password")}
                onClick={handleForgotPasswordButton}
              >
                Forgot Password?
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
