import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TablePagination,
  Typography,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import StatusChip from "../Chips/StatusChip";
import ActionIcons from "../../utils/ActionIcons";
import { useDispatch } from "react-redux";
import { getOneOrg } from "../../redux/action/companyManagement/companyManagementSlice";
import {
  formatDateTime,
  formatTimestamp,
  FormatTimestamp,
} from "../../utils/FormatDateTime";
import PaymentModal from "../Modals/PaymentModal";
import ReviewModal from "../Modals/ReviewModal";
import CircularProgress from "@mui/material/CircularProgress";

export const DateTimeDisplay = ({ dateTime }) => {
  const date = new Date(dateTime);
  const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  }); // Format: hh:mm AM/PM

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        color={"#2F2F2F"}
        fontSize={"14px"}
        fontFamily={"mySecondFont"}
        textAlign={"start"}
      >
        {formattedDate}
      </Typography>
      <Typography
        color={"#666"}
        fontSize={"14px"}
        fontFamily={"myThirdFont"}
        textAlign={"start"}
      >
        {formattedTime}
      </Typography>
    </Box>
  );
};

const OcpiCustomTable = ({
  columns,
  rows,
  isRowClickable,
  actionType,
  clickableColumns = [],
  rowClickPath,
  columnClickPaths,
  onRowClick,
  selectedRow,
  columnKeyMappings,
  specialColumns = [],
  modalHeading,
  statsHeading,
  sortColumn,
  userRoleProp,
  loading,

  showCheckbox,
  onRowSelectionChange,
  tariffIds,
  onTariffChange,
  evseData,
  newData,
}) => {
  const newDataCode = newData?.map((item) => item.tariffId) || [];

  console.log("ffffff", newData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, setTableData] = React.useState(rows);
  const [selectedRowData, setSelectedRowData] = React.useState(null); // State for the selected row data
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //For check box selectedrows
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedCount, setSelectedCount] = React.useState(0);

  React.useEffect(() => {
    if (!sortColumn) {
      const sortedRows = [...rows].sort((a, b) => {
        // Handle missing timestamps
        const aSessionStart = a.sessionStart
          ? new Date(a.sessionStart).getTime()
          : 0;
        const aSessionStop = a.sessionStop
          ? new Date(a.sessionStop).getTime()
          : 0;
        const aCreatedAt = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const aTxnTime = a.txnTime ? new Date(a.txnTime).getTime() : 0;

        const bSessionStart = b.sessionStart
          ? new Date(b.sessionStart).getTime()
          : 0;
        const bSessionStop = b.sessionStop
          ? new Date(b.sessionStop).getTime()
          : 0;
        const bCreatedAt = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        const bTxnTime = b.txnTime ? new Date(b.txnTime).getTime() : 0;

        // Determine the latest timestamp for each row
        const aLatest = Math.max(
          aSessionStart,
          aSessionStop,
          aCreatedAt,
          aTxnTime
        );
        const bLatest = Math.max(
          bSessionStart,
          bSessionStop,
          bCreatedAt,
          bTxnTime
        );

        return bLatest - aLatest; // Sort in descending order
      });
      setTableData(sortedRows);
    } else {
      setTableData(rows);
    }
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the rows to be displayed on the current page
  const displayRows = tableData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  console.log("tableData", tableData);

  const handleRowClick = (row, rowIndex) => {
    if (!row) {
      console.error("Row is undefined or null");
      return;
    }

    if (modalHeading === "Payments") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setIsModalOpen(true);
    } else if (modalHeading === "Reviews") {
      // Set selected row data for modal
      setSelectedRowData(row);
      // Open the modal
      setIsModalOpen(true);
    } else {
      // Existing navigation logic
      const clickableColumnIds = clickableColumns.filter((col) => row[col]);

      if (clickableColumnIds.length === 1) {
        const column = clickableColumnIds[0];
        const path = columnClickPaths[column];
        const key = columnKeyMappings[column] || column;
        navigate(`${path}/${row[key]}`, { replace: true });
      } else if (clickableColumnIds.length > 1) {
        const firstColumn = clickableColumnIds[0];
        const path = columnClickPaths[firstColumn];
        const key = columnKeyMappings[firstColumn] || firstColumn;
        navigate(`${path}/${row[key]}`);
      } else if (isRowClickable && rowClickPath) {
        navigate(`${rowClickPath}/${row?.code}`, { replace: true });
      }
    }

    // Call the onRowClick callback if provided
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleColumnClick = (column, row) => {
    const path = columnClickPaths[column.id];
    const key = columnKeyMappings[column.id] || column.id;

    if (path) {
      // Extract the ID from nested objects
      const value = row[key];
      const idToNavigate =
        (key === "station" && value ? value._id : row[key]) || "";
      navigate(`${path}/${idToNavigate}`, { replace: true });
    }
  };

  const getSpecialBackground = (columnId, row) => {
    if (columnId === "message") {
      return row?.direction === "Incoming" ? "#027900" : "#E50000";
    }
    if (columnId === "_id") {
      return row.status === true ? "#027900" : "#790000";
    }
    if (columnId === "paymentId") {
      return row.status === "Success" ? "#027900" : "#790000";
    }
    if (columnId === "paymentID") {
      return row.paymentStatus === "credited" ? "#027900" : "#790000";
    }
    if (columnId === "name") {
      return row.active === true ? "#027900" : "#790000";
    }
    return "transparent";
  };

  // Format value to two decimal places if it has more than two decimal places
  const formatValue = (value, columnId, row) => {
    if (typeof value === "object" && value !== null) {
      // Handle specific cases for nested objects
      if (columnId === "org" || "station") {
        return value.name || ""; // Assuming you want to display the name field
      }
      if (columnId === "createdBy") {
        return value.name || ""; // Assuming you want to display the name field
      }
    }

    // Handle special case for energyConsumed
    if (columnId === "energyConsumed") {
      if (row.energyConsumed !== undefined) {
        return (parseFloat(row.energyConsumed) || 0).toFixed(2);
      }
      const stopValue = parseFloat(row.stopMeterValue) || 0;
      const startValue = parseFloat(row.startMeterValue) || 0;
      return ((stopValue - startValue) / 1000).toFixed(2);
    }

    // Check if value is a number or a string that represents a number
    if (
      typeof value === "number" ||
      (!isNaN(parseFloat(value)) && isFinite(value))
    ) {
      const numberValue = parseFloat(value);

      // Only format if the number has more than two decimal places
      if (
        numberValue.toString().includes(".") &&
        numberValue.toString().split(".")[1].length > 2
      ) {
        return numberValue.toFixed(2);
      }
      return numberValue.toString(); // Return the value as is if no formatting is needed
    }

    // Return non-numeric values unchanged
    return value;
  };

  const formatSpecialValue = (value, columnId) => {
    if (columnId === "sessionStart" || columnId === "sessionStop") {
      // Ensure value is treated as a number (milliseconds) and convert to Date
      const date = new Date(parseInt(value, 10)); // Convert milliseconds to Date
      const formattedDate = date.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy
      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }); // Format: hh:mm AM/PM
      return (
        <Box>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
          >
            {formattedDate}
          </Typography>
          <Typography
            color={"#666"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
          >
            {formattedTime}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const formatRating = (rating) => {
    switch (rating) {
      case 5:
        return "Ok";
      case 10:
        return "Great";
      case 3:
        return "Bad";
      default:
        return ""; // Return an empty string for other ratings
    }
  };

  // React.useEffect(() => {
  //   setTableData(rows);
  //   // Initialize selectedRows based on isActive property
  //   const initiallySelected = rows.filter((row) => row.isActive);
  //   setSelectedRows(initiallySelected);
  // }, [rows]);

  // Calculate defaultTariff once based on tariffIds
  const [selectedTariffs, setSelectedTariffs] = React.useState("");
  const [defaultTariff, setDefaultTariff] = React.useState("");

  // Set selected tariffs from newData if available
  React.useEffect(() => {
    if (newData) {
      // Extract the tariffIds as an array

      // Set selectedTariffs to this array
      setSelectedTariffs(newDataCode);
    }
  }, [newData]);

  console.log("tygytygytyVV 22222", defaultTariff);
  console.log("iiijjjhh 222222", selectedTariffs);

  React.useEffect(() => {
    // Calculate defaultTariff when tariffIds change
    const newDefaultTariff =
      tariffIds?.find((tariff) => tariff.isDefault)?.id || "";
    setDefaultTariff(newDefaultTariff);
  }, [tariffIds]);

  // React.useEffect(() => {
  //   if (evseData && evseData.length > 0) {
  //     const newSelectedTariffs = {};

  //     evseData.forEach((row) => {
  //       // If row has tariffId, use that, otherwise use defaultTariff
  //       const tariffId = row.tariffId || defaultTariff;
  //       newSelectedTariffs[row.deviceId] = tariffId;
  //     });

  //     setSelectedTariffs(newSelectedTariffs);
  //   }
  // }, [evseData, defaultTariff]);

  React.useEffect(() => {
    const initialTableData = rows.map((row) => {
      return {
        ...row,
        selectedTariff: row.isActive ? row.tariffId : defaultTariff, // Set the default tariff

        //   const initiallySelected = rows.filter((row) => row.isActive);
        //   setSelectedRows(initiallySelected);
      };
    });

    setTableData(initialTableData);

    const initiallySelected = rows.filter((row) => row.isActive);
    setSelectedRows(initiallySelected);
  }, [rows, defaultTariff]);

  const handleSelectRow = (row) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some(
        (item) => item.deviceId === row.deviceId
      );
      const newSelected = isSelected
        ? prevSelected.filter((item) => item.deviceId !== row.deviceId)
        : [...prevSelected, row];
      onRowSelectionChange(newSelected);
      setSelectedTariffs(newDataCode);
      return newSelected;
    });
  };

  const handleCheckboxChange = (row) => {
    const updatedRows = tableData.map((item) => {
      if (item.deviceId === row.deviceId) {
        return { ...item, isActive: !item.isActive }; // Toggle isActive
      }
      return item;
    });
    setTableData(updatedRows);
    handleSelectRow(row); // Update selection state
  };

  const handleSelectAll = () => {
    if (selectedRows.length === displayRows.length) {
      setSelectedRows([]); // Deselect all
      setSelectedCount(0); // Reset selected count
      onRowSelectionChange([]); // Notify parent of deselection
    } else {
      setSelectedRows(displayRows); // Select all
      setSelectedCount(displayRows.length); // Update selected count
      onRowSelectionChange(displayRows); // Notify parent of selection
    }
  };

  // const handleTariffChange = (rowIndex, selectedValue) => {
  //   const updatedRows = tableData.map((row, index) => {
  //     if (index === rowIndex) {
  //       const updatedRow = {
  //         ...row,
  //         selectedTariff: selectedValue, // Update only the selected row
  //       };
  //       // Notify the parent component about the tariff change
  //       if (onTariffChange) {
  //         onTariffChange(updatedRow.deviceId, selectedValue); // Pass deviceId and selected tariff
  //       }
  //       return updatedRow;
  //     }
  //     return row; // Return the unchanged row
  //   });
  //   setTableData(updatedRows); // Update the state with the new array
  // };

  // Handle tariff change for a row
  const handleTariffChange = (rowIndex, selectedValue) => {
    const updatedRows = tableData.map((row, index) => {
      if (index === rowIndex) {
        const updatedRow = {
          ...row,
          // selectedTariff: selectedValue || defaultTariff,
          selectedTariff: selectedValue,
        };
        if (onTariffChange) {
          onTariffChange(updatedRow.deviceId, selectedValue);
        }
        return updatedRow;
      }
      return row; // Return the unchanged row
    });
    setTableData(updatedRows); // Update the state with the new array
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign={"start"}
          fontSize={"14px"}
          fontFamily={"myThirdFont"}
          color="#2F2F2F"
        >
          {modalHeading === "OCPI Stations"
            ? `Please choose the charging points to be shared (${selectedCount}/${rows.length}) selected`
            : modalHeading === "OCPI Edit One Station"
            ? `Permitted Charging points (${selectedCount}/${rows.length}) selected`
            : `Showing ${
                modalHeading === "Transactions" ||
                modalHeading === "Payment History"
                  ? "all"
                  : ""
              } ${rows.length} ${modalHeading}`}
        </Typography>
        {statsHeading === "true" ? (
          <Typography
            textAlign={"start"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
            color="#2F2F2F"
          >
            *stats from created date to present
          </Typography>
        ) : (
          ""
        )}
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#292D33", padding: "8px 16px" }}>
              {showCheckbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === displayRows.length}
                    onChange={handleSelectAll}
                    sx={{
                      color: "#FFF", // Default color for unchecked
                      "& .MuiSvgIcon-root": {
                        fill: "#FFF", // Background color of the checkbox
                      },
                    }}
                  />
                </TableCell>
              )}

              {columns.map((item) => (
                <TableCell
                  sx={{
                    color: "#fff",
                    fontFamily: "myFirstFont",
                    textWrap: "nowrap",
                  }}
                  key={item.id}
                  align={item.align}
                >
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <Typography sx={{ fontFamily: "mySecondFont" }}>
                    No Data Available
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              displayRows.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={row.stationName || rowIndex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: isRowClickable ? "pointer" : "default",
                      backgroundColor:
                        selectedRow === rowIndex ? "red" : "transparent",
                      "&:hover": {
                        backgroundColor: isRowClickable ? "#f5f5f5" : "inherit",
                      },
                    }}
                    onClick={
                      isRowClickable ? () => handleRowClick(row) : undefined
                    }
                  >
                    {showCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.some(
                            (item) => item.deviceId === row.deviceId
                          )}
                          onChange={() => handleSelectRow(row)}
                        />
                      </TableCell>
                    )}

                    {columns.map((column) => (
                      <TableCell
                        sx={{
                          color: "#2F2F2F",
                          fontFamily: "mySecondFont",
                          textTransform: "capitalize",
                          textWrap: "nowrap",
                          textDecoration:
                            isRowClickable &&
                            clickableColumns.includes(column.id) &&
                            column.id !== "action"
                              ? "underline"
                              : "none",
                          cursor:
                            isRowClickable &&
                            clickableColumns.includes(column.id) &&
                            column.id !== "action"
                              ? "pointer"
                              : "default",
                        }}
                        key={column.id}
                        align={column.align}
                        onClick={(e) => {
                          if (
                            isRowClickable &&
                            clickableColumns.includes(column.id) &&
                            column.id !== "action"
                          ) {
                            e.stopPropagation();
                            handleColumnClick(column, row);
                          }
                        }}
                      >
                        {column.id === "selectTariff" ? (
                          <Select
                            value={row.selectedTariff}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleTariffChange(rowIndex, e.target.value);
                            }}
                          >
                            {tariffIds.map((tariff) => (
                              <MenuItem key={tariff?._id} value={tariff.id}>
                                {tariff.id}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : column.id === "action" ? (
                          <ActionIcons
                            actions={actionType}
                            row={row}
                            heading={modalHeading}
                            setTableData={setTableData}
                          />
                        ) : column.id === "sr" ? (
                          <Typography
                            sx={{
                              textAlign: "start",
                              fontFamily: "mySecondFont",
                              color: "#2F2F2F",
                              fontSize: "14px",
                            }}
                          >
                            {page * rowsPerPage + rowIndex + 1}
                          </Typography>
                        ) : column.id === "status" ? (
                          <Box>
                            <StatusChip text={row[column.id]} />
                          </Box>
                        ) : column.id === "rating" ? (
                          <Box>
                            <StatusChip text={formatRating(row[column.id])} />
                          </Box>
                        ) : column.id === "mode" ? (
                          <Box>
                            {column.id === "mode"
                              ? row[column.id] === "Upi"
                                ? "UPI"
                                : row[column.id] === "APP_CHARGING"
                                ? "APP CHARGING"
                                : row[column.id] === "App"
                                ? "APP"
                                : row[column.id]
                              : row[column.id]}
                          </Box>
                        ) : column.id === "from" ? (
                          <Box>
                            {column.id === "from"
                              ? row[column.id] === "Upi"
                                ? "UPI"
                                : row[column.id] === "APP_CHARGING"
                                ? "APP CHARGING"
                                : row[column.id] === "DIRECT_PAYMENT"
                                ? "DIRECT PAYMENT"
                                : row[column.id] === "App"
                                ? "APP"
                                : row[column.id]
                              : row[column.id]}
                          </Box>
                        ) : column.id === "type" ? (
                          <Box>
                            {row[column.id] === "AddMoney"
                              ? "Add Money"
                              : row[column.id]}
                          </Box>
                        ) : column.id === "deviceId" ? (
                          <Box>
                            {row[column.id] === null ? "N/A" : row[column.id]}
                          </Box>
                        ) : column.id === "ratingReviewImages" ? (
                          <Box>{row[column.id].length}</Box>
                        ) : column.id === "description" ? (
                          <Box
                            sx={{
                              width: "100px", // Set the width of the Box
                              overflow: "hidden", // Hide overflow content
                              textOverflow: "ellipsis", // Show ellipsis for overflowing text
                              whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                            }}
                          >
                            {row[column.id].length > 0 ? row[column.id] : "N/A"}
                          </Box>
                        ) : column.id === "userId" ? (
                          <Box>
                            {typeof row[column.id] === "string"
                              ? row[column.id].replace(
                                  /(\+\d{2})(\d+)/,
                                  "$1 $2"
                                )
                              : row[column.id]}
                          </Box>
                        ) : column.id === "Reviewed On" ? (
                          <Box>
                            {typeof row[column.id] === "string"
                              ? row[column.id].replace(
                                  /(\+\d{2})(\d+)/,
                                  "$1 $2"
                                )
                              : row[column.id]}
                          </Box>
                        ) : column.id === "phoneNumber" ? (
                          <Box>
                            {typeof row[column.id] === "string"
                              ? row[column.id].replace(
                                  /(\+\d{2})(\d+)/,
                                  "$1 $2"
                                )
                              : row[column.id]}
                          </Box>
                        ) : specialColumns.includes(column.id) ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "4px",
                                height: "16px",
                                borderRadius: "50px",
                                background: getSpecialBackground(
                                  column.id,
                                  row
                                ),
                                marginRight: "8px",
                              }}
                            />
                            {formatSpecialValue(row[column.id], column.id) ||
                              formatValue(row[column.id])}
                          </Box>
                        ) : column.id === "createdAt" ||
                          column.id === "updatedAt" ||
                          column.id === "updated_at" ||
                          column.id === "nestedLastUpdated" ||
                          column.id === "last_updated" ? (
                          <DateTimeDisplay dateTime={row[column.id]} />
                        ) : column.id === "nestedLastUpdated" ||
                          column.id === "last_updated" ? (
                          formatTimestamp(row[column.id])
                        ) : column.id === "sessionStart" ||
                          column.id === "sessionStop" ? (
                          <FormatTimestamp timestamp={row[column.id]} />
                        ) : column.id === "txnTime" ? (
                          <FormatTimestamp timestamp={row[column.id]} />
                        ) : (
                          formatValue(row[column.id], column.id, row)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {rows.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {/* Modal to display row details */}
      {selectedRowData && isModalOpen && (
        <PaymentModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          row={selectedRowData}
        />
      )}
      {selectedRowData && isModalOpen && (
        <ReviewModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          row={selectedRowData}
        />
      )}
    </Box>
  );
};

const handleEdit = (row) => {
  console.log("Edit action for:", row);
};

const handleDelete = (row) => {
  console.log("Delete action for:", row);
};

export default OcpiCustomTable;
