import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";
import { useDispatch, useSelector } from "react-redux";
import { generateInvoice } from "../../../redux/action/sessions/sessionsSlice";
import { formatTimestamp } from "../../../utils/FormatDateTime";
import { useLocation } from "react-router-dom";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: icon ? "8px" : "",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
const SessionDetailBox1 = ({ data }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false);
  // const [downloadUrl, setDownloadUrl] = useState(null);
  const { invoice, loading, error } = useSelector((state) => state.sessions);
  const hubSession = location.pathname.includes("/hub_management");
  let sessionId = data?._id;

  // Effect to handle download when invoice URL is available
  useEffect(() => {
    if (invoice?.pdfUrl && isDownloading) {
      // Trigger the download
      const link = document.createElement("a");
      link.href = invoice.pdfUrl;
      link.download = "invoice.pdf"; // Default file name
      link.click();
      setIsDownloading(false); // Reset download state
    }
  }, [invoice, isDownloading]);

  const handleGenerateAndDownloadInvoice = async () => {
    if (data) {
      setIsDownloading(true); // Start loading
      await dispatch(generateInvoice(sessionId));
    }
  };

  const startTime = data?.sessionStart
    ? formatTimestamp(data.sessionStart)
    : { formattedTime: "-", formattedDate: "-" };
  const endTime = data?.sessionStop
    ? formatTimestamp(data.sessionStop)
    : { formattedTime: "-", formattedDate: "-" };

  return (
    <>
      {hubSession ? (
        <Box
          sx={{
            padding: "24px 16px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {loading && !isDownloading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 10, // Ensure it is above other content
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {hubSession !== true ? (
              <InfoBox
                label="Transaction ID (OCPP)"
                value={data?.transactionId}
                icon={"content_copy"}
              />
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                gap: "72px",
                alignItems: "center",
              }}
            >
              <InfoBox label="Station Name" value={data?.stationName} />
              <InfoBox label="City" value={data?.city || "dummy"} />
            </Box>

            <InfoBox label="Charge Duration " value={data?.sessionDuration} />
            <InfoBox
              label="Energy consumed (Kwh)"
              value={data?.energyConsumed}
            />
            <InfoBox label="Stop Reason" value={data?.stopReason} />
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoBox
                  label="Start time"
                  value={`${startTime.formattedTime} `}
                />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {startTime.formattedDate}
                </Typography>
              </Box>

              <Box
                sx={{ width: "50%", display: "flex", flexDirection: "column" }}
              >
                <InfoBox label="End time" value={`${endTime.formattedTime}`} />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {endTime.formattedDate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px 16px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {loading && !isDownloading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 10, // Ensure it is above other content
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {hubSession !== true ? (
              <InfoBox
                label="Transaction ID (OCPP)"
                value={data?.transactionId}
                icon={"content_copy"}
              />
            ) : (
              ""
            )}

            <InfoBox
              label="Bill ID"
              value={
                data?.from === "WEB_CHARGING" || !data?.invoiceId
                  ? "N/A"
                  : data.invoiceId
              }
              icon={
                data?.from === "WEB_CHARGING" || !data?.invoiceId
                  ? ""
                  : "content_copy"
              }
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <InfoBox label="Invoice" />
              {data?.from === "WEB_CHARGING" ? (
                <Button
                  sx={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "4px",
                    padding: "4px 12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "mySecondFont",
                    fontSize: "14px",
                    color: "#e50000",
                    maxWidth: "120px",
                    width: "100%",
                  }}
                  onClick={handleGenerateAndDownloadInvoice}
                  disabled={true}
                >
                  <span
                    style={{ paddingRight: "8px" }}
                    className="material-symbols-outlined"
                  >
                    download
                  </span>
                  Invoice
                </Button>
              ) : (
                <Button
                  sx={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "4px",
                    padding: "4px 12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "mySecondFont",
                    fontSize: "14px",
                    color: "#e50000",
                    maxWidth: "120px",
                    width: "100%",
                  }}
                  onClick={handleGenerateAndDownloadInvoice}
                  disabled={isDownloading}
                >
                  <span
                    style={{ paddingRight: "8px" }}
                    className="material-symbols-outlined"
                  >
                    download
                  </span>
                  Invoice
                </Button>
              )}
            </Box>
            <InfoBox label="Charger point" value={data?.chargingPointName} />
            <InfoBox label="Charging station" value={data?.stationName} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SessionDetailBox1;
