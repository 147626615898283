import { Box, Grid } from "@mui/material";
import React from "react";
import OcpiGraphBoxTwo from "./OcpiGraphBoxTwo";
import OcpiGraphBoxThree from "./OcpiGraphBoxThree";
import OcpiGraphBoxOne from "./OcpiGraphBoxOne";

const OcpiMainGraphBox = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      <Box>
        <OcpiGraphBoxOne />
      </Box>
      <Box>
        <OcpiGraphBoxTwo />
      </Box>
      <Box>
        <OcpiGraphBoxThree />
      </Box>
    </Box>
  );
};

export default OcpiMainGraphBox;
