import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import SessionTableBox from "./sessions/SessionTableBox";
import StationTableBox from "./stations/StationTableBox";
import PassHolderTableBox from "./pass_holders/PassHolderTableBox";
import GaurdTableBox from "./gaurds/GaurdTableBox";
import ChargingPasses from "./charging_passes/ChargingPasses";
import Collection from "./collection/Collection";
import {
  HubChargingPassesColumn,
  HubGaurdColumns,
  HubPassHolderColumn,
  HubStationColumn,
} from "../../utils/DummyData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchHubStats,
  getAllChargingPasses,
  getAllGuards,
  getAllPassHolders,
  getAllStationsHub,
  getAllTransaction,
  getShiftCollection,
} from "../../redux/action/hubManagement/hubManagementSlice";
import { formatPhoneNumber } from "../../utils/countryCode";
import HubOverviewBox from "./overview/HubOverviewBox";
import TimeFilter from "../Filters/TimeFilter";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);
  const [currentOverviewData, setCurrentOverviewData] = useState(null);
  const dispatch = useDispatch();

  // Access the chargingPasses from the correct state slice
  const {
    chargingPasses,
    passHolders,
    guards,
    transactions,
    shiftCollections,
    allStations,
    hubStats,
    loading,
  } = useSelector(
    (state) => state.hubManagement // Ensure this matches your slice name
  );

  // Fetch all charging passes on component mount
  useEffect(() => {
    dispatch(getAllChargingPasses());
    dispatch(getAllPassHolders());
    dispatch(getAllGuards());
    dispatch(getAllTransaction());
    dispatch(getShiftCollection());
    dispatch(getAllStationsHub());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (durationType && startTime && endTime) {
        try {
          // Fetch overview data
          const overviewDummyData = await dispatch(
            fetchHubStats({
              durationType,
              startTime,
              endTime,
            })
          ).unwrap();
          setCurrentOverviewData(overviewDummyData);
        } catch (error) {
          console.error("Error fetching current time data: ", error);
        }
      }
    };

    fetchData();
  }, [durationType, startTime, endTime, dispatch]);

  useEffect(() => {
    if (
      durationType &&
      prevStartTime &&
      prevEndTime &&
      currentOverviewData !== null
    ) {
      dispatch(
        fetchHubStats({
          durationType,
          startTime: prevStartTime,
          endTime: prevEndTime,
        })
      );
    }
  }, [durationType, prevStartTime, prevEndTime, dispatch, currentOverviewData]);

  const passHolderData = passHolders.map((form) => {
    const rawPhone = form?.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form?._id,
      name: form?.name,
      hubPassPlans: form.HubPassPlans,
      passHolderName: form?.name,
      passPlan: form?.passPlan,
      phone: formattedPhone,
      stations: form?.stations,
      updatedAt: form?.updatedAt,
      validTill: form?.validTill,
      vehicleNumber: form?.vehicleNumber,
      stationName: form?.stations?.name,
      passName: form?.passPlan?.name,
      passId: form?.passPlan?.passId,
      city: form?.stations?.city,
      purchaseDate: form?.purchaseDate,
    };
  });

  const allGuards = guards.map((form) => {
    const rawPhone = form?.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form?._id,
      name: form?.name,
      phone: formattedPhone,
      aadharBack: form?.aadharBack,
      aadharFront: form?.aadharFront,
      aadharNo: form?.aadharNo,
      address: form?.address,
      collectedAmount: form?.collectedAmount,
      createdAt: form?.createdAt,
      createdBy: form?.createdBy,
      email: form?.email,
      language: form?.language,
      profilePic: form?.profile_pic?.url,
      stationId: form?.stationId,
      status: form?.status,
      updatedAt: form?.updatedAt,
      city: form?.stationId?.city,
      stationName: form?.stationId?.name,
    };
  });

  const getShiftData = shiftCollections.map((form) => {
    return {
      _id: form?._id,
      stationId: form?.stationId.name,
      hubUser: form?.hubUser?.name,
      collectedAmount: form?.collectedAmount,
      passesCount: form?.passesCount,
      passValue: form?.passValue,
      city: form?.stationId?.city,
      createdAt: form?.createdAt,
    };
  });

  const getAllTransactionData = transactions.map((form) => {
    const rawPhone = form?.passHolder?.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form?._id,
      cashAmount: form?.cashAmount,
      createdAt: form?.createdAt,
      createdBy: form?.createdBy,
      hubPassPlans: form?.hubPassPlans,
      passHolder: form?.passHolder,
      station: form?.station,
      updatedAt: form?.updatedAt,
      passId: form?.hubPassPlans?.passId,
      passType: form?.hubPassPlans?.passType,
      passHolderName: form?.passHolder?.name,
      passHolderPhone: formattedPhone,
      city: form?.station?.city,
      guardName: form?.createdBy?.name,
    };
  });

  const tabsData = [
    {
      label: "Overview",
      content: (
        <HubOverviewBox
          data={currentOverviewData}
          selectedTime={durationType}
          previousDashboardData={hubStats}
          startTime={startTime}
          endTime={endTime}
          loading={loading}
        />
      ),
    },
    {
      label: "Sessions",
      content: <SessionTableBox />,
    },
    {
      label: "Stations",
      content: <StationTableBox columns={HubStationColumn} row={allStations} />,
    },
    {
      label: "Pass Holders",
      content: (
        <PassHolderTableBox
          columns={HubPassHolderColumn}
          row={passHolderData}
        />
      ),
    },
    {
      label: "Guard",
      content: <GaurdTableBox columns={HubGaurdColumns()} row={allGuards} />,
    },
    {
      label: "Charging Passes",
      content: (
        <ChargingPasses
          columns={HubChargingPassesColumn()}
          row={chargingPasses}
        />
      ),
    },
    {
      label: "Collection",
      content: (
        <Collection
          shiftCollection={getShiftData}
          data={getAllTransactionData}
        />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box sx={{ display: "none" }}>
          <MainHeading text={"Hub Management"} />
        </Box>
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
