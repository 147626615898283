import { Box, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";
import { formatTimestamp } from "../../../utils/FormatDateTime";
import { useLocation } from "react-router-dom";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: icon ? "8px" : "",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const ConnectorBox = ({ connectors }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "4px",
      flexDirection: "row",
    }}
  >
    {connectors?.map((connector) => (
      <Box
        key={connector._id} // Use connector._id for unique key
        sx={{
          padding: "2px 12px",
          background: "rgba(41, 45, 51, 0.08)",
          maxWidth: "85px",
          width: "100%",
          borderRadius: "85px",
          textWrap: "nowrap",
        }}
      >
        <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
          {connector?.connectorType}
        </Typography>
      </Box>
    ))}
  </Box>
);

const SessionDetailBox2 = ({ data }) => {
  const location = useLocation();
  const hubSession = location.pathname.includes("/hub_management");

  const vehicleEntryTime = data?.hubEntryDetails?.vehicleEntryTime
    ? formatTimestamp(data.hubEntryDetails?.vehicleEntryTime)
    : { formattedTime: "-", formattedDate: "-" };
  const vehicleExitTime = data?.hubEntryDetails?.vehicleExitTime
    ? formatTimestamp(data.hubEntryDetails?.vehicleExitTime)
    : { formattedTime: "-", formattedDate: "-" };

  return (
    <>
      {hubSession ? (
        <Box
          sx={{
            padding: "24px 16px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <InfoBox label="Started By" value={data?.guardDetails?.name} />

            <InfoBox
              label="Vehicle Number"
              value={data?.hubEntryDetails?.vehicleNumber}
              icon={"content_copy"}
            />

            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoBox
                  label="Vehicle Entry Time"
                  value={`${vehicleEntryTime.formattedTime} `}
                />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {vehicleEntryTime.formattedDate}
                </Typography>
              </Box>

              <Box
                sx={{ width: "50%", display: "flex", flexDirection: "column" }}
              >
                <InfoBox
                  label="Vehicle Exit Time"
                  value={`${vehicleExitTime.formattedTime}`}
                />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {vehicleExitTime.formattedDate}
                </Typography>
              </Box>
            </Box>
            <InfoBox
              label="Vehicle Duration"
              value={data?.hubEntryDetails?.vehicleDuration}
            />
            <InfoBox
              label="Vehicle Type"
              value={data?.hubEntryDetails?.vehicleType}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px 16px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <InfoBox
              label="Device ID"
              value={data?.deviceId}
              icon={"content_copy"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  flexDirection: "row",
                }}
              >
                <Typography
                  fontFamily={"myThirdFont"}
                  fontSize={"14px"}
                  color={"#2F2F2F"}
                  textAlign={"start"}
                >
                  Connector & Connector ID
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                  flexDirection: "row",
                }}
              >
                <ConnectorBox connectors={data?.connectors} />
                <Typography
                  color={"#2F2F2F"}
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  textAlign={"start"}
                >
                  {data?.connectorId}
                </Typography>
              </Box>
            </Box>
            <InfoBox
              label="Energy consumed (Kwh)"
              value={data?.energyConsumed}
            />
            <InfoBox
              label="Charging Mode"
              value={
                data?.from === "DIRECT_PAYMENT"
                  ? "DIRECT PAYMENT"
                  : data?.from === "APP_CHARGING"
                  ? "APP CHARGING"
                  : data?.from
              }
            />
            <InfoBox label="Customer ID" value={data?.customerId} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SessionDetailBox2;
