import React from "react";
import TopBar from "../../components/charging_stations_details/TopBar";
import { Box } from "@mui/material";
import Breadcrumbs from "../../components/breadcrumbs/BreadCrumbs";

const ChargingStationDetails = () => {
  return (
    <Box>
      {/* Top - Bar */}

      <TopBar />
    </Box>
  );
};

export default ChargingStationDetails;
