import { Box } from '@mui/material'
import React from 'react'
import TopBar from '../../components/hub_management/TopBar';
import { useNavigate } from 'react-router-dom';
import useScrollToTop from '../../Hooks/useScrollToTop';

const HubManagement = () => {
    const navigate = useNavigate();
    useScrollToTop(navigate);
    return (
      <Box>
        <TopBar/>
      </Box>
    );
}

export default HubManagement
