import React from 'react'
import TopBar from '../../components/ocpi_management_details/TopBar';
import { Box } from '@mui/material';

const OcpiManagementDetails = () => {
    return (
        <Box>
          {/* Top - Bar */}
          <TopBar />
        </Box>
      );
}

export default OcpiManagementDetails
