import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import { useDispatch } from "react-redux";
import {
  getAllRFIDRequests,
  rejectRFID,
} from "../../../redux/action/RFID/rfidSlice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 440,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "36px 32px",
};

const WarningRFID = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = React.useState("");

  const handleReject = () => {
    const id = data?._id;

    if (!reason.trim()) {
      toast.error("Please Provide a Rejection Reason.");
      return; // Do not proceed if the reason is empty
    }

    dispatch(rejectRFID({ id, reason })) // Pass the reason to the API call
      .unwrap() // To handle the response and any errors
      .then(() => {
        dispatch(getAllRFIDRequests());
        handleClose(); // Close the modal
        toast.success("RFID Request Rejected!");
      })
      .catch((error) => {
        toast.error("Failed to reject RFID:", error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "80px",
                background: "rgba(41, 45, 51, 0.08);",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{ fontSize: "42px", fontWeight: "300" }}
                className="material-symbols-outlined"
              >
                warning
              </span>
            </Box>
            <Typography
              color={"#1C1C1C"}
              fontSize={"18px"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
              textAlign={"center"}
            >
              Are you sure you want to reject
              <br />
              <span style={{ color: "#8A0000", textTransform: "capitalize" }}>
                "{data?.customer?.name}"
              </span>{" "}
              <br />
              RFID Request?
            </Typography>
            <Box>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Enter Reject Reason Here"
                style={{
                  width: "330px",
                  height: "80px",
                  borderRadius: "4px",
                  padding: "8px",
                  outline: "none",
                  fontSize: "14px",
                  fontFamily: "myThirdFont",
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              mt: "24px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <Button
              sx={{
                padding: "12px 24px",
                borderRadius: "8px",
                border: "1px solid #808080",
                width: "40%",
                color: "#790000",
                fontFamily: "mySecondFont",
              }}
              onClick={handleClose}
            >
              NO
            </Button>
            <Button
              sx={{
                padding: "12px 24px",
                borderRadius: "8px",
                border: "1px solid #808080",
                width: "60%",
                color: "#790000",
                fontFamily: "mySecondFont",
                background: "#222",
                color: "#fff",
                ":hover": {
                  background: "#222",
                },
              }}
              onClick={handleReject}
            >
              YES
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default WarningRFID;
