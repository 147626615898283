import { Box, Typography } from "@mui/material";
import React from "react";
import { formatTimestamp } from "../../../utils/FormatDateTime";
import { useLocation } from "react-router-dom";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        // justifyContent: icon ? "space-between" : "start",
        alignItems: "center",
        gap: icon ? "8px" : "",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {value}
      </Typography>
      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const SessionDetailBox3 = ({ data }) => {
  const location = useLocation();
  const hubSession = location.pathname.includes("/hub_management");

  const startTime = data?.sessionStart
    ? formatTimestamp(data.sessionStart)
    : { formattedTime: "-", formattedDate: "-" };
  const endTime = data?.sessionStop
    ? formatTimestamp(data.sessionStop)
    : { formattedTime: "-", formattedDate: "-" };

  const purchaseTime = data?.hubEntryDetails?.passPuchaseDate
    ? formatTimestamp(data?.hubEntryDetails?.passPuchaseDate)
    : { formattedTime: "-", formattedDate: "-" };
  const validTill = data?.hubEntryDetails?.passExpiryDate
    ? formatTimestamp(data?.hubEntryDetails?.passExpiryDate)
    : { formattedTime: "-", formattedDate: "-" };

  const currencySymbol = GetCurrencySymbol();

  return (
    <>
      {hubSession ? (
        <Box
          sx={{
            padding: "24px 16px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <InfoBox
                  label="Pass ID"
                  value={data?.hubEntryDetails?.passNumber}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <InfoBox
                  label="Parking No."
                  value={data?.connector[0]?.parking}
                />
              </Box>
            </Box>
            <InfoBox
              label="Pass Type"
              value={data?.hubEntryDetails?.passType}
            />
            <Box sx={{ width: "50%" }}>
              <InfoBox label="Device ID" value={data?.deviceId} />
            </Box>
            <InfoBox label="Connector ID" value={data?.connectorId} />
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoBox
                  label="Purchase Date"
                  value={`${purchaseTime.formattedTime} `}
                />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {purchaseTime.formattedDate}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoBox
                  label="Valid Till"
                  value={`${validTill.formattedTime} `}
                />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {validTill.formattedDate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px 16px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <InfoBox
                  label={`Entered Amount (${currencySymbol})`}
                  value={data?.enteredAmount}
                />
              </Box>

              <Box sx={{ width: "50%" }}>
                <InfoBox
                  label={`Billed Amount (${currencySymbol})`}
                  value={data?.chargedAmount}
                />
              </Box>
            </Box>
            <InfoBox
              label={`Refund (${currencySymbol})`}
              value={data?.refundAmount?.toFixed(2)}
            />

            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoBox
                  label="Charge Duration"
                  value={data?.sessionDuration}
                />
              </Box>

              <Box
                sx={{ width: "50%", display: "flex", flexDirection: "column" }}
              >
                <InfoBox label="Stop Reason" value={data?.stopReason} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InfoBox
                  label="Start time"
                  value={`${startTime.formattedTime} `}
                />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {startTime.formattedDate}
                </Typography>
              </Box>

              <Box
                sx={{ width: "50%", display: "flex", flexDirection: "column" }}
              >
                <InfoBox label="End time" value={`${endTime.formattedTime}`} />
                <Typography
                  textAlign={"start"}
                  color={"#666"}
                  fontFamily={"myThirdFont"}
                >
                  {endTime.formattedDate}
                </Typography>
              </Box>
            </Box>
            <InfoBox
              label="Status"
              value={data?.isActive === false ? "Completed" : "Active"}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SessionDetailBox3;
