import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../../utils/MainHeading";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";
import { useDispatch, useSelector } from "react-redux";
import { addFinanceAmount } from "../../../redux/action/financeManager/financeManagerSlice";
import AddAmountModal from "../../Modals/FinanceManager/AddAmountModal";
import { toast } from "react-toastify";
import ConfirmAmountAddModal from "../../Modals/FinanceManager/ConfirmAmountAddModal";
import { clearAmountAdded } from "../../../redux/action/financeManager/financeManagerSlice";
import { useNavigate } from "react-router-dom";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
                marginLeft: "8px",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const StationsDetails = ({ station, onBack }) => {
  const [open, setOpen] = useState(false);
  const [amountData, setAmountData] = useState(null);
  const [amount, setAmount] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addStatus = useSelector((state) => state.financeStations.addStatus);
  const amountAdded = useSelector((state) => state.financeStations.amountAdded);

  const handleCloseConfirmModal = () => {
    setShowConfirmation(false);
    setAmount(""); // Reset the input after the confirmation modal closes
    dispatch(clearAmountAdded()); // Clear the amountAdded in Redux
    navigate("/payment_history", {
      state: { selectedStationName: station?.name },
    });
  };

  // React to changes in addStatus to handle modal transitions
  useEffect(() => {
    if (addStatus === "succeeded" && amountAdded) {
      setOpen(false); // Close AddAmountModal
      setTimeout(() => setShowConfirmation(true), 0); // Open ConfirmAmountAddModal
    }
    // else if (addStatus === "failed") {
    //   toast.error("Failed to add amount");
    // }
  }, [addStatus, amountAdded]);

  // Ensure station is available
  if (!station) return <Typography>No station data available.</Typography>;

  const selectedStationName = station?.name;

  // Handle opening of AddAmountModal
  const handleOpen = () => {
    if (!amount || !station?._id || !station?.contactNumber) {
      toast.error("Please enter an amount");
      return;
    }

    const data = {
      amount_added: parseFloat(amount),
      station_id: station._id,
      mobilenumber: station.contactNumber,
    };

    setAmountData(data);
    console.log(data);
    setOpen(true);
  };

  // Proceed button handler inside AddAmountModal
  const handleProceed = async () => {
    if (amountData) {
      try {
        await dispatch(addFinanceAmount(amountData)).unwrap();
        console.log(amount);
        setAmount(amount);
      } catch (error) {
        // Handle API call failure
        console.error("API call failed with error:", error.message);
        if (error.message.includes("amount_added must be greater than 0")) {
          toast.error("Amount must be greater than 0");
        } else {
          toast.error("Failed to add amount");
        }
      }
    }
  };

  const isAmountValid = parseFloat(amount) > 0;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: "self-start",
        }}
      >
        <span
          className="material-symbols-outlined"
          style={{ cursor: "pointer" }}
          onClick={onBack}
        >
          arrow_back
        </span>
        <Box sx={{ marginLeft: "16px" }}>
          <MainHeading text={station?.name} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              mt: "24px",
            }}
          >
            <InfoBox
              label="Station/ Hub Code"
              value={station?._id}
              icon="content_copy"
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: "48px" }}>
              <InfoBox label="Address" value={station?.fullAddress} />
              <Box sx={{ display: "flex", alignItems: "center", gap: "48px" }}>
                <InfoBox label="Pin code" value={station?.pinCode} />
                <InfoBox label="City/Town" value={station?.city} />
              </Box>
            </Box>
            <InfoBox
              label="In charge Phone Number"
              value={station?.contactNumber}
              icon="content_copy"
            />
          </Box>

          {/* input - button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              mt: "24px",
            }}
          >
            <Typography
              textAlign={"start"}
              fontSize={"16px"}
              fontFamily={"myThirdFont"}
            >
              Enter Money to Add
            </Typography>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{
                padding: "12px 16px",
                borderRadius: "4px",
                border: "1px solid #858585",
                fontFamily: "myThirdFont",
                height: "48px",
                outline: "none",
                maxWidth: "416px",
                width: "100%",
                fontSize: "16px",
              }}
              placeholder="Enter Amount"
            />
            <Button
              onClick={handleOpen}
              sx={{
                padding: "12px 24px",
                borderRadius: "4px",
                background: "#E50000",
                maxWidth: "416px",
                width: "100%",
                mt: "8px",
                color: "#fff",
                fontFamily: "mySecondFont",
                ":hover": {
                  background: "#E50000",
                },
              }}
              disabled={!isAmountValid}
            >
              Add Money
            </Button>
          </Box>
        </Box>
      </Box>

      {/* AddAmountModal */}
      <AddAmountModal
        open={open}
        handleClose={() => setOpen(false)}
        amountData={amountData}
        onProceed={handleProceed}
        selectedStationName={selectedStationName}
      />

      {/* ConfirmAmountAddModal */}
      {showConfirmation && amountAdded && (
        <ConfirmAmountAddModal
          open={showConfirmation}
          handleClose={handleCloseConfirmModal}
          amountData={amountData}
          selectedStationName={station?.name}
          amount={amountAdded}
        />
      )}
    </>
  );
};

export default StationsDetails;
