import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDailySheetPassbook,
  fetchFinanceStations,
  fetchOneStationBalance,
  fetchOneStationPaymentHistory,
  fetchOneStationPaymentHistoryWithTime,
  fetchOneStationWalletHistory,
  fetchWalletHistory,
  fetchWalletHistoryWithTime,
} from "../../../redux/action/financeManager/financeManagerSlice";
import Heading from "../../../utils/Heading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const FinanceTimeBox = ({ onStationChange, defaultStationName }) => {
  const [sortColumn, setSortColumn] = useState("");
  const [dayFilter, setDayFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedStationId, setSelectedStationId] = useState("");
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const {
    stations,
    walletHistory,
    walletStatus,
    stationBalance,
    status,
    error,
  } = useSelector((state) => state.financeStations);

  const today = dayjs(); // Current date

  useEffect(() => {
    if (defaultStationName) {
      const defaultStation = stations.find(
        (station) => station.name === defaultStationName
      );
      if (defaultStation) {
        setSelectedStationId(defaultStation._id);
        setSortColumn(defaultStation._id);
        onStationChange(defaultStation.name);
        dispatch(
          fetchOneStationWalletHistory({ stationId: defaultStation._id })
        );
        dispatch(fetchOneStationBalance(defaultStation.contactNumber));
      }
    }
  }, [defaultStationName, stations]);

  // Handle change event for Select component
  const handleSortChange = (event) => {
    const selectedId = event.target.value;
    setSelectedStationId(selectedId);
    setSortColumn(selectedId);

    if (selectedId === "") {
      dispatch(fetchWalletHistory()); // Fetch all stations' wallet history immediately
      onStationChange(""); // Clear station name

      // Clear API data for daily sheet and payment history
      dispatch(
        fetchDailySheetPassbook({
          mobileNumber: "",
          startDate: "",
          endDate: "",
        })
      );
      dispatch(
        fetchOneStationPaymentHistory({
          mobileNumber: "",
          startDate: "",
          endDate: "",
        })
      );
    } else {
      const selectedStation = stations.find(
        (station) => station._id === selectedId
      );
      if (selectedStation) {
        onStationChange(selectedStation.name);

        // Fetch the selected station's wallet history immediately
        dispatch(fetchOneStationWalletHistory({ stationId: selectedId }));

        // Dispatch action to fetch station balance immediately when a station is selected
        if (selectedStation.contactNumber) {
          dispatch(fetchOneStationBalance(selectedStation.contactNumber));
        }
      }
    }
  };

  // Handle change event for the second Select component (day filter)
  const handleDayFilterChange = (event) => {
    setDayFilter(event.target.value);
  };

  // Handle change event for DatePicker components
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const totalAmountAdded = walletHistory?.reduce(
    (sum, item) => sum + (item.amount_added || 0),
    0
  );

  // Find the selected station from the stations array
  const selectedStation = stations.find(
    (station) => station._id === selectedStationId
  );

  console.log(selectedStation);

  // Handle Apply button click for custom date range
  const handleApplyClick = () => {
    if (dayFilter === "custom" && startDate && endDate) {
      // Convert dates to format YYYY-MM-DD
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");

      // Dispatch the action
      dispatch(
        fetchWalletHistoryWithTime({
          stationId: selectedStationId, // Use the selected station ID
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        })
      );

      // Dispatch fetchDailySheetPassbook
      if (selectedStation?.contactNumber) {
        dispatch(
          fetchDailySheetPassbook({
            mobileNumber: selectedStation.contactNumber,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          })
        );

        dispatch(
          fetchOneStationPaymentHistoryWithTime({
            mobileNumber: selectedStation.contactNumber,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(fetchFinanceStations());
  }, [dispatch]);

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        height: "calc(100vh - 140px)",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          color={"#2F2F2F"}
          fontSize={"16px"}
          fontFamily={"myThirdFont"}
          textAlign={"start"}
        >
          Please choose the Station/ Hub
        </Typography>
        <FormControl fullWidth>
          <Select
            displayEmpty
            value={sortColumn}
            onChange={handleSortChange}
            sx={{
              padding: "12px 8px 12px 16px",
              width: "100%",
              height: "48px",
              color: "#1C1C1C",
              fontFamily: "myThirdFont",
              textAlign: "left",
              "& .MuiSelect-icon": {
                color: "#1C1C1C", // Customize icon color
              },
            }}
          >
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value=""
            >
              <span>All Station</span> {/* Placeholder text */}
            </MenuItem>
            {stations?.map((item) => (
              <MenuItem
                key={item._id}
                value={item._id}
                sx={{ fontFamily: "myThirdFont" }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginTop: "24px" }}>
        <Select
          displayEmpty
          value={dayFilter}
          onChange={handleDayFilterChange}
          sx={{
            padding: "12px 8px 12px 16px",
            width: "100%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "myThirdFont",
            textAlign: "left",
            "& .MuiSelect-icon": {
              color: "#1C1C1C",
            },
          }}
        >
          <MenuItem sx={{ fontFamily: "myThirdFont" }} value="">
            All Days
          </MenuItem>
          <MenuItem sx={{ fontFamily: "myThirdFont" }} value="custom">
            Custom
          </MenuItem>
        </Select>
      </Box>

      {/* Conditionally render date pickers */}
      {dayFilter === "custom" && (
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            mt: "24px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                maxDate={endDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                minDate={startDate}
                maxDate={today}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      )}

      {/* btn */}
      <Box sx={{ marginTop: "16px" }}>
        {dayFilter === "custom" && (
          <Button
            onClick={handleApplyClick}
            sx={{
              padding: "12px 24px",
              borderRadius: "8px",
              border: "1px solid #808080",
              width: "100%",
              fontFamily: "mySecondFont",
              background: "#222",
              color: "#fff",
              ":hover": {
                background: "#222",
              },
            }}
          >
            Apply
          </Button>
        )}
      </Box>
      {/* box */}
      <Box sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            padding: "16px",
            background:
              "linear-gradient(0deg, rgba(2, 121, 0, 0.10) 0%, rgba(2, 121, 0, 0.10) 100%), #FFF",
            borderRadius: "4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Heading text={"Total Amount Added"} />
            <Typography
              color={"#027900"}
              fontSize={"clamp(1.375rem, 1.2453rem + 0.6289vw, 2rem);"}
              fontWeight={600}
            >
              ₹{totalAmountAdded}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* info */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "24px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
            textAlign={"start"}
          >
            Station In charge
          </Typography>
          <Typography
            color={"#2F2F2F"}
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
            textAlign={"start"}
          >
            {selectedStation
              ? selectedStation.stationIncharge || "N/A"
              : "No station selected"}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            color={"#2F2F2F"}
            fontSize={"14px"}
            fontFamily={"myThirdFont"}
            textAlign={"start"}
          >
            Phone Number
          </Typography>
          <Typography
            color={"#2F2F2F"}
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
            textAlign={"start"}
          >
            {selectedStation
              ? selectedStation.contactNumber
              : "No station selected"}
          </Typography>
        </Box>

        {/* current balance */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 24px",
            borderRadius: "8px",
            border: "1px solid rgb(221, 221, 221)",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{ fontSize: "24px" }}
              className="material-symbols-outlined"
            >
              account_balance_wallet
            </span>
            <Typography
              color={"#2F2F2F"}
              fontSize={"14px"}
              fontFamily={"myThirdFont"}
              textAlign={"start"}
            >
              Current Station
              <br /> Balance(₹)
            </Typography>
          </Box>
          <Typography
            color={"#2F2F2F"}
            fontSize={"18px"}
            fontFamily={"mySecondFont"}
            textAlign={"start"}
          >
            {selectedStation
              ? stationBalance?.balance !== undefined
                ? `₹${stationBalance.balance.toFixed(2)}`
                : "Balance Not Available"
              : "No station selected"}
          </Typography>
        </Box> */}
        <Box
          sx={{
            padding: "16px",
            background:
              "linear-gradient(0deg, rgba(2, 121, 0, 0.10) 0%, rgba(2, 121, 0, 0.10) 100%), #FFF",
            borderRadius: "4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Heading text={"Charging Station Balance"} />
            <Typography
              color={"#027900"}
              fontSize={"clamp(1.375rem, 1.2453rem + 0.6289vw, 2rem);"}
              fontWeight={600}
            >
              {selectedStation
                ? stationBalance?.balance !== undefined
                  ? `₹${stationBalance.balance.toFixed(2)}`
                  : "Balance Not Available"
                : "No station selected"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FinanceTimeBox;
