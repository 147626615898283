import { Box } from "@mui/material";
import React, { useState } from "react";
import HubTableFilterModal from "../Modals/HubManagement/HubTableFilterModal";
import { TimeProvider } from "../../Hooks/TimeContext";

const HubTableFilter = ({ tabTitle, setSearchQuery, isActive, stationId }) => {
  const [open, setOpen] = useState(false);
  const [selectedStation, setSelectedStation] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleFilterBoxClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Check if any filter is applied
  const isFilterApplied = selectedCity || selectedStation;

  return (
    <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
      <input
        style={{
          padding: "10px 16px",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #c0c0c0",
          color: "#9C9C9B",
          fontFamily: "myThirdFont",
          height: "40px",
        }}
        type="text"
        placeholder="Search by column name"
        className="chargingSessionTable"
        onChange={handleSearchChange}
      />
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          border: "1px solid #ccc",
          padding: "12px",
          borderRadius: "4px",
          height: "40px",
          fontFamily: "mySecondFont",
          fontSize: "14px",
          textTransform: "uppercase",
          cursor: "pointer",
        }}
        onClick={handleFilterBoxClick}
      >
        Filters
        {isFilterApplied ? (
          <span
            style={{
              display: "inline-block",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "#333",
              color: "white",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            1
          </span>
        ) : (
          <span className="material-symbols-outlined">filter_list</span>
        )}
      </Box>
      {/* Modal for filters */}
      {/* <TimeProvider> */}
      <HubTableFilterModal
        open={open}
        handleClose={handleClose}
        selectedStation={selectedStation}
        setSelectedStation={setSelectedStation}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        tabTitle={tabTitle}
        isActive={isActive}
        stationId={stationId}
      />
      {/* </TimeProvider> */}
    </Box>
  );
};

export default HubTableFilter;
