import { Box, Button } from "@mui/material";
import React from "react";
import TableFilters from "../charging-sessions/TableFilters";
import CustomTable from "../custom/CustomTable";
import { useNavigate } from "react-router-dom";
import AddTariffSnackBar from "../OcpiUiComponents/AddTariffSnackBar";

const OcpiChargerBox = ({ columns, rows, isRowClickable, networkDetail }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >     
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableFilters />
      </Box>

      <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        actionType={"qr-copy"}
        clickableColumns={["operatorName", "nestedOperatorId"]}
        //To be changed on checks
        rowClickPath="/ocpi_management_details"
        columnClickPaths={{
          network: "/ocpi_management_details",
          name: "/ocpi_management_details",
          nestedOperatorId: "/ocpi_management_details",
         
        }}
        columnKeyMappings={{
          name: "deviceId",
          station: "deviceId",
        }}
      />

      {/* <CustomTable
        columns={columns}
        rows={rows}
        isRowClickable={isRowClickable}
        clickableColumns={["name", "network", "operatorName"]}
        actionType={
          customAction === "tariff"
            ? "view"
            : customAction === "ocpi"
            ? "qr-copy"
            : customAction === "network"
            ? "edit-delete" : null
        }

        //To be changed on checks
        rowClickPath="/charging_points"

        columnClickPaths={{
          name: "/charging_points",
          //To Be Changed
          network: "/charging_points",
          operatorName: "/ocpi_management_details",
        }}
        columnKeyMappings={{
          name: "deviceID",
          network: "deviceID",
          operatorName: "operatorName",
        }}
      /> */}
    </Box>
  );
};

export default OcpiChargerBox;
