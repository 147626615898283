import React from "react";
import OrganizationStatistics from "./OrganizationStats/OrganizationStatistics";
import { Box } from "@mui/material";

const OrganizationBox = () => {
  return (
    <Box>
      <OrganizationStatistics />
    </Box>
  );
};

export default OrganizationBox;
