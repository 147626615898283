import { Box, Button, Divider } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";
import ChangeAvailability from "./ConfigComponent/ChangeAvailability";
import ChangeConfig from "./ConfigComponent/ChangeConfig";
import RemoteStart from "./ConfigComponent/RemoteStart";
import RemoteStop from "./ConfigComponent/RemoteStop";
import GetDiagnostics from "./ConfigComponent/GetDiagnostics";
import UpdateFirmware from "./ConfigComponent/UpdateFirmware";
import Reset from "./ConfigComponent/Reset";
import TriggerMessage from "./ConfigComponent/TriggerMessage";
import UnlockConnector from "./ConfigComponent/UnlockConnector";

const ConfigurationMessageBox = ({
  selectedConfig,
  onInputChange,
  onSendMessage,
  onClear,
  formValues,
  data,
}) => {
  const renderContent = () => {
    switch (selectedConfig) {
      case "Change Availability":
        return (
          <ChangeAvailability
            onInputChange={onInputChange}
            formValues={formValues}
            data={data}
          />
        );
      // Add cases for other configurations and their components
      case "Change Configuration":
        return (
          <ChangeConfig onInputChange={onInputChange} formValues={formValues} />
        );
      case "Remote Start":
        return (
          <RemoteStart
            data={data}
            onInputChange={onInputChange}
            formValues={formValues}
          />
        );
      case "Remote Stop":
        return (
          <RemoteStop
            data={data}
            onInputChange={onInputChange}
            formValues={formValues}
          />
        );
      case "Get Diagnostics":
        return (
          <GetDiagnostics
            onInputChange={onInputChange}
            formValues={formValues}
          />
        );
      case "Update Firmware":
        return (
          <UpdateFirmware
            onInputChange={onInputChange}
            formValues={formValues}
          />
        );
      case "Reset":
        return <Reset onInputChange={onInputChange} formValues={formValues} />;
      case "Trigger Message":
        return (
          <TriggerMessage
            onInputChange={onInputChange}
            formValues={formValues}
          />
        );
      case "Unlock Connector":
        return (
          <UnlockConnector
            onInputChange={onInputChange}
            formValues={formValues}
          />
        );
      // Add other cases as needed
      default:
        return <div>Select a configuration to see details</div>;
    }
  };

  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        height: "calc(100vh - 332px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <span className="material-symbols-outlined">settings</span>
          <Heading text={selectedConfig || "Configuration Name"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
        <Box sx={{ padding: "16px 0px" }}>{renderContent()}</Box>
      </Box>

      <Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            mt: "16px",
          }}
        >
          <Button
            onClick={onSendMessage}
            sx={{
              padding: "8px 24px",
              borderRadius: "4px",
              background: "#222",
              fontFamily: "mySecondFont",
              fontSize: "16px",
              color: "#fff",
              ":hover": {
                background: "#222",
              },
            }}
          >
            Send Message
          </Button>
          <Button
            onClick={onClear}
            sx={{
              background: "transparent",
              fontFamily: "mySecondFont",
              fontSize: "13px",
              color: "#790000",
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfigurationMessageBox;
