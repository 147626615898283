import { Box, Skeleton, Divider, Typography } from "@mui/material";
import React from "react";

const TopPerformingSkeleton = () => {
  return (
    <Box
      sx={{
        padding: "24px 24px 28px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Skeleton variant="circular" width={24} height={24} animation="wave" />
        <Skeleton variant="text" width={150} height={24} animation="wave" />
      </Box>
      <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />

      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {[...Array(3)].map((_, index) => (
          <Box
            key={index}
            sx={{
              padding: "12px",
              borderRadius: "4px",
              background:
                index === 0
                  ? "rgba(255, 176, 46, 0.1)"
                  : index === 1
                  ? "rgba(211, 136, 62, 0.1)"
                  : "rgba(190, 190, 190, 0.1)",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width={120}
                height={20}
                animation="wave"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Skeleton
                  variant="circular"
                  width={16}
                  height={16}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width={60}
                  height={20}
                  animation="wave"
                />
              </Box>
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Skeleton
                  variant="circular"
                  width={16}
                  height={16}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width={60}
                  height={20}
                  animation="wave"
                />
              </Box>
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Skeleton
                  variant="circular"
                  width={16}
                  height={16}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width={60}
                  height={20}
                  animation="wave"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TopPerformingSkeleton;
