import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints"; // Adjust the path as necessary

// Initial state
const initialState = {
  allStations: [],
  stationList: [],
  hubStations: [],
  hubCities: [],
  chargingPasses: [],
  passHolders: [],
  guards: [],
  loading: false,
  error: null,
  chargingPass: null, // State to store the created charging pass
  selectedChargingPass: null,
  guard: null,
  guardAttendance: [],
  transactions: [],
  shiftCollections: [],
  stationChargers: [],
  guardDetails: null,
  allSessions: [],
  singleSession: null,
  getOneStationGuardDetails: null,
  shiftAllTransaction: [],
  hubStats: [],
  hubStationStats: [],
};

// Thunk for fetching all hub stations
export const getAllHubStation = createAsyncThunk(
  "hubStations/getAllHubStation",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.getAllHubStation}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for creating a charging pass
export const createChargingPass = createAsyncThunk(
  "hubStations/createChargingPass",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.createChargingPass}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all charging passes
export const getAllChargingPasses = createAsyncThunk(
  "hubStations/getAllChargingPasses",
  async (payload, { rejectWithValue }) => {
    const { stationIds = [], startDate = null, endDate = null } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getAllChargingPasses}?startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getAllChargingPasses}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching a single charging pass
export const getOneChargingPass = createAsyncThunk(
  "hubStations/getOneChargingPass",
  async (passId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneChargingPass}/${passId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all pass holders
export const getAllPassHolders = createAsyncThunk(
  "hubStations/getAllPassHolders",
  async (payload, { rejectWithValue }) => {
    const { stationIds = [], startDate = null, endDate = null } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getAllPassHolders}?startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getAllPassHolders}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for creating a guard
export const createGuard = createAsyncThunk(
  "hubStations/createGuard",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${endPoints.createGuard}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all guards
export const getAllGuards = createAsyncThunk(
  "hubStations/getAllGuards",
  async (payload, { rejectWithValue }) => {
    const { stationIds = [], startDate = null, endDate = null } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getAllGuards}?startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getAllGuards}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching attendance of a specific guard
export const getOneGuardAttendance = createAsyncThunk(
  "hubStations/getOneGuardAttendance",
  async (guardId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneGuardAttendance}/${guardId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getOneGuardDetails = createAsyncThunk(
  "hubUser/getOneGuardDetails",
  async (guardId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneGuardDetails}/${guardId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all transactions
export const getAllTransaction = createAsyncThunk(
  "hubStations/getAllTransaction",
  async (payload, { rejectWithValue }) => {
    const { stationIds = [], startDate = null, endDate = null } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getAllTransaction}?startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getAllTransaction}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching shift collections
// export const getShiftCollection = createAsyncThunk(
//   "hubStations/getShiftCollection",
//   async (payload = {}, { rejectWithValue }) => {
//     const { stationIds = [], startDate = null, endDate = null } = payload || {};

//     try {
//       const token = localStorage.getItem("token");
//       const formattedUrl =
//         startDate && endDate && stationIds
//           ? `${endPoints.getShiftCollection}?startTime=${startDate}&endTime=${endDate}`
//           : `${endPoints.getShiftCollection}`;

//       const response = await axios.post(formattedUrl, stationIds, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return response.data.data; // Adjust based on your API response structure
//     } catch (error) {
//       return rejectWithValue(error.response?.data || "Something went wrong");
//     }
//   }
// );

export const getShiftCollection = createAsyncThunk(
  "hubStations/getShiftCollection",
  async (payload, { rejectWithValue }) => {
    const { stationIds = [], startDate = null, endDate = null } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getShiftCollection}?startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getShiftCollection}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all stations hub
export const getAllStationsHub = createAsyncThunk(
  "hubStations/getAllStationsHub",
  async (payload, { rejectWithValue }) => {
    const { stationIds = [], startDate = null, endDate = null } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getAllStationsHub}?startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getAllStationsHub}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all stations hub
// export const getAllStationsHub = createAsyncThunk(
//   "hubStations/getAllStationsHub",
//   async (_, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get(`${endPoints.getAllStationsHub}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return response.data.data; // Adjust based on your API response structure
//     } catch (error) {
//       return rejectWithValue(error.response?.data || "Something went wrong");
//     }
//   }
// );

// Thunk for updating a charging pass
export const updateChargingPass = createAsyncThunk(
  "hubStations/updateChargingPass",
  async ({ passId, formData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateChargingPass}/${passId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for updating a guard
export const updateOneGuard = createAsyncThunk(
  "hubStations/updateOneGuard",
  async ({ guardId, formData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateOneGuard}/${guardId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Adjust if necessary
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getAllStationList = createAsyncThunk(
  "hubStations/getAllStationList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.getAllStationList}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for fetching all station chargers
export const getAllStationChargers = createAsyncThunk(
  "hubStations/getAllStationChargers",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAllStationChargers}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for creating a hub station
export const createHubStation = createAsyncThunk(
  "hubStations/createHubStation",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.createHubStation}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Adjust based on your needs
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Thunk for updating a hub station
export const updateHubStation = createAsyncThunk(
  "hubStations/updateHubStation",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${endPoints.updateHubStation}/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Adjust based on your needs
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getAllHubSessions = createAsyncThunk(
  "hubSessions/getAllHubSessions",
  async (payload, { rejectWithValue }) => {
    const {
      activeCheck,
      stationIds = [],
      startDate = null,
      endDate = null,
    } = payload || {};
    try {
      const token = localStorage.getItem("token");
      const formattedUrl =
        startDate && endDate && stationIds
          ? `${endPoints.getAllHubSessions}?isActive=${activeCheck}&startTime=${startDate}&endTime=${endDate}`
          : `${endPoints.getAllHubSessions}?isActive=${activeCheck}`;

      const response = await axios.post(formattedUrl, stationIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getOneHubSession = createAsyncThunk(
  "hubSessions/getOneHubSession",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneHubSessions}/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getOneStationHubGuard = createAsyncThunk(
  "hubStations/getOneStationHubGuard",
  async (hubId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStationHubGuardDetails}/${hubId}`, // Adjust the endpoint as necessary
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getShiftAllTransaction = createAsyncThunk(
  "hubStations/getShiftAllTransaction",
  async (hubId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getShiftAllTransaction}/${hubId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const fetchHubStats = createAsyncThunk(
  "hubStations/fetchHubStats",
  async ({ durationType, startTime, endTime }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getHubStats}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming the data is inside `data.data`
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchHubStationStats = createAsyncThunk(
  "hubStations/fetchHubStationStats",
  async ({ durationType, startTime, endTime, stationIds }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.getHubStats}?durationType=${durationType}&startTime=${startTime}&endTime=${endTime}`,
        { stationIds: stationIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming the data is inside `data.data`
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create the stations slice
const stationsSlice = createSlice({
  name: "hubManagement",
  initialState,
  reducers: {
    // Add any additional synchronous actions if needed
  },
  extraReducers: (builder) => {
    // Handle getAllHubStation
    builder
      .addCase(getAllHubStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllHubStation.fulfilled, (state, action) => {
        state.loading = false;
        state.hubStations = action.payload.allStations; // Store the fetched stations
        state.hubCities = action.payload.allCities;
      })
      .addCase(getAllHubStation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // Handle createChargingPass
    builder
      .addCase(createChargingPass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createChargingPass.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPass = action.payload; // Store the created charging pass
      })
      .addCase(createChargingPass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // Handle getAllChargingPasses
    builder
      .addCase(getAllChargingPasses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllChargingPasses.fulfilled, (state, action) => {
        state.loading = false;
        state.chargingPasses = action.payload; // Store the fetched charging passes
      })
      .addCase(getAllChargingPasses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // Handle getOneChargingPass
    builder
      .addCase(getOneChargingPass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneChargingPass.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedChargingPass = action.payload; // Store the fetched charging pass
      })
      .addCase(getOneChargingPass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    // Handle getAllPassHolders
    builder
      .addCase(getAllPassHolders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPassHolders.fulfilled, (state, action) => {
        state.loading = false;
        state.passHolders = action.payload; // Store the fetched pass holders
      })
      .addCase(getAllPassHolders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // Handle createGuard
    builder
      .addCase(createGuard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGuard.fulfilled, (state, action) => {
        state.loading = false;
        state.guard = action.payload; // Store the created guard
      })
      .addCase(createGuard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    // Handle getAllGuards
    builder
      .addCase(getAllGuards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllGuards.fulfilled, (state, action) => {
        state.loading = false;
        state.guards = action.payload; // Store the fetched guards
      })
      .addCase(getAllGuards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // Handle getOneGuardAttendance
    builder
      .addCase(getOneGuardAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneGuardAttendance.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming you want to store attendance in a specific state
        state.guardAttendance = action.payload; // Store the fetched guard attendance
      })
      .addCase(getOneGuardAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // Handle getOneGuardDetails
    builder
      .addCase(getOneGuardDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneGuardDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.guardDetails = action.payload;
      })
      .addCase(getOneGuardDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Inside the extraReducers section
    builder
      .addCase(getAllTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload; // Store the fetched transactions
      })
      .addCase(getAllTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    // Inside the extraReducers section
    builder
      // Handle getShiftCollection
      .addCase(getShiftCollection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getShiftCollection.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming you want to store the fetched shift collections
        state.shiftCollections = action.payload; // Add a new state property if necessary
      })
      .addCase(getShiftCollection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    // Handle getAllStationsHub
    builder
      .addCase(getAllStationsHub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStationsHub.fulfilled, (state, action) => {
        state.loading = false;
        state.allStations = action.payload; // Store the fetched stations
      })
      .addCase(getAllStationsHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    // update charging pass
    builder.addCase(updateChargingPass.fulfilled, (state, action) => {
      state.loading = false;
      const updatedPass = action.payload; // This should be the updated charging pass

      // Find the index of the pass to update
      const index = state.chargingPasses.findIndex(
        (pass) => pass._id === updatedPass._id // Ensure this matches your API response
      );

      if (index !== -1) {
        state.chargingPasses[index] = updatedPass; // Update the existing pass in the array
      }
    });

    builder
      // Handle getAllStationList
      .addCase(getAllStationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStationList.fulfilled, (state, action) => {
        state.loading = false;
        state.stationList = action.payload; // Store the fetched station list
      })
      .addCase(getAllStationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    builder
      // Handle getAllStationChargers
      .addCase(getAllStationChargers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStationChargers.fulfilled, (state, action) => {
        state.loading = false;
        state.stationChargers = action.payload; // Store the fetched station chargers
      })
      .addCase(getAllStationChargers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });

    builder
      // Handle createHubStation
      .addCase(createHubStation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createHubStation.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming the response returns the created hub station
        state.hubStations.push(action.payload); // Add the new station to the list
      })
      .addCase(createHubStation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
    builder
      .addCase(getAllHubSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllHubSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.allSessions = action.payload;
      })
      .addCase(getAllHubSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getOneHubSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneHubSession.fulfilled, (state, action) => {
        state.loading = false;
        state.singleSession = action.payload;
      })
      .addCase(getOneHubSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getOneStationHubGuard.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(getOneStationHubGuard.fulfilled, (state, action) => {
        state.loading = false;
        state.getOneStationGuardDetails = action.payload; // Store the response data
      })
      .addCase(getOneStationHubGuard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      });
    builder
      .addCase(getShiftAllTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getShiftAllTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.shiftAllTransaction = action.payload; // Adjust as per your data structure
      })
      .addCase(getShiftAllTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchHubStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHubStats.fulfilled, (state, action) => {
        state.hubStats = action.payload;
        state.loading = false;
      })
      .addCase(fetchHubStats.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
    builder
      .addCase(fetchHubStationStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHubStationStats.fulfilled, (state, action) => {
        state.hubStationStats = action.payload;
        state.loading = false;
      })
      .addCase(fetchHubStationStats.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// Export actions if needed
export const {} = stationsSlice.actions;

// Export the reducer
export default stationsSlice.reducer;
