import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Heading from "../../utils/Heading";
import PhoneInput from "react-phone-number-input";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createOcpiTariff } from "../../redux/action/ocpiManagement/ocpiSlice";
import { toast } from "react-toastify";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InputField = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  type = "text",
  disabled,
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="#2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    {type === "phone" ? (
      <PhoneInput
        international
        defaultCountry="IN"
        value={value}
        className={"input-phone-number"}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          color: "rgba(47, 47, 47, 0.50)",
          fontFamily: "myThirdFont",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          position: "relative",
          width: "100%",
          height: "48px",
          borderRadius: "4px",
          border: "1px solid #ddd",
          padding: "0 12px",
          boxSizing: "border-box",
          backgroundColor: "#fff",
        }}
      />
    ) : (
      <input
        type={type}
        style={{
          padding: "12px 16px",
          borderRadius: "4px",
          border: "1px solid #cbcbcb",
          color: "rgba(47, 47, 47, 0.50)",
          fontFamily: "myThirdFont",
          fontSize: "16px",
          height: "48px",
          outline: "none",
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
    )}
  </Box>
);

const SelectField = ({ label, value, handleChange, menuItems }) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <FormControl fullWidth>
      <InputLabel
        sx={{ fontSize: "14px", fontFamily: "myThirdFont", textAlign: "start" }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        sx={{
          height: "48px",
          fontFamily: "myThirdFont !important",
          textAlign: "start",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const TextareaField = ({ label, placeholder, value, onChange, name }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    <Typography
      textAlign="start"
      fontSize="14px"
      color="2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <textarea
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "100px",
        outline: "none",
        resize: "none",
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
    />
  </Box>
);

const Section = ({ icon, title, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span
          style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
        <Heading text={title} />
      </Box>
      <Divider sx={{ mt: "8px" }} />
    </Box>
    {children}
  </Box>
);

const AddNewOcpiTariffForm = () => {
  const [selectedWorkingDays, setSelectedWorkingDays] = useState([]);
  const [isAllDays, setIsAllDays] = useState(false);
  const [openingTime, setOpenTime] = useState(dayjs());
  const [closingTime, setCloseTime] = useState(dayjs());
  const [workingDays, setWorkingDays] = useState([]);
  const [isOpen24Hours, setIsOpen24Hours] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const { state } = location;
  const networkDetail = state?.networkDetail;
  console.log("ffcccc", networkDetail);

  const [formData, setFormData] = useState({
    name: "",
    tariff_alt_text: {},
    currency: "",
    tariffType: "",
    min_price: 0,
    max_price: 0,

    base_charge: 0,
    roaming_fee: 0,
    // workingDays: [],
    // openingTime: 0,
    // closingTime: 0,

    // price: "",
    // vatInPercentage: "",
    elements: [
      {
        price_components: [],
      },
    ],
  });
  const [priceComponents, setPriceComponents] = useState([]);

  const navigate = useNavigate();

  const handleAddPriceComponent = () => {

    const isFormValid = validateFormForAddignPriceComponent();
    if (!isFormValid) return;


    // Create the new price component entry
    const newPriceComponent = {
      price: formData.price,
      vat: formData.vat,
      step_size: formData.step_size,
      type: formData.type,
    };

    console.log("tariffocpi", newPriceComponent);

    //Tbale Data To Be Mapped
    const tableDataToBeMapped = {
      workingDays: formData.workingDays,
      openingTime: formData.start_time,
      closingTime: formData.end_time,
      price: formData.price,
      vat: formData.vat,
      step_size: formData.step_size,
      type: formData.type,
    };
    setPriceComponents((prev) => [...prev, tableDataToBeMapped]);

    // Update the allPriceData array with the new entry
    setFormData((prevState) => {
      const newPriceComp = [
        ...prevState.elements[0].price_components,
        newPriceComponent,
      ];
      return {
        ...prevState,
        elements: [
          {
            price_components: newPriceComp,
            restrictions: {
              day_of_week: formData.day_of_week,
              start_time: formData.start_time,
              end_time: formData.end_time,
            },
          },
        ],
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name) => (event) => {
    setFormData((prev) => ({ ...prev, [name]: event.target.value }));
  };

  const orgMenuItem = [{ value: "REGULAR", label: "REGULAR" }];

  const tariffPriceType = [
    { value: "ENERGY", label: "ENERGY" },
    // { value: "FLAT", label: "FLAT" },
    // { value: "TIME", label: "TIME" },
  ];

  const currencyIcon = () => {
    switch (formData.currency) {
      case "INR":
        return "currency_rupee"; // Icon for INR
      case "USD":
        return "attach_money"; // Icon for USD
      case "GBP":
        return "currency_pound"; // Icon for GBP
      default:
        return "currency_rupee"; // Default icon
    }
  };

  const amenitiesList = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  // Handling changes in working days selection
  const handleWorkingDaysClick = (day) => {
    const updatedWorkingDays = selectedWorkingDays.includes(day)
      ? selectedWorkingDays.filter((item) => item !== day)
      : [...selectedWorkingDays, day];

    setSelectedWorkingDays(updatedWorkingDays);

    // Update form data and check if all days are selected
    const allDaysSelected = updatedWorkingDays.length === amenitiesList.length;
    setIsAllDays(allDaysSelected);

    setFormData((prev) => ({
      ...prev,
      day_of_week: updatedWorkingDays,
    }));
  };

  const handleIsAllDaysChange = () => {
    const newIsAllDays = !isAllDays;
    setIsAllDays(newIsAllDays);

    const newWorkingDays = newIsAllDays ? amenitiesList : [];
    setSelectedWorkingDays(newWorkingDays);

    setFormData((prev) => ({
      ...prev,
      day_of_week: newWorkingDays,
    }));
  };

  const handleOpenTimeChange = (newValue) => {
    console.log("New Value:", newValue);
    setOpenTime(newValue);
    if (!isOpen24Hours) {
      setFormData({
        ...formData,
        start_time: newValue ? newValue.format("HH:mm") : "",
      });
    }
  };

  const handleCloseTimeChange = (newValue) => {
    setCloseTime(newValue);
    if (!isOpen24Hours) {
      setFormData({
        ...formData,
        end_time: newValue ? newValue.format("HH:mm") : "",
      });
    }
  };

  // const handleIsOpen24HoursChange = (event) => {
  //   const isChecked = event.target.checked;
  //   setIsOpen24Hours(isChecked);

  //   if (isChecked) {
  //     // Set times to 24 hours mode
  //     const open24 = dayjs()
  //       .set("hour", 0)
  //       .set("minute", 0)
  //       .set("second", 0)
  //       .set("millisecond", 0); // 12:00 AM
  //     const close24 = dayjs()
  //       .set("hour", 23)
  //       .set("minute", 59)
  //       .set("second", 0)
  //       .set("millisecond", 0); // 11:59 PM

  //     setOpenTime(open24);
  //     setCloseTime(close24);

  //     setFormData({
  //       ...formData,
  //       start_time: open24.format("HH:mm"),
  //       end_time: close24.format("HH:mm"),
  //     });
  //   } else {
  //     // Use real-time values from the picker when unchecked
  //     const newOpeningTime = openingTime ? openingTime.format("HH:mm") : "";
  //     const newClosingTime = closingTime ? closingTime.format("HH:mm") : "";

  //     setFormData({
  //       ...formData,
  //       start_time: newOpeningTime.format("HH:mm"),
  //       end_time: newClosingTime.format("HH:mm"),
  //     });
  //   }
  // };

  // const handleSubmit = async () => {
  //   // Prepare the data to send
  //   const finalData = {
  //     ...formData,
  //     type: formData.tariffType,
  //     tariff_alt_text: {
  //       text: formData.name,
  //       language: "en",
  //     },
  //   };

  //   console.log("tariffocpi", finalData); // Log all fields to the console

  //   // navigate(-1);
  // };

  const handleIsOpen24HoursChange = (event) => {
    const isChecked = event.target.checked;
    setIsOpen24Hours(isChecked);

    if (isChecked) {
      // Set times to 24 hours mode
      const open24 = dayjs().set("hour", 0).set("minute", 0);
      const close24 = dayjs().set("hour", 23).set("minute", 59);

      setOpenTime(open24);
      setCloseTime(close24);

      setFormData({
        ...formData,
        start_time: open24.format("HH:mm"),
        end_time: close24.format("HH:mm"),
      });
    } else {
      // Use real-time values from the picker when unchecked
      const newOpeningTime = openingTime.format("HH:mm"); // Ensure openingTime is a Day.js object
      const newClosingTime = closingTime.format("HH:mm"); // Ensure closingTime is a Day.js object

      setFormData({
        ...formData,
        start_time: newOpeningTime, // Use formatted string directly
        end_time: newClosingTime, // Use formatted string directly
      });
    }
  };

  const validateForm = () => {
    // Define all required fields, including vatInPercentage, price, priceType, stepSize
    const requiredFields = [
      "name",
      "currency",
      "tariffType",
      "min_price",
      "max_price",
      "base_charge",
      "roaming_fee",
      "vat", // Add validation for VAT percentage
      "price", // Add validation for price
      "step_size", // Add validation for step_size
      "type", // Add validation for priceType
    ];

    let isValid = true;
    const missingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field] === "") {
        missingFields.push(field);
        isValid = false;
      }
    });

    // Check if price and step_size are positive numbers
    if (formData.price && formData.price <= 0) {
      toast.error("Price must be a positive number");
      isValid = false;
    }

    if (formData.step_size && formData.step_size <= 0) {
      toast.error("Step Size must be a positive number");
      isValid = false;
    }

    // If any fields are missing or invalid, show a toast error
    if (!isValid) {
      const missingFieldsList = missingFields.length
        ? `Please fill the following required fields: ${missingFields.join(
            ", "
          )}`
        : "";
      toast.error(missingFieldsList || "Some fields are invalid or missing");
    }

    return isValid;
  };


  const validateFormForAddignPriceComponent = () => {

    const requiredFields = [
      "vat", 
      "price", 
      "step_size", 
      "type", 
    ];

    let isValid = true;
    const missingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field] === "") {
        missingFields.push(field);
        isValid = false;
      }
    });

    // Check if price and step_size are positive numbers
    if (formData.price && formData.price <= 0) {
      toast.error("Price must be a positive number");
      isValid = false;
    }

    if (formData.step_size && formData.step_size <= 0) {
      toast.error("Step Size must be a positive number");
      isValid = false;
    }

    // If any fields are missing or invalid, show a toast error
    if (!isValid) {
      const missingFieldsList = missingFields.length
        ? `Please fill the following required fields: ${missingFields.join(
            ", "
          )}`
        : "";
      toast.error(missingFieldsList || "Some fields are invalid or missing");
    }

    return isValid;
  };

  const handleSubmit = async () => {

    const isFormValid = validateForm();
    if (!isFormValid) return;

    // Prepare the data to send
    const finalData = {
      ...formData,
      tariff_alt_text: {
        text: formData.name,
        language: "en",
      },
    };

    console.log("tariffocpi", finalData); // Log all fields to the console

    try {
      const operatorId = networkDetail?.operatorId;
      await dispatch(createOcpiTariff({ formData: finalData, operatorId }));

      toast.success("Tariff created successfully!", {});
    } catch (error) {
      console.error("Error creating tariff:", error);

      toast.error("Error creating tariff. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }

    navigate(-1);
  };

  return (
    <Box
      sx={{
        padding: "24px 24px 78px 24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      {/* Basic Details */}
      <Section icon="ev_station" title="Tariff Basic details">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Tariff Name*"
                placeholder="Enter the Tariff Name here"
                value={formData.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SelectField
                label="Tariff type*"
                value={formData.tariffType}
                handleChange={handleSelectChange("tariffType")}
                menuItems={orgMenuItem}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Base Charge*"
                placeholder="Enter the Base Charge here"
                value={formData.base_charge}
                onChange={handleInputChange}
                name="base_charge"
                type="number"
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <InputField
                label="Roaming Fee*"
                placeholder="Enter the Roaming Fee here"
                value={formData.roaming_fee}
                onChange={handleInputChange}
                name="roaming_fee"
                type="number"
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={2}>
            <Grid item md={1} sm={6} xs={12}>
              <Box>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Currency*
                </label>
                <FormControl fullWidth>
                  <Select
                    name="currency"
                    value={formData.currency}
                    onChange={handleInputChange}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      color: "#1C1C1C",
                      fontFamily: "myThirdFont",
                      lineHeight: "24px",
                      fontSize: "16px",
                    }}
                  >
                    <MenuItem value="">Select the Currency</MenuItem>
                    <MenuItem value="INR">INR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={2.5} sm={6} xs={12}>
              <Box>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Minimum Charges*
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Minimum Charges"
                  fullWidth
                  name="min_price"
                  value={formData.min_price}
                  onChange={handleInputChange}
                  type={"Number"}
                />
              </Box>
            </Grid>
            <Grid item md={2.5} sm={6} xs={12}>
              <Box>
                <label
                  style={{
                    color: "#2F2F2F",
                    fontFamily: "myThirdFont",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Maximum Charges*
                </label>
                <TextField
                  style={{
                    color: "rgba(47, 47, 47, 0.50)",
                    fontFamily: "myThirdFont",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-symbols-outlined">
                          {currencyIcon()}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Maximum Charges"
                  fullWidth
                  name="max_price"
                  value={formData.max_price}
                  onChange={handleInputChange}
                  type={"Number"}
                />
              </Box>
            </Grid>
            <Grid item md={2.5} sm={6} xs={12}>
              <Box></Box>
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/*Price Components*/}
      <Section icon="local_convenience_store" title="Price Components">
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <SelectField
              label="Tariff Price Type*"
              value={formData.type}
              handleChange={handleSelectChange("type")}
              menuItems={tariffPriceType}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <InputField
              label="Step Size*"
              placeholder="Enter the setp size here"
              value={formData.step_size}
              onChange={handleInputChange}
              name="step_size"
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <InputField
              label="Price*"
              placeholder="Enter the Price here"
              value={formData.price}
              onChange={handleInputChange}
              name="price"
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <InputField
              label="Vat in Percentage*"
              placeholder="Enter the vatInPercentage here"
              value={formData.vat}
              onChange={handleInputChange}
              name="vat"
            />
          </Grid>
        </Grid>
      </Section>

      {/*Restrictions*/}
      <Section icon="local_convenience_store" title="Restrictions">
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                fontSize={"14px"}
                fontFamily={"myThirdFont"}
                color={"#666"}
                textAlign={"center"}
              >
                Days of the Week
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  mt: "12px",
                  flexWrap: "wrap",
                }}
              >
                {amenitiesList.map((item, index) => {
                  const isActive = selectedWorkingDays.includes(item);
                  return (
                    <Box
                      key={index}
                      onClick={() => handleWorkingDaysClick(item)}
                      sx={{
                        padding: "12px",
                        background: isActive ? "#292D33" : "#fff",
                        borderRadius: "6px",
                        maxWidth: "100px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        border: isActive
                          ? "1px solid transparent"
                          : "1px solid rgba(41, 45, 51, 0.50)",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        fontSize={"14px"}
                        color={isActive ? "#fff" : "#000"}
                        fontFamily={"mySecondFont"}
                      >
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Checkbox
                  checked={isAllDays}
                  onChange={handleIsAllDaysChange}
                />
                <Typography
                  color={"#2F2F2F"}
                  fontFamily={"myThirdFont"}
                  fontSize={"14px"}
                >
                  All Days
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                fontSize={"14px"}
                fontFamily={"myThirdFont"}
                color={"#666"}
                textAlign={"center"}
              >
                Timings
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={openingTime}
                      onChange={handleOpenTimeChange}
                      ampm={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select open"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={closingTime}
                      ampm={false}
                      onChange={handleCloseTimeChange}
                      minTime={openingTime ? openingTime : null} // Add conditional restriction
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select close"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={isOpen24Hours}
                  onChange={handleIsOpen24HoursChange}
                />
                <Typography
                  color="#2F2F2F"
                  fontFamily="myThirdFont"
                  fontSize="14px"
                >
                  Open 24 hrs
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Button
          onClick={handleAddPriceComponent}
          sx={{
            padding: "8px 16px",
            background: "#FFF",
            borderRadius: "4px",
            color: "#E50000",
            fontSize: "14px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "600",
            maxWidth: "255px",
            border: "1px solid #E50000",
            width: "100%",
          }}
        >
          + Add price component
        </Button>
      </Section>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container>
          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                padding: "8px 16px",
                borderRadius: "4px",
                background: "rgba(41, 45, 51, 0.10)",
              }}
            >
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"5%"}
              >
                S.No
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"20%"}
              >
                Tariff Price Type
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"20%"}
              >
                Price
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"20%"}
              >
                Vat(%)
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"20%"}
              >
                Step Size
              </Typography>

              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"20%"}
              >
                Timings
              </Typography>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "mySecondFont",
                  fontSize: "14px",
                  fontStyle: "normal",

                  lineHeight: "24px",
                }}
                width={"15%"}
              >
                Working Days
              </Typography>

              {/* <Typography width={"20%"}>Action</Typography> */}
            </Box>

            {priceComponents.map((item, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    padding: "12px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",

                      lineHeight: "24px",
                    }}
                    width={"5%"}
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",

                      lineHeight: "24px",
                    }}
                    width={"20%"}
                  >
                    {item.type}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",

                      lineHeight: "24px",
                    }}
                    width={"20%"}
                  >
                    {item.price}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",

                      lineHeight: "24px",
                    }}
                    width={"20%"}
                  >
                    {item.vat}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",

                      lineHeight: "24px",
                    }}
                    width={"20%"}
                  >
                    {item.step_size}
                  </Typography>
                  <Typography
                    width={"15%"}
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",
                      lineHeight: "24px",
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {amenitiesList.map((day) => {
                      const initial = day.charAt(0).toUpperCase(); // Get the initial
                      const isSelected = selectedWorkingDays.includes(day); // Check if day is selected

                      return (
                        <span
                          key={day}
                          style={{
                            color: isSelected ? "#0CA109" : "#790000", // Green if selected, blue if unselected
                          }}
                        >
                          {initial}
                        </span>
                      );
                    })}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#2F2F2F",
                      fontFamily: "myThirdFont",
                      fontSize: "14px",
                      fontStyle: "normal",

                      lineHeight: "24px",

                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    width={"20%"}
                  >
                    {item.openingTime} - {item.closingTime}
                  </Typography>
                  <Box>
                    {/* <span
          className="material-symbols-outlined"
          onClick={() => handleDeletePriceComponent(index)} // Delete functionality
          style={{ cursor: 'pointer', color: 'red' }} // Styling
        >
          delete
        </span> */}
                  </Box>
                </Box>
                <Divider />
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={handleSubmit}
          disabled={priceComponents.length === 0}
          sx={{
            padding: "12px 24px",
            background: priceComponents?.length === 0 ? "#e3e3e3" : "#E50000",
            borderRadius: "4px",
            color: "#FCFCFC",
            fontSize: "16px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "700",
            letterSpacing: "1.28px",
            ":hover": {
              background: "#E50000",
            },
          }}
        >
          Save and Proceed
        </Button>
      </Box>
    </Box>
  );
};

export default AddNewOcpiTariffForm;
