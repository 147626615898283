import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomTabs from "../../custom/CustomTabs";
import {
  HubAllCollectionColumns,
  HubDailyCollectionColumns,
} from "../../../utils/DummyData";
import DailySheet from "./DailySheet";
import AllCollection from "./AllCollection";

const Collection = ({ data, shiftCollection }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabsData = [
    {
      label: "Guard Shift Collection",
      content: (
        <DailySheet
          columns={HubDailyCollectionColumns()}
          row={shiftCollection}
        />
      ),
    },
    {
      label: "All Transactions",
      content: <AllCollection columns={HubAllCollectionColumns()} row={data} />,
    },
  ];
  return (
    <Box sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}>
      {/* Custom Tabs */}
      <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />

      {/* Tab content displayed below the tabs */}
      <Box sx={{ mt: 3 }}>{tabsData[selectedTab].content}</Box>
    </Box>
  );
};

export default Collection;
