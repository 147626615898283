import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../../utils/Heading";

const InfoBox = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"start"}
        textTransform={"capitalize"}
      >
        {value}
      </Typography>
    </Box>
  );
};

const GuardInfo = ({ data }) => {
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            security
          </span>
          <Heading text={"Guard Information"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* Guard specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Guard ID" value={data?._id} />
        <InfoBox label="Guard Name" value={data?.name} />
        <InfoBox label="Mobile Number" value={data?.phone} />
        <InfoBox label="Email" value={data?.email} />
        <InfoBox label="Address" value={data?.address} />

        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "32px",
          }}
        >
          <Box>
            <InfoBox label="Created At" value={data?.createdAt} />
          </Box>
          <Box>
            <InfoBox label="Last Modified" value={data?.lastModified} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default GuardInfo;
