import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../../utils/MainHeading";
import CustomTabs from "../custom/CustomTabs";
import TableBox from "./TableBox";
import {
  allChargingLocationsOcpiColumnData,
  chargingLocationsOcpiColumnData,
  ocpiChargerColumns,
  ocpiChargerRowData,
  ocpiNetworkColumns,
  ocpiNetworkRowColumns,
  ocpiTariffColumns,
  ocpiTariffRowData,
} from "../../utils/DummyData";
import OverviewBox from "./OverviewBox";
import TimeFilter from "../Filters/TimeFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOcpiLocations,
  getAllOcpiNetworks,
  getAllOcpiTariffs,
  getAllThirdPartyChargers,
} from "../../redux/action/ocpiManagement/ocpiSlice";

const TopBar = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [durationType, setDurationType] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  //added for previous time
  const [prevStartTime, setPrevStartTime] = useState(null);
  const [prevEndTime, setPrevEndTime] = useState(null);

  const dispatch = useDispatch();

  // Access the state from the Redux store
  const {
    networks,
    allTariffs,
    allThirdPartyChargers,
    allLocationsData,
    loading,
    error,
  } = useSelector((state) => state.ocpi);

  console.log("jjjffff", allThirdPartyChargers);

  useEffect(() => {
    // Dispatch the action to fetch OCPI networks
    dispatch(getAllOcpiNetworks());
    dispatch(getAllOcpiTariffs());
    dispatch(getAllThirdPartyChargers());
    dispatch(getAllOcpiLocations());
  }, [dispatch]);

  const evsesNestedData = allThirdPartyChargers.map((form) => {
    // Extract the sessionCount from the first connector (if available)
    const nestedSessionCount =
      form.connectors && form.connectors.length > 0
        ? form.connectors[0].sessions
          ? form.connectors[0].sessions.sessionCount
          : 0
        : 0;

    const nestedBilledAmount =
      form.connectors && form.connectors.length > 0
        ? form.connectors[0].sessions
          ? form.connectors[0].sessions.totalCostInclVat
          : 0
        : 0;

    const nestedEnergyConsumed =
      form.connectors && form.connectors.length > 0
        ? form.connectors[0].sessions
          ? form.connectors[0].sessions.totalKwh
          : 0
        : 0;



  // Extract the tariff ID from the first tariff in the first connector (if available)
  const nestedTariffId =
    form.connectors && form.connectors.length > 0 && form.connectors[0].tariff_ids && form.connectors[0].tariff_ids.length > 0
      ? form.connectors[0].tariff_ids[0]?.id
      : null; // You can use `null` or `0` depending on your preference

    const standards = form.connectors
      ? [
          ...new Set(form.connectors.map((connector) => connector.standard)),
        ].join(", ")
      : "";

    return {
      nestedDeviceId: form.evse_id,
      nestedStatus: form.status,
      nestedOperatorId: form.operatorId,
      nestedNoOfConnectors: form.connectors ? form.connectors.length : 0,
      // nestedChargerType: form.connectors  ?
      nestedSessionCount: nestedSessionCount,
      nestedBilledAmount: nestedBilledAmount,
      nestedLastUpdated: form.last_updated,
      nestedEnergyConsumed: nestedEnergyConsumed,
      nestedStandards: standards,
      nestedTariffId: nestedTariffId
    };
  });

  const networksNestedData = networks.map((form) => {
    const { networks, ...rest } = form;
    return {
      ...rest,
      createdAt: form.ocpiOrg ? form.ocpiOrg.createdAt : form.createdAt,
      nestedStatus: form?.ocpiOrg ? "registered" : "unregistered",
    };
  });

  const tabsData = [
    {
      label: "overview",
      content: (
        <Box>
          <OverviewBox
            selectedTime={durationType}
            startTime={startTime}
            endTime={endTime}
          />
        </Box>
      ),
    },
    {
      label: "networks",
      content: (
        <Box>
          <TableBox
            columns={ocpiNetworkColumns}
            rows={networksNestedData}
            isRowClickable={true}
            showAddButton={true}
            customAction={"network"}
          />
        </Box>
      ),
    },
    {
      label: "3rd Party OCPI chargers",
      content: (
        <Box>
          <TableBox
            columns={ocpiChargerColumns}
            rows={evsesNestedData}
            isRowClickable={false}
            showAddButton={false}
            customAction={"ocpi"}
          />
        </Box>
      ),
    },
    {
      label: "Locations",
      content: (
        <Box>
          <TableBox
            columns={allChargingLocationsOcpiColumnData()}
            rows={allLocationsData}
            isRowClickable={false}
            customAction={"tariff"}
          />
        </Box>
      ),
    },
    {
      label: "Tariffs",
      content: (
        <Box>
          <TableBox
            columns={ocpiTariffColumns}
            // rows={ocpiTariffRowData}
            rows={allTariffs}
            isRowClickable={false}
            customAction={"tariff"}
          />
        </Box>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <MainHeading text={"OCPI Management"} />
        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
        {selectedTab === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TimeFilter
              setDurationType={setDurationType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setPrevStartTime={setPrevStartTime}
              setPrevEndTime={setPrevEndTime}
            />
          </Box>
        )}
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopBar;
