import React, { useCallback, useEffect, useState } from "react";
import { Box, MenuItem, FormControl, Select } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const TableFilters = ({
  data = [],
  onFilterChange = () => {},
  filters = [],
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState(""); // Default sort column

  const applySortingAndFiltering = useCallback(() => {
    let filteredData = [...data];

    // Apply search filter
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    // Apply sorting
    if (sortColumn) {
      filteredData.sort((a, b) => {
        const aValue = String(a[sortColumn]).toLowerCase();
        const bValue = String(b[sortColumn]).toLowerCase();

        if (sortColumn === "createdAt") {
          return new Date(bValue) - new Date(aValue);
        }
        return aValue.localeCompare(bValue);
      });
    }

    onFilterChange(filteredData, sortColumn);
  }, [data, searchQuery, sortColumn]);

  useEffect(() => {
    applySortingAndFiltering();
  }, [applySortingAndFiltering]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortColumn(event.target.value);
  };

  return (
    <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
      <input
        style={{
          padding: "10px 16px",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #c0c0c0",
          color: "#9C9C9B",
          fontFamily: "myThirdFont",
          height: "40px",
        }}
        value={searchQuery}
        onChange={handleSearchChange}
        type="text"
        placeholder="Search by columns name"
        className="chargingSessionTable"
      />
      <Box>
        <FormControl fullWidth>
          <Select
            displayEmpty
            value={sortColumn}
            onChange={handleSortChange}
            IconComponent={FilterListIcon}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "144px",
              height: "40px",
              color: "#1C1C1C",
              fontFamily: "myThirdFont",
              textAlign: "left",
            }}
          >
            <MenuItem
              sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
              value=""
              disabled
            >
              <span>Sort</span> {/* Placeholder text */}
            </MenuItem>
            {filters.map((item) => {
              return (
                <MenuItem
                  sx={{ fontFamily: "myThirdFont", fontSize: "16px" }}
                  key={item.value}
                  value={item.value}
                >
                  {item.title}
                </MenuItem>
              );
            })}
            {/* <MenuItem value="stationName">Station Name</MenuItem>
            <MenuItem value="_id">Session ID</MenuItem>
            <MenuItem value="createdAt">Sort by Date</MenuItem> */}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default TableFilters;
