import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import CustomTable from "../../custom/CustomTable";
import {
  ChargingPointlogsColumns,
  ChargingPointlogsData,
} from "../../../utils/DummyData";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { fetchLiveLogs } from "../../../redux/action/chargingPointSlice/chargingPointSlice";
import { useDispatch, useSelector } from "react-redux";
import LiveLogTable from "../../custom/LiveLogTable";
import { formatDateTime } from "../../../utils/FormatDateTime";

const LogsTableBox = ({
  onRowClick,
  liveLogBtn,
  data,
  logsCount,
  lastLog,
  handleLiveData,
  getOneData,
  page,
  limit,
  logsFilter,
  setLogsFilter,
  setPage,
  setLimit,
  modalHeading,
  loading,
}) => {
  const { liveLogs } = useSelector((state) => state.chargingPoints);

  const [selectedRow, setSelectedRow] = useState(null);
  const [logsEnabled, setLogsEnabled] = useState(false);

  const dispatch = useDispatch();

  let deviceId = getOneData?.device_id;
  const createdAt = lastLog?.createdAt ? formatDateTime(lastLog.createdAt) : {};

  useEffect(() => {
    // Define the interval variable
    let interval;

    // Perform the polling only if logsEnabled and deviceId are available
    if (logsEnabled && deviceId) {
      // Fetch logs immediately on enabling live logs
      dispatch(fetchLiveLogs(deviceId))
        .then(() => toast.success("Live logs enabled"))
        .catch(() => toast.error("Failed to fetch live logs"));

      // Start polling every 1 minute (60000 milliseconds)
      interval = setInterval(() => {
        dispatch(fetchLiveLogs(deviceId));
      }, 10000);
    }

    // Clean up interval when component unmounts or when logsEnabled/deviceId changes
    return () => clearInterval(interval);
  }, [logsEnabled, deviceId, dispatch]);

  useEffect(() => {
    if (liveLogs.length > 0) {
      handleLiveData();
    }
  }, [liveLogs]);

  // if (!data) {
  //   return <Typography>No data available123</Typography>;
  // }

  const handleChange = (event) => {
    setLogsFilter(event.target.value);
  };

  const handleRowClick = (rowData, rowIndex) => {
    setSelectedRow(rowIndex);
    if (onRowClick) {
      onRowClick(rowData);
    }
  };

  const handleExport = () => {
    if (data.length === 0) {
      toast.error("No logs available"); // Show toast notification
      return;
    }

    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Logs");

    // Export the workbook
    XLSX.writeFile(wb, "LogsData.xlsx");
  };

  const handleLiveLogs = () => {
    setLogsEnabled(!logsEnabled);
  };

  // Render loading state or data
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          background: "#FFF",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 332px)",
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl>
          {/* const [filteredStations, setFilteredStations] = useState([]); */}
          <InputLabel
            sx={{
              fontSize: "14px",
              fontFamily: "myThirdFont",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="demo-simple-select-label"
          >
            Sort By
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={logsFilter}
            label="Sort By"
            onChange={handleChange}
            sx={{
              width: "134px",
              height: "48px",
              color: "#1C1C1C",
              fontFamily: "mySecondFont",
            }}
          >
            <MenuItem value={"createdAt"}>Recorded On</MenuItem>
            <MenuItem value={"logType"}>Log Type</MenuItem>
            <MenuItem value={"command"}>Command</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", gap: "8px" }}>
          {liveLogBtn === true ? (
            <Button
              sx={{
                padding: "4px 12px",
                border: "1px solid #292D33",
                borderRadius: "4px",
                fontSize: "14px",
                color: "#292D33",
                fontFamily: "mySecondFont",
                height: "34px",
                textTransform: "unset",
              }}
              onClick={handleLiveLogs}
            >
              {logsEnabled ? "Disable live logs" : "Enable live logs"}
              <span
                style={{ paddingLeft: "4px" }}
                className="material-symbols-outlined"
              >
                not_started
              </span>
            </Button>
          ) : (
            ""
          )}

          <Button
            sx={{
              padding: "4px 12px",
              border: "1px solid #292D33",
              borderRadius: "4px",
              fontSize: "14px",
              color: "#292D33",
              fontFamily: "mySecondFont",
              height: "34px",
              textTransform: "unset",
            }}
            onClick={handleExport}
          >
            <span
              style={{ paddingRight: "4px" }}
              className="material-symbols-outlined"
            >
              download
            </span>
            Export
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "12px",
        }}
      >
        <Typography fontFamily={"mySecondFont"} color={"#2F2F2F"}>
          <span style={{ fontFamily: "myThirdFont" }}>Last heartbeat:</span>{" "}
          {lastLog
            ? createdAt?.date + ", " + createdAt?.time
            : "No heartbeat available"}
        </Typography>
        <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "8px",
                background: "#027900",
              }}
            ></Box>
            <Typography color={"#2F2F2F"} fontFamily={"myThirdFont"}>
              Incoming logs
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "8px",
                background: "#E50000",
              }}
            ></Box>
            <Typography color={"#2F2F2F"} fontFamily={"myThirdFont"}>
              Outgoing logs
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Table */}
      <Box sx={{ flex: 1, mt: "12px", overflowY: "auto" }}>
        <LiveLogTable
          columns={ChargingPointlogsColumns}
          rows={data}
          logsCount={logsCount}
          liveLogs={liveLogs ? liveLogs : []}
          isRowClickable={true}
          onRowClick={handleRowClick}
          selectedRow={selectedRow}
          specialColumns={["message"]}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onPageChange={(newPage) => setPage(newPage)}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0); // Reset to first page on rows per page change
          }}
          modalHeading={modalHeading}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default LogsTableBox;
