import { Box } from "@mui/material";
import React from "react";
import AlertBox from "../../dashboard/AlertBox";

const AlertBoxCard = () => {
  return (
    <Box>
      <AlertBox />
    </Box>
  );
};

export default AlertBoxCard;
