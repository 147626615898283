import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import ConfigurationListBox from "./Configuration/ConfigurationListBox";
import ConfigurationMessageBox from "./Configuration/ConfigurationMessageBox";
import {
  changeAvailabilityConfiguration,
  changeConfiguration,
  configurationFirmware,
  configurationGetDiagnostic,
  configurationMsgTrigger,
  configurationReset,
  configurationStopCharging,
  configurationUnlockConnector,
  startChargingPoint,
} from "../../redux/action/chargingPointSlice/chargingPointSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ConfigurationBox = ({ getOneData }) => {
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);

  const handleRadioChange = (value) => {
    setSelectedConfig(value);
    setFormValues({});
  };

  const handleInputChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const transformData = () => {
    // Initialize the base data object with deviceId
    const baseData = {
      deviceId: getOneData?.device_id || "defaultDeviceId", // Default or fetched device ID
    };

    // Transform data based on selected configuration
    switch (selectedConfig) {
      case "Change Configuration":
        const configData = {};

        // Set key and value based on form inputs
        if (
          formValues.selectKeyType === "Predefined" &&
          formValues.configurationKey
        ) {
          configData.key = formValues.configurationKey;
        } else if (
          formValues.selectKeyType === "Custom" &&
          formValues.customConfigurationKey
        ) {
          configData.key = formValues.customConfigurationKey;
        }

        if (formValues.configurationValue) {
          configData.value = formValues.configurationValue;
        }

        return {
          ...baseData,
          ...configData,
        };

      case "Change Availability":
        return {
          ...baseData,
          connectorId: String(formValues.changeAvalaibility), // Default or from form
          status: formValues.changeType, // Default or from form
        };

      case "Remote Start":
        return {
          deviceId: formValues.connectorID + getOneData?.device_id,
          startReason: "CMS Test",
          from: "WEB_CHARGING",
          userId: profile?._id,
        };

      case "Remote Stop":
        return {
          ...baseData,
          transactionId: formValues.transactionId,
        };

      case "Trigger Message":
        return {
          ...baseData,
          key: formValues.triggerMessage,
          connectorId: formValues.connectorId,
        };

      case "Unlock Connector":
        return {
          ...baseData,
          connectorId: formValues.connectorId, // Default or from form
        };

      case "Reset":
        return {
          ...baseData,
          status: formValues.changeAvalaibility,
        };

      case "Get Diagnostics":
        return {
          ...baseData,
          location: formValues.location,
          retries: Number(formValues.retries) || 0, // Ensure retries is a number, default to 0 if NaN
          retryInterval: Number(formValues.retryInterval) || 0, // Ensure retryInterval is a number, default to 0 if NaN
          startTime: formValues.startDate
            ? new Date(formValues.startDate).toISOString()
            : null, // Convert to ISO string
          stopTime: formValues.stopDate
            ? new Date(formValues.stopDate).toISOString()
            : null, // Convert to ISO string
        };

      case "Update Firmware":
        return {
          ...baseData,
          location: formValues.location,
          retries: Number(formValues.retries) || 0, // Ensure retries is a number, default to 0 if NaN
          retryInterval: Number(formValues.retryInterval) || 0, // Ensure retryInterval is a number, default to 0 if NaN
          retrieveDate: formValues.startDate
            ? new Date(formValues.startDate).toISOString()
            : null, // Convert to ISO string
        };

      default:
        return baseData; // Return base data if no valid configuration is selected
    }
  };

  const handleSendMessage = async () => {
    const dataToSend = transformData();

    try {
      let response;

      switch (selectedConfig) {
        case "Change Configuration":
          response = await dispatch(changeConfiguration(dataToSend));
          break;
        case "Change Availability":
          response = await dispatch(
            changeAvailabilityConfiguration(dataToSend)
          );
          break;
        case "Remote Start":
          response = await dispatch(startChargingPoint(dataToSend));
          break;
        case "Remote Stop":
          response = await dispatch(configurationStopCharging(dataToSend));
          break;
        case "Trigger Message":
          response = await dispatch(configurationMsgTrigger(dataToSend));
          break;
        case "Unlock Connector":
          response = await dispatch(configurationUnlockConnector(dataToSend));
          break;
        case "Reset":
          response = await dispatch(configurationReset(dataToSend));
          break;
        case "Get Diagnostics":
          response = await dispatch(configurationGetDiagnostic(dataToSend));
          break;
        case "Update Firmware":
          response = await dispatch(configurationFirmware(dataToSend));
          break;
        default:
          throw new Error("No configuration selected or API not defined.");
      }

      // Check the response for success status
      if (response.payload && response.payload.success) {
        toast.success(response.payload.message || "Operation successful");
      } else {
        toast.error(response.payload?.message || "Operation failed");
      }

      setFormValues({});
    } catch (error) {
      toast.error("Internal Server Error");
      console.error("Error in handleSendMessage:", error);
    }
  };

  const handleClear = () => {
    setFormValues({});
  };

  return (
    <Box>
      <Grid container columnSpacing={2}>
        <Grid item md={5}>
          <ConfigurationListBox
            selectedConfig={selectedConfig}
            onRadioChange={handleRadioChange}
          />
        </Grid>
        <Grid item md={7}>
          <ConfigurationMessageBox
            selectedConfig={selectedConfig}
            onInputChange={handleInputChange}
            onSendMessage={handleSendMessage}
            formValues={formValues}
            onClear={handleClear}
            data={getOneData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfigurationBox;
