import { createContext, useContext, useState } from "react";

// Create context
const TablePageContext = createContext();

// Create a provider component
export const TablePageProvider = ({ children }) => {
  const [page, setPage] = useState(0); // Initial page set to 1
  return (
    <TablePageContext.Provider value={{ page, setPage }}>
      {children}
    </TablePageContext.Provider>
  );
};

// Custom hook to use the context
export const useTablePage = () => {
  const context = useContext(TablePageContext);
  if (!context) {
    throw new Error("useTablePage must be used within a TablePageProvider");
  }
  return context;
};
