import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";
import Heading from "../../../utils/Heading";
import { MyRoutes } from "../../../routes/routes";

const TopPerformingHubCard = ({ data, loading, title, passHolderCard }) => {
  // Dynamically select the data based on the title
  const getTopPerformingStations = () => {
    switch (title) {
      case "Top Station (Duration)":
        return (
          data?.hubChargingSessionsStats?.topThreeStationsBySessionDuration ||
          []
        );
      case "Top Station (Energy)":
        return (
          data?.hubChargingSessionsStats?.topThreeStationsByEnergyConsumed || []
        );
      case "Top Station (Pass Sold)":
        return (
          data?.hubCashTransactionStats?.topThreeStationsByPassesSold || []
        );
      case "Top Pass Holder (Duration)":
        return (
          data?.hubChargingSessionsStats?.topThreeCustomersBySessionDuration ||
          []
        );
      case "Top Pass Holder (Frequent Visit)":
        return (
          data?.hubChargingSessionsStats?.topThreeCustomersByFrequentVisits ||
          []
        );
      case "Top Pass Holder (Energy)":
        return (
          data?.hubChargingSessionsStats?.topThreeCustomersByEneryConsumed || []
        );
      default:
        return [];
    }
  };

  const topPerformStations = getTopPerformingStations();
  const currencySymbol = GetCurrencySymbol();

  return (
    <Box
      sx={{
        padding: "24px 24px 28px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src="/images/stars.png" alt="" />
        <Heading text={title} />
      </Box>
      <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />

      {/* Loading state */}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "150px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {topPerformStations.length === 0 ? (
            <Typography
              sx={{
                color: "#AFAFAF",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                mt: "18px",
              }}
            >
              No Data Available
            </Typography>
          ) : (
            topPerformStations.map((item, index) => (
              <Link
                key={item._id}
                to={
                  !passHolderCard
                    ? `${MyRoutes.hub_management_charging_stations_performance}/${item._id}`
                    : ""
                }
                style={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    padding: "12px",
                    borderLeft:
                      index === 0
                        ? "1px solid #FFB02E"
                        : index === 1
                        ? "1px solid #D3883E"
                        : "1px solid #BEBEBE",
                    background:
                      index === 0
                        ? ""
                        : index === 1
                        ? "rgba(211, 136, 62, 0.1)"
                        : "rgba(190, 190, 190, 0.1)",
                    boxShadow: "0px 1px 4px 0px rgba(41, 45, 51, 0.25)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    "&:hover": {
                      background:
                        index === 0
                          ? "rgba(255, 176, 46, 0.2)"
                          : index === 1
                          ? "rgba(211, 136, 62, 0.2)"
                          : "rgba(190, 190, 190, 0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <img
                      width={"20px"}
                      src={
                        index === 1
                          ? "/images/top2.png"
                          : index === 2
                          ? "/images/top3.png"
                          : "/images/top1.png"
                      }
                      alt=""
                    />
                    <Typography
                      color={"#2f2f2f"}
                      fontSize={"16px"}
                      fontFamily={"mySecondFont"}
                    >
                      {title === "Top Pass Holder (Duration)" ||
                      title === "Top Pass Holder (Frequent Visit)" ||
                      title === "Top Pass Holder (Energy)" ||
                      title === "Top Station (Duration)" ||
                      title === "Top Station (Energy)" ||
                      title === "Top Station (Pass Sold)"
                        ? item?.name
                        : item?.station?.name}
                    </Typography>
                  </Box>

                  {title === "Top Pass Holder (Duration)" ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "24px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          chronic
                        </span>
                        <Typography
                          color={"#2F2F2F"}
                          fontFamily={"myThirdFont"}
                          fontSize={"14px"}
                        >
                          {`${item?.formattedSessionDuration} h:m:s` || "N/A"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          star
                        </span>
                        <Typography
                          color={"#2F2F2F"}
                          fontFamily={"myThirdFont"}
                          fontSize={"14px"}
                        >
                          {`Frequent Visits: ${item?.frequentVisit || 0}`}
                        </Typography>
                      </Box>
                    </Box>
                  ) : title === "Top Pass Holder (Frequent Visit)" ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "24px",
                        alignItems: "center",
                      }}
                    >
                      {/* Components specific to Top Pass Holder (Frequent Visit) */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          star
                        </span>
                        <Typography
                          color={"#2F2F2F"}
                          fontFamily={"myThirdFont"}
                          fontSize={"14px"}
                        >
                          {`Frequent Visit: ${item?.frequentVisit || 0}`}
                        </Typography>
                      </Box>
                      {/* Add more elements as needed */}
                    </Box>
                  ) : title === "Top Pass Holder (Energy)" ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "24px",
                        alignItems: "center",
                      }}
                    >
                      {/* Components specific to Top Pass Holder (Frequent Visit) */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          bolt
                        </span>
                        <Typography
                          color={"#2F2F2F"}
                          fontFamily={"myThirdFont"}
                          fontSize={"14px"}
                        >
                          {`${item?.energyConsumed || 0} kWh`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          star
                        </span>
                        <Typography
                          color={"#2F2F2F"}
                          fontFamily={"myThirdFont"}
                          fontSize={"14px"}
                        >
                          {`Frequent Visit: ${item?.frequentVisit || 0}`}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "24px",
                      }}
                    >
                      {/* Other station details */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          {title === "Top Station (Pass Sold)"
                            ? "book_online"
                            : "bolt"}
                        </span>
                        <Typography
                          color={"#2F2F2F"}
                          fontFamily={"myThirdFont"}
                          fontSize={"14px"}
                        >
                          {title === "Top Station (Pass Sold)"
                            ? `${item?.passSold || 0} Passes`
                            : `${item?.energyConsumed || 0} kWh`}
                        </Typography>
                      </Box>
                      {title === "Top Station (Pass Sold)" ? (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "16px",
                              height: "16px",
                              borderRadius: "50%",
                              border: "1px solid #666",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#666",
                              fontWeight: "200",
                              fontFamily: "myThirdFont",
                            }}
                          >
                            {currencySymbol}
                          </Box>
                          <Typography
                            color={"#2F2F2F"}
                            fontFamily={"myThirdFont"}
                            fontSize={"14px"}
                          >
                            {currencySymbol}
                            {item?.passValue}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ color: "#666666", fontWeight: "200" }}
                            className="material-symbols-outlined"
                          >
                            chronic
                          </span>
                          <Typography
                            color={"#2F2F2F"}
                            fontFamily={"myThirdFont"}
                            fontSize={"14px"}
                          >
                            {`${item?.formattedSessionDuration} h:m:s` || "N/A"}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Link>
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export default TopPerformingHubCard;
