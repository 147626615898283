import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters from "../../charging-sessions/TableFilters";
import CustomTable from "../../custom/CustomTable";
import { fetchRFIDDetails } from "../../../redux/action/RFID/rfidSlice";
import { useDispatch } from "react-redux";

const RetailTableBox = ({ columns, row }) => {
  const dispatch = useDispatch();

  const [filteredStations, setFilteredStations] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  useEffect(() => {
    if (row.length > 0) {
      setFilteredStations(row);
    }
  }, [row]);

  const handleFilterChange = (filteredData, sortColumn) => {
    setSortColumn(sortColumn);
    setFilteredStations(filteredData);
  };

  const myFilters = [
    {
      value: "status",
      title: "Status",
    },
    {
      value: "expiry_date",
      title: "Validity",
    },
    {
      value: "assignedDate",
      title: "Asigned Date",
    },
  ];

  useEffect(() => {
    dispatch(fetchRFIDDetails());
  }, [dispatch]);

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {/* filters */}
      <TableFilters
        data={row}
        onFilterChange={handleFilterChange}
        filters={myFilters}
      />

      {/* Table */}
      <CustomTable
        columns={columns}
        rows={filteredStations}
        modalHeading={"Retail RFID"}
        isRowClickable={true}
        sortColumn={sortColumn}
      />
    </Box>
  );
};

export default RetailTableBox;
