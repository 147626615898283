import {
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Heading from "../../../utils/Heading";
import CustomTransition from "../../custom/CustomTransition";
import UnregisteredManageBox from "../../ocpi_management_details/UnregisteredManageBox";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: "24px",
};

const InputField = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  type = "text",
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}
  >
    <Typography
      textAlign="start"
      fontSize="14px"
      color="#2f2f2f"
      fontFamily="myThirdFont"
      outline="none"
    >
      {label}
    </Typography>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      style={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #cbcbcb",
        color: "rgba(47, 47, 47, 0.50)",
        fontFamily: "myThirdFont",
        fontSize: "16px",
        height: "48px",
        outline: "none",
        // Remove spinner arrows for number inputs
        WebkitAppearance: "none",
        MozAppearance: "textfield",
      }}
      placeholder={placeholder}
    />
  </Box>
);

const InfoBox = ({ label, value, icon, propType }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontFamily={"myThirdFont"}
          fontSize={"14px"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {label}
        </Typography>

        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>

      {propType === "token" ? (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
          <Typography
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
            color={"#2F2F2F"}
            textAlign={"start"}
            sx={{ textWrap: "wrap" }}
          >
            {value}
          </Typography>
          <Button sx={{ width: "150px", fontSize: "12px", p: 0 }}>
            Generate Token
          </Button>
        </Box>
      ) : (
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
          textAlign={"start"}
          sx={{ textWrap: "wrap" }}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

const OcpiRegisterModal = ({ open, onClose, onRegister }) => {
  const [ocpiUrl, setOcpiUrl] = useState("");
  const [tokenA, setTokenA] = useState("");

  // Function to handle form submission
  const handleSubmit = () => {
    const formData = {
      ocpiUrl,
      tokenA,
    };
    onRegister(formData);
    onClose();
  };

  const isFormValid = ocpiUrl.trim() !== "" && tokenA.trim() !== "";

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      TransitionComponent={CustomTransition}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="/images/ev_charger_icon.png" alt="" />
            <Heading text={"Register with Tata Power Corporation"} />
          </Box>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClose}
            className="material-symbols-outlined"
          >
            cancel
          </span>
        </Box>
        <Divider sx={{ mt: "8px" }} />
        <Box
          sx={{
            padding: "24px",
            background: "#fff",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  background: "#fff",
                  gap: "24px",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  {/* OCPI Register Form */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Box>
                      <InputField
                        label="OCPI URL"
                        placeholder="Enter OCPI URL here"
                        value={ocpiUrl}
                        onChange={(e) => setOcpiUrl(e.target.value)}
                        name="ocpiUrl"
                        type="text"
                      />
                    </Box>
                    <Box>
                      <InputField
                        label="Token A"
                        placeholder="Enter Token A here"
                        value={tokenA}
                        onChange={(e) => setTokenA(e.target.value)}
                        name="Token A"
                        type="text"
                      />
                    </Box>
                    <Button
                      onClick={handleSubmit}
                      disabled={!isFormValid}
                      sx={{
                        borderRadius: "4px",
                        padding: "8px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#E50000",
                        width: "100%",
                        color: "#fff",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginTop: "8px",
                        ":hover": {
                          background: "#E50000",
                        },
                        // Add styles for the disabled state
                        // opacity: !isFormValid ? 0.25 : 1,
                        background: !isFormValid ? "gray" : "#E50000",
                        color: !isFormValid ? "#fff" : "#fff",
                        cursor: !isFormValid ? "not-allowed" : "pointer",
                      }}
                    >
                      Register
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  {/* Heading */}
                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <img src="/images/ev_charger_icon.png" alt="" />
                        <Heading text={"1C OCPI"} />
                      </Box>
                    </Box>

                    <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <InfoBox
                      label={"OCPI URL"}
                      value={`wss://test.1charging.com/ws/ocpp/16/ocpiurl`}
                      icon="content_copy"
                    />
                    <InfoBox
                      propType="token"
                      label={"Token A"}
                      value={`OCPIMANAGEMENT123`}
                      icon="content_copy"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default OcpiRegisterModal;
