import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../custom/CustomTable";
import HubTimeFilter from "../../Filters/HubTimeFilter";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import { TimeProvider } from "../../../Hooks/TimeContext";

const AllCollection = ({ columns, row }) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter rows based on search query
  const filteredRows = row.filter((item) => {
    return Object.keys(item).some((key) =>
      String(item[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  return (
    <StationProvider>
      <TimeProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {/* filters */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <HubTableFilter
              tabTitle={"AllTransaction"}
              setSearchQuery={setSearchQuery}
            />
            <HubTimeFilter tabTitle={"AllTransaction"} />
          </Box>

          {/* Table */}
          <CustomTable
            columns={columns}
            rows={filteredRows}
            modalHeading={"Hub All Collection"}
          />
        </Box>
      </TimeProvider>
    </StationProvider>
  );
};

export default AllCollection;
