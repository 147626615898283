import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../../custom/CustomTable";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable"; // AutoTable plugin for jsPDF
import { passbookColumns } from "../../../utils/DummyData";
import MainHeading from "../../../utils/MainHeading";

const DailySheetTable = ({ columns, row = [], selectedStationName }) => {
  const sortedData = Array.isArray(row)
    ? [...row].sort((a, b) => new Date(b.txnTime) - new Date(a.txnTime))
    : [];

  // Calculate Grand Total for Added Amount and Charged Amount
  const grandTotalAdded = sortedData.reduce(
    (total, item) => total + (item.creditAmount || 0),
    0
  );
  const grandTotalCharged = sortedData.reduce(
    (total, item) => total + (item.debitAmount || 0),
    0
  );

  const downloadExcel = () => {
    // Filter walletHistory data to match the columns being displayed
    const filteredData = sortedData?.map((item, index) =>
      passbookColumns.reduce((acc, col) => {
        if (col.id === "sr") {
          acc[col.id] = index + 1; // Add serial number
        } else {
          acc[col.id] = item[col.id];
        }
        return acc;
      }, {})
    );

    // Create a new workbook and add a worksheet with filtered data
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "Wallet History");

    // Generate buffer and save the file
    const fileName = `wallet_history_${
      selectedStationName || "all_stations"
    }.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Create a modified version of columns for the PDF (change 'Credited Amount(₹)' to 'Amount')
    const modifiedColumns = passbookColumns.map((col) => {
      if (col.id === "creditAmount") {
        return { ...col, label: "Total Amount Credited" }; // Change label to 'Amount' for PDF
      } else if (col.id === "debitAmount") {
        return { ...col, label: "Total Amount Used" };
      } else if (col.id === "startingBalance") {
        return { ...col, label: "Starting Balance" };
      } else if (col.id === "endingBalance") {
        return { ...col, label: "Ending Balance" };
      }
      return col;
    });

    // Filter walletHistory data to match the columns being displayed
    const filteredData = sortedData?.map((item, index) =>
      modifiedColumns.reduce((acc, col) => {
        if (col.id === "sr") {
          acc[col.id] = index + 1; // Add serial number
        } else {
          // Format numeric values to two decimal places
          acc[col.id] =
            typeof item[col.id] === "number"
              ? item[col.id].toFixed(2)
              : item[col.id];
        }
        return acc;
      }, {})
    );

    // Define the table columns
    const columns = modifiedColumns.map((col) => ({
      header: col.label, // Use 'label' as the header for the PDF
      dataKey: col.id, // Use 'id' to map the data keys
    }));

    // Add the table to the PDF
    doc.autoTable({
      columns,
      body: filteredData,
      margin: { top: 10 },
    });

    // Save the PDF with station name
    const fileName = `wallet_history_${
      selectedStationName || "all_stations"
    }.pdf`;
    doc.save(fileName);
  };

  return (
    <Box>
      <Box
        sx={{
          padding: "0px 24px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <MainHeading text={selectedStationName || "No station selected"} />
            {!selectedStationName ? (
              <Typography textAlign={"start"} fontFamily={"myThirdFont"}>
                (Select a Station to View the Daily Sheet)
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              sx={{
                padding: "8px 12px",
                border: "1px solid rgba(34, 34, 34, 0.50)",
                borderRadius: "4px",
                color: "#2F2F2F",
                fontFamily: "mySecondFont",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
              onClick={downloadExcel}
            >
              <img
                style={{ marginRight: "4px" }}
                src="/images/excel.png"
                alt=""
              />
              Excel
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginLeft: "4px",
                }}
                className="material-symbols-outlined"
              >
                download
              </span>
            </Button>
            <Button
              sx={{
                padding: "8px 12px",
                border: "1px solid rgba(34, 34, 34, 0.50)",
                borderRadius: "4px",
                color: "#2F2F2F",
                fontFamily: "mySecondFont",
                fontSize: "14px",
              }}
              onClick={downloadPDF}
            >
              <img
                style={{ marginRight: "4px" }}
                src="/images/pdf.png"
                alt=""
              />
              PDF
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginLeft: "4px",
                }}
                className="material-symbols-outlined"
              >
                download
              </span>
            </Button>
          </Box>
        </Box>
        <CustomTable
          columns={columns}
          rows={sortedData}
          modalHeading={"Transactions"}
        />

        {/* Grand Total */}
        <Box
          sx={{
            display: "flex",
            gap: "48px",
            alignItems: "center",
            mt: "32px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography fontFamily={"myThirdFont"} fontSize={"16px"}>
              Grand Total of Added Amount
            </Typography>
            <Typography fontFamily={"mySecondFont"} fontSize={"18px"}>
              ₹{grandTotalAdded.toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography fontFamily={"myThirdFont"} fontSize={"16px"}>
              Grand Total of Charged Amount
            </Typography>
            <Typography fontFamily={"mySecondFont"} fontSize={"18px"}>
              ₹{grandTotalCharged.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DailySheetTable;
