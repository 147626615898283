import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const DoubleStackedBarGraph = () => {
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"     tick={{
              fontSize: 14,
              fontFamily: "mySecondFont",
              fill: "#666",
            }}/>
          <YAxis     tick={{
              fontSize: 14,
              fontFamily: "mySecondFont",
              fill: "#666",
            }}/>
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" stackId="a" fill="#7F73C6" />
          <Bar dataKey="uv" stackId="a" fill="#FF5454" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DoubleStackedBarGraph;
