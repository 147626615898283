import React from "react";
import TopHeader from "../../components/add_new_charging_point/TopHeader";
import AddChargingPointForm from "../../components/add_new_charging_point/AddChargingPointForm";
import { useLocation } from "react-router-dom";


const AddNewChargingPoint = () => {
  const location = useLocation();
  const {edit} = location.state || [];
  console.log("state123", edit)

  return (
    <>
      {/* Top header */}
      <TopHeader headingCheck={edit} />

      {/* Add Charging Point Form */}
      <AddChargingPointForm />
    </>
  );
};

export default AddNewChargingPoint;
