import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import Heading from "../../../utils/Heading";
import { TextCopy } from "../../../utils/TextCopy/TextCopy";

const InfoBox = ({ label, value, icon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied); // Call the TextCopy function
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
          color={"#2F2F2F"}
        >
          {value}
        </Typography>
        {icon && (
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              onClick={handleCopy}
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const ConnectorBox = ({ connectors }) => (
  <Box
    sx={{ display: "flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}
  >
    {connectors.map((connector, index) => (
      <Box
        key={index}
        sx={{
          padding: "2px 12px",
          background: "rgba(41, 45, 51, 0.08)",
          maxWidth: "85px",
          width: "100%",
          borderRadius: "85px",
        }}
      >
        <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
          {connector}
        </Typography>
      </Box>
    ))}
  </Box>
);

const SpecificationBox = ({ data }) => {
  const connectors = ["CCS2", "CCS2", "CCS2"];

  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            page_info
          </span>
          <Heading text={"Specifications"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Station ID" value={data?._id} icon="content_copy" />
        <InfoBox
          label="Grid connection power capacity"
          value={data?.powerCapacity}
        />
        <InfoBox label="Grid Phase" value={data?.gridPhase} />
        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
            color={"#2F2F2F"}
            textAlign={"start"}
          >
            Connectors
          </Typography>
          <ConnectorBox connectors={connectors} />
        </Box> */}
      </Box>
    </Box>
  );
};

export default SpecificationBox;
