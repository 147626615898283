import { Box } from "@mui/material";
import React from "react";
import TopHeader from "../../components/statistics/TopHeader";

const Statistics = () => {
  return (
    <Box>
      <TopHeader />
    </Box>
  );
};

export default Statistics;
