import React from "react";
import DoubleBarGraph from "../../Graphs/DoubleBarGraph";
import { Box, Divider, Grid } from "@mui/material";
import Heading from "../../../utils/Heading";

const OcpiGraphBoxTwo = () => {
  return (
    <Box
      sx={{
        background: "#fff",
        padding: "24px",
        borderRadius: "8px",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        {/* Left Graph */}
        <Grid item md={6}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Heading text={"1C devices revenue VS energy"} />
              </Box>
            </Box>

            <Divider sx={{ background: "#ddd", margin: "8px 0px" , mb:"16px"}} />
          </Box>
          <DoubleBarGraph barGraphType="chargingOne"/>
        </Grid>

        {/* Right Graph */}
        <Grid item md={6}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Heading text={"Tata devices revenue VS energy"} />
              </Box>
            </Box>

            <Divider sx={{ background: "#ddd", margin: "8px 0px",mb:"16px"}} />
          </Box>
          <DoubleBarGraph barGraphType="network"/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcpiGraphBoxTwo;
