import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FinanceTimeBox from "../../../components/FinanceManager/PaymentHistory/FinanceTimeBox";
// import TableBox from "../../../components/FinanceManager/PaymentHistory/TableBox";
import TopBar from "../../../components/FinanceManager/PaymentHistory/TopBar";
import { useLocation } from "react-router-dom";

const PaymentHistory = () => {
  const location = useLocation();
  const [selectedStationName, setSelectedStationName] = useState("");

  useEffect(() => {
    if (location.state?.selectedStationName) {
      setSelectedStationName(location.state.selectedStationName);
    }
  }, [location.state]);

  // Callback function to handle the selected station name
  const handleStationChange = (stationName) => {
    setSelectedStationName(stationName);
  };
  return (
    <Box>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={"16px"}
        sx={{ height: "100%" }}
      >
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <FinanceTimeBox
              onStationChange={handleStationChange}
              defaultStationName={selectedStationName}
            />
          </Box>
        </Grid>
        <Grid item md={8} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TopBar selectedStationName={selectedStationName} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentHistory;
