import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { alertCardData } from "../../utils/DummyData";
import Heading from "../../utils/Heading";

const AlertBox = () => {
  return (
    <Box sx={{ padding: "16px", borderRadius: "8px", background: "#fff" }}>
      <Heading text={"Alerts"} />
      <Divider sx={{ mt: "8px", color: "#ddd" }} />

      {/* Alert cards */}
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "8px", mt: "8px" }}
      >
        {alertCardData.map((item) => {
          return (
            <Box
              key={item.id}
              sx={{
                borderRadius: "4px",
                borderLeft:
                  item?.alertType !== "max"
                    ? "1px solid #E86F00"
                    : "1px solid #e50000",
                background:
                  item?.alertType !== "max"
                    ? "rgba(232, 111, 0, 0.05)"
                    : "rgba(229, 0, 0, 0.05)",
                padding: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <span
                    style={{ color: "#e50000", fontWeight: "200" }}
                    className="material-symbols-outlined"
                  >
                    {item?.icon}
                  </span>
                  <Typography
                    fontFamily={"myFirstFont"}
                    fontSize={"14px"}
                    color={"#1C1C1C"}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Box>
                  <span
                    style={{ fontWeight: "300", cursor: "pointer" }}
                    className="material-symbols-outlined"
                  >
                    cancel
                  </span>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  gap: "8px",
                }}
              >
                <Typography
                  textAlign={"start"}
                  color={"#2F2F2F"}
                  fontSize={"12px"}
                  fontFamily={"myThirdFont"}
                >
                  {item?.description}
                </Typography>
                <Typography
                  color={"#2F2F2F"}
                  fontSize={"12px"}
                  fontFamily={"mySecondFont"}
                  pr={"4px"}
                >
                  {item.time}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Typography
          color={"#222"}
          fontFamily="'Montserrat', sans-serif"
          fontWeight={500}
          mt={"16px"}
          letterSpacing={"0.96px"}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          VIEW ALL
        </Typography>
      </Box>
    </Box>
  );
};

export default AlertBox;
