import { Box } from "@mui/material";
import React from "react";
import TableFilters from "../../../charging-sessions/TableFilters";
import CustomTable from "../../../custom/CustomTable";
import TimeFilter from "../../../Filters/TimeFilter";
import HubTimeFilter from "../../../Filters/HubTimeFilter";

const Attendence = ({ columns, row }) => {
  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* filters */}
        <TableFilters />
        <HubTimeFilter tabTitle={"Attendence"} />
      </Box>

      {/* Table */}
      <CustomTable
        modalHeading={"Guard Attendence"}
        columns={columns}
        rows={row}
        actionType={"edit"}
      />
    </Box>
  );
};

export default Attendence;
