import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../../components/hub_management/gaurds/guardDetails/TopBar";

const GuardDetails = () => {
  return (
    <Box>
      <TopBar />
    </Box>
  );
};

export default GuardDetails;
