import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../utils/Heading";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import { MyRoutes } from "../../routes/routes";
import { Link } from "react-router-dom";

const TopPerformStationBox = ({ topPerformerStation, type }) => {
  const currencySymbol = GetCurrencySymbol();

  console.log("uuuuggg", topPerformerStation)

  return (
    <Box
      sx={{
        padding: "24px 24px 28px 24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        minHeight: "200px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src="/images/stars.png" alt="" />
        <Heading
          text={
            type === "energy"
              ? "Top Stations ( Energy )"
              : "Top Stations ( Duration )"
          }
        />
      </Box>
      <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />

      {/* Top performer Card */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {/* {stationStats?.map((item, index) => {
          return <p>hey</p>;
        })} */}

        {topPerformerStation?.length === 0 ? (
          <Typography
            sx={{
              color: "#AFAFAF",
              fontFamily: "myThirdFont",
              fontSize: "14px",
              mt: "18px",
            }}
          >
            No Stations available
          </Typography>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {topPerformerStation?.map((item, index) => (
              <Link
                key={item._id}
                to={`${MyRoutes.charging_stations}/${item._id}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  key={index}
                  sx={{
                    padding: "12px",
                    borderLeft:
                      index === 0
                        ? "1px solid #FFB02E"
                        : index === 1
                        ? "1px solid #D3883E"
                        : "1px solid #BEBEBE",
                    background:
                      index === 0
                        ? "rgba(255, 176, 46, 0.1)"
                        : index === 1
                        ? "rgba(211, 136, 62, 0.1)"
                        : "rgba(190, 190, 190, 0.1)",
                    boxShadow: "0px 1px 4px 0px rgba(41, 45, 51, 0.25)",

                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",

                    "&:hover": {
                      background:
                        index === 0
                          ? "rgba(255, 176, 46, 0.2)"
                          : index === 1
                          ? "rgba(211, 136, 62, 0.2)"
                          : "rgba(190, 190, 190, 0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <img
                      width={"20px"}
                      src={
                        index === 1
                          ? "/images/top2.png"
                          : index === 2
                          ? "/images/top3.png"
                          : "/images/top1.png"
                      }
                      alt=""
                    />
                    <Typography
                      color={"#2f2f2f"}
                      fontSize={"16px"}
                      fontFamily={"mySecondFont"}
                    >
                      {item?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "24px" }}
                  >
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      {type === "energy" ? (
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          bolt
                        </span>
                      ) : (
                        <span
                          style={{ color: "#666666", fontWeight: "200" }}
                          className="material-symbols-outlined"
                        >
                          chronic
                        </span>
                      )}

                      <Typography
                        color={"#2F2F2F"}
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                      >
                        {type === "energy"
                          ? item?.energyConsumed + " kwH"
                          : item?.sessionDuration + " h:m:s"}{" "}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          width: "16px",
                          marginLeft: "5px",
                          height: "16px",
                          borderRadius: "50%",
                          border: "1px solid #666",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#333",
                          fontFamily: "myFirstFont",
                        }}
                      >
                        {currencySymbol}
                      </Box>
                      <Typography
                        color={"#2F2F2F"}
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                      >
                        {`${currencySymbol} ${item?.chargingAmount}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <span
                        style={{ color: "#666666", fontWeight: "200" }}
                        className="material-symbols-outlined"
                      >
                        ev_station
                      </span>
                      <Typography
                        color={"#2F2F2F"}
                        fontFamily={"myThirdFont"}
                        fontSize={"14px"}
                      >
                        {item?.sessionCount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TopPerformStationBox;
