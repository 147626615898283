import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../../utils/Heading";
import { formatDateTime } from "../../../../utils/FormatDateTime";
import { GetCurrencySymbol } from "../../../../utils/currency/currencyChangeFunction";

const InfoBox = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography
        fontFamily={"myThirdFont"}
        fontSize={"14px"}
        color={"#2F2F2F"}
        textAlign={"start"}
      >
        {label}
      </Typography>
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"start"}
        textTransform={"capitalize"}
      >
        {value}
      </Typography>
    </Box>
  );
};

const GuardBasicDetails = ({ data }) => {
  const createdAt = data?.createdAt ? formatDateTime(data?.createdAt) : {};
  const updatedAt = data?.updatedAt ? formatDateTime(data?.updatedAt) : {};

  const currencySymbol = GetCurrencySymbol();

  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            badge
          </span>
          <Heading text={"Guard Basic Details"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* Guard specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Station Name" value={data?.stationId?.name} />
        <InfoBox label="Guard Aadhar Number" value={data?.aadharNo} />
        <InfoBox
          label={`Colllected Amount (${currencySymbol})`}
          value={data?.collectedAmount}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "32px",
          }}
        >
          <InfoBox label="Created By" value={data?.createdBy?.name} />
          <InfoBox
            label="Language"
            value={
              data?.language === "en"
                ? "English"
                : data?.language === "hi"
                ? "Hindi"
                : data?.language
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "32px",
          }}
        >
          <Box>
            <InfoBox label="Created At" value={createdAt.date} />
            <Typography
              fontSize={"12px"}
              fontFamily={"myThirdFont"}
              color={"#666"}
              textAlign={"start"}
            >
              {createdAt.time}
            </Typography>
          </Box>
          <Box>
            <InfoBox label="Updated At" value={updatedAt.date} />
            <Typography
              fontSize={"12px"}
              fontFamily={"myThirdFont"}
              color={"#666"}
              textAlign={"start"}
            >
              {updatedAt.time}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GuardBasicDetails;
