import React from "react";
import ChargerStatistics from "./ChargerStatistics/ChargerStatistics";
import { Box } from "@mui/material";

const ChargersBox = () => {
  return (
    <Box>
      <ChargerStatistics />
    </Box>
  );
};

export default ChargersBox;
