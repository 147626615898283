import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: icon ? "space-between" : "start",
        alignItems: "center",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
        textAlign={"left"}
      >
        {value}
      </Typography>
      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const PaymentDetails = ({data}) => {
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            info
          </span>
          <Heading text={"Payment details"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Bank account number" value={data?.accountNumber} />
        <InfoBox label="IFSC code" value={data?.ifscCode} />
        <InfoBox label="Billing Address" value={data?.billingFullAddress} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Pin code" value={data?.pinCode} />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="City/Town" value={data?.city} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InfoBox label="State" value={data?.state} />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InfoBox label="Country" value={data?.country} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
