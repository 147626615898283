import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/charging_points/TopBar";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";

const ChargingPoints = () => {
  const navigate = useNavigate();
  useScrollToTop(navigate);
  return (
    <Box>
      {/* Top - Bar */}
      <TopBar />
    </Box>
  );
};

export default ChargingPoints;
