import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  Slide,
} from "@mui/material";

import TableFilters from "../../charging-sessions/TableFilters";
import {
  chargerData,
  statisticsChargerColumns,
} from "../../../utils/DummyData";
import CustomTable from "../../custom/CustomTable";
import CustomLineGraph from "../../Graphs/CustomLineGraph";

// Material UI components
import { endPoints } from "../../../Api/endPoints";
import axios from "axios";
import dayjs from "dayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";

const OverallStatistics = () => {
  // const dispatch = useDispatch();
  const graphType = "organization";
  const { stations, stationsLoading, timeStamp } = useSelector(
    (state) => state.stations
  );
  const [chargerFinalData, setChargerFinalData] = useState([]);
  const [userData, setUserData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  // const { data, isFetching } = useGetDetailsQuery("userDetails", {
  //   pollingInterval: 900000, // 15mins
  // });

  // useEffect(() => {
  //   setUserData(data?.data);
  // }, [data]);

  // Demo

  const [timeOptions, setTimeOptions] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  const [selectedTime, setSelectedTime] = useState("week");
  const [selectedTimeField, setSelectedTimeField] = useState("week");
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [startTime, setStartTime] = useState(
    dayjs().subtract(6, "day").startOf("day")
  );
  const [endTime, setEndTime] = useState(dayjs().endOf("day"));
  const [startDate, setStartDate] = useState(
    dayjs().subtract(6, "day").startOf("day")
  );
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));

  function handleUpdateButtonClick() {
    const newSelectedTime = selectedTime;
    if (newSelectedTime === "customRange") {
      setSelectedTimeField(newSelectedTime);
      setStartTime(startDate);
      setEndTime(endDate);

      fetchAllChargerStats(newSelectedTime, startDate, endDate);
    } else {
      console.log("okkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    }
    // Function to handle the "Apply" button click
  }

  async function fetchAllChargerStats(selectedTime, startTime, endTime) {
    const token = localStorage.getItem("token");
    let { data } = await axios.get(
      `${
        endPoints.getChargerStats
      }/${selectedTime}/${startTime.valueOf()}/${endTime.valueOf()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {
        showLoadingIndicator: true,
      }
    );

    setChargerFinalData(data);
  }

  useEffect(() => {
    fetchAllChargerStats(selectedTime, startTime, endTime);
  }, []);

  useEffect(() => {
    if (chargerData) {
      const times = [
        { value: "week", label: "Last 7 days" },
        { value: "month", label: "Current Month" },
        { value: "year", label: "Current Year" },
        { value: "customRange", label: "Custom Range" },
      ];
      setTimeOptions(times);

      // const orgsList = orgFinalData?.statsReport?.map((org) => org.name);

      if (chargerFinalData?.statsReport) {
        // const fields = Object.keys(
        //     orgFinalData.statsReport[orgsList[0].value][Object.keys(orgFinalData.statsReport[orgsList[0].value])[0]][0]
        // )
        //     .filter((key) => key !== 'date')
        //     .map((field) => ({
        //         value: field,
        //         label: field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
        //     }));
        // setFieldOptions(fields);
        // setSelectedFields([fields[0].value]);

        const fields = Object.keys(chargerFinalData?.statsReport).flatMap(
          (org) =>
            chargerFinalData?.statsReport[org].length > 0
              ? Object.keys(chargerFinalData?.statsReport[org][0]).filter(
                  (field) => field !== "_id"
                )
              : []
        );

        const uniqueFields = [...new Set(fields)];
        const orgs = Object.keys(chargerFinalData?.statsReport);

        setFieldOptions(
          uniqueFields.map((field) => ({
            value: field,
            label: field
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase()),
          }))
        );
        if (selectedFields.length === 0) {
          setSelectedFields([uniqueFields[0]]);
        }

        setOrgOptions([
          { value: "selectAll", label: "Accumulated" },
          ...orgs.map((org) => ({
            value: org,
            label: org,
          })),
        ]);
        if (selectedOrgs.length === 0) {
          setSelectedOrgs([orgs[0]]);
        }
      }
    }
  }, [chargerData, chargerFinalData]);

  // const handleTimeChange = (event) => {
  //     setSelectedTime(event.target.value);
  // };

  const handleFieldsChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 3) {
      setSnackbarMessage("You can select up to 3 fields only!");
      setOpenSnackbar(true);
      return;
    }
    setSelectedFields(typeof value === "string" ? value.split(",") : value);
  };

  const handleOrgsChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("selectAll")) {
      if (!selectAll) {
        setSelectedOrgs(
          orgOptions
            .filter((org) => org.value !== "selectAll")
            .map((org) => org.value)
        );
      } else {
        setSelectedOrgs([]);
      }
      setSelectAll(!selectAll);
    } else {
      if (value.length > 3) {
        setSnackbarMessage("You can select up to 3 organizations only!");
        setOpenSnackbar(true);
        return;
      }
      setSelectedOrgs(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleTimeChange = (event) => {
    const newSelectedTime = event.target.value;
    setSelectedTime(newSelectedTime);
    if (event.target.value !== "customRange") {
      setSelectedTimeField(newSelectedTime);
      let newStartTime, newEndTime;

      if (newSelectedTime === "week") {
        newStartTime = dayjs().subtract(6, "day").startOf("day");
        newEndTime = dayjs().endOf("day");
      } else if (newSelectedTime === "month") {
        newStartTime = dayjs().startOf("month");
        newEndTime = dayjs().endOf("month");
      } else if (newSelectedTime === "year") {
        newStartTime = dayjs().startOf("year");
        newEndTime = dayjs().endOf("year");
      }
      setStartTime(newStartTime);
      setEndTime(newEndTime);
      fetchAllChargerStats(newSelectedTime, newStartTime, newEndTime);
    } else {
      console.log(event.target.value, "okkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    }
  };

  useEffect(() => {
    if (chargerFinalData && chargerFinalData.length > 0) {
      setDataLoading(false);
    }
  }, [chargerFinalData]);

  const todayMaxDate = dayjs(); // Get today's date

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Box
              sx={{
                padding: "24px",
                borderRadius: "8px",
                background: "#fff",
                width: "100%",
              }}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", md: "flex-start" }}
                mb={0}
              >
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "33%",
                    minWidth: { xs: "100%", md: 150 },
                    mb: { xs: 2, md: 0 },
                    // maxWidth: 250,
                  }}
                >
                  <Box>
                    <InputLabel>Time Frame</InputLabel>
                    <Select
                      value={selectedTime}
                      onChange={handleTimeChange}
                      label="Time Frame"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "left",
                        textAlign: "left",
                        width: "200px",
                        height: "40px",
                        color: "#1C1C1C",
                        fontFamily: "mySecondFont",
                      }}
                    >
                      {timeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {/* {selectedTime === "customRange" && (
                    <Slide
                      direction="up"
                      in={selectedTime === "customRange"}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                            gap: "18px",
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => {
                                  setStartDate(newValue);
                                }}
                                maxDate={todayMaxDate} // Restrict end date to today or earlier
                              />
                            </LocalizationProvider>
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => {
                                  setEndDate(newValue);
                                }}
                                minDate={
                                  startDate ? startDate.add(0, "day") : null
                                }
                                maxDate={todayMaxDate} // Restrict end date to today or earlier
                              />
                            </LocalizationProvider>
                          </Box>

                          <Box>
                            <Button
                              sx={{
                                background: "#222",
                                padding: "8px 24px",
                                height: "48px",
                                width: "142px",
                                borderRadius: "4px",
                                color: "#FCFCFC",
                                fontFamily: "mySecondFont",
                                letterSpacing: "1.12px",
                                ":hover": {
                                  background: "#222",
                                },
                              }}
                              onClick={handleUpdateButtonClick}
                            >
                              Apply
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Slide>
                  )} */}
                  {selectedTime === "customRange" && (
                    <Slide
                      direction="up"
                      in={selectedTime === "customRange"}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Box mt={2}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          gap={2}
                          mt={2}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Start Date & Time"
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                              maxDate={todayMaxDate} // Restrict end date to today or earlier
                            />
                            <DateTimePicker
                              label="End Date & Time"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              minDateTime={
                                startDate ? startDate.add(0, "minute") : null
                              } // Ensure end date is after start date
                              maxDate={todayMaxDate} // Restrict end date to today or earlier
                            />
                          </LocalizationProvider>
                          <Button
                            sx={{
                              background: "#222",
                              padding: "8px 24px",
                              width: "142px",
                              height: "48px",
                              borderRadius: "4px",
                              color: "#FCFCFC",
                              fontFamily: "mySecondFont",
                              letterSpacing: "1.12px",
                              ":hover": {
                                background: "#222",
                              },
                            }}
                            onClick={handleUpdateButtonClick}
                          >
                            Apply
                          </Button>
                        </Box>
                      </Box>
                    </Slide>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{
                    width: "100%",
                    minWidth: { xs: "100%", md: 150 },
                    mb: { xs: 2, md: 0 },
                    maxWidth: 250,
                  }}
                >
                  <InputLabel>Fields</InputLabel>
                  <Select
                    multiple
                    value={selectedFields}
                    onChange={handleFieldsChange}
                    input={<OutlinedInput label="Fields" />}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "left",
                      textAlign: "left",
                      width: "200px",
                      height: "40px",
                      color: "#1C1C1C",
                      fontFamily: "mySecondFont",
                    }}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {fieldOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={selectedFields.indexOf(option.value) > -1}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Line Graph */}
              <CustomLineGraph
                data={chargerFinalData.statsReport}
                graphType={"overall"}
                selectedTime={selectedTimeField}
                selectedFields={selectedFields}
                selectAll={selectAll}
                selectedOrgs={selectedOrgs}
                openSnackbar={openSnackbar}
                snackbarMessage={snackbarMessage}
                orgOptions={orgOptions}
                startTime={startTime.valueOf()}
                endTime={endTime.valueOf()}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* below - table - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ padding: "24px", borderRadius: "8px", background: "#fff" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <TableFilters />
            </Box>

            {/* Table */}
            <CustomTable
              columns={statisticsChargerColumns()}
              rows={
                chargerFinalData?.chargingPointsReport
                  ? chargerFinalData?.chargingPointsReport
                  : []
              }
              // actionType={"edit-delete"}
              isRowClickable={true}
              clickableColumns={["name"]}
              columnClickPaths={{
                name: "/charging_points",
              }}
              columnKeyMappings={{
                name: "device_id",
              }}
              modalHeading={"Items"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverallStatistics;
