import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  sessions: [],
  sessionDetail: null,
  sessionLogs: [],
  invoice: null,
  loading: false,
  error: null,
};

// Create async thunks for API calls
export const fetchAllSessions = createAsyncThunk(
  "sessions/fetchAllSessions",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getAllSessions}?isActive=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOneSession = createAsyncThunk(
  "sessions/fetchOneSession",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneSession}/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOneSessionLogs = createAsyncThunk(
  "sessions/fetchOneSessionLogs",
  async ({ sessionId, page, limit, logsFilter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneSessionLogs}/${sessionId}?page=${page}&limit=${limit}&filter=${logsFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateInvoice = createAsyncThunk(
  "sessions/generateInvoice",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.invoiceGenerate}/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming the API response contains the generated invoice data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
      })
      .addCase(fetchAllSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionDetail = action.payload;
      })
      .addCase(fetchOneSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneSessionLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneSessionLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionLogs = action.payload; // Store session logs in state
      })
      .addCase(fetchOneSessionLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(generateInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.invoice = action.payload; // Store the generated invoice data
      })
      .addCase(generateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default sessionsSlice.reducer;
