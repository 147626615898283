import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/tariff_management/TopBar";
import TariffTableBox from "../../components/tariff_management/TariffTableBox";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";

const TariffManagement = () => {
  const navigate = useNavigate();
  useScrollToTop(navigate);
  return (
    <Box>
      {/* TopBar */}
      <TopBar />

      {/* Tariff Table Box */}
      <TariffTableBox />
    </Box>
  );
};

export default TariffManagement;
