// src/slices/financeStationsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  stations: [],
  walletHistory: [],
  stationPaymentHistory: [],
  dailySheetPassbook: [],
  status: "idle", // idle | loading | succeeded | failed
  walletStatus: "idle", // added this to manage wallet history status separately
  error: null,
  amountAdded: null,
  addStatus: "idle",
  stationBalance: null,
};
// Create async thunk for fetching finance stations
export const fetchFinanceStations = createAsyncThunk(
  "financeStations/fetchFinanceStations",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllFinanceStation, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for adding finance amount
export const addFinanceAmount = createAsyncThunk(
  "financeStations/addFinanceAmount",
  async (amountData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.financeAmountAdd,
        amountData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching wallet history
export const fetchWalletHistory = createAsyncThunk(
  "financeStations/fetchWalletHistory",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.allWalletHistory, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching wallet history with time
export const fetchWalletHistoryWithTime = createAsyncThunk(
  "financeStations/fetchWalletHistoryWithTime",
  async ({ stationId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.allWalletHistory}/?stationId=${stationId}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching wallet history with time
export const fetchOneStationWalletHistory = createAsyncThunk(
  "financeStations/fetchOneStationWalletHistory",
  async ({ stationId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.allWalletHistory}/?stationId=${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching one station's balance
export const fetchOneStationBalance = createAsyncThunk(
  "financeStations/fetchOneStationBalance",
  async (mobileNumber, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStationBalance}/${mobileNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching one station's balance
export const fetchOneStationPaymentHistory = createAsyncThunk(
  "financeStations/fetchOneStationPaymentHistory",
  async (mobileNumber, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStationPaymentHistory}/${mobileNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching one station's payment history with time
export const fetchOneStationPaymentHistoryWithTime = createAsyncThunk(
  "financeStations/fetchOneStationPaymentHistoryWithTime",
  async ({ mobileNumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getOneStationPaymentHistory}/${mobileNumber}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching daily sheet passbook
export const fetchAllDailySheetPassbook = createAsyncThunk(
  "financeStations/fetchAllDailySheetPassbook",
  async (mobileNumber, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getDailySheet}/${mobileNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.walletHistory; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create async thunk for fetching daily sheet passbook
export const fetchDailySheetPassbook = createAsyncThunk(
  "financeStations/fetchDailySheetPassbook",
  async ({ mobileNumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${endPoints.getDailySheet}/${mobileNumber}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.walletHistory; // Adjust this if your API response structure differs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Create the slice
const financeStationsSlice = createSlice({
  name: "financeStations",
  initialState,
  reducers: {
    clearAmountAdded(state) {
      state.amountAdded = null; // Clear the amountAdded state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinanceStations.pending, (state) => {
        state.walletStatus = "loading";
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchFinanceStations.fulfilled, (state, action) => {
        state.walletStatus = "succeeded";
        state.stations = action.payload; // Assuming payload is an array of stations
      })
      .addCase(fetchFinanceStations.rejected, (state, action) => {
        state.walletStatus = "failed";
        state.error = action.payload; // Use action.payload for detailed error messages
      })
      .addCase(addFinanceAmount.pending, (state) => {
        state.addStatus = "loading";
        state.error = null; // Clear any previous errors
        state.amountAdded = null; // Reset amountAdded when API call starts
      })
      .addCase(addFinanceAmount.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
        state.amountAdded = action.meta.arg.amount_added;
        // Handle success state or update local state as needed
      })
      .addCase(addFinanceAmount.rejected, (state, action) => {
        state.addStatus = "failed";
        state.error = action.payload; // Use action.payload for detailed error messages
      })
      .addCase(fetchWalletHistory.pending, (state) => {
        state.walletStatus = "loading";
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchWalletHistory.fulfilled, (state, action) => {
        state.walletStatus = "succeeded";
        state.walletHistory = action.payload; // Assuming payload is an array of wallet history
      })
      .addCase(fetchWalletHistory.rejected, (state, action) => {
        state.walletStatus = "failed";
        state.error = action.payload; // Use action.payload for detailed error messages
      })
      .addCase(fetchWalletHistoryWithTime.pending, (state) => {
        state.walletStatus = "loading";
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchWalletHistoryWithTime.fulfilled, (state, action) => {
        state.walletStatus = "succeeded";
        state.walletHistory = action.payload; // Update walletHistory with the filtered data
      })
      .addCase(fetchWalletHistoryWithTime.rejected, (state, action) => {
        state.walletStatus = "failed";
        state.error = action.payload; // Use action.payload for detailed error messages
      })
      .addCase(fetchOneStationWalletHistory.pending, (state) => {
        state.walletStatus = "loading";
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchOneStationWalletHistory.fulfilled, (state, action) => {
        state.walletStatus = "succeeded";
        state.walletHistory = action.payload; // Update walletHistory with the filtered data
      })
      .addCase(fetchOneStationWalletHistory.rejected, (state, action) => {
        state.walletStatus = "failed";
        state.error = action.payload; // Use action.payload for detailed error messages
      })
      .addCase(fetchOneStationBalance.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOneStationBalance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stationBalance = action.payload; // Store the balance
      })
      .addCase(fetchOneStationBalance.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOneStationPaymentHistory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOneStationPaymentHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stationPaymentHistory = action.payload; // Store the payment history
      })
      .addCase(fetchOneStationPaymentHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOneStationPaymentHistoryWithTime.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchOneStationPaymentHistoryWithTime.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.stationPaymentHistory = action.payload; // Store the payment history
        }
      )
      .addCase(
        fetchOneStationPaymentHistoryWithTime.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      )
      .addCase(fetchAllDailySheetPassbook.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAllDailySheetPassbook.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dailySheetPassbook = action.payload; // Store passbook data separately
      })
      .addCase(fetchAllDailySheetPassbook.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchDailySheetPassbook.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDailySheetPassbook.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dailySheetPassbook = action.payload; // Store passbook data separately
      })
      .addCase(fetchDailySheetPassbook.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearAmountAdded } = financeStationsSlice.actions;
// Export the reducer
export default financeStationsSlice.reducer;
