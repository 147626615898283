import { Box, Typography } from "@mui/material";
import React from "react";
import ValueChip from "../Chips/ValueChip";
import { GetCurrencySymbol } from "../../utils/currency/currencyChangeFunction";
import { useLocation } from "react-router-dom";

const ValueCard = ({
  data,
  previousDashboardData,
  customers,
  previousCustomersData,
  topGraphBox,
  title,
  orgPaymentData,
  previousOrgPaymentData,
  previousPaymentWalletData,
  selectedTime,
  cardTypeProp,
  hubSession,
  walletData,
  previousWalletData,
}) => {
  const location = useLocation();
  // Helper function to format value with + sign for positive values
  const formatValue = (value) => {
    if (typeof value === "string" && value.startsWith("-")) {
      return value;
    }
    return `+${value}`;
  };

  console.log("iiijjggg", selectedTime);

  const convertDurationToHours = (duration) => {
    if (!duration || typeof duration !== "string" || !duration.includes(":")) {
      console.warn("Invalid duration format:", duration);
      return "0.00";
    }

    const [hours, minutes, seconds] = duration.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.warn("Invalid duration components:", duration);
      return "0.00";
    }

    const totalHours = hours + minutes / 60 + seconds / 3600;

    return totalHours.toFixed(2); // Round to two decimal places
  };

  const hubStatsOverview = location.pathname.includes("/hub_management");

  const currencySymbol = GetCurrencySymbol();

  const changeIcons = () => {
    switch (title) {
      case "Total Billed Amount":
        return (
          <Box
            sx={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #666",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "myFirstFont",
            }}
          >
            {currencySymbol}
          </Box>
        );
      case "Hub Sessions":
        return "offline_bolt";
      case "Total Hub Energy (kWh)":
        return "bolt";
      case "Total Hub Passes Sold":
        return "book_online";
      case "Total Value of Passes Sold":
        return (
          <Box
            sx={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #666",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "mySecondFont",
            }}
          >
            {currencySymbol}
          </Box>
        );
      case "Total Energy Consumed (kWh)":
        return "bolt";
      case "Total uptime (hrs)":
        return "pace";
      case "New customers":
        return "groups";
      case "Total Sessions":
        return "bolt";
      case "Wallet Recharge":
        return "account_balance_wallet";
      case "Wallet Recharge (INR)":
        return "account_balance_wallet";
      case "Wallet Balance":
        return "account_balance_wallet";
      case "Cashbacks":
        return "smartphone";
      case "Refund Amount":
        return "undo";
      case "Direct amount (QR)":
        return "qr_code_2";
      case "Amount from app":
        return "smartphone";
      case "Total Sold Pass Value":
        return (
          <Box
            sx={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "2px solid #333",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "mySecondFont",
              fontWeight: "bold",
            }}
          >
            {currencySymbol}
          </Box>
        );
      default:
        return "info";
    }
  };

  // Function to get the number of days based on selectedTime
  const getDaysBasedOnSelectedTime = (selectedTime) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    switch (selectedTime) {
      case "week":
        return 7;
      case "today":
      case "yesterday":
        return 1;
      case "month":
        const daysInMonth = new Date(
          currentYear,
          currentMonth + 1,
          0
        ).getDate();
        return daysInMonth;
      case "year":
        // Return the number of days in the current year
        const isLeapYear = (year) =>
          (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        return isLeapYear(currentYear) ? 366 : 365;
      default:
        return 0;
    }
  };

  const convertedTotalSessionDurationInPercentage = (data) => {
    const convertedDurationToHours = convertDurationToHours(data?.totalDuration);
    const charging_point_time_based = data?.totalCharingPoints_time;
    const noOfDaysBasedOnSelection = getDaysBasedOnSelectedTime(selectedTime);
  

    const denominator = noOfDaysBasedOnSelection * 24 * charging_point_time_based;

    const safeDenominator = denominator === 0 ? 1 : denominator;
  

    const utilizationResult = (convertedDurationToHours / safeDenominator) * 100;
  
    return utilizationResult.toFixed(2); // Ensure the result is rounded to 2 decimal places
  };
  console.log("convertedddd", convertedTotalSessionDurationInPercentage);

  const changevalue = () => {
    switch (title) {
      case "Total Billed Amount":
        return `${currencySymbol} ${data?.totalRevenue || orgPaymentData}`;
      case "Hub Sessions":
        return `${data?.totalSessionCount_Guard}`;
      case "Total Hub Sessions":
        return `${
          data?.hubChargingSessionsStats?.overallChargingSessionsDetails
            ?.completedSessionsCount || 0
        }`;
      case "Total Hub Energy (kWh)":
        return `${data?.totalHubEnergy}`;
      case "Total Hub Passes Sold":
        return `${data?.totalPassCount}`;
      case "Total Value of Passes Sold":
        return `${currencySymbol} ${data?.totalPassValue}`;
      case "Total Billed Amount (INR)":
        return `${currencySymbol}  ${orgPaymentData}`;
      case "Total Energy Consumed (kWh)":
        return hubStatsOverview
          ? data?.hubChargingSessionsStats?.overallChargingSessionsDetails
              ?.energyConsumed || 0
          : (data?.totalEnergy / 1000).toFixed(2);
      case "Total uptime (hrs)":
        return (
          <>
            {convertedTotalSessionDurationInPercentage(data)}%{" "}
            <div
              style={{ fontSize: "12px", color: "#FF6347", fontWeight: "bold" }}
            >
              {convertDurationToHours(data?.totalDuration)} Hrs
            </div>
          </>
        );
        case "Total Utilization (hrs)":
          return (
            <>
              {convertedTotalSessionDurationInPercentage(data)}%{" "}
              <div
                style={{ fontSize: "12px", color: "#FF6347", fontWeight: "bold" }}
              >
                {convertDurationToHours(data?.totalDuration)} Hrs
              </div>
            </>
          );
      case "New customers":
        return customers?.customersCount;
      case "Total Sessions":
        return data?.totalSessionCount;
      case "Wallet Recharge":
        return data?.totalWalletAmountAdded;
      case `Wallet Recharge (${currencySymbol})`:
        return data?.totalWalletAmountAdded;
      case "Cashbacks":
        return `${currencySymbol}  ${data?.totalWalletCashbackAmount}`;
      case "Refund Amount":
        return `${currencySymbol}  ${data?.refundAmount || orgPaymentData}`;
      case "Total Amount":
        return `${currencySymbol}  ${data?.paidAmount}`;
      case "Direct Amount (QR)":
        return `${currencySymbol}  ${orgPaymentData}`;
      case "Amount from App":
        return `${currencySymbol}  ${orgPaymentData}`;
      case "Total Sold Pass Count":
        return (
          data?.hubCashTransactionStats?.overallPassDetails?.totalPassCount || 0
        );
      case "Total Sold Pass Value":
        return `${currencySymbol} ${
          data?.hubCashTransactionStats?.overallPassDetails?.totalPassValue || 0
        }`;
      default:
        return data?.value || "N/A";
    }
  };

  // Helper function to convert duration string to seconds
  const convertToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
  };

  // Helper function to convert seconds back to duration string
  const convertToDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  // Calculate the difference in durations
  const calculateDurationDifference = (currentDuration, previousDuration) => {
    const currentSeconds = convertToSeconds(currentDuration);
    const previousSeconds = convertToSeconds(previousDuration);

    let differenceInSeconds = previousSeconds - currentSeconds;

    return convertToDuration(Math.abs(differenceInSeconds));
  };

  const compareResultTotalRevenue = (
    data?.totalRevenue - previousDashboardData?.totalRevenue
  ).toFixed(2);

  const compareResultTotalEnergy =
    data?.totalEnergy - previousDashboardData?.totalEnergy;

  const compareResultTotalDuration =
    data?.totalDuration && previousDashboardData?.totalDuration
      ? data?.totalDuration.startsWith("-")
        ? "-" +
          calculateDurationDifference(
            "0:0:0",
            previousDashboardData?.totalDuration || "0:0:0"
          )
        : calculateDurationDifference(
            data?.totalDuration || "0:0:0",
            previousDashboardData?.totalDuration || "0:0:0"
          )
      : "0:0:0";

  const compareResultTotalDurationInHours = (data, previousDashboardData) => {
    const currentDuration = convertDurationToHours(data?.totalDuration);
    const previousDuration = convertDurationToHours(
      previousDashboardData?.totalDuration
    );
    const result = currentDuration - previousDuration;

    return result.toFixed(2);
  };

  const compareResultTotalSession =
    data?.totalSessionCount - previousDashboardData?.totalSessionCount;

  const compareResultWalletRecharge = (
    data?.totalWalletAmountAdded -
    previousPaymentWalletData?.totalWalletAmountAdded
  ).toFixed(2);

  const compareResultCashback = (
    data?.totalWalletCashbackAmount -
    previousPaymentWalletData?.totalWalletCashbackAmount
  ).toFixed(2);

  const compareNewUsers =
    customers?.customersCount - previousCustomersData?.customersCount;

  const compareResultWalletRechargeINR =
    data?.totalWalletAmountAdded -
    previousDashboardData?.totalWalletAmountAdded;

  const comparePassValue =
    data?.hubCashTransactionStats?.overallPassDetails?.totalPassValue -
    previousDashboardData?.hubCashTransactionStats?.overallPassDetails
      ?.totalPassValue;

  const comparePassCount =
    data?.hubCashTransactionStats?.overallPassDetails?.totalPassCount -
    previousDashboardData?.hubCashTransactionStats?.overallPassDetails
      ?.totalPassCount;

  const compareHubEnergy =
    data?.hubChargingSessionsStats?.overallChargingSessionsDetails
      ?.energyConsumed -
    previousDashboardData?.hubChargingSessionsStats
      ?.overallChargingSessionsDetails?.energyConsumed;

  const compareHubTotalSessions =
    data?.hubChargingSessionsStats?.overallChargingSessionsDetails
      ?.completedSessionsCount -
    previousDashboardData?.hubChargingSessionsStats
      ?.overallChargingSessionsDetails?.completedSessionsCount;

  const compareTotalPassSold =
    data?.totalPassCount - previousDashboardData?.totalPassCount;

  const compareTotalPassValueSold =
    data?.totalPassValue - previousDashboardData?.totalPassValue;

  const compareHubSessions =
    data?.totalSessionCount_Guard -
    previousDashboardData?.totalSessionCount_Guard;

  const changeCompare = () => {
    switch (title) {
      case "Total Billed Amount":
        return compareResultTotalRevenue;
      case "Total Energy Consumed (kWh)":
        return hubStatsOverview
          ? compareHubEnergy.toFixed(2)
          : (compareResultTotalEnergy / 1000).toFixed(2);
      case "Total uptime (hrs)":
        return `${compareResultTotalDurationInHours(
          data,
          previousDashboardData
        )} Hrs`;
        case "Total Utilization (hrs)":
          return `${compareResultTotalDurationInHours(
            data,
            previousDashboardData
          )} Hrs`;
      case "Total Sessions":
        return compareResultTotalSession;
      case "Wallet Recharge":
        return compareResultWalletRecharge;
      case "Wallet Recharge (INR)":
        return compareResultWalletRechargeINR;
      case `Wallet Recharge (${currencySymbol})`:
        return compareResultWalletRechargeINR;
      case "Cashbacks":
        return compareResultCashback;
      case "New customers":
        return compareNewUsers;
      case "Total Sold Pass Value":
        return comparePassValue;
      case "Total Sold Pass Count":
        return comparePassCount;
      case "Total Hub Sessions":
        return compareHubTotalSessions;
      case "Hub Sessions":
        return compareHubSessions;
      case "Total Hub Passes Sold":
        return compareTotalPassSold;
      case "Total Value of Passes Sold":
        return compareTotalPassValueSold;

      //payment
      case "Total Billed Amount (INR)":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      case "Direct Amount (QR)":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      case "Amount from App":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      case "Refund Amount":
        return (orgPaymentData - previousOrgPaymentData).toFixed(2);
      default:
        return "No Data" || "N/A";
    }
  };

  // Function to get the comparison text based on selectedTime
  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  const getCompareColor = (compare) => {
    const compareStr = String(compare); // Ensure it's a string
    return compareStr.startsWith("-") ? "#790000" : "#027900";
  };

  return (
    <>
      {cardTypeProp === "dashboard" ? (
        <Box
          sx={{
            padding: topGraphBox === "true" ? "12px" : "24px",
            borderRadius: "8px",
            background: "#fff",
            height: "100%",
            border: topGraphBox === "true" ? "1px solid #ddd" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Box=1 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(41, 45, 51, 0.08)",
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  marginRight: "8px",
                }}
              >
                <span
                  style={{ color: "#292D33", fontWeight: 300 }}
                  className="material-symbols-outlined"
                >
                  {changeIcons()}
                </span>
              </Box>
              <Box>
                <Typography
                  textAlign={"start"}
                  fontSize={"14px"}
                  fontFamily={"mySecondFont"}
                  color={"#666"}
                >
                  {title}
                </Typography>
                <Typography
                  textAlign={"start"}
                  fontSize={"clamp(1.25rem, 1.1981rem + 0.2516vw, 1.5rem)"}
                  color={"#0C0C0C"}
                  fontFamily="'Montserrat', sans-serif"
                  fontWeight={600}
                >
                  {changevalue()}
                </Typography>
              </Box>
            </Box>
            {/* Box=2 */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-end",
              }}
            >
              <ValueChip
                data={data}
                previousDashboardData={previousDashboardData}
                previousPaymentWalletData={previousPaymentWalletData}
                orgPaymentData={orgPaymentData}
                previousOrgPaymentData={previousOrgPaymentData}
                title={title}
                customers={customers}
                previousCustomersData={previousCustomersData}
                hubSession={hubSession}
              />
              <Typography
                fontFamily={"myThirdFont"}
                color={"#666"}
                sx={{
                  textAlign: "right",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                <span
                  style={{
                    color: getCompareColor(changeCompare()),
                    fontFamily: "mySecondFont",
                  }}
                >
                  {changeCompare()}
                </span>{" "}
                than {getComparisonText()}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: topGraphBox === "true" ? "12px" : "24px",
            borderRadius: "8px",
            background: "#fff",
            border: topGraphBox === "true" ? "1px solid #ddd" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(41, 45, 51, 0.08)",
                width: "32px",
                height: "32px",
                borderRadius: "32px",
              }}
            >
              <span
                style={{ color: "#292D33" }}
                className="material-symbols-outlined"
              >
                {changeIcons()}
              </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-end",
              }}
            >
              <ValueChip
                data={data}
                previousDashboardData={previousDashboardData}
                previousPaymentWalletData={previousPaymentWalletData}
                orgPaymentData={orgPaymentData}
                previousOrgPaymentData={previousOrgPaymentData}
                title={title}
                customers={customers}
                previousCustomersData={previousCustomersData}
              />
              <Typography fontFamily={"mySecondFont"} color={"#666"}>
                <span
                  style={{
                    color: getCompareColor(changeCompare()),
                    fontFamily: "mySecondFont",
                  }}
                >
                  {changeCompare()}
                </span>{" "}
                than {getComparisonText()}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: "12px" }}>
            <Typography
              textAlign={"start"}
              fontSize={"14px"}
              fontFamily={"mySecondFont"}
              color={"#666"}
            >
              {title}
            </Typography>
            <Typography
              textAlign={"start"}
              fontSize={"clamp(1.25rem, 1.1981rem + 0.2516vw, 1.5rem)"}
              color={"#0C0C0C"}
              fontFamily="'Montserrat', sans-serif"
              fontWeight={600}
            >
              {changevalue()}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ValueCard;
