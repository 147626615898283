import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CustomTable from "../../custom/CustomTable";
import { HubOneShiftTransaction } from "../../../utils/DummyData";
import { useDispatch } from "react-redux";
import { getShiftAllTransaction } from "../../../redux/action/hubManagement/hubManagementSlice";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { formatPhoneNumber } from "../../../utils/countryCode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  maxHeight: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 3,
};

const GuardShiftModal = ({ open, handleClose, data }) => {
  console.log("yoooo", data);
  const hubId = data?._id;
  const dispatch = useDispatch();

  const { shiftAllTransaction } = useSelector((state) => state.hubManagement);

  React.useEffect(() => {
    dispatch(getShiftAllTransaction(hubId));
  }, [dispatch]);

  const getAllSgiftTransactionData = shiftAllTransaction.map((form) => {
    const rawPhone = form?.passHolder?.phone;
    const formattedPhone = formatPhoneNumber(rawPhone);

    return {
      _id: form?._id,
      cashAmount: form?.cashAmount,
      passType: form?.hubPassPlans?.passType,
      passId: form?.hubPassPlans?.passId,
      passHolderName: form?.passHolder?.name,
      passHolderPhone: formattedPhone,
      city: form?.station?.city,
      createdAt: form?.createdAt,
    };
  });

  console.log("yoooo", shiftAllTransaction);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "12px",
            }}
          >
            <Typography fontFamily={"mySecondFont"} fontSize={"18px"}>
              Guard: {data?.hubUser}
            </Typography>
            <Typography fontFamily={"mySecondFont"} fontSize={"18px"}>
              Station: {data?.stationId}
            </Typography>
          </Box>
          {/* Table */}
          <CustomTable
            columns={HubOneShiftTransaction()}
            rows={getAllSgiftTransactionData}
            modalHeading={"Guard Shift Transactions"}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default GuardShiftModal;
