import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import LogsDetails from "./Logs/LogsDetails";
import LogsTableBox from "./Logs/LogsTableBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneChargingPointLogs } from "../../redux/action/chargingPointSlice/chargingPointSlice";

const LogsBox = ({ getOneData }) => {
  const [selectedLog, setSelectedLog] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [logsFilter, setLogsFilter] = useState("createdAt");
  const [liveLogsData, setLiveLogsData] = useState(true);

  const { logs, loading } = useSelector((state) => state.chargingPoints);

  useEffect(() => {
    let chargingPointId = getOneData?.device_id;
    if (chargingPointId) {
      dispatch(
        fetchOneChargingPointLogs({ chargingPointId, page, limit, logsFilter })
      );
    }
  }, [dispatch, getOneData, page, limit, logsFilter, liveLogsData]);

  const handleLiveData = () => {
    setLiveLogsData(!liveLogsData);
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ flex: 1, overflow: "hidden" }}>
        <Grid
          item
          md={8}
          sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
        >
          <LogsTableBox
            onRowClick={setSelectedLog}
            liveLogBtn={true}
            data={logs?.logs}
            logsCount={logs?.logsCount}
            lastLog={logs?.lastLog}
            liveLogsData={liveLogsData}
            handleLiveData={handleLiveData}
            page={page}
            limit={limit}
            logsFilter={logsFilter}
            setLogsFilter={setLogsFilter}
            setPage={setPage}
            setLimit={setLimit}
            getOneData={getOneData}
            loading={loading}
          />
        </Grid>
        <Grid
          item
          md={4}
          sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
        >
          <LogsDetails logData={selectedLog} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogsBox;
