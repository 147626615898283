import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import { Button, Divider, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  assignNewRFID,
  assignRFID,
  checkRFIDAvailability,
  getAllRFIDRejectRequests,
  getAllRFIDRequests,
} from "../../../redux/action/RFID/rfidSlice";
import { toast } from "react-toastify";
import { formatDateTime } from "../../../utils/FormatDateTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const InfoBox = ({ label, value }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontFamily={"myThirdFont"} fontSize={"14px"} color={"#2F2F2F"}>
      {label}
    </Typography>
    <Typography fontSize={"16px"} fontFamily={"mySecondFont"} color={"#2F2F2F"}>
      {value}
    </Typography>
  </Box>
);

const RFIDConfirmModal = ({
  open,
  handleClose,
  rejectedModal,
  handleCurrentModal,
}) => {
  const [validity, setValidity] = React.useState(1);
  const dispatch = useDispatch();
  const selectedRow = useSelector((state) => state.rfid.selectedRow);
  const assignedRFID = useSelector((state) => state.rfid.assignedRFID);
  const { rfidAvailability, loading, error } = useSelector(
    (state) => state.rfid
  );
  const [serialNum, setSerialNum] = React.useState("");
  const [inputError, setInputError] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow only numbers and limit to 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setSerialNum(value);
      setInputError(""); // Clear error if the input is valid

      // Enable the button only if the serial number has exactly 10 digits
      if (value.length === 10) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  };

  React.useEffect(() => {
    if (serialNum.length === 10) {
      dispatch(checkRFIDAvailability(serialNum))
        .unwrap() // Get the response directly
        .then((response) => {
          // Assuming response contains a 'message' property
          if (response.status === "success") {
            setInputError(""); // Clear any previous errors
            setButtonDisabled(false); // Enable button if the RFID is available
          }
        })
        .catch((err) => {
          setButtonDisabled(true); // Keep the button disabled if there is an error
          setInputError("Wrong RFID number or unavailable RFID.");
        });
    }
  }, [serialNum, dispatch]);

  // Function to safely format date and time
  const safeFormatDateTime = (dateString) => {
    if (!dateString) return { date: "N/A", time: "N/A" }; // Default value for invalid date
    const formattedDate = formatDateTime(dateString);
    return formattedDate || { date: "N/A", time: "N/A" };
  };

  const formattedRequestDate = safeFormatDateTime(selectedRow?.createdAt);

  const handleChange = (event) => {
    setValidity(event.target.value);
  };

  // const handleAssignRFID = () => {
  //   const requestData = {
  //     rfid: assignedRFID,
  //     expiryYears: +validity,
  //     customer_id: selectedRow?.customer?._id, // Add any other data you need
  //   };

  //   dispatch(assignRFID(requestData))
  //     .unwrap() // This allows you to handle success and error
  //     .then(() => {
  //       toast.success("RFID assigned successfully");
  //       // Conditional dispatch based on the rejectedModal prop
  //       if (rejectedModal) {
  //         handleCurrentModal();
  //         dispatch(getAllRFIDRejectRequests());
  //       } else {
  //         dispatch(getAllRFIDRequests());
  //       }
  //       handleClose(); // Close the modal after successful assignment
  //     })
  //     .catch((error) => {
  //       console.error("Failed to assign RFID:", error);
  //     });
  // };

  const handleAssignRFID = () => {
    const data = {
      customer_id: selectedRow?.customer?._id,
      serialNumber: serialNum,
    };

    dispatch(assignNewRFID(data))
      .unwrap() // This allows you to handle success and error
      .then(() => {
        toast.success("RFID assigned successfully");
        // Conditional dispatch based on the rejectedModal prop
        if (rejectedModal) {
          handleCurrentModal();
          dispatch(getAllRFIDRejectRequests());
        } else {
          dispatch(getAllRFIDRejectRequests());
          dispatch(getAllRFIDRequests());
        }
        handleClose(); // Close the modal after successful assignment
      })
      .catch((error) => {
        console.error("Failed to assign RFID:", error);
      });
  };

  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="assign-rfid-modal"
      >
        <Box sx={style}>
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading text="Confirm Details" />
          </Box>
          <Divider sx={{ background: "#DDD", mt: "8px" }} />

          {/* Modal Content */}
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              alignItems: "center",
              mt: "24px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                fontSize={"14px"}
                color={"#2f2f2f"}
                fontFamily={"myThirdFont"}
              >
                Enter RFID Serial No.
              </Typography>
              <Box sx={{ minWidth: 320 }}>
                <input
                  style={{
                    width: "100%",
                    padding: "16px",
                    outline: "none",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontFamily: "mySecondFont",
                  }}
                  onChange={handleInputChange} // Track input changes
                  maxLength={10}
                  type="number"
                  value={serialNum}
                />
                {/* Error message below the input */}
                {inputError && (
                  <Typography
                    sx={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {inputError}
                  </Typography>
                )}
              </Box>
            </Box>

            {/* <Box
              sx={{
                padding: "12px 24px",
                width: "364px",
                borderRadius: "4px",
                background:
                  "linear-gradient(0deg, rgba(2, 121, 0, 0.10) 0%, rgba(2, 121, 0, 0.10) 100%), #FFF",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"18px"}
                color={"#2f2f2f"}
              >
                Assigned RFID UID
              </Typography>
              <Typography
                fontFamily={"mySecondFont"}
                fontSize={"24px"}
                color={"#027900"}
              >
                {assignedRFID}
              </Typography>
            </Box> */}

            {/* Select */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                fontSize={"14px"}
                color={"#2f2f2f"}
                fontFamily={"myThirdFont"}
              >
                RFID Validity
              </Typography>
              <Box sx={{ minWidth: 320 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={validity}
                    onChange={handleChange}
                    sx={{ fontFamily: "myThirdFont" }}
                  >
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={1}>
                      1 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={2}>
                      2 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={3}>
                      3 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={4}>
                      4 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={5}>
                      5 Year
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "28px",
              mt: "24px",
            }}
          >
            {/* First Row */}
            <InfoBox
              label="Customer Name"
              value={selectedRow?.customer?.name}
            />
            <InfoBox
              label="Mobile Number"
              value={selectedRow?.customer?.phone}
            />
            <InfoBox label="Organization" value={"1C"} />
            {/* <InfoBox
              label="Request Date"
              value={selectedRow?.customer?.createdAt}
            /> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#2F2F2F"}
              >
                Request date
              </Typography>
              <Box>
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#2F2F2F"}
                  lineHeight={"16px"}
                >
                  {formattedRequestDate?.date}
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                  color={"#666"}
                  lineHeight={"16px"}
                >
                  {formattedRequestDate?.time}
                </Typography>
              </Box>
            </Box>

            {/* Second Row */}
            <InfoBox label="Pin code" value={selectedRow?.pincode} />
            <InfoBox label="City/ Town" value={selectedRow?.city} />
            <InfoBox label="State" value={selectedRow?.state} />
            <InfoBox label="Country" value={selectedRow?.country} />
          </Box>

          {/* Address Row */}
          <Box sx={{ display: "flex", flexDirection: "column", mt: "24px" }}>
            <InfoBox label="Full address" value={selectedRow?.address} />
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              mt: "24px",
              gap: "16px",
              mt: "42px",
            }}
          >
            <Button
              sx={{
                padding: "8px 24px",
                borderRadius: "4px",
                background: "#E50000",
                fontFamily: "'Montserrat', sans-serif",
                textTransform: "capitalize",
                color: "#fff",
                fontSize: "16px",
                width: "320px",
                ":hover": { background: "#E50000" },
              }}
              onClick={handleAssignRFID}
              disabled={buttonDisabled || loading}
            >
              Assign RFID
            </Button>
            <Button
              sx={{
                padding: "8px 24px",
                borderRadius: "4px",
                background: "#fff",
                color: "#790000",
                fontSize: "14px",
                fontFamily: "'Montserrat', sans-serif",
                textTransform: "capitalize",
                border: "1px solid #292D33",
                fontWeight: 600,
                width: "100px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RFIDConfirmModal;
