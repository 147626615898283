import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

const AcDcBoxSkeleton = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item md={6} xs={12} key={index}>
            <Box
              sx={{
                border: "1px solid #ddd",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                padding: "12px",
                gap: "10px",
              }}
            >
              {/* Icon and Title Skeleton */}
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Skeleton
                  variant="circular"
                  width={24}
                  height={24}
                  animation="wave"
                />
                <Typography
                  fontSize={"18px"}
                  fontFamily={"mySecondFont"}
                  color={"#1c1c1c"}
                >
                  <Skeleton width="60%" animation="wave" />
                </Typography>
              </Box>

              {/* Percentage Change Skeleton */}
              <Box
                sx={{
                  padding: "2px 8px",
                  background: "rgba(0,0,0,0.08)",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "90px",
                  width: "100%",
                }}
              >
                <Typography fontFamily={"mySecondFont"} color={"#666"}>
                  <Skeleton width="80%" animation="wave" />
                </Typography>
              </Box>

              {/* Energy and Profit Skeleton */}
              <Box
                sx={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                {/* Energy */}
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <Skeleton
                    variant="text"
                    width={16}
                    height={16}
                    animation="wave"
                  />
                  <Typography
                    color={"#2F2F2F"}
                    fontFamily={"myThirdFont"}
                    fontSize={"14px"}
                  >
                    <Skeleton width="40px" animation="wave" />
                  </Typography>
                </Box>

                {/* Profit */}
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <Skeleton
                    variant="circular"
                    width={15}
                    height={15}
                    animation="wave"
                  />
                  <Typography
                    color={"#2F2F2F"}
                    fontFamily={"myThirdFont"}
                    fontSize={"14px"}
                  >
                    <Skeleton width="40px" animation="wave" />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AcDcBoxSkeleton;
