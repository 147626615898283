import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

// List of country codes to exclude from the UI
const excludedCountryCodes = [
  "NZ",
  "US",
  "IN",
  "GB",
  "AU",
  "CA",
  "DE",
  "FR",
  "IT",
  "ES",
  "MX",
  "BR",
  "ZA",
  "RU",
  "CN",
  "JP",
];

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  let breadcrumbPath = "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {pathnames.map((name, index) => {
        breadcrumbPath += `/${name}`;
        const isLast = index === pathnames.length - 1;

        // Check if the current breadcrumb is a country code (to exclude from UI)
        const isCountryCode = excludedCountryCodes.includes(name);

        // Handle dynamic route segments (like :id)
        const isDynamicSegment = name.includes(":"); // Checking for dynamic parameters

        return (
          <Box
            key={breadcrumbPath}
            sx={{
              display: "flex",
              alignItems: "center",
              "&:not(:last-of-type)": {
                marginRight: "4px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#292D33",
                fontFamily: "myThirdFont",
                fontSize: "14px",
                lineHeight: "20px", // 142.857%
                textDecoration: isLast ? "none" : "underline",
                textTransform: "uppercase",
                display: isCountryCode || isDynamicSegment ? "none" : "inline", // Hide country code and dynamic segments
              }}
            >
              {isLast ? (
                `${name}` // Show the last segment without a link
              ) : (
                <Link
                  to={breadcrumbPath}
                  style={{
                    color: "#292D33",
                    textDecoration: "none",
                  }}
                >
                  {name}
                </Link>
              )}
            </Typography>
            {/* Add separator if not the last breadcrumb */}
            {!isLast && !isCountryCode && !isDynamicSegment && (
              <Typography
                sx={{
                  color: "#292D33",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "inline",
                  marginX: "4px",
                }}
              >
                {" > "}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Breadcrumbs;
