import { Box, Button, Typography } from "@mui/material";
import React from "react";
import OcpiManageMainTable from "./OcpiManageMainTable";

const OcpiMainTableBox = ({
  isRegistered,
  onProceedClick,
  operatorId,
  networkDetail,
  defaultTariffs,
}) => {
  console.log("gggggggggggggggg", defaultTariffs);
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        // height: "calc(100vh - 290px)",
        // overflowY: "auto",
      }}
    >
      <OcpiManageMainTable
        operatorId={operatorId}
        networkDetail={networkDetail}
        defaultTariffs={defaultTariffs}
      />
    </Box>
  );
};

export default OcpiMainTableBox;
