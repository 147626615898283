import { Box, Grid } from "@mui/material";
import React from "react";
import ValueCard from "../dashboard/ValueCard";
import ComposedGraph from "../Graphs/ComposedGraph";
import GraphZoomButton from "../../utils/GraphZoomButton";
import { useLocation } from "react-router-dom";
import ComposedGraphSkeleton from "../Skeleton/ComposedGraphSkeleton";
import SkeletonValueCard from "../Skeleton/SkeletonValueCard";

const TopGraphBox = ({
  data,
  previousDashboardData,
  selectedTime,
  startTime,
  endTime,
  hubSession,
  loading,
}) => {
  const location = useLocation();
  const hubStatsOverview = location.pathname.includes("/hub_management");
  return (
    <Box
      sx={{
        padding: "24px",
        background: "#fff",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item md={8} sx={12}>
          <Box sx={{}}>
            {loading ? (
              <ComposedGraphSkeleton /> // Show Skeleton when loading is true
            ) : (
              <ComposedGraph
                data={data}
                selectedTime={selectedTime}
                startTime={startTime}
                endTime={endTime}
              />
            )}
          </Box>
          {loading ? (
            ""
          ) : (
            <Box sx={{}}>
              <GraphZoomButton
                GraphComponent={ComposedGraph}
                graphProps={{ data, selectedTime, startTime, endTime }}
              />
            </Box>
          )}
        </Grid>
        <Grid item md={4} sx={12}>
          {/* value cards */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {loading ? (
              <SkeletonValueCard /> // Show Skeleton when loading is true
            ) : (
              <ValueCard
                selectedTime={selectedTime}
                title={
                  hubStatsOverview
                    ? "Total Sold Pass Count"
                    : "Total Billed Amount"
                }
                data={data}
                previousDashboardData={previousDashboardData}
                topGraphBox="true"
                hubSession={hubSession}
              />
            )}

            {loading ? (
              <SkeletonValueCard /> // Show Skeleton when loading is true
            ) : (
              <ValueCard
                selectedTime={selectedTime}
                title={
                  hubStatsOverview
                    ? "Total Sold Pass Value"
                    : "Total Energy Consumed (kWh)"
                }
                data={data}
                previousDashboardData={previousDashboardData}
                topGraphBox="true"
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopGraphBox;
