import React from "react";
import TopGraphBox from "../../dashboard/TopGraphBox";
import { Box } from "@mui/material";

const TopGraph = ({data, selectedTime, startTime, endTime, previousDashboardData}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <TopGraphBox data={data} selectedTime={selectedTime} startTime={startTime} endTime={endTime} previousDashboardData={previousDashboardData} />
    </Box>
  );
};

export default TopGraph;
