import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomTabs from "../custom/CustomTabs";
import MainHeading from "../../utils/MainHeading";
import OverAllBox from "./OverAllBox";
import OrganizationBox from "./OrganizationBox";
import StationsBox from "./StationsBox";
import ChargersBox from "./ChargersBox";
import { useSelector } from "react-redux";

// for tabs icons
const CustomIcon = ({ icon }) => {
  return (
    <Box
      component="span"
      className="material-symbols-outlined"
      sx={{ fontWeight: "200" }}
    >
      {icon}
    </Box>
  );
};

const TopHeader = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { profile } = useSelector((state) => state.profile);

  const tabsData = [
    {
      label: "Overview",
      content: (
        <Box>
          <OverAllBox />
        </Box>
      ),
      icon: <CustomIcon icon={"bar_chart_4_bars"} />,
    },
    {
      label: "Stations",
      content: (
        <Box>
          <StationsBox />
        </Box>
      ),
      icon: <CustomIcon icon={"sync_alt"} />,
    },
    {
      label: "Chargers",
      content: (
        <Box>
          <ChargersBox />
        </Box>
      ),
      icon: <CustomIcon icon={"sync_alt"} />,
    },
    ...(profile?.role !== "CPO_Admin"
      ? [
          {
            label: "Organization",
            content: (
              <Box>
                <OrganizationBox />
              </Box>
            ),
            icon: <CustomIcon icon={"sync_alt"} />,
          },
        ]
      : []),
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "24px 32px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mb: "24px",
          marginLeft: "-32px",
          marginRight: "-24px",
          marginTop: "-22px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <MainHeading text={"Statistics"} />
          </Box>
        </Box>

        <CustomTabs tabs={tabsData} onTabChange={handleTabChange} />
      </Box>
      <Box>{tabsData[selectedTab].content}</Box>
    </React.Fragment>
  );
};

export default TopHeader;
