import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Tooltip,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Heading from "../../../../utils/Heading";
import { TextCopy } from "../../../../utils/TextCopy/TextCopy";
import { useDispatch } from "react-redux";
import {
  getHandshakeStatus,
  getOcpiTokenA,
} from "../../../../redux/action/ocpiManagement/ocpiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const InfoBox = ({ label, icon, propType, value, canGenerate, onGenerate }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    TextCopy(value, setCopied); // Use the value prop to copy
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: icon && value ? "space-between" : "start",
          alignItems: "center",
        }}
      >
        <Typography
          fontFamily={"myThirdFont"}
          fontSize={"14px"}
          color={"#2F2F2F"}
          textAlign={"start"}
        >
          {label}
        </Typography>
        {icon && value && (  // Only render icon if value exists
          <Tooltip title={copied ? "Copied!" : ""} open={copied} arrow>
            <span
              style={{
                fontSize: "24px",
                color: "#292D33",
                fontWeight: "200",
                cursor: "pointer",
              }}
              className="material-symbols-outlined"
              onClick={handleCopy}
            >
              {icon}
            </span>
          </Tooltip>
        )}
      </Box>
      {propType === "token" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "rgba(2, 121, 0, 0.10)",
              padding: "12px 16px",
              overflow: "hidden", // Prevent overflow
              whiteSpace: "normal", // Allow wrapping
              textOverflow: "ellipsis", // Add ellipsis if text is too long
            }}
          >
            <Typography
              fontSize={"16px"}
              fontFamily={"mySecondFont"}
              color={value ? "#027900" : "rgba(47, 47, 47, 0.50)"}
              textAlign={"start"}
              noWrap={false} // Ensure wrapping is allowed
            >
              {value || "Click on generate to view token"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
            color={"#2F2F2F"}
            textAlign={"start"}
            sx={{ textWrap: "wrap" }}
          >
            {value}
          </Typography>
        </Box>
      )}
    </Box>
  );
};


const HandshakeLeftBox = ({ versionsList, handshakeStatus }) => {
  const [operatorId, setOperatorId] = useState(null);

  useEffect(() => {
    const extractIdFromUrl = () => {
      const url = window.location.pathname;
      const parts = url.split("/");
      return parts[parts.length - 1];
    };

    const id = extractIdFromUrl();
    if (id) {
      setOperatorId(id);
    }
  }, []);

  console.log("Handshake Status:122", handshakeStatus);

  // if (handshakeStatus?.success) {
  //   const { url, tokenA } = handshakeStatus;
  // } else return;

  const [selectedOptions, setSelectedOptions] = useState({
    option1: false,
    option2: false,
  });
  const [selectedVersion, setSelectedVersion] = useState("");

  const dispatch = useDispatch();

  const handleOptionChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const { tokenA, loading, error } = useSelector((state) => state.ocpi);

  const handleGenerateToken = () => {
    const selectedRoles = [];
    if (selectedOptions.option1) selectedRoles.push("CPO");
    if (selectedOptions.option2) selectedRoles.push("EMSP");

    const version = selectedVersion || "2.2.1"; // Default to 2.2.1 if no version selected

    if (operatorId) {
      dispatch(getOcpiTokenA({ operatorId, roles: selectedRoles, version }))
        .unwrap()
        .then(() => {
          toast.success("Token A generated successfully");
        })
        .catch((error) => {
          console.error("Error generating Token A:", error);
          toast.error("Error generating Token A");
        });
    }

    console.log("Selected Options:", selectedRoles);

  };

  console.log("hghghg", tokenA?.tokenA);

  const handleVersionChange = (event) => {
    const version = event.target.value;
    setSelectedVersion(version);
    console.log("Selected Version:", version);
  };

  // Map the dynamic versionsList to version options
  const versionOptions = versionsList.map((versionObj) => ({
    version: versionObj.version,
  }));

  const canGenerateToken = (selectedOptions.option1 || selectedOptions.option2) && selectedVersion;
  const tokenExisting = tokenA?.tokenA || handshakeStatus?.tokenA;
  const isButtonDisabled = () => {
    return !canGenerateToken || (tokenExisting && !handshakeStatus?.handshake);
  };

  console.log("ssddd", !handshakeStatus?.handshake)

  return (
    <Box sx={{ background: "#fff", borderRadius: "8px", width: "100%" }}>
      <Grid container columnSpacing={2}>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                background: "#fff",
                borderRadius: "8px",
              }}
            >
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <img src="/images/ev_charger_icon.png" alt="" />
                    <Heading text={"1C OCPI"} />
                  </Box>
                </Box>
                <Divider sx={{ background: "#ddd", margin: "8px 0px" }} />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <InfoBox
                      label={
                        "Please select at least one option to generate a new OCPI token"
                      }
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <label>
                        <Checkbox
                          name="option1"
                          checked={selectedOptions.option1}
                          onChange={handleOptionChange}
                        />
                        CPO
                      </label>
                      <label>
                        <Checkbox
                          name="option2"
                          checked={selectedOptions.option2}
                          onChange={handleOptionChange}
                        />
                        eMSP
                      </label>
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      textAlign={"left"}
                      fontSize={"14px"}
                      fontFamily={"myThirdFont"}
                      color="#2F2F2F"
                    >
                      Versions Dropdown for OCPI
                    </Typography>
                    <Select
                      value={selectedVersion}
                      onChange={handleVersionChange}
                      displayEmpty
                      sx={{
                        width: "232px",
                        height: "40px",
                        color: "#1C1C1C",
                        fontFamily: "myThirdFont",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Versions
                      </MenuItem>
                      {versionOptions.map((org) => (
                        <MenuItem key={org.version} value={org.version}>
                          {org.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>

                <InfoBox
                  label={"OCPI URL"}
                  value={tokenA?.url || handshakeStatus?.url} // Use url from handshakeStatus
                  icon="content_copy"
                />
                <InfoBox
                  propType="token"
                  label={"Token A"}
                  icon="content_copy"
                  value={tokenA?.tokenA || handshakeStatus?.tokenA} // Use tokenA from handshakeStatus
                  canGenerate={canGenerateToken}
                  onGenerate={handleGenerateToken}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-betweem",
                  }}
                >
                  <Box sx={{ width: "40%" }}>
                    <Button
                      onClick={handleGenerateToken}
                      disabled={isButtonDisabled()}
                      sx={{
                        borderRadius: "4px",
                        padding: "12px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#E50000",
                        opacity: isButtonDisabled() ? "0.25" : "1",
                        width: "100%",
                        color: "#fff",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "14px",
                        fontWeight: "600",
                        "&:hover": {
                          backgroundColor:
                            canGenerateToken &&
                            !(tokenExisting && !handshakeStatus?.success)
                              ? "#c70000"
                              : "#E50000",
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "#E50000",
                          color: "#fff",
                        },
                      }}
                    >
                      Generate Token
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HandshakeLeftBox;
