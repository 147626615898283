import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import LogsDetails from "../chargingPointsDetails/Logs/LogsDetails";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LogsTableBox from "../chargingPointsDetails/Logs/LogsTableBox";
import OcpiLogsTableBox from "./Logs/OcpiLogsTableBox";

const OcpiLogsBox = ({ ocpiHandshakeLogs, loading, operatorId }) => {
  const [selectedLog, setSelectedLog] = useState(null);
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [logsFilter, setLogsFilter] = useState("createdAt");

  console.log("hhhhgghgg", ocpiHandshakeLogs?.data)

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={8} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <OcpiLogsTableBox
              data={ocpiHandshakeLogs?.data}
              logsCount={ocpiHandshakeLogs?.totalCount}
            //   lastLog={ocpiHandshakeLogs?.lastLog}
              page={offset}
              limit={limit}
              setPage={setOffset}
              setLimit={setLimit}
              setLogsFilter={setLogsFilter}
              logsFilter={logsFilter}
              onRowClick={setSelectedLog}
              liveLogBtn={false} 
              modalHeading={"Logs"}
              loading={loading}
            />
          </Box>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <LogsDetails logData={selectedLog} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcpiLogsBox;
