import { Box, Grid } from "@mui/material";
import React from "react";
import BasicDetails from "./Details/BasicDetails";
import AddressContact from "./Details/AddressContact";
import PaymentDetails from "./Details/PaymentDetails";
import BillingsDetails from "./Details/BillingsDetails";
import DocumentTable from "./Details/DocumentTable";

const DetailsBox = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - Box - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <BasicDetails data={data} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <AddressContact data={data} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <PaymentDetails data={data} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <BillingsDetails data={data} />
          </Box>
        </Grid>
      </Grid>

      {/* Gallery - Box */}
      <Box
        sx={{
          padding: "16px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <DocumentTable orgDocs={data?.orgDocs || []} />
      </Box>
    </Box>
  );
};

export default DetailsBox;
