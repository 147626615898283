import React from "react";
import TopHeader from "../../../components/hub_management/gaurds/AddNewGuard/TopHeader";
import AddNewGuardFormDetails from "../../../components/hub_management/gaurds/AddNewGuard/AddNewGuardFormDetails";
import { useLocation } from "react-router-dom";

const AddNewGuardForm = () => {
  const location = useLocation();
  const { edit } = location.state || [];
  return (
    <>
      {/* Top header */}
      <TopHeader headingCheck={edit} />

      {/* Add Charging Station Form */}
      <AddNewGuardFormDetails />
    </>
  );
};

export default AddNewGuardForm;
