import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../custom/CustomTable";
import HubTimeFilter from "../../Filters/HubTimeFilter";
import HubTableFilter from "../../Filters/HubTableFilter";
import { StationProvider } from "../../../Hooks/StationContext";
import { TimeProvider } from "../../../Hooks/TimeContext";
import ChooseStationModal from "../../Modals/HubManagement/ChooseStationModal";
import { useDispatch } from "react-redux";
import { getAllHubStation } from "../../../redux/action/hubManagement/hubManagementSlice";
import { useSelector } from "react-redux";

const StationTableBox = ({ columns, row }) => {
  const [openChooseStationModal, setOpenChooseStationModal] =
    React.useState(false);
  const handleOpenChooseStationModal = () => setOpenChooseStationModal(true);
  const handleCloseChooseStationModal = () => setOpenChooseStationModal(false);

  const [searchQuery, setSearchQuery] = useState("");

  // Filter rows based on search query
  const filteredRows = row.filter((item) => {
    return Object.keys(item).some((key) =>
      String(item[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const dispatch = useDispatch();
  const { hubStations, loading, error } = useSelector(
    (state) => state.hubManagement
  );

  React.useEffect(() => {
    dispatch(getAllHubStation());
  }, [dispatch]);

  return (
    <>
      <StationProvider>
        <TimeProvider>
          <Box
            sx={{
              padding: "24px",
              borderRadius: "8px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* filters */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "36px" }}>
                <HubTableFilter
                  tabTitle={"HubStations"}
                  setSearchQuery={setSearchQuery}
                />
                <HubTimeFilter tabTitle={"HubStations"} />
              </Box>
              <Button
                onClick={handleOpenChooseStationModal}
                sx={{
                  background: "#222",
                  padding: "8px 24px",
                  width: "120px",
                  borderRadius: "4px",
                  color: "#FCFCFC",
                  mb: "12px",
                  fontFamily: "mySecondFont",
                  letterSpacing: "1.12px",
                  textWrap: "nowrap",
                  ":hover": {
                    background: "#222",
                  },
                }}
              >
                + ADD NEW
              </Button>
            </Box>

            {/* Table */}
            <CustomTable
              columns={columns}
              rows={filteredRows}
              modalHeading={"Hub Stations"}
              isRowClickable={true}
              actionType={"edit"}
              rowClickPath={"/hub_management/charging_stations"}
              clickableColumns={["name"]}
              columnClickPaths={{
                name: "/hub_management/charging_stations",
              }}
              columnKeyMappings={{
                name: "_id",
              }}
              hubSession={true}
            />
          </Box>
        </TimeProvider>
      </StationProvider>

      {/* Modal - 1 */}
      <ChooseStationModal
        open={openChooseStationModal}
        handleClose={handleCloseChooseStationModal}
        stations={hubStations}
      />
    </>
  );
};

export default StationTableBox;
