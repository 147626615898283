import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const SessionProgressBar = ({ data }) => {
  // Calculating total session count
  const totalSessionCount =
    (data?.totalSessionCount_App || 0) +
    (data?.totalSessionCount_Guard || 0) +
    (data?.totalSessionCount_Ocpi || 0) +
    (data?.totalSessionCount_Rfid || 0) +
    (data?.totalSessionCount_Upi || 0);

  // Defining the session types and values
  const sessionData = [
    { label: "Upi", value: data?.totalSessionCount_Upi, color: "#FDDA0D" },
    { label: "App", value: data?.totalSessionCount_App, color: "#69A5FF" },
    { label: "Guard", value: data?.totalSessionCount_Guard, color: "#1AC95D" },
    { label: "Rfid", value: data?.totalSessionCount_Rfid, color: "#FF6969" },
    { label: "Ocpi", value: data?.totalSessionCount_Ocpi, color: "#AB6AF0" },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Align text to the top
            }}
          >
            {/* Vertical Progress Bar with Label */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  writingMode: "vertical-rl", // Rotate text vertically
                  transform: "rotate(180deg)", // Correct rotation direction
                  marginBottom: "12px",
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "16px",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                }}
              >
                Total Sessions {totalSessionCount}
              </Typography>

              {/* Actual Vertical Bar */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "350px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "4px",
                  overflow: "hidden",
                  backgroundColor: "#E0E0E0",
                  width: "24px",
                }}
              >
                {sessionData.map((session) => (
                  <Box
                    key={session.label}
                    sx={{
                      backgroundColor:
                        session.value > 0 ? session.color : "#E0E0E0", // Gray color for zero values
                      width: "40px",
                      height: `${
                        totalSessionCount
                          ? (session.value / totalSessionCount) * 100
                          : 100 // Minimum height for zero values
                      }%`,
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SessionProgressBar;
