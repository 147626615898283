import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CreateHubStationModal from "./CreateHubStationModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAllStationChargers,
  getAllStationList,
} from "../../../redux/action/hubManagement/hubManagementSlice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const ChooseStationModal = ({ open, handleClose }) => {
  const [openCreateStationModal, setOpenCreateStationModal] =
    React.useState(false);
  const [selectedStationId, setSelectedStationId] = React.useState("");
  const handleOpenCreateStation = () => setOpenCreateStationModal(true);
  const handleCloseCreateStation = () => setOpenCreateStationModal(false);

  const dispatch = useDispatch();

  const { stationList, stationChargers } = useSelector(
    (state) => state.hubManagement
  );

  React.useEffect(() => {
    dispatch(getAllStationList());
  }, [dispatch]);

  console.log("yoooo", stationList);

  const handleProceed = () => {
    if (!selectedStationId) {
      toast.error("Please Choose a Station!");
      return; // Optionally handle the case where no station is selected
    }

    // Dispatch the thunk with the selected station ID
    dispatch(getAllStationChargers(selectedStationId));
    handleClose(); // Close the modal
    handleOpenCreateStation(); // Open the create station modal
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              fontFamily={"mySecondFont"}
              fontSize={"24px"}
              textAlign="center"
            >
              Select Your Station
            </Typography>

            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel
                sx={{ textAlign: "start", fontFamily: "mySecondFont" }}
              >
                Select Station
              </InputLabel>
              <Select
                sx={{ fontFamily: "mySecondFont" }}
                labelId="station-select-label"
                label="Select Station"
                value={selectedStationId} // Set the selected value
                onChange={(e) => setSelectedStationId(e.target.value)}
              >
                {stationList?.map((station) => (
                  <MenuItem
                    sx={{ fontFamily: "mySecondFont" }}
                    key={station._id}
                    value={station._id}
                  >
                    {station.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                sx={{
                  background: "#e50000",
                  color: "#fff",
                  fontFamily: "mySecondFont",
                  padding: "10px 16px",
                  ":hover": {
                    background: "#e50000",
                  },
                }}
                onClick={handleProceed}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      {/* Create Hub Station Modal */}
      <CreateHubStationModal
        open={openCreateStationModal}
        handleClose={handleCloseCreateStation}
        data={stationChargers}
        stationId={selectedStationId}
        newCheck={true}
      />
    </>
  );
};

export default ChooseStationModal;
