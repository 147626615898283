import React, { createContext, useContext, useState } from "react";

const TimeContext = createContext();

export const TimeProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  return (
    <TimeContext.Provider
      value={{ startTime, endTime, setStartTime, setEndTime }}
    >
      {children}
    </TimeContext.Provider>
  );
};

// Default value for useTimeContext when not provided
export const useTimeContext = () => {
  const context = useContext(TimeContext);
  const defaultValues = {
    startTime: null,
    endTime: null,
    setStartTime: () => {},
    setEndTime: () => {},
  };

  return context || defaultValues;
};
