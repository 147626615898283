import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import TopGraphBox from "../../dashboard/TopGraphBox";
import VisitUserGraph from "../../Graphs/HubManagementGraphs/VisitUserGraph";
import DoubleLineGraph from "../../Graphs/DoubleLineGraph";
import GraphZoomButton from "../../../utils/GraphZoomButton";
import ValueCard from "../../dashboard/ValueCard";
import PassValueGraph from "../../Graphs/HubManagementGraphs/PassValueGraph";
import SemiDoughnutChart from "../../Graphs/SemiDoughnutChart";
import ValueChip from "../../Chips/ValueChip";
import LineGraph from "../../Graphs/LineGraph";
import { GetCurrencySymbol } from "../../../utils/currency/currencyChangeFunction";

const HubStationOverview = ({
  previousDashboardData,
  data,
  selectedTime,
  startTime,
  endTime,
}) => {
  const totalGuards = data?.guardCollectionsStats?.totalHubUsersCount || 0;

  const compareTotalGuards =
    data?.guardCollectionsStats?.totalHubUsersCount -
    previousDashboardData?.guardCollectionsStats?.totalHubUsersCount;

  const segmentData = data?.guardCollectionsStats?.allHubUsersCollections;

  const preDefinedColors = [
    "#FF9F69",
    "#69A5FF",
    "#00FF00",
    "#9400D3",
    "#FF0000",
    "#FFFF00",
    "#0000FF",
    "#4B0082",
  ];

  const getComparisonText = () => {
    switch (selectedTime) {
      case "today":
        return "Yesterday";
      case "yesterday":
        return "Previous Day";
      case "week":
        return "Last Week";
      case "month":
        return "Last Month";
      case "year":
        return "Last Year";
      case "Custom Range":
        return "Previous Range";
      default:
        return "N/A";
    }
  };

  const formatValue = (value) => {
    if (value === null || value === undefined) {
      return "N/A";
    }
    const valueStr = String(value); // Convert value to string
    if (valueStr.startsWith("-")) {
      return valueStr;
    }
    return `+${valueStr}`;
  };

  const overallCompareColor = compareTotalGuards < 0 ? "#790000" : "#027900";
  const currencySymbol = GetCurrencySymbol();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* section */}
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item md={8.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <TopGraphBox
              hubSession={true}
              data={data}
              previousDashboardData={previousDashboardData}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>
        <Grid item md={3.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <VisitUserGraph data={data} />
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item md={8.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              gap: "16px",
              padding: "24px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Grid item md={8} sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <DoubleLineGraph
                  data={data}
                  selectedTime={selectedTime}
                  startTime={startTime}
                  endTime={endTime}
                  graphTitle="Energy vs Hub Session"
                />
              </Box>
              <Box sx={{}}>
                <GraphZoomButton
                  GraphComponent={DoubleLineGraph}
                  graphProps={{ data, selectedTime, startTime, endTime }}
                />
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <ValueCard
                title="Total Energy Consumed (kWh)"
                topGraphBox="true"
                selectedTime={selectedTime}
                data={data}
                previousDashboardData={previousDashboardData}
              />
              <ValueCard
                title="Total Hub Sessions"
                topGraphBox="true"
                selectedTime={selectedTime}
                data={data}
                previousDashboardData={previousDashboardData}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid item md={3.5} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <PassValueGraph data={data} passType={true} />
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item md={4}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "100%", // Ensure parent container adapts to content
              position: "relative",
              gap: "24px",
            }}
          >
            <SemiDoughnutChart
              data={segmentData}
              preDefinedColors={preDefinedColors}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "30%",
                transform: "translate(-50%, -50%)",
                gap: "8px",
              }}
            >
              <ValueChip
                title={"Total Sessions"}
                data={data}
                previousDashboardData={previousDashboardData}
              />
              <Box>
                <Typography
                  sx={{
                    color: "#666",
                    textAlign: "center",
                    fontFamily: "myThirdFont",
                    fontSize: { xs: "12px", sm: "14px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  Total Guards
                </Typography>
                <Typography
                  sx={{
                    color: "#2F2F2F",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: { xs: "20px", sm: "24px" },
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "32px",
                  }}
                >
                  {totalGuards}
                </Typography>
              </Box>

              <Typography
                fontFamily={"myThirdFont"}
                color={"#666"}
                fontWeight={"400"}
                fontSize={"12px"}
              >
                <span
                  style={{
                    color: overallCompareColor,
                    fontFamily: "mySecondFont",
                  }}
                >
                  {formatValue(compareTotalGuards)}
                </span>{" "}
                than {getComparisonText()}
              </Typography>
            </Box>

            {/* New Box for displaying name : value */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "12px",
                  mb: "8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#727272",

                    fontFamily: "mySecondFont",
                    fontSize: "14px",

                    lineHeight: "16px",
                  }}
                >
                  Guard Name
                </Typography>

                <Typography
                  sx={{
                    color: "#727272",

                    fontFamily: "mySecondFont",
                    fontSize: "14px",

                    lineHeight: "16px",
                  }}
                >
                  Guard Collection {`(${currencySymbol})`}
                </Typography>
              </Box>
              {segmentData?.length > 0 ? (
                segmentData?.map((segment, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: preDefinedColors[index] || "#000000",
                          borderRadius: "24px",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          color: "#2f2f2f",

                          fontFamily: "myThirdFont",
                          fontSize: "14px",

                          lineHeight: "16px",
                        }}
                      >
                        {segment.name}:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "#2f2f2f",

                          fontFamily: "mySecondFont",
                          fontSize: "14px",

                          lineHeight: "16px",
                        }}
                      >
                        {segment.collectedAmount || 0}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography sx={{ marginTop: "40px" }}>
                  No guards available for this station.
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={5}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "100%",
            }}
          >
            <LineGraph
              graphTitle="Vehicles Entered"
              data={data}
              selectedTime={selectedTime}
              startTime={startTime}
              endTime={endTime}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HubStationOverview;
