import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function TableSkeleton() {
  // Define the number of rows for the skeleton
  const rowCount = 10;
  const theme = useTheme();

  // Set responsive widths for each skeleton cell
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Define widths for different screen sizes
  const getSkeletonWidth = (defaultWidth) => {
    if (isSmallScreen) return defaultWidth * 0.6;
    if (isMediumScreen) return defaultWidth * 0.8;
    return defaultWidth;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Array.from({ length: rowCount }).map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(20)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(150)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(100)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(60)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(40)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(60)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(40)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(80)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(80)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(30)}
                  animation="wave"
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="text"
                  width={getSkeletonWidth(160)}
                  animation="wave"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableSkeleton;
