import React from "react";
import { Skeleton } from "@mui/material";
import { ResponsiveContainer } from "recharts";

const ComposedGraphSkeleton = () => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        animation="wave"
      />
    </ResponsiveContainer>
  );
};

export default ComposedGraphSkeleton;
