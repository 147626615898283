import { Box } from "@mui/material";
import React from "react";
import TopBar from "../../components/charging-sessions/TopBar";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { TablePageProvider } from "../../Hooks/TablePageContext";

const ChargingSessions = () => {
  const navigate = useNavigate();
  useScrollToTop(navigate);
  return (
    <Box>
      {/* Top - Bar */}
      <TablePageProvider>
        <TopBar />
      </TablePageProvider>
    </Box>
  );
};

export default ChargingSessions;
