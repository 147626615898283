import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = ({ text }) => {
  return (
    <Box>
      <Typography
        color={"#1C1C1C"}
        fontSize={"18px"}
        fontFamily="'Montserrat', sans-serif"
        fontWeight={600}
        textAlign={"start"}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Heading;
