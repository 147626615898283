import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../../Api/endPoints";

// Define the initial state
const initialState = {
  createOtherStations: {},
  otherStations: [],
  loading: false,
  error: null,
};

// Create async thunks for API calls
export const fetchAllOtherStations = createAsyncThunk(
  "stations/fetchAllOtherStations",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.getAllOtherStations, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const bulkUploadOtherStations = createAsyncThunk(
  "companyManagement/bulkUploadOtherStations",
  async (file, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.bulkUploadOtherStations,
        file,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const otherStationsSlice = createSlice({
  name: "otherStations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOtherStations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllOtherStations.fulfilled, (state, action) => {
        state.loading = false;
        state.otherStations = action.payload;
      })
      .addCase(fetchAllOtherStations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(bulkUploadOtherStations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(bulkUploadOtherStations.fulfilled, (state, action) => {
        state.loading = false;
        state.createOtherStations = action.payload;
      })
      .addCase(bulkUploadOtherStations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default otherStationsSlice.reducer;
