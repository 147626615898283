import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../utils/Heading";

const InfoBox = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography
      fontFamily={"myThirdFont"}
      fontSize={"14px"}
      color={"#2F2F2F"}
      textAlign={"start"}
    >
      {label}
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: icon ? "space-between" : "start",
        alignItems: "center",
      }}
    >
      <Typography
        fontSize={"16px"}
        fontFamily={"mySecondFont"}
        color={"#2F2F2F"}
      >
        {value}
      </Typography>
      {icon && (
        <span
          style={{
            fontSize: "24px",
            color: "#292D33",
            fontWeight: "200",
            cursor: "pointer",
          }}
          className="material-symbols-outlined"
        >
          {icon}
        </span>
      )}
    </Box>
  </Box>
);

const ConnectorBox = ({ connectors }) => (
  <Box
    sx={{ display: "flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}
  >
    {connectors.map((connector, index) => (
      <Box
        key={index}
        sx={{
          padding: "2px 12px",
          background: "rgba(41, 45, 51, 0.08)",
          maxWidth: "90px",
          width: "100%",
          borderRadius: "85px",
          textWrap: "nowrap",
        }}
      >
        <Typography fontSize={"14px"} fontFamily={"mySecondFont"}>
          {connector.connectorType}
        </Typography>
      </Box>
    ))}
  </Box>
);

const SpecificationDetails = ({ data }) => {
  const connectors = data?.connectors || [];
  return (
    <Box
      sx={{
        padding: "16px 16px 48px 16px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span
            style={{ fontSize: "24px", color: "#292D33", fontWeight: "300" }}
            className="material-symbols-outlined"
          >
            page_info
          </span>
          <Heading text={"Specifications"} />
        </Box>
        <Divider sx={{ mt: "8px" }} />
      </Box>

      {/* specifications */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "16px",
        }}
      >
        <InfoBox label="Charger type" value={data?.chargingPointType} />
        <InfoBox label="Max power supply" value={`${data?.maxPower} kWh`} />
        <InfoBox label="No of connectors" value={data?.connectors?.length} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            fontFamily={"myThirdFont"}
            fontSize={"14px"}
            color={"#2F2F2F"}
            textAlign={"start"}
          >
            Connectors
          </Typography>
          <ConnectorBox connectors={connectors} />
        </Box>
        <InfoBox label="Firmware Version" value={data?.firmwareVersion} />
      </Box>
    </Box>
  );
};

export default SpecificationDetails;
