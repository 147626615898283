import { Box, Button } from "@mui/material";
import React from "react";
import MainHeading from "../../utils/MainHeading";
import { useNavigate } from "react-router-dom";

const TopHeader = ({headingCheck}) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <Box
      sx={{
        padding: "16px 32px",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "24px",
        marginLeft: "-32px",
        marginRight: "-24px",
        marginTop: "-22px",
      }}
    >
      <MainHeading
        text={
          headingCheck === true
            ? "Edit Charging Station"
            : "Add New Charging Station"
        }
      />
      {/* Time - filter */}
      <Button
        sx={{
          padding: "8px 16px",
          border: "1px solid #cbcbcb",
          borderRadius: "4px",
          color: "#790000",
          fontWeight: "600",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "14px",
        }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </Box>
  );
};

export default TopHeader;
