import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const DoughnutChart = ({ customers, data, donut_type, rechargeData }) => {
  const totalRecharges = data.reduce(
    (total, item) => total + parseInt(item.rechargeValue, 10),
    0
  );

  // const totalSubscribers = data.reduce(
  //   (total, item) => total + parseInt(item.planTotalSubscriber, 10),
  //   0
  // );

  const totalSubscribers = customers?.newCustomers | 0;
  const oneMonthSubs = customers?.oneMonthCustomers || 0;
  const sixMonthSubs = customers?.sixMonthCustomers || 0;
  const annualPlanCustomers = customers?.annualPlanCustomers || 0;

  const totalRecharge = rechargeData?.totalWalletRecharge | 0;
  const lessThanHundred = rechargeData?.lessThenHundredRecharge || 0;
  const betweenHundredAndFiveHundred =
    rechargeData?.betweenHundredAndFiveHundred || 0;
  const moreThenFiveHundred = rechargeData?.moreThenFiveHundred || 0;

  // Default gray chart data
  const defaultChartData = [
    { name: "No Data", value: 100, color: "#D3D3D3" }, // Gray color
  ];

  // Prepare data for charts
  const segmentsForWallet =
    totalRecharge > 0
      ? [
          { name: "Less than 100", value: lessThanHundred, color: "#69A5FF" },
          {
            name: "Between 100 and 500",
            value: betweenHundredAndFiveHundred,
            color: "#8D6AF0",
          },
          {
            name: "More than 500",
            value: moreThenFiveHundred,
            color: "#FF9F69",
          },
        ]
      : defaultChartData;

  const segmentsForSubscribers =
    totalSubscribers > 0
      ? [
          { name: "1 Month Plan", value: oneMonthSubs, color: "#69A5FF" },
          { name: "6 Months Plan", value: sixMonthSubs, color: "#8D6AF0" },
          { name: "Annual Plan", value: annualPlanCustomers, color: "#FF9F69" },
        ]
      : defaultChartData;

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        padding: donut_type != "dashboard" ? "8px 24px" : "8px 24px",
        borderRadius: "8px",
        background: "#fff",
        border: donut_type != "dashboard" ? "1px solid #ddd" : "none",

        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box>
        <Box sx={{ height: donut_type != "dashboard" ? 204 : 150 }}>
          {/* Payment Donut Chart */}
          {donut_type != "dashboard" ? (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={segmentsForWallet}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={90}
                  innerRadius={70}
                  stroke="none"
                  startAngle={0}
                  endAngle={360}
                >
                  {segmentsForWallet.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            // Dashboard Donut Chart
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={segmentsForSubscribers}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  innerRadius={60}
                  stroke="none"
                  startAngle={0}
                  endAngle={360}
                >
                  {segmentsForSubscribers.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </Box>

        {/* Payment Donut Chart */}
        {donut_type != "dashboard" ? (
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              pointerEvents: "none",
            }}
          >
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "14px",
                color: "#666",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              No. of Wallet
            </Typography>
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "14px",
                color: "#666",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Recharges
            </Typography>
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "24px",
                color: "#2F2F2F",
                fontWeight: "600",
              }}
            >
              {totalRecharge}
            </Typography>
          </Box>
        ) : (
          //  Payment Donut Chart
          <Box
            sx={{
              position: "absolute",
              top: "32%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              pointerEvents: "none",
            }}
          >
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "14px",
                color: "#666",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              New
            </Typography>
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "14px",
                color: "#666",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Subscribers
            </Typography>
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "24px",
                color: "#2F2F2F",
                fontWeight: "600",
              }}
            >
              {totalSubscribers}
            </Typography>
          </Box>
        )}
      </Box>

      {donut_type != "dashboard" ? (
        <Box sx={{}}>
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginBottom: "4px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#69A5FF",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                Less than 100
                <Typography
                  paddingLeft={"4px"}
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                >
                  {lessThanHundred}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginBottom: "4px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#8D6AF0",
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                Between 100 and 500
                <Typography
                  paddingLeft={"4px"}
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                >
                  {betweenHundredAndFiveHundred}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginBottom: "4px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#FF9F69",
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                More than 500
                <Typography
                  paddingLeft={"4px"}
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                >
                  {moreThenFiveHundred}
                </Typography>
              </Typography>
            </Box>
          </>
        </Box>
      ) : (
        <Box sx={{}}>
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginBottom: "4px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#69A5FF",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                1 Month plan
                <Typography
                  paddingLeft={"4px"}
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                >
                  {customers?.oneMonthCustomers}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginBottom: "4px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#8D6AF0",
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                6 months plan
                <Typography
                  paddingLeft={"4px"}
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                >
                  {customers?.sixMonthCustomers}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginBottom: "4px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#FF9F69",
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                Annual Plan
                <Typography
                  paddingLeft={"4px"}
                  color={"#2F2F2F"}
                  fontFamily={"mySecondFont"}
                >
                  {customers?.annualPlanCustomers}
                </Typography>
              </Typography>
            </Box>
          </>
        </Box>
      )}
    </Box>
  );
};

export default DoughnutChart;
