import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../../redux/action/authSlice";
import { useNavigate } from "react-router-dom";

const CreatePasswordPage = () => {
  const [credentials, setCredentials] = useState({
    passwordNew: "",
    passwordConfirm: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validPassword, setValidPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  console.log(token);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the passwords match
    if (credentials.passwordNew !== credentials.passwordConfirm) {
      setError({ message: "Passwords do not match" });
      return;
    }

    // Ensure that token and password are provided
    if (!token || !credentials.passwordNew) {
      setError({ message: "Invalid input or token missing" });
      return;
    }

    // Clear any previous errors
    setError(null);
    setLoading(true);

    try {
      // Dispatch the forgetPassword API call
      const response = await dispatch(
        forgetPassword({
          password: credentials.passwordNew,
          userToken: token,
        })
      );

      // If the API call is successful, show the success UI
      if (forgetPassword.fulfilled.match(response)) {
        setValidPassword(true); // Show success message
      } else {
        setError({
          message: response.payload.error || "Password reset failed",
        });
      }
    } catch (err) {
      setError({ message: "An error occurred. Please try again." });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundImage: 'url("/images/auth-bg-final.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            padding: "32px",
            borderRadius: "8px",
            height: "586px",
            justifyContent: validPassword ? "center" : "start",
          }}
        >
          {!validPassword ? (
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
              >
                <Box sx={{ width: "100%" }}>
                  <img src="/images/reset-password-logo.png" alt="logo" />
                </Box>

                <Box sx={{ paddingBottom: "8px" }}>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "32px",
                      marginBottom: "8px",
                    }}
                  >
                    Create new password
                  </Typography>
                </Box>

                {/* New Password Parent */}
                <Box>
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginBottom: "8px",
                      fontSize: "16px",
                      color: "#2F2F2F",
                      fontFamily: "mySecondFont",
                    }}
                  >
                    New Password
                  </Typography>
                  <input
                    name="passwordNew"
                    value={credentials.passwordNew}
                    onChange={handleChange}
                    variant="outlined"
                    style={{
                      width: "416px",
                      height: "48px",
                      borderRadius: "4px",
                      border: "1px solid #CBCBCB",
                      outline: "none",
                      padding: "0 16px",
                    }}
                  />
                </Box>

                {/* Password confirm Parent */}
                <Box>
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginBottom: "8px",
                      fontSize: "16px",
                      color: "#2F2F2F",
                      fontFamily: "mySecondFont",
                    }}
                  >
                    Reconfirm password
                  </Typography>
                  <input
                    name="passwordConfirm"
                    value={credentials.passwordConfirm}
                    onChange={handleChange}
                    variant="outlined"
                    style={{
                      width: "416px",
                      height: "48px",
                      borderRadius: "4px",
                      border: "1px solid #CBCBCB",
                      outline: "none",
                      padding: "0 16px",
                    }}
                  />
                </Box>

                {/* Button Parent */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
                >
                  <Box>
                    <Button
                      onClick={handleSubmit}
                      sx={{
                        width: "100%",
                        background: "#000000",
                        padding: "12px 24px",
                        color: "#FCFCFC",
                        fontFamily: "mySecondFont",
                        justifyContent: "center",
                        alignItems: "center",
                        letterSpacing: "1.28px",
                        borderRadius: "8px",
                        "&:hover": {
                          background: "#2F2F2F",
                          color: "#EEEEEE",
                        },
                      }}
                      disabled={loading}
                    >
                      UPDATE NEW PASSWORD
                    </Button>
                    {error && (
                      <Typography color="error">{error.message}</Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  justifyContent: "center",
                  width: "416px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <img src="/images/success-logo.png" alt="logo" />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "32px",
                    }}
                  >
                    New Password
                  </Typography>
                  <Typography
                    sx={{
                      color: "#0C0C0C",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "32px",
                    }}
                  >
                    successfully Created
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
                >
                  <Box>
                    <Button
                      sx={{
                        width: "100%",
                        background: "#FFF",
                        padding: "12px 24px",
                        color: "#222222",
                        fontFamily: "mySecondFont",
                        justifyContent: "center",
                        alignItems: "center",
                        letterSpacing: "1.28px",
                        borderRadius: "8px",
                        border: "1px solid #CBCBCB",
                        "&:hover": {
                          background: "#EEEEEE",
                        },
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Proceed TO LOGIN
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreatePasswordPage;
