import { Box, Grid } from "@mui/material";
import React from "react";
import GuardBasicDetails from "./GuardBasicDetails";
import GuardInfo from "./GuardInfo";
import GaurdDocBox from "./GaurdDocBox";

const Details = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - graph - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item md={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <GuardInfo data={data} />
          </Box>
        </Grid>
        <Grid item md={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <GuardBasicDetails data={data} />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ background: "#fff", borderRadius: "8px", padding: "16px" }}>
        <GaurdDocBox data={data} />
      </Box>
    </Box>
  );
};

export default Details;
