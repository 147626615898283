import { Box, Grid } from "@mui/material";
import React from "react";
import BasicDetails from "./Details/BasicDetails";
import AddressContact from "./Details/AddressContact";
import PaymentDetails from "./Details/PaymentDetails";
// import DocumentTable from "./Details/DocumentTable";
import OtherDetails from "./Details/OtherDetails";
import AddTariffSnackBar from "../OcpiUiComponents/AddTariffSnackBar";
import DocumentTable from "../company_management_details/Details/DocumentTable";

const DetailsBox = ({networkDetail}) => {
  console.log("lllkkkk",networkDetail )
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Top - Box - component */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <BasicDetails data={networkDetail} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <AddressContact data={networkDetail} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <PaymentDetails data={networkDetail} />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <OtherDetails data={networkDetail}/>
          </Box>
        </Grid>
      </Grid>

      {/* Gallery - Box */}
      <Box
        sx={{
          padding: "16px",
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        {/* <DocumentTable /> */}
        {/* <DocumentTable orgDocs={networkDetail?.orgDocs || []}/> */}
        <DocumentTable orgDocs={networkDetail?.orgDocs || []}/>
      </Box>
    </Box>
  );
};

export default DetailsBox;
