import { Box } from '@mui/material'
import React from 'react'
import CreatePasswordPage from '../../../components/auth/createPassword/CreatePasswordPage'

const CreatePassword = () => {
  return (
    <Box>
      <CreatePasswordPage/>
    </Box>
  )
}

export default CreatePassword
