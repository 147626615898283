import React from "react";
import DoubleBarGraph from "../../Graphs/DoubleBarGraph";
import { Box, Divider, Grid } from "@mui/material";
import Heading from "../../../utils/Heading";
import DoubleStackedBarGraph from "../../Graphs/DoubleStackedBarGraph";
import TrippleBarGraph from "../../Graphs/TrippleBarGraph";

const OcpiGraphBoxThree = () => {
  return (
    <Box
      sx={{
        background: "#fff",
        padding: "24px",
        borderRadius: "8px",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Heading text={"1C devices payments"} />
              </Box>
            </Box>

            <Divider sx={{ background: "#ddd", margin: "8px 0px", mb:"16px" }} />
          </Box>
        <DoubleStackedBarGraph barGraphType="chargingOne"/>
        </Grid>

        <Grid item md={6}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Heading text={"Tata devices payments"} />
              </Box>
            </Box>

            <Divider sx={{ background: "#ddd", margin: "8px 0px", mb:"16px" }} />
          </Box>
          <TrippleBarGraph barGraphType="network"/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcpiGraphBoxThree;
