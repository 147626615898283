import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, LoadScript, InfoWindow } from "@react-google-maps/api";
import { Box, Paper, Typography } from "@mui/material";
import NearbyDevices from "./NearbyDevices";
import { useNavigate } from "react-router-dom";
import { MyRoutes } from "../../routes/routes";

const delhiNCRCenter = { lat: 28.6139, lng: 77.209 };
const mapContainerStyle = { height: "700px", width: "100%" };
const mapOptions = {
  styles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#76aee3", // Blue color for water
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ],
  zoomControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: true,
};

const getMarkerIcon = (status) => {
  switch (status) {
    case "active":
      return "/images/map-available-icon.png"; // Update this path or import the image
    case "inactive":
      return "/images/mapImages/map-unavailable-icon.png"; // Update this path or import the image
    case "status_unknown":
      return "/images/mapImages/map-unknown-icon.png"; // Update this path or import the image
    default:
      return "/images/map-unknown-icon.png"; // Update this path or import the image
  }
};

const getStatusBoxStyles = (status) => {
  switch (status) {
    case "active":
      return {
        background:
          "linear-gradient(0deg, rgba(2, 121, 0, 0.05) 0%, rgba(2, 121, 0, 0.05) 100%)",
        hoverBackground:
          "linear-gradient(0deg, rgba(2, 121, 0, 0.1) 0%, rgba(2, 121, 0, 0.1) 100%)",
        dotColor: "#027900",
      };
    case "inactive":
      return {
        background:
          "linear-gradient(0deg, rgba(121, 0, 0, 0.05) 0%, rgba(121, 0, 0, 0.05) 100%)",
        hoverBackground:
          "linear-gradient(0deg, rgba(121, 0, 0, 0.1) 0%, rgba(121, 0, 0, 0.1) 100%)",
        dotColor: "#790000",
      };
    case "maintenance":
      return {
        background:
          "linear-gradient(0deg, rgba(41, 45, 51, 0.05) 0%, rgba(41, 45, 51, 0.05) 100%)",
        hoverBackground:
          "linear-gradient(0deg, rgba(193, 116, 0, 0.1) 0%, rgba(193, 116, 0, 0.1) 100%)",
        dotColor: "#292D33",
      };
    default:
      return {};
  }
};

const StationMap = ({ data }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);
  const navigate = useNavigate();

  const onLoad = (map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    if (mapRef.current && window.google) {
      // Clear previous markers
      markers.forEach((marker) => marker.setMap(null));

      const newMarkers = data
        .map((station) => {
          if (station?.loc?.coordinates) {
            const marker = new window.google.maps.Marker({
              position: {
                lat: station.loc.coordinates[1],
                lng: station.loc.coordinates[0],
              },
              map: mapRef.current,
              icon: getMarkerIcon(station.status),
            });

            marker.addListener("click", () => {
              setSelectedMarker(station);
            });

            return marker;
          }
          return null; // Skip this station if coordinates are not available
        })
        .filter((marker) => marker !== null); // Remove null markers

      setMarkers(newMarkers);
    }

    // Cleanup function to remove markers when component unmounts or data changes
    return () => {
      markers.forEach((marker) => marker.setMap(null));
    };
  }, [data]); // Make sure to add 'markers' if needed, but be cautious to avoid infinite loops

  const lat = selectedMarker?.loc?.coordinates[1];
  const lng = selectedMarker?.loc?.coordinates[0];

  const position = { lat, lng };

  const handleBoxClick = (id) => {
    navigate(MyRoutes.charging_stations + `/${id}`);
  };

  const countStations = (stations) => {
    return stations.reduce(
      (acc, station) => {
        if (station.status === "active") {
          acc.available += 1;
        } else if (station.status === "inactive") {
          acc.unavailable += 1;
        } else if (station.status === "maintenance") {
          acc.unknown += 1;
        }
        return acc;
      },
      { available: 0, unavailable: 0, unknown: 0 }
    );
  };

  const stationCounts = countStations(data);

  return (
    <Box sx={{ position: "relative" }}>
      <LoadScript
        googleMapsApiKey="AIzaSyA00C5oMIAZe3CQzg107eG1SgdPCdPyq5o"
        libraries={["places"]}
        loadingElement={<div>Loading...</div>}
        onLoad={() => console.log("Google Maps script loaded")}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={delhiNCRCenter}
          zoom={10}
          options={mapOptions}
          onLoad={onLoad}
        >
          {selectedMarker && (
            <InfoWindow
              position={position}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <Box
                key={selectedMarker._id}
                onClick={() => handleBoxClick(selectedMarker._id)}
                sx={{
                  width: "100%",
                  padding: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  gap: "12px",
                  boxShadow: "0px 2px 4px 0px rgba(41, 45, 51, 0.25)",
                  marginBottom: "8px",
                  background: getStatusBoxStyles(selectedMarker?.status)
                    .background,
                  "&:hover": {
                    backgroundImage: getStatusBoxStyles(selectedMarker?.status)
                      .hoverBackground,
                    cursor: "pointer",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: getStatusBoxStyles(
                        selectedMarker?.status
                      ).dotColor,
                      textAlign: "right",
                      borderRadius: "50%",
                    }}
                  ></Box>
                </Box>
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  lineHeight={"24px"}
                  color={"#262626"}
                  sx={{
                    marginLeft: "8px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                  }}
                >
                  {selectedMarker.stationName}
                </Typography>
                <Typography
                  fontFamily={"myThirdFont"}
                  fontSize={"14px"}
                  fontWeight={"400"}
                  lineHeight={"20px"}
                  color={"#727272"}
                  sx={{
                    marginLeft: "8px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                  }}
                >
                  {selectedMarker.address}
                </Typography>
              </Box>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>

      <Paper
        sx={{
          position: "absolute",
          top: 32,
          left: 32,
          padding: 2,
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "320px",
          borderRadius: "6px",
        }}
      >
        <Box>
          <NearbyDevices data={data} />
        </Box>
      </Paper>

      <Paper
        sx={{
          position: "absolute",
          top: 32,
          right: 64,
          padding: 2,
          zIndex: 1000,
          borderRadius: "48px",
          border: "1px solid #DDD",
          padding: "12px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "32px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src={"/images/available-icon.png"} alt="Available Icon" />
            <Typography
              sx={{
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Available ({stationCounts.available})
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img
              src={"/images/map-unavailable-icon.png"}
              alt="Unavailable Icon"
            />
            <Typography
              sx={{
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Unavailable ({stationCounts.unavailable})
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img
              src={"/images/map-unknown-icon.png"}
              alt="Unknown Status Icon"
            />
            <Typography
              sx={{
                color: "#1C1C1C",
                fontFamily: "myThirdFont",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Status unknown ({stationCounts.unknown})
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default StationMap;
