import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import MainHeading from "../../../utils/MainHeading";

const DoughnutCharHub = ({ data, passType, title }) => {
  // Existing data for frequent customers
  const totalUser =
    data?.hubChargingSessionsStats?.frequentCustomersPrecentage
      ?.allUsersCount || 0;
  const lessThanTwoVisits =
    data?.hubChargingSessionsStats?.frequentCustomersPrecentage
      ?.lessThanTwoVisits || 0;
  const moreThanTwoVisits =
    data?.hubChargingSessionsStats?.frequentCustomersPrecentage
      ?.moreThanTwoVisits || 0;
  const moreThanSixVisits =
    data?.hubChargingSessionsStats?.frequentCustomersPrecentage
      ?.moreThanSixVisits || 0;
  const moreThanFourVisits =
    data?.hubChargingSessionsStats?.frequentCustomersPrecentage
      ?.moreThanFourVisits || 0;

  // Data for pass type customers
  const totalPassHolder =
    data?.hubCustomerStats?.overallHubCustomers?.passHoldersCount || 0;
  const passHoldersData = data?.hubCustomerStats?.hubCustomersByPassType || [];

  // Prepare data for pass type pie chart
  const segmentsForPassType =
    passHoldersData.length > 0
      ? passHoldersData.map((pass) => ({
          name: pass._id,
          value: pass.passHoldersCount,
          percentage: pass.percentage,
          color:
            pass._id === "12hr"
              ? "#69A5FF"
              : pass._id === "24hr"
              ? "#FF9F69"
              : pass._id === "1week"
              ? "#8D6AF0"
              : "#4CAF50", // Default to green for 1hr
        }))
      : [];

  // Prepare data for frequent customers pie chart
  const segmentsForFrequentCustomers =
    totalUser > 0
      ? [
          {
            name: "Less than 2 visits",
            value: lessThanTwoVisits,
            color: "#69A5FF",
            percentage:
              data?.hubChargingSessionsStats?.frequentCustomersPrecentage
                ?.lessThanTwoVisitsPrecent,
          },
          {
            name: "More than 2 visits",
            value: moreThanTwoVisits,
            color: "#8D6AF0",
            percentage:
              data?.hubChargingSessionsStats?.frequentCustomersPrecentage
                ?.moreThanTwoVisitsPrecent,
          },
          {
            name: "More than 4 visits",
            value: moreThanFourVisits,
            color: "#008000",
            percentage:
              data?.hubChargingSessionsStats?.frequentCustomersPrecentage
                ?.moreThanFourVisitsPrecent,
          },
          {
            name: "More than 6 Visits",
            value: moreThanSixVisits,
            color: "#FF9F69",
            percentage:
              data?.hubChargingSessionsStats?.frequentCustomersPrecentage
                ?.moreThanSixVisitsPrecent,
          },
        ]
      : [];

  const chartData = passType
    ? segmentsForPassType
    : segmentsForFrequentCustomers;
  const totalDisplayValue = passType ? totalPassHolder : totalUser;
  const centerText = passType ? "Total Pass Holders" : "Total Users";

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        padding: "8px 24px",
        borderRadius: "8px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <MainHeading text={title} />
      </Box>

      {totalDisplayValue > 0 ? (
        <Box sx={{ position: "relative" }}>
          <Box sx={{ height: 250 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={90}
                  innerRadius={80}
                  stroke="none"
                  startAngle={0}
                  endAngle={360}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top:
                chartData.length === 4
                  ? "40%"
                  : chartData.length === 1
                  ? "55%"
                  : chartData.length === 2
                  ? "45%"
                  : "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "24px",
                color: "#2F2F2F",
              }}
            >
              {centerText}
            </Typography>
            <Typography
              sx={{
                fontFamily: "myThirdFont",
                fontSize: "24px",
                color: "#2F2F2F",
                fontWeight: "600",
              }}
            >
              {totalDisplayValue}
            </Typography>
          </Box>

          {/* Display segments info */}
          {chartData.map((segment, index) => (
            <Box
              key={`segment-${index}`}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: segment.color,
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#2F2F2F",
                  fontFamily: "myThirdFont",
                  fontSize: "14px",
                  lineHeight: "20px",
                  display: "flex",
                }}
              >
                {segment.name} {passType ? "Pass Holders" : ""} : -
                <Typography color={"#2F2F2F"} fontFamily={"mySecondFont"}>
                  {segment.value} - {`(${segment.percentage}%)`}
                </Typography>
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            color: "#666",
            fontFamily: "myThirdFont",
            fontSize: "16px",
          }}
        >
          No data available.
        </Typography>
      )}
    </Box>
  );
};

export default DoughnutCharHub;
