import { Box } from "@mui/material";
import React from "react";

const UnlockConnector = ({ onInputChange, formValues }) => {
  const handleChange = (event) => {
    onInputChange("connectorId", event.target.value);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <input
        style={{
          width: "50%",
          height: "48px",
          color: "#1C1C1C",
          fontFamily: "myThirdFont",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #ccc",
          padding: "4px 12px",
        }}
        value={formValues.connectorId || ""}
        placeholder="Enter Connector Id"
        onChange={handleChange}
        type="text"
      />
    </Box>
  );
};

export default UnlockConnector;
