import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Heading from "../../../utils/Heading";
import { Button, Divider, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  assignRFID,
  fetchRFIDDetails,
} from "../../../redux/action/RFID/rfidSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatDateTime } from "../../../utils/FormatDateTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 960,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const InfoBox = ({ label, value }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontFamily={"myThirdFont"} fontSize={"14px"} color={"#2F2F2F"}>
      {label}
    </Typography>
    <Typography fontSize={"16px"} fontFamily={"mySecondFont"} color={"#2F2F2F"}>
      {value}
    </Typography>
  </Box>
);

const RFIDUpdateModal = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState("ASSIGNED");
  const [validity, setValidity] = React.useState("Change Validity");

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeValidity = (event) => {
    setValidity(event.target.value);
  };

  const safeFormatDateTime = (dateString) => {
    if (!dateString) return { date: "N/A", time: "N/A" }; // Default value for invalid date
    const formattedDate = formatDateTime(dateString);
    return formattedDate || { date: "N/A", time: "N/A" };
  };

  const formattedRequestDate = safeFormatDateTime(data?.formDetails?.createdAt);
  const formattedAssignedDate = safeFormatDateTime(data?.assignedDate);
  const formattedValidityDate = safeFormatDateTime(data?.expiry_date);
  const formattedLastUpdateDate = safeFormatDateTime(data?.updatedAt);

  const handleAssignRFID = () => {
    const requestData = {
      rfid: data?.rfid,
      status: status,
      expiryYears: validity,
      customer_id: data?.customer?._id, // Add any other data you need
    };

    dispatch(assignRFID(requestData))
      .unwrap() // This allows you to handle success and error
      .then(() => {
        toast.success("RFID Updated Successfully");
        dispatch(fetchRFIDDetails());
        handleClose(); // Close the modal after successful assignment
      })
      .catch((error) => {
        console.error("Failed to assign RFID:", error);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="assign-rfid-modal"
      >
        <Box sx={style}>
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <span
                style={{ color: "#292D33", fontWeight: 300 }}
                className="material-symbols-outlined"
              >
                credit_card_gear
              </span>
              <Heading text="RFID card Status" />
            </Box>
            <Box onClick={handleClose}>
              <span
                style={{ fontSize: "32px", fontWeight: 300, cursor: "pointer" }}
                className="material-symbols-outlined"
              >
                cancel
              </span>
            </Box>
          </Box>
          <Divider sx={{ background: "#DDD", mt: "8px" }} />

          {/* Modal Content */}
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              alignItems: "center",
              mt: "24px",
            }}
          >
            <Box
              sx={{
                padding: "12px 24px",
                width: "364px",
                borderRadius: "4px",
                background:
                  "linear-gradient(0deg, rgba(2, 121, 0, 0.10) 0%, rgba(2, 121, 0, 0.10) 100%), #FFF",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"18px"}
                color={"#2f2f2f"}
              >
                Assigned RFID Serial No.
              </Typography>
              <Typography
                fontFamily={"mySecondFont"}
                fontSize={"24px"}
                color={"#027900"}
              >
                {data?.serialNumber}
              </Typography>
            </Box>

            {/* Select */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                fontSize={"14px"}
                color={"#2f2f2f"}
                fontFamily={"myThirdFont"}
              >
                RFID Status
              </Typography>
              <Box sx={{ minWidth: 260 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    onChange={handleChangeStatus}
                    sx={{ fontFamily: "myThirdFont" }}
                  >
                    <MenuItem
                      sx={{ fontFamily: "myThirdFont" }}
                      value={"ASSIGNED"}
                    >
                      Assigned
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "myThirdFont" }}
                      value={"ACTIVE"}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "myThirdFont" }}
                      value={"INACTIVE"}
                    >
                      Inactive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {/* validity */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                fontSize={"14px"}
                color={"#2f2f2f"}
                fontFamily={"myThirdFont"}
              >
                RFID Validity
              </Typography>
              <Box sx={{ minWidth: 260 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={validity}
                    onChange={handleChangeValidity}
                    sx={{ fontFamily: "myThirdFont" }}
                  >
                    <MenuItem
                      sx={{ fontFamily: "myThirdFont" }}
                      value={"Change Validity"}
                    >
                      Change Validity
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={1}>
                      1 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={2}>
                      2 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={3}>
                      3 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={4}>
                      4 Year
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "myThirdFont" }} value={5}>
                      5 Year
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "28px",
              mt: "24px",
            }}
          >
            {/* First Row */}
            <InfoBox label="Customer Name" value={data?.customer?.name} />
            <InfoBox label="Mobile Number" value={data?.customer?.phone} />
            <InfoBox label="Organization" value={"1C"} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#2F2F2F"}
              >
                Request date
              </Typography>
              <Box>
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#2F2F2F"}
                  lineHeight={"16px"}
                >
                  {formattedRequestDate?.date}
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                  color={"#666"}
                  lineHeight={"16px"}
                >
                  {formattedRequestDate?.time}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: "28px",
              mt: "32px",
            }}
          >
            {/* Second Row */}
            <InfoBox label="Request ID" value={data?.formDetails?._id} />
            <InfoBox label="Pin code" value={data?.formDetails?.pincode} />
            <InfoBox label="City/ Town" value={data?.formDetails?.city} />
            <InfoBox label="State" value={data?.formDetails?.state} />
            <InfoBox label="Country" value={data?.formDetails?.country} />
          </Box>
          {/* Address Row */}
          <Box sx={{ display: "flex", flexDirection: "column", mt: "24px" }}>
            <InfoBox label="Full address" value={data?.formDetails?.address} />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: "28px",
              mt: "24px",
            }}
          >
            <InfoBox label="Assigned by" value={data?.assignedBy?.name} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#2F2F2F"}
              >
                Assigned date
              </Typography>
              <Box>
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#2F2F2F"}
                  lineHeight={"16px"}
                >
                  {formattedAssignedDate?.date}
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                  color={"#666"}
                  lineHeight={"16px"}
                >
                  {formattedAssignedDate?.time}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#2F2F2F"}
              >
                Validity
              </Typography>
              <Box>
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#2F2F2F"}
                  lineHeight={"16px"}
                >
                  {formattedValidityDate?.date}
                </Typography>
              </Box>
            </Box>
            <InfoBox
              label="Last updated by"
              value={data?.lastUpdatedBy?.name}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
                color={"#2F2F2F"}
              >
                Last status update
              </Typography>
              <Box>
                <Typography
                  fontSize={"16px"}
                  fontFamily={"mySecondFont"}
                  color={"#2F2F2F"}
                  lineHeight={"16px"}
                >
                  {formattedLastUpdateDate?.date}
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontFamily={"myThirdFont"}
                  color={"#666"}
                  lineHeight={"16px"}
                >
                  {formattedLastUpdateDate?.time}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              mt: "24px",
              gap: "16px",
              mt: "42px",
            }}
          >
            <Button
              sx={{
                padding: "8px 24px",
                borderRadius: "4px",
                background: "#E50000",
                fontFamily: "'Montserrat', sans-serif",
                textTransform: "capitalize",
                color: "#fff",
                fontSize: "16px",
                width: "200px",
                ":hover": { background: "#E50000" },
              }}
              onClick={handleAssignRFID}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RFIDUpdateModal;
