import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import OcpiMainGraphBox from "./OcpiMainGraphBox";

const RegisterInfoBox = ({ handshakeStatus }) => {
  const [registered, setRegistered] = useState(false);
  console.log("cccccxxx", handshakeStatus?.handshake);

  const handleRegisterClick = () => {
    setRegistered(true);
  };

  return (
    <Box>
      {handshakeStatus?.handshake ? (
        <OcpiMainGraphBox />
      ) : (
        <Box
          sx={{
            padding: "24px",
            background: "#fff",
            borderRadius: "8px",
            alignItems: "center",
            gap: "24px",
            width: "100%",
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item md={12} sx={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "48px",
                  padding: "24px 0",
                  width: "100%",
                }}
              >
                <Box>
                  <img src="/images/register_img.png" alt="" />
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "'Montserrat', sans-serif",
                        color: "#0C0C0C",
                        fontWeight: "600",
                        fontSize: "32px",
                        lineHeight: "40px",
                      }}
                    >
                      Not yet registered!
                    </Typography>
                    <Box
                      sx={{
                        width: "400px",
                        textAlign: "left",
                        fontFamily: "myThirdFont",
                        color: "#2F2F2F",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                      }}
                    >
                      <Typography sx={{ textAlign: "left" }}>
                        Please initiate OCPI Handshake to view the Analytics of
                        this Charging Network.
                      </Typography>
                    </Box>

                    {/* <Button
                      onClick={handleRegisterClick}
                      sx={{
                        borderRadius: "4px",
                        padding: "8px 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#E50000",
                        width: "200px",
                        color: "#fff",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginTop: "8px",
                        ":hover": {
                          background: "#E50000",
                        },
                      }}
                    >
                      Register Now
                    </Button> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default RegisterInfoBox;
