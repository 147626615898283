import { Box, Typography } from "@mui/material";
import React from "react";

const MainHeading = ({ text }) => {
  return (
    <Box>
      <Typography
        color={"#1C1C1C"}
        fontSize={"clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem)"}
        fontFamily="'Montserrat', sans-serif"
        fontWeight={600}
        textAlign={"start"}
        textTransform={"capitalize"}
        sx={{ textWrap: "nowrap" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default MainHeading;
