import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { changeAvalaible } from "../../../../utils/DummyData";

const TriggerMessage = ({ onInputChange, formValues }) => {
  const handleChange = (event) => {
    onInputChange("triggerMessage", event.target.value);
  };

  const handleChangeType = (event) => {
    onInputChange("connectorId", event.target.value);
  };
  return (
    <Box
      sx={{ display: "flex", gap: "16px", alignItems: "center", width: "100%" }}
    >
      <FormControl sx={{ width: "100%" }}>
        <InputLabel
          sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
          id="demo-simple-select-label"
        >
          Select Trigger Message
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formValues.triggerMessage || ""}
          label="Select Trigger Message"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            //   padding: "8px 16px",
            width: "100%",
            height: "48px",
            color: "#1C1C1C",
            fontFamily: "mySecondFont",
          }}
        >
          {changeAvalaible.map((item) => {
            return (
              <MenuItem
                sx={{ fontSize: "14px", fontFamily: "myThirdFont" }}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <input
        style={{
          width: "100%",
          height: "48px",
          color: "#1C1C1C",
          fontFamily: "myThirdFont",
          outline: "none",
          borderRadius: "4px",
          border: "1px solid #ccc",
          padding: "4px 12px",
        }}
        value={formValues.connectorId || ""}
        placeholder="Enter Connector Id"
        onChange={handleChangeType}
        type="text"
      />
    </Box>
  );
};

export default TriggerMessage;
